import { createSlice } from '@reduxjs/toolkit';

export const benefitsFinderSlice = createSlice({
  name: 'benefitsFinder',
  initialState: {
    results: {},
  },
  reducers: {
    updateKey: (state, action) => {
      const { key, value } = action.payload;
      state.results[key] = value;
    },
    resetSurvey: (state) => {
      state.results = {};
    },
    removeKey: (state, action) => {
      const objCopy = { ...state.results };
      delete objCopy[action.payload];
      state.results = objCopy;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateKey, removeKey, resetSurvey } = benefitsFinderSlice.actions;

export default benefitsFinderSlice.reducer;
