/* API */
import { postProfile } from 'lib/API';

/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useDispatch } from 'react-redux';
import { setCareers } from 'app/slices/careersSlice';
import { setJobs } from 'app/slices/jobsSlice';
import { setTraining } from 'app/slices/trainingSlice';
import { setExperienceSkills, setReloadPending, setExperienceForm } from 'app/slices/experienceSlice';
import { setLoadingRecommendations } from 'app/slices/appSlice';
import { useSnackbar } from 'notistack';

const useUpdateRecs = () => {
  const { locale } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleUpdateRecommendations = () => {
    dispatch(setLoadingRecommendations(true));
    postProfile({ action: 'recommend' })
      .then((regen_response) => {
        dispatch(setCareers(regen_response.careers.value));
        dispatch(setJobs(regen_response.jobs.value));
        dispatch(setTraining(regen_response.training.value));
        dispatch(setExperienceSkills(regen_response.skills.value));
        dispatch(setExperienceForm({ ...regen_response.experience.value, language: locale }));
        dispatch(setLoadingRecommendations(false));
        dispatch(setReloadPending(false));
      })
      .catch(function (error) {
        dispatch(setLoadingRecommendations(false));
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  return handleUpdateRecommendations;
};

export default useUpdateRecs;
