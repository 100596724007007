import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import vars from './vars.module.scss';

const theme = createTheme({
  typography: {
    fontFamily: vars.fontFamily,
    h1: {
      fontFamily: 'Source Serif Pro',
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 700,
      color: vars.black,
    },

    h2: {
      fontSize: 40,
      fontWeight: 600,
    },

    h3: {
      fontSize: 18,
      lineHeight: '27px',
      fontWeight: 400,
      color: vars.black,
    },

    h4: {
      fontFamily: 'Source Serif Pro',
      fontSize: 20,
      lineHeight: '27px',
      fontWeight: 700,
      color: vars.black,
    },

    h5: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 700,
      color: vars.darkGray,
    },
    h6: {
      fontSize: 18,
    },

    body1: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 400,
    },

    body2: {
      fontSize: 16,
      lineHeight: '24px',
      color: vars.darkGray,
    },
    caption: {
      fontSize: 12,
      color: vars.darkGray,
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 700,
    },
  },
  shadows: ['none'],
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: 700,
          borderRadius: 0,
          boxShadow: 'none',
          // height: 31,
          // '@media (max-width: 479px)': {
          padding: '5px 22px !important',
          // },
        },
        contained: {
          boxShadow: 'none',
        },
        containedPrimary: {
          color: vars.white,
        },
        containedSecondary: {
          color: vars.darkGray,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: vars.lightGray,
        },
        input: {
          padding: '17px 11px 9px',
          fontSize: 16,
          '@media (min-width: 640px)': {
            fontSize: 12,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: vars.mediumGray,
          },
          '&:hover:not(.Mui-focused):before': {
            borderBottomColor: vars.mediumGray,
          },
          '&:after': {
            // focused
            borderBottomColor: vars.actionBlue,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingRight: 0,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          height: 11,
          width: 11,
        },
        track: {
          height: 2,
        },
        rail: {
          color: '#007041',
          height: 2,
        },
        mark: {
          width: 2,
          height: 2,
        },
        markLabel: {
          fontSize: 14,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 16,
          width: '100%',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
        formControl: {
          top: -5,
        },
        filled: {
          color: vars.darkGray,
          '&.Mui-focused': {
            color: vars.actionBlue,
          },
          '.MuiFormControl-root:hover &:not($focused)': {
            color: vars.darkGray,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: vars.mediumGray,
          '&.Mui-focused': {
            color: vars.black,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: grey['900'],
          fontSize: 9,
          margin: 10,
          padding: '0',
          textAlign: 'right',
          position: 'absolute',
          top: '100%',
          right: 0,
        },
        contained: {
          margin: '0 10px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          paddingRight: '0',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.75rem',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingTop: '10px !important;',
        },
        option: {
          fontSize: 14,
        },
        input: {
          fontSize: 16,
          '@media (min-width: 640px)': {
            fontSize: 12,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: vars.primaryColorMain,
      dark: vars.primaryColorDark,
    },
    secondary: {
      main: vars.secondaryColorMain,
      dark: vars.secondaryColorDark,
    },
    accent: {
      main: vars.accentColorMain,
    },
    error: {
      main: vars.errorColorMain,
    },
    background: {
      default: vars.backgroundColorDefault,
      light: vars.backgroundColorLight,
    },
  },
});

export default theme;
