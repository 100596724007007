import PropTypes from 'prop-types';
import { css } from '@emotion/css';
import { propOr } from 'ramda';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { updateKey } from 'app/slices/benefitsFinderSlice';

/* i18n */
import { useIntl } from 'react-intl';

/* Assets */
import info from 'assets/images/Info_Cards.svg';

/* Styles */
import cssVars from 'styles/vars.module.scss';
import withStyles from '@mui/styles/withStyles';

/* Material UI and other UI Dependencies */
import { FormControl, FormControlLabel, Checkbox, Tooltip } from '@mui/material';

const styles = {
  title: css(
    {
      fontSize: '18px',
    },
    { label: 'title' }
  ),
  formControlRoot: css({
    display: 'block !important',
  }),
  checkBoxRoot: css(
    {
      transform: 'scale(1.5)',
      padding: '2px 9px 0 12px !important',
    },
    { label: 'checkbox-root' }
  ),
  error: css({
    color: cssVars.errorColorMain,
    fontSize: 15,
  }),
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: cssVars.white,
    color: cssVars.black,
    fontSize: 13,
    border: `2px solid ${cssVars.actionBlue}`,
    borderRadius: 0,
    position: 'absolute',
    left: -12,
    width: '360px',
    maxWidth: '500px',
    '& h2': {
      fontSize: '15px',
    },
    '@media (min-width: 640px)': {
      width: '640px',
      fontSize: 14,
    },
  },
  arrow: {
    color: cssVars.actionBlue,
    '&::before': {
      borderTopColor: cssVars.actionBlue,
    },
  },
}))(Tooltip);

export default function MultiSelectQuestion({ name, title, options, touched, error }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const benefitsResults = useSelector((state) => state.benefitsFinder.results);
  const values = propOr([], name, benefitsResults);

  const addVal = (newVal) => {
    const newValues = new Set(values);
    newValues.add(newVal);
    dispatch(updateKey({ key: name, value: Array.from(newValues) }));
  };

  const removeVal = (newVal) => {
    const newValues = new Set(values);
    newValues.delete(newVal);
    dispatch(updateKey({ key: name, value: Array.from(newValues) }));
  };
  return (
    <div>
      <h2 className={styles.title}>{title}</h2>
      <div>
        {options.map((option) => {
          return (
            <FormControl key={option.value} classes={{ root: styles.formControlRoot }}>
              <FormControlLabel
                labelPlacement="end"
                classes={{ root: styles.formControlLabelRoot, label: styles.formControlLabeLabel }}
                label={option.text}
                control={
                  <Checkbox
                    className={styles.checkBoxRoot}
                    checked={values.includes(option.value)}
                    color="primary"
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        addVal(option.value);
                      } else {
                        removeVal(option.value);
                      }
                    }}
                    name={name}
                  />
                }
              />
              {option.tooltipTitle && option.tooltipBody ? (
                <HtmlTooltip
                  arrow
                  enterTouchDelay={0}
                  title={
                    <div>
                      <h2>{option.tooltipTitle}</h2>
                      <p>{option.tooltipBody}</p>
                    </div>
                  }
                >
                  <img
                    alt="Info"
                    src={info}
                    className={css({
                      position: 'relative',
                      top: '1px',
                      width: '15px',
                      height: '15px',
                      cursor: 'pointer',
                    })}
                  />
                </HtmlTooltip>
              ) : null}
            </FormControl>
          );
        })}
        {touched && error ? <div className={styles.error}>{intl.formatMessage({ id: error })}</div> : null}
      </div>
    </div>
  );
}

MultiSelectQuestion.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};
