import { useEffect, useMemo } from 'react';
import { css } from '@emotion/css';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Chip } from '@mui/material';
import { difference } from 'ramda';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { useSkills } from 'shared/hooks';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setSkillsDict, setSkills } from 'app/slices/skillsSlice';

/* Styles */
import cssVars from 'styles/vars.module.scss';
import CheckIcon from '@mui/icons-material/Check';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const CreateAutocomplete = (locality) =>
  withStyles({
    tag: {
      '& .MuiChip-label': {
        color: locality === 'ar' ? cssVars.white : cssVars.darkGray,
        fontWeight: 700,
        fontSize: '10px',
        textTransform: 'uppercase',
      },
      '& .MuiChip-deleteIcon': {
        width: '14px',
        height: '14px',
        ...(locality === 'ar'
          ? {
              color: 'rgb(250 250 250 / 40%) !important',
              '&:hover': {
                color: 'rgb(250 250 250 / 60%) !important',
              },
            }
          : {}),
      },
    },
    endAdornment: {
      top: 'initial',
      bottom: '9px',
    },
    input: css({ flex: '0 1 100%' }),
    paper: css({
      border: `solid 1px ${cssVars.mediumGray}`,
      margin: '0 !important',
      borderRadius: '0 !important',
    }),
  })(Autocomplete);

export default function SkillsAutocompleteV2({
  isDisabled,
  addSkillHandler,
  removeSkillHandler,
  activeSkills,
  onboarding = false,
}) {
  const locality = process.env.REACT_APP_LOCALITY;
  const intl = useIntl();
  const dispatch = useDispatch();
  const { skills } = useSelector((state) => state.skills);
  const skillsQuery = useSkills();
  const CustomAutocomplete = useMemo(() => CreateAutocomplete(locality), [locality]);
  console.log('hey the skills are', skills);
  console.log('hey the active skills are', activeSkills);
  useEffect(() => {
    if (skillsQuery.isSuccess) {
      dispatch(setSkills(skillsQuery.data));
      const skillsDict = skillsQuery.data.reduce((skDict, skill) => {
        return { ...skDict, [skill.id]: skill.name };
      }, {});
      dispatch(setSkillsDict(skillsDict));
    }
  }, [skillsQuery.isSuccess, skillsQuery.data, skillsQuery.status]);

  return (
    <CustomAutocomplete
      multiple
      disableClearable
      disableCloseOnSelect={true}
      id={'add-skills'}
      options={skills}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disabled={isDisabled}
      value={activeSkills}
      // getOptionDisabled={(option) => { // For accessibility reasons this was enabled again
      //   return activeSkills.some((skill) => skill.id === option.id);
      // }}
      onChange={(_, newValue) => {
        const oldSkills = activeSkills.map((s) => s.id);
        const newSkills = newValue.map((s) => s.id);
        if (newValue.length < oldSkills.length) {
          const removedKey = difference(oldSkills, newSkills)[0];
          const removedSkill = activeSkills.reduce((acc, sk) => (sk.id === removedKey ? sk : acc), {});
          removeSkillHandler(removedSkill);
        } else {
          const addedKey = difference(newSkills, oldSkills)[0];
          const newSkill = newValue.reduce((acc, sk) => (sk.id === addedKey ? sk : acc), {});
          addSkillHandler(newSkill);
          if (onboarding) {
            trackEvent('ONBOARDING_SKILLS_ENTRY');
          }
        }
      }}
      renderOption={(props, option, { selected }) => (
        <div {...props} style={{ display: 'flex', alignItems: 'center', margin: '2px 0', position: 'relative' }}>
          {selected ? <CheckIcon fontSize="small" style={{ position: 'absolute', left: 11 }} /> : null}
          <span style={{ paddingLeft: 20 }}>{option.name}</span>
        </div>
      )}
      renderTags={(value, getTagProps) => (
        <div style={{ width: '100%' }}>
          {value.map((option, index) => (
            <Chip key={option.id} variant="outlined" label={option.name} {...getTagProps({ index })} />
          ))}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={activeSkills.length ? '' : intl.formatMessage({ id: 'forms.onboarding.education.skillsLabel' })}
          placeholder={intl.formatMessage({ id: 'forms.onboarding.education.skillsLabel' })}
          inputProps={{
            ...params.inputProps,
            'aria-label': 'skills',
          }}
        />
      )}
    />
  );
}

SkillsAutocompleteV2.propTypes = {
  isDisabled: PropTypes.bool,
  addSkillHandler: PropTypes.func,
  removeSkillHandler: PropTypes.func,
  activeSkills: PropTypes.arrayOf(PropTypes.object),
  onboarding: PropTypes.bool,
};
