import { css } from '@emotion/css';
import { useHistory } from 'react-router-dom';

/* Styles */
import cssVars from 'styles/vars.module.scss';

const styles = {
  footerLink: css({
    color: cssVars.white,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:visited': {
      color: cssVars.white,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '&:hover': {
      color: cssVars.lightGray,
      cursor: 'pointer',
    },
    '&:active': {
      color: cssVars.mediumGray,
    },
  }),
};

const ExperienceSavedNotificationContents = ({ intl, history }) => {
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  const handleRedirect = (route) => {
    history.push(`${urlPrefix}/${route}`);
  };
  return (
    <>
      {intl.formatMessage({ id: 'experience.changesSaved1' })}&nbsp;
      <span className={styles.footerLink} onClick={() => handleRedirect('careers')}>
        {intl.formatMessage({ id: 'layout.menu.careers' })}
      </span>
      ,&nbsp;
      <span className={styles.footerLink} onClick={() => handleRedirect('jobs')}>
        {intl.formatMessage({ id: 'layout.menu.jobs' })}
      </span>
      &nbsp;{intl.formatMessage({ id: 'experience.changesSaved2' })}&nbsp;
      <span className={styles.footerLink} onClick={() => handleRedirect('training')}>
        {intl.formatMessage({ id: 'layout.menu.training' })}
      </span>
      &nbsp;
      {intl.formatMessage({ id: 'experience.changesSaved3' })}
    </>
  );
};

export default ExperienceSavedNotificationContents;
