import { createSlice } from '@reduxjs/toolkit';

export const jobCoachSlice = createSlice({
  name: 'coach',
  initialState: {
    results: [],
    pageSize: 5,
    page: 0,
    loading: false,
    query: '',
  },
  reducers: {
    setResults: (state, action) => {
      const resultsCopy = action.payload.filter((x) => x.result_type !== 'Video');
      const mySet1 = new Set();
      resultsCopy.forEach((x) => mySet1.add(x.result_type));
      console.log('set', mySet1);
      resultsCopy.sort(function compare(a, b) {
        if (parseFloat(a) && parseFloat(b) && a < b) {
          return -1;
        }
        if (parseFloat(b) && !parseFloat(a)) {
          return -1;
        }
        if (parseFloat(a) && !parseFloat(b)) {
          return 1;
        }
        if (parseFloat(a) && parseFloat(b) && a > b) {
          return 1;
        }

        return 0;
      });
      state.results = resultsCopy;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setResults, setPage, setLoading, setQuery } = jobCoachSlice.actions;

export default jobCoachSlice.reducer;
