import { createSlice } from '@reduxjs/toolkit';

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    results: {},
    pageSize: 10,
    page: 0,
    currentSoc: '',
    activeSoc: '',
    activeCareer: {},
    availableFilters: ['careerPathName', 'nearby', 'partTime'],
    activeFilters: ['careerPathName'],
    tagsActive: '',
    keywordFilter: '',
    filterOptions: {
      minSalary: 0,
      maxSalary: 100000,
      locations: [],
      careerPaths: [],
      minDistance: 0,
      maxDistance: 100,
    },
    salaryFilterValues: [0, 0],
    locationsFilterValues: [],
    careerPathsFilterValues: [],
    distanceFilterValue: 100,
    datePostedFilterValue: 'anyTime',
    zipcodeFilterValue: '',
  },
  reducers: {
    setJobs: (state, action) => {
      state.results = action.payload;
    },
    setActiveSoc: (state, action) => {
      state.activeSoc = action.payload;
    },
    setActiveCareer: (state, action) => {
      state.activeCareer = action.payload;
    },
    setCurrentSoc: (state, action) => {
      state.currentSoc = action.payload;
    },
    setActiveFilters: (state, action) => {
      state.activeFilters = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setTagsActive: (state, action) => {
      state.tagsActive = action.payload;
    },
    setKeywordFilter: (state, action) => {
      state.keywordFilter = action.payload;
    },
    setFilterOptions: (state, action) => {
      state.filterOptions = action.payload;
    },
    setSalaryFilterValues: (state, action) => {
      state.salaryFilterValues = action.payload;
    },
    setLocationsFilterValues: (state, action) => {
      state.locationsFilterValues = action.payload;
    },
    setCareerPathsFilterValues: (state, action) => {
      state.careerPathsFilterValues = action.payload;
    },
    setDistanceFilterValue: (state, action) => {
      state.distanceFilterValue = action.payload;
    },
    setDatePostedFilterValue: (state, action) => {
      state.datePostedFilterValue = action.payload;
    },
    setZipcodeFilterValue: (state, action) => {
      state.zipcodeFilterValue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setJobs,
  setActiveSoc,
  setCurrentSoc,
  setActiveFilters,
  setPage,
  setTagsActive,
  setActiveCareer,
  setKeywordFilter,
  setFilterOptions,
  setSalaryFilterValues,
  setLocationsFilterValues,
  setCareerPathsFilterValues,
  setDistanceFilterValue,
  setDatePostedFilterValue,
  setZipcodeFilterValue,
} = jobsSlice.actions;

export default jobsSlice.reducer;
