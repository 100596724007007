import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getRecommendedTrainings, updateTrainings } from '../../API';

const getTrainingsQueryKey = (filters) => [{ entity: 'trainings', ...filters }];

const paginatedTrainingsQueryFn = async ({ queryKey, pageParam = 0 }) => {
  const { filter_by_name, filter_by_county, filter_by_soc, distance_max, duration_min, duration_max, zipcode } =
    queryKey[0];

  const data = await getRecommendedTrainings([], {
    limit: 10,
    offset: pageParam * 10,
    ...(filter_by_name && filter_by_name.length ? { filter_by_name } : {}),
    ...(filter_by_soc && filter_by_soc.length ? { filter_by_soc: filter_by_soc.map((c) => c.soc).join('|') } : {}),
    ...(filter_by_county && filter_by_county.length ? { filter_by_county: filter_by_county.join('|') } : {}),
    ...(distance_max !== 100 && zipcode?.length === 5 ? { distance_max, zipcode } : {}),
    ...(duration_min > 0 ? { duration_min } : {}),
    ...(duration_max > 0 ? { duration_max } : {}),
  });

  return data;
};

export const useInfiniteTrainings = (filters) => {
  return useInfiniteQuery({
    queryFn: paginatedTrainingsQueryFn,
    retry: false,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length < 10) {
        return undefined;
      }
      return pages.flat(1).length / 10;
    },
    queryKey: getTrainingsQueryKey(filters),
  });
};

export const useRateTraining = (filters) => {
  const queryClient = useQueryClient();
  const queryKey = getTrainingsQueryKey(filters);

  return useMutation({
    mutationFn: ({ training, rating }) => {
      return updateTrainings(training, rating);
    },
    onMutate: async ({ training, rating }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousTrainings = queryClient.getQueryData(queryKey);

      // Optimistically update with the new rating
      queryClient.setQueryData(queryKey, (old) => {
        const clone = structuredClone(old);
        const index = clone.pages.flat(1).findIndex((oldTraining) => oldTraining.id === training.id);

        clone.pages.flat(1)[index].rating = rating;

        return clone;
      });

      // Return a context object with the snapshotted value
      return { previousTrainings };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context.previousTrainings);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
