import { css, cx } from '@emotion/css';
import { useState, useEffect } from 'react';
import { equals, propOr } from 'ramda';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { TextField, Button, useMediaQuery } from '@mui/material';
import { PhoneNumberInput } from 'shared/components';
import ZipcodeInput from 'components/inputs/ZipcodeInput';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
//import { updateUserData, updateExperience } from 'lib/API';
import { updateUserData } from '../../lib/API/user';

/* Styles */
import cssVars from 'styles/vars.module.scss';

/* Redux */
import { useDispatch } from 'react-redux';
import { setEducation } from 'app/slices/onboardingSlice';
import { setPreferredName } from 'app/slices/appSlice';
import { setZipcode } from 'app/slices/experienceSlice';

/* Utils */
import { preventSubmitOnEnter } from 'shared/utils';

const makeStyles = () => ({
  emailHelperText: css({
    fontSize: 12,
    color: cssVars.darkGray,
    position: 'relative',
    top: -8,
    paddingBottom: 4,
  }),
  emailHelperTextError: css({
    paddingTop: 6,
  }),
  textFieldWhite: css(
    {
      backgroundColor: `${cssVars.white} !important`,
      borderRadius: '3px 3px 0 0 !important',
      input: {
        backgroundColor: cssVars.white,
        borderRadius: '4px 4px 0 0',
      },
    },
    { label: 'text-field-white' }
  ),
  submitBtn__container: css(
    {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 16,
    },
    { label: 'submit-button__container' }
  ),
  formContainer: css(
    {
      maxWidth: '568px',
      margin: '0 auto',
    },
    { label: 'form-container' }
  ),
  formBorder: css(
    {
      border: `solid 1px ${cssVars.midLightGray}`,
      padding: '8px',
      position: 'relative',
      boxSizing: 'border-box',
      minHeight: '301px',
      '@media screen and (min-width: 672px)': {
        padding: '1.15em',
        paddingBottom: '1em',
      },
    },
    { label: 'form-border' }
  ),
});

export default function ContactForm({
  classNames,
  submitCallback = () => {},
  submitLabel,
  valueChanged = () => {},
  choseToStay,
  validZipCodes,
  initialValues: defaultValues,
  state = 'Wisconsin',
}) {
  const locality = process.env.REACT_APP_LOCALITY;
  const intl = useIntl();
  const dispatch = useDispatch();

  //eslint-disable-next-line
  const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const emailRegExp = /^\S+@\S+\.\w\w+$/;
  const validationSchema = yup.object({
    first_name: yup
      .string('forms.onboarding.firstNameValidation')
      .min(2, 'forms.onboarding.firstNameMinLength')
      .max(50, 'forms.onboarding.firstNameMaxLength')
      .required('forms.onboarding.firstNameRequired'),
    last_name: yup
      .string('forms.onboarding.lastNameValidation')
      .min(2, 'forms.onboarding.lastNameMinLength')
      .max(50, 'forms.onboarding.lastNameMaxLength')
      .required('forms.onboarding.lastNameRequired'),
    email: yup
      .string('forms.onboarding.emailRequired')
      .matches(emailRegExp, 'forms.onboarding.emailValidation')
      .required('forms.onboarding.emailRequired'),
    ...(!['nj', 'co'].includes(locality) && {
      phone_number: yup
        .string('forms.onboarding.phoneNumber')
        .matches(phoneRegExp, 'forms.onboarding.exactlyXDigits')
        .required('forms.onboarding.phoneNumberRequired'),
    }),
    zipcode: yup
      .string('forms.onboarding.zipcode')
      .matches(/^[0-9]+$/, 'forms.onboarding.onlyDigits')
      .oneOf(
        validZipCodes,
        intl.formatMessage(
          {
            id: 'training.filters.zipcodeFromStateValidation',
          },
          { state }
        )
      )
      .min(5, 'forms.onboarding.exactlyXDigits')
      .max(5, 'forms.onboarding.exactlyXDigits'),
  });

  const initialValues = {
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    zipcode: '',
    ...defaultValues,
  };
  if (!initialValues.zipcode) {
    initialValues.zipcode = '';
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async ({ zipcode, first_name, last_name, email, phone_number }) => {
      setSubmitting(true);
      const valuesToSubmit = { first_name, last_name, email, phone_number, zipcode, preferred_name: first_name || '' };

      updateUserData(valuesToSubmit)
        .then(function () {
          dispatch(setPreferredName(first_name));
          dispatch(setZipcode(zipcode));
          setSubmitting(false);
          submitCallback();
        })
        .catch(function (error) {
          console.log(error.message);
          setSubmitting(false);
        });
    },
  });

  const [initialFormikValues, setInitialFormikValues] = useState({ ...formik.values });
  const [keysWithChanges, setKeysWithChanges] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const isSmall = useMediaQuery('(max-width:767px)');
  const styles = makeStyles();

  useEffect(() => {
    if (!formik.values.empty && initialFormikValues.empty) {
      setInitialFormikValues({ ...formik.values });
    }
  }, [formik.values]);

  const handleDiffs = (fieldName, evt) => {
    const initialValues = initialFormikValues;
    const currentValues = { ...formik.values, [fieldName]: evt.target.value };
    if (equals(initialValues, currentValues)) {
      setKeysWithChanges([]);
      valueChanged(false);
    } else {
      const differentKeys = [];
      for (const key in initialValues) {
        if (initialValues[key] !== currentValues[key]) {
          differentKeys.push(key);
        }
      }
      setKeysWithChanges(differentKeys);
      valueChanged(true);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        formik.handleSubmit(e);
        setInitialFormikValues({ ...formik.values });
        valueChanged(false);
        dispatch(setEducation({}));
        setKeysWithChanges([]);
      }}
      className={classNames}
    >
      <div className={cx({ [styles.formBorder]: true })}>
        <TextField
          FormHelperTextProps={{
            className: styles.helperText,
          }}
          error={
            (formik.touched.first_name && Boolean(formik.errors.first_name)) ||
            (choseToStay && keysWithChanges.includes('first_name'))
          }
          fullWidth
          helperText={
            formik.touched.first_name &&
            formik.errors.first_name &&
            intl.formatMessage({ id: formik.errors.first_name })
          }
          label={
            <>
              {intl.formatMessage({ id: 'forms.onboarding.firstName' })}
              <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
            </>
          }
          name="first_name"
          classes={{ root: styles.textFieldWhite }}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
            handleDiffs('first_name', e);
          }}
          onKeyDown={preventSubmitOnEnter}
          type="text"
          value={formik.values.first_name}
          variant="filled"
          inputProps={{
            title: intl.formatMessage({ id: 'forms.onboarding.firstName' }),
          }}
        />
        <TextField
          FormHelperTextProps={{
            className: styles.helperText,
          }}
          error={
            (formik.touched.last_name && Boolean(formik.errors.last_name)) ||
            (choseToStay && keysWithChanges.includes('last_name'))
          }
          fullWidth
          helperText={
            formik.touched.last_name && formik.errors.last_name && intl.formatMessage({ id: formik.errors.last_name })
          }
          label={
            <>
              {intl.formatMessage({ id: 'forms.onboarding.lastName' })}
              <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
            </>
          }
          name="last_name"
          classes={{ root: styles.textFieldWhite }}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
            handleDiffs('last_name', e);
          }}
          onKeyDown={preventSubmitOnEnter}
          type="text"
          value={formik.values.last_name}
          variant="filled"
          inputProps={{
            title: intl.formatMessage({ id: 'forms.onboarding.lastName' }),
          }}
        />
        <TextField
          FormHelperTextProps={{
            className: styles.helperText,
          }}
          error={
            (formik.touched.email && Boolean(formik.errors.email)) || (choseToStay && keysWithChanges.includes('email'))
          }
          fullWidth
          helperText={formik.touched.email && formik.errors.email && intl.formatMessage({ id: formik.errors.email })}
          label={
            <>
              {intl.formatMessage({ id: 'forms.signIn.username' })}
              <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
            </>
          }
          name="email"
          classes={{ root: styles.textFieldWhite }}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            formik.handleChange(e);
            handleDiffs('email', e);
          }}
          onKeyDown={preventSubmitOnEnter}
          type="text"
          value={formik.values.email}
          variant="filled"
          inputProps={{
            title: intl.formatMessage({ id: 'forms.signIn.username' }),
          }}
        />
        <div
          className={cx({
            [styles.emailHelperText]: true,
            [styles.emailHelperTextError]:
              (formik.touched.email && Boolean(formik.errors.email)) ||
              (choseToStay && keysWithChanges.includes('email')),
          })}
        >
          {intl.formatMessage({ id: 'experience.contact.emailHelper' })}
        </div>
        <TextField
          FormHelperTextProps={{
            className: styles.helperText,
          }}
          error={
            (formik.touched.phone_number && Boolean(formik.errors.phone_number)) ||
            (choseToStay && keysWithChanges.includes('phone_number'))
          }
          name="phone_number"
          classes={{ root: styles.textFieldWhite }}
          type="text"
          variant="filled"
          fullWidth
          helperText={
            formik.touched.phone_number &&
            formik.errors.phone_number &&
            intl.formatMessage({ id: formik.errors.phone_number }, { amount: 10 })
          }
          autoComplete="off"
          label={
            <>
              {intl.formatMessage({ id: 'forms.onboarding.phoneNumber' })}
              <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>
                {!['nj', 'co'].includes(locality) ? '*' : ''}
              </span>
            </>
          }
          value={formik.values.phone_number}
          onChange={(e) => {
            formik.handleChange(e);
            handleDiffs('phone_number', e);
          }}
          onKeyDown={preventSubmitOnEnter}
          onBlur={formik.handleBlur}
          InputProps={{
            inputComponent: PhoneNumberInput,
          }}
          inputProps={{
            title: intl.formatMessage({ id: 'forms.onboarding.phoneNumber' }),
          }}
        />
        <TextField
          FormHelperTextProps={{
            className: styles.helperText,
          }}
          error={
            (formik.touched.zipcode && Boolean(formik.errors.zipcode)) ||
            (choseToStay && keysWithChanges.includes('zipcode'))
          }
          name="zipcode"
          classes={{ root: styles.textFieldWhite }}
          type="text"
          variant="filled"
          fullWidth
          helperText={
            formik.touched.zipcode &&
            formik.errors.zipcode &&
            intl.formatMessage({ id: formik.errors.zipcode }, { amount: 5 })
          }
          autoComplete="off"
          label={intl.formatMessage({ id: 'forms.onboarding.zipcode' })}
          value={formik.values.zipcode}
          onChange={(e) => {
            formik.handleChange(e);
            handleDiffs('zipcode', e);
          }}
          onKeyDown={preventSubmitOnEnter}
          onBlur={formik.handleBlur}
          InputProps={{
            inputComponent: ZipcodeInput,
          }}
          inputProps={{
            title: intl.formatMessage({ id: 'forms.onboarding.zipcode' }),
          }}
        />
      </div>
      <div
        className={styles.submitBtn__container}
        style={{ display: 'flex', justifyContent: isSmall ? 'space-between' : 'flex-end' }}
      >
        <Button
          color={locality === 'nj' ? 'primary' : 'secondary'}
          disabled={submitting}
          size="large"
          type="submit"
          variant="contained"
        >
          {submitting ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">{intl.formatMessage({ id: 'forms.signIn.loading' })}</span>
            </div>
          ) : (
            submitLabel
          )}
        </Button>
      </div>
    </form>
  );
}

ContactForm.propTypes = {
  choseToStay: PropTypes.bool,
  classNames: PropTypes.string,
  submitCallback: PropTypes.func,
  valueChanged: PropTypes.func,
  submitLabel: PropTypes.string,
  state: PropTypes.string,
  validZipCodes: PropTypes.arrayOf(PropTypes.string),
  initialValues: PropTypes.object,
};
