import { useState } from 'react';
import { Popper } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from 'prop-types';

/* Styles */
import { css } from '@emotion/css';
import cssVars from 'styles/vars.module.scss';

import FiltersButton from './FiltersButton';

const styles = {
  paper: css(
    {
      backgroundColor: 'white',
      border: `solid 1px ${cssVars.mediumGray}`,
      borderRadius: '4px',
      position: 'relative',
      boxShadow: '3px 0px 15px 5px rgb(0 0 0 / 13%)',
      top: '6px',
    },
    { label: 'paper' }
  ),
  filtersContainer: css(
    {
      minWidth: 246,
      zIndex: 4,
    },
    { label: 'filters-container' }
  ),
};

export default function FiltersPopper({ children, open, setOpen = () => {} }) {
  const [anchorEl, setAnchorEl] = useState(null);
  //const [open, setOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(!open);
  };

  //const open = Boolean(anchorEl);
  const id = open ? 'filters-popper' : undefined;

  return (
    <div>
      <FiltersButton onClick={handleClick} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <Popper id={id} open={open} anchorEl={anchorEl} className={styles.filtersContainer} placement="bottom-end">
        <div>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={styles.paper}>{children}</div>
          </ClickAwayListener>
        </div>
      </Popper>
    </div>
  );
}

FiltersPopper.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.func,
  id: PropTypes.string,
  setOpen: PropTypes.func,
  onButtonClick: PropTypes.func,
};
