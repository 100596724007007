import { propOr } from 'ramda';

/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';
import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { useQuery } from '@tanstack/react-query';

/* Material UI and other UI Dependencies */
import Typography from '@mui/material/Typography';
import OnboardingSteps from 'components/OnboardingSteps';
import { Box, CircularProgress } from '@mui/material';

import { initialExperienceFormValues, getExperienceInitialValues, OnboardingExperienceForm } from 'shared/components';

export default function OnboardingExperience() {
  const experienceQuery = useQuery({
    queryKey: ['/users/me/experience/'],
    refetchOnMount: 'always',
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const intl = useIntl();

  return (
    <>
      <div className={styles.loginFlow__form__onboarding}>
        <Box px={3}>
          <Typography align="center" variant="h1" gutterBottom>
            {intl.formatMessage({ id: 'onboarding.resume.title', description: 'string' })}
          </Typography>
        </Box>
        <OnboardingSteps activeStep={1} />
      </div>
      <hr className={layoutStyles.separatorBold} />
      <div className={styles.onboardingExperience__cardsContainer}>
        {!experienceQuery.isLoading && !experienceQuery.isFetching ? (
          <OnboardingExperienceForm
            workHistory={{
              ...initialExperienceFormValues,
              ...getExperienceInitialValues(propOr({}, 'data', experienceQuery), 2),
            }}
            submitLabel={intl.formatMessage({ id: 'forms.onboarding.next' })}
            classNames={cn(styles.loginFlow__form, styles.signInFormNoStripe, onboardingStyles.formContainer)}
            has_experience={experienceQuery.isSuccess}
            onboarding
            showTitle
          />
        ) : (
          <Box m={2} pb={6} pt={4} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </div>
    </>
  );
}
