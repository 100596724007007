import { css } from '@emotion/css';

import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDropzone } from 'react-dropzone';
import { Alert, AlertTitle } from '@mui/material';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { connect } from 'formik';

/* Styles */
import cssVars from 'styles/vars.module.scss';

/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import Button from '@mui/material/Button';

/* Assets */
import upload from 'assets/images/Upload.svg';

// Styles using emotion
const styles = {
  dropZone: css(
    {
      padding: '26px 30px',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '250px',
      width: '100%',
      boxSizing: 'border-box',
      border: `dashed 1px ${cssVars.mediumGray}`,
      '&:hover': {
        backgroundColor: cssVars.lightGray,
        opacity: 0.5,
        cursor: 'pointer',
      },
    },
    { label: 'drop-zone' }
  ),
  uploadImage: css({
    width: '30px',
  }),
  list: css({
    padding: 0,
  }),
  lastUpdatedText: css(
    {
      span: {
        fontSize: 16,
      },
    },
    { label: 'last-updated-text' }
  ),
};

// These styles need values from theme so we used MUI's makeStyles
const useStyles = makeStyles((theme) => ({
  image: {
    width: 130,
  },
  info: {
    marginTop: 8,
  },
  actions: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: 16,
    },
  },
}));

const bytesToSize = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

function UploadResume({
  className,
  onDrop,
  handleClick = () => {},
  lastUpdated,
  showImage = true,
  showRecommended = true,
  ...props
}) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const intl = useIntl();
  const locality = process.env.REACT_APP_LOCALITY;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      // Read file contents and store base64 encoded data to
      // File object in 'contents' attribute
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          file.contents = reader.result;
          const tokens = file.name.split('.');
          let ext = '';
          if (tokens.length > 1) {
            file.ext = '.' + tokens.pop();
            ext = file.ext;
          }
          onDrop(reader.result, ext);
        };
        reader.readAsDataURL(file);
      });

      setFiles(acceptedFiles);

      const {
        field: { name },
        form: { setFieldValue },
      } = props;

      setFieldValue(name, files);
    },
    [props, onDrop]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections, maxSize } = useDropzone({
    onDrop: handleDrop,
    accept:
      'text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf',
    minSize: 0,
    maxSize: 10000000,
    maxFiles: 1,
    multiple: false,
  });

  const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].size > maxSize;

  const fileRejectionItems = fileRejections.map(({ file }) => (
    <Alert severity="error" key={file.path}>
      <AlertTitle>
        {file.path} - {file.size} bytes
      </AlertTitle>
      <Typography variant="body1" gutterBottom={0}>
        <FormattedMessage id="forms.enroll.fileFormats" defaultMessage="Must be a (.doc, .docx, .pdf, .txt) format" />
      </Typography>
    </Alert>
  ));

  return (
    <div className={cn(classes.root, className)} {...props}>
      <Box
        className={cn({
          [styles.dropZone]: true,
        })}
        {...getRootProps()}
        onClick={(e) => {
          getRootProps().onClick(e);
          handleClick();
        }}
        sx={{
          backgroundColor: (theme) => (isDragActive ? theme.palette.action.active : 'transparent'),
          opacity: isDragActive ? 0.5 : 1,
        }}
      >
        <input {...getInputProps()} title={intl.formatMessage({ id: 'experience.form.dragResume' })} />
        <div>
          <Box mb={2}>
            <Typography
              variant="h5"
              component="h2"
              align="center"
              {...(locality === 'nj' ? { style: { fontSize: 15 } } : {})}
            >
              <FormattedMessage id="experience.form.dragResume" />
            </Typography>
            {showRecommended ? (
              <Typography variant="body2" align="center" style={{ fontSize: 16 }}>
                <FormattedMessage id="experience.form.recommended" />
              </Typography>
            ) : null}
          </Box>
        </div>
        <div>
          {showImage ? (
            <img
              alt="Upload"
              src={upload}
              className={styles.uploadImage}
              {...(locality === 'co' ? {} : { style: { padding: '5px 0 7px' } })}
            />
          ) : null}
        </div>
        <Box style={{ marginTop: 10 }}>
          <Typography variant="body2" component="p" align="center" gutterBottom style={{ fontSize: 15 }}>
            <FormattedMessage id="experience.form.resumeInstructions" />
          </Typography>
          <Typography variant="body2" align="center" style={{ fontSize: 15 }}>
            <FormattedMessage id="experience.form.filesFormat" />
          </Typography>
          <Box mt={2} align="center">
            <Button
              color={locality === 'nj' ? 'primary' : 'secondary'}
              disabled={false}
              size="large"
              type="submit"
              variant="contained"
              tabIndex={0}
            >
              {`${intl.formatMessage({ id: 'experience.form.browse', description: 'string' })}${
                locality === 'nj' ? '…' : ''
              }`}
            </Button>
            {lastUpdated && !showImage ? (
              <Box mt={2}>
                <Typography variant="body2" align="center" style={{ fontSize: 14 }}>
                  {`${intl.formatMessage({ id: 'jobSearchActivity.lastUpdated' })}: ${lastUpdated}`}
                </Typography>
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Box>
      </Box>
      {files.length > 0 ? (
        <>
          <List className={styles.list}>
            {files.map((file, i) => (
              <ListItem divider={i < files.length - 1} key={i}>
                <ListItemIcon styles={{ textAlign: 'center' }}>
                  <FileCopyIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    root: {
                      span: {
                        fontSize: 16,
                      },
                    },
                  }}
                  primary={`${file.name}${lastUpdated ? ` - ${lastUpdated}` : ''}`}
                  primaryTypographyProps={{ variant: 'caption' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                  secondary={bytesToSize(file.size)}
                />
              </ListItem>
            ))}
          </List>
        </>
      ) : lastUpdated && showImage ? (
        <List className={styles.list}>
          <ListItem>
            <ListItemText
              classes={{
                root: {
                  span: {
                    fontSize: 16,
                  },
                },
              }}
              primary={`${intl.formatMessage({ id: 'jobSearchActivity.lastUpdated' })}: ${lastUpdated}`}
              primaryTypographyProps={{ variant: 'caption', align: 'center' }}
              secondaryTypographyProps={{ variant: 'caption' }}
            />
          </ListItem>
        </List>
      ) : (
        ''
      )}
      {isFileTooLarge && (
        <Alert severity="error">
          <FormattedMessage id="forms.enroll.uploadErrorTooLarge" defaultMessage="File is too large" />
        </Alert>
      )}
      {fileRejections && <div>{fileRejectionItems}</div>}
    </div>
  );
}

UploadResume.propTypes = {
  className: PropTypes.string,
  lastUpdated: PropTypes.string,
  onDrop: PropTypes.func,
  handleClick: PropTypes.func,
  field: PropTypes.object,
  form: PropTypes.object,
  showImage: PropTypes.bool,
  showRecommended: PropTypes.bool,
};

export default connect(UploadResume);
