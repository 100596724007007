import { useRef } from 'react';
import { Paper, IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import cssVars from 'styles/vars.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

export default function KeywordSearchInput({
  setKeywordFilter = () => {},
  keywordFilter = '',
  uncontrolled = false,
  inputRef = null,
}) {
  const intl = useIntl();

  const searchInputEl = inputRef || useRef(null);
  const handleMagnifierClick = () => {
    const element = searchInputEl.current;
    element.focus();
  };

  const props = uncontrolled ? { defaultValue: keywordFilter } : { value: keywordFilter };

  return (
    <Paper
      component="div"
      sx={{
        borderRadius: '0 !important',
        padding: '0px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: { sm: 190 },
        border: `solid 1px ${cssVars.mediumGray}`,
      }}
    >
      <IconButton
        sx={{
          padding: '7px 5px 7px 10px',
          '& span': {
            height: '24px',
            width: '24px',
          },
        }}
        aria-label="search"
        onClick={handleMagnifierClick}
        size="large"
      >
        <SearchIcon
          sx={{
            fontSize: 18,
            '@media screen and (max-width: 767px)': {
              fontSize: 22,
              position: 'relative',
              bottom: 1,
            },
          }}
        />
      </IconButton>
      <InputBase
        {...props}
        sx={{
          marginLeft: '0px',
          flex: 1,
          fontSize: { xs: 16, sm: 12 },
        }}
        onChange={(e) => setKeywordFilter(e.target.value)}
        placeholder={intl.formatMessage({ id: 'training.filters.keyword' })}
        inputProps={{
          ref: searchInputEl,
          'aria-label': intl.formatMessage({ id: 'training.filters.keyword' }),
          sx: {
            '&::placeholder': {
              color: 'rgba(0,0,0,0.87)',
              opacity: 1, // otherwise firefox shows a lighter color
            },
          },
        }}
      />
    </Paper>
  );
}
