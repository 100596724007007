import { createSlice } from '@reduxjs/toolkit';

export const trainingSlice = createSlice({
  name: 'training',
  initialState: {
    results: [],
    pageSize: 10,
    page: 0,
    keywordFilter: '',
    filterOptions: {
      minDuration: 0,
      maxDuration: 100000,
      locations: [],
      careerPaths: [],
      minDistance: 0,
      maxDistance: 100,
    },
    durationFilterValues: [0, 0],
    locationsFilterValues: [],
    careerPathsFilterValues: [],
    distanceFilterValue: 100,
    zipcodeFilterValue: '',
  },
  reducers: {
    setTraining: (state, action) => {
      state.results = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setKeywordFilter: (state, action) => {
      state.keywordFilter = action.payload;
    },
    setFilterOptions: (state, action) => {
      state.filterOptions = action.payload;
    },
    setDurationFilterValues: (state, action) => {
      state.durationFilterValues = action.payload;
    },
    setLocationsFilterValues: (state, action) => {
      state.locationsFilterValues = action.payload;
    },
    setCareerPathsFilterValues: (state, action) => {
      state.careerPathsFilterValues = action.payload;
    },
    setDistanceFilterValue: (state, action) => {
      state.distanceFilterValue = action.payload;
    },
    setZipcodeFilterValue: (state, action) => {
      state.zipcodeFilterValue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTraining,
  setPage,
  setKeywordFilter,
  setFilterOptions,
  setDurationFilterValues,
  setLocationsFilterValues,
  setCareerPathsFilterValues,
  setDistanceFilterValue,
  setZipcodeFilterValue,
} = trainingSlice.actions;

export default trainingSlice.reducer;
