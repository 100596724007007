/* Styles */
import styles from 'styles/financial-help-card.module.scss';
import PropTypes from 'prop-types';
import { css, cx } from '@emotion/css';

/* UI Elements */
import { Typography, Box } from '@mui/material';

/* Styles */
import cssVars from 'styles/vars.module.scss';
const localStyles = {
  card: css({
    border: `solid 1px ${cssVars.mediumGray}`,
  }),
};

export default function ResultsCard({
  title,
  description,
  toQualifyTitle,
  toQualifyItems,
  image,
  imageDescription,
  imageStyles,
  children,
}) {
  return (
    <article className={cx(styles.card, localStyles.card)}>
      <div className={styles.top}>
        <div className={styles.imageSection}>
          <div className={styles.imageWrapper}>
            {image ? <img src={image} alt={title} style={imageStyles} /> : null}
          </div>
          <span className={styles.imageDescription}>{imageDescription}</span>
        </div>
        <div className={styles.textSection}>
          <Typography variant="h4" component="h2" gutterBottom>
            {title}
          </Typography>
          <Box mb={1} />
          {toQualifyTitle ? <span className={styles.toQualifyTitle}>{toQualifyTitle}</span> : null}
          {toQualifyItems.length ? (
            <ul className={styles.toQualifyItems}>
              {toQualifyItems.map((q) => (
                <li key={q}>
                  <Typography variant="body2" gutterBottom>
                    {q}
                  </Typography>
                </li>
              ))}
            </ul>
          ) : null}
          <Box mb={1} />
          <Typography variant="body2" gutterBottom>
            {description}
          </Typography>
          <div>{children}</div>
        </div>
      </div>
    </article>
  );
}

ResultsCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  toQualifyTitle: PropTypes.string,
  toQualifyItems: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string,
  imageDescription: PropTypes.string,
  imageStyles: PropTypes.any,
  children: PropTypes.any,
};
