import { useEffect, useState, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ReactDom from 'react-dom/server.js';
import { propOr } from 'ramda';
import { REC_ENGINE_ROUTES } from 'lib/routes';
import PropTypes from 'prop-types';

/* i18n Dependencies */
import { useIntl } from 'react-intl';

/* API */
import { postChat } from 'lib/API';

/* UI Components */
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatSurvey from 'components/ChatSurvey';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setWelcomeShown } from 'app/slices/appSlice';

/* UI Components */
import { Widget, toggleWidget, toggleMsgLoader, renderCustomComponent, isWidgetOpened } from 'react-chat-widget';

/* Styles */
import cssVars from 'styles/vars.module.scss';

/* Styles */
import 'react-chat-widget/lib/styles.css';
import recStyles from 'styles/recommendations-header.module.scss';
import surveyStyles from 'styles/survey.module.scss';

export default function ChatWrapper({ disableSurvey = false, preferredName }) {
  const [notificationsNumber, setNotificationsNumber] = useState(0);
  const [openChat, setOpenChat] = useState(false);
  const [pristineChat, setPristineChat] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [surveySeen, setSurveySeen] = useState(false);
  const [surveyComplete, setSurveyComplete] = useState(false);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const activePath = location.pathname.split('/').reverse()[0];
  const audio = useMemo(() => new Audio('/audio/notification.mp3'), []);
  const { survey, chat_welcome_shown } = useSelector((state) => state.app);
  const locality = process.env.REACT_APP_LOCALITY;

  // Use a ref to access the current state of surveySeen
  const seenRef = useRef(surveySeen);
  seenRef.current = surveySeen;
  const completeRef = useRef(surveyComplete);
  completeRef.current = surveyComplete;
  const surveyRef = useRef(survey);
  surveyRef.current = survey;
  const pathRef = useRef(activePath);
  pathRef.current = activePath;

  // Audio related effects
  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    // React Chat Widget internally uses Redux, so even if the component is
    // unmounted, it will keep its state.
    if (!openChat && isWidgetOpened()) {
      setOpenChat(true);
    }
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);
  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  const showWelcomeMessageAndSurvey = () => {
    if (chat_welcome_shown) {
      return false;
    }
    const activePath = window.location.href.split('/').reverse()[0];
    const validInitPages = [
      'training-explorer',
      'recommendation-engine',
      'careers',
      'jobs',
      'training',
      'experience',
      'ratings',
    ];
    if (validInitPages.includes(activePath) && !seenRef.current) {
      const message =
        activePath === 'experience'
          ? 'init#skills'
          : `init#${['training-explorer', 'recommendation-engine'].includes(activePath) ? 'careers' : activePath}`;
      toggleMsgLoader();
      setSurveySeen(true);
      dispatch(setWelcomeShown(true));
      postChat({
        session: 'Career_Compass_V1',
        chat_question: message,
        language: intl.locale,
      })
        .then((response) => {
          if (!openChat || locality === 'nj') {
            setPlaying(true);
          }
          toggleMsgLoader();
          renderCustomComponent(CustomChatMessage, {
            message: response.message ?? response,
            timestamp: new Date(),
            preferredName,
          });
          if (!surveyRef.current.completed && !disableSurvey) {
            renderCustomComponent(ChatSurvey, { timestamp: new Date(), completeRef, setSurveyComplete });
          }
          if (!openChat && locality !== 'nj') {
            setNotificationsNumber(notificationsNumber + (surveyRef.current.completed ? 1 : 2));
          }
          if (!openChat) {
            toggleWidget();
            setOpenChat(true);
            setNotificationsNumber(0);
            setPlaying(true);
          }
        })
        .catch(() => {
          toggleMsgLoader();
        });
    }
  };
  useEffect(() => {
    const recEnginePaths = Object.values(REC_ENGINE_ROUTES);
    if (!chat_welcome_shown && timeIsUp && recEnginePaths.includes(activePath)) {
      showWelcomeMessageAndSurvey();
    }
  }, [activePath, chat_welcome_shown]);

  useEffect(() => {
    if (!pristineChat) {
      showWelcomeMessageAndSurvey();
    }
  }, [pristineChat]);

  useEffect(() => {
    const recEnginePaths = Object.values(REC_ENGINE_ROUTES);
    const one_sec = 1000;
    if (survey.loaded) {
      setTimeout(() => {
        setTimeIsUp(true);
        if (recEnginePaths.includes(pathRef.current)) {
          showWelcomeMessageAndSurvey();
        }
      }, 5 * 60 * one_sec);
    }
  }, [survey.loaded]);

  useEffect(() => {
    if (openChat) {
      setPristineChat(false);
    }
  }, [openChat]);

  const handleSend = (userMessage) => {
    toggleMsgLoader();
    postChat({
      session: 'HICAN_V1',
      chat_question: userMessage,
      language: intl.locale,
    })
      .then((response) => {
        toggleMsgLoader();
        renderCustomComponent(CustomChatMessage, { message: response.message, timestamp: new Date(), preferredName });
        if (!openChat) {
          setNotificationsNumber(notificationsNumber + 1);
        }
      })
      .catch(() => {
        toggleMsgLoader();
      });
  };

  const toggleIcon = (handleToggle) => {
    const current_location = window.location.href.split('/').reverse()[0];
    trackEvent('CHAT_TOGGLE', { current_state: openChat });
    if (!openChat) {
      setNotificationsNumber(0);
      trackEvent('GLOBAL_BOT_OPEN');
      switch (current_location) {
        case 'careers':
          trackEvent('CAREERS_BOT_OPEN');
          break;
        case 'jobs':
          trackEvent('JOBS_BOT_OPEN');
          break;
        case 'training':
          trackEvent('TRAINING_BOT_OPEN');
          break;
        case 'experience':
          trackEvent('EXPERIENCE_BOT_OPEN');
          break;
        case 'coach':
          trackEvent('COACH_BOT_OPEN');
          break;
        case 'ratings':
          trackEvent('RATINGS_BOT_OPEN');
          break;
        default:
          break;
      }
    }
    setOpenChat(!openChat);
    handleToggle();
  };

  const getCustomLauncher = (handleToggle, toggleIcon) => {
    return !openChat ? (
      <>
        <button
          type="button"
          onClick={() => toggleIcon(handleToggle)}
          title={intl.formatMessage({ id: 'skipper.chatIconAlt' })}
          className={`rcw-launcher${openChat ? ' rcw-hide-sm' : ''}`}
        />
        {notificationsNumber > 0 ? (
          <span className={surveyStyles.bellIcon__container}>
            <NotificationsIcon className={recStyles.bellIcon} />
            <span className={recStyles.bellIcon__number}>{notificationsNumber}</span>
          </span>
        ) : null}
      </>
    ) : (
      <button
        className="minimize-chat-button"
        style={{ pointerEvents: 'all' }}
        type="button"
        title={intl.formatMessage({ id: 'chat.minimizeTitle' })}
        onClick={() => toggleIcon(handleToggle)}
      />
    );
  };

  return (
    <Widget
      title={intl.formatMessage({ id: 'skipper.title', description: 'string' })}
      handleNewUserMessage={handleSend}
      senderPlaceHolder={intl.formatMessage({ id: 'skipper.askSomething', description: 'string' })}
      launcher={(handleToggle) => getCustomLauncher(handleToggle, toggleIcon)}
      subtitle={''}
      showCloseButton={false}
      fullScreenMode={false}
      titleAvatar={propOr('', 'chatTitleAvatar', cssVars).slice(1, -1)}
      profileAvatar={propOr('', 'profileAvatar', cssVars).slice(1, -1)}
    />
  );
}

ChatWrapper.propTypes = {
  disableSurvey: PropTypes.bool,
  preferredName: PropTypes.string,
};

function asHtml(input) {
  return ReactDom.renderToStaticMarkup(input);
}

function CustomChatMessage({ message, timestamp, preferredName }) {
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  const hours = timestamp.getHours() > 12 ? timestamp.getHours() - 12 : timestamp.getHours();
  const minutes = timestamp.getMinutes();

  const messageWithnewLines = message
    ? `${message.split('<br/><br/>').join('[doubleline]').split('<br/>').join('\n')}`
    : '';
  let preContent = asHtml(<ReactMarkdown>{messageWithnewLines}</ReactMarkdown>);
  preContent = preContent.replaceAll('[doubleline]', '<p> </p>');
  preContent = preContent.replaceAll('user', preferredName);
  const content = preContent.replaceAll(/href="([^"]+)"/gm, (match, url) => {
    if (url[0] === '/') {
      return `href="/#${urlPrefix}${url}" target="_top" class="chat-link"`;
    }
    return `href="${url}" target="_blank" rel="noopener noreferrer" class="chat-link"`;
  });

  return (
    <>
      <img src={propOr('', 'chatLogo', cssVars).slice(1, -1)} className="rcw-avatar" alt="profile" />
      <div className="rcw-response">
        <div className="rcw-message-text">
          <p dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <span className="rcw-timestamp">
          {hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}
        </span>
      </div>
    </>
  );
}

CustomChatMessage.propTypes = {
  message: PropTypes.string,
  preferredName: PropTypes.string,
  timestamp: PropTypes.instanceOf(Date),
};
