/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';
import { Link } from 'react-router-dom';

/* Styles */
import styles from 'styles/layout.module.scss';
import cn from 'classnames';

/* Redux */
import { useDispatch } from 'react-redux';
import { resetSurvey } from 'app/slices/benefitsFinderSlice';

/* API */
import { genericApiRequest } from 'lib/API';

/* Assets */
import riplVars from 'styles/vars.module.scss';
import hiVars from 'styles/vars-hi.module.scss';

/* Material UI and other UI Dependencies */
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { grey } from '@mui/material/colors';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const varsDict = {
  ripl: riplVars,
  hi: hiVars,
};

const Footer = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const urlPrefix = getUrlPrefix(intl.locale);
  const locality = process.env.REACT_APP_LOCALITY;
  const vars = locality in varsDict ? varsDict[locality] : varsDict.ripl;
  if (vars.stateSealHref) {
    vars.stateSealHref = vars.stateSealHref.replace(/['"]+/g, '');
  }

  return (
    <div className={styles.footer}>
      <div className={styles.colorsBar}>
        <div className={cn(styles.colorsBar__red, styles.colorsBar__color)}></div>
        <div className={cn(styles.colorsBar__green, styles.colorsBar__color)}></div>
        <div className={cn(styles.colorsBar__orange, styles.colorsBar__color)}></div>
        <div className={cn(styles.colorsBar__blue, styles.colorsBar__color)}></div>
      </div>
      <Container maxWidth="lg">
        <Grid container className={styles.footerContent} spacing={0}>
          <Grid item xs={12} sm={4}>
            <div className={styles.footerImages}>
              {vars.stateSealUrl ? (
                <a href={vars.stateSealHref}>
                  <img
                    className={styles.footerLogo}
                    src={vars.stateSealUrl.slice(1, -1)}
                    alt={`${vars.organizationName}'s seal`}
                  />
                </a>
              ) : (
                <a href={vars.stateSealHref}>
                  <img
                    className={styles.footerLogo}
                    href={vars.stateSealHref}
                    src={vars.logoFooterUrl.slice(1, -1)}
                    alt={vars.organizationName}
                  />
                </a>
              )}
            </div>
            <div className={cn(styles.footerText, styles.officialText)}>
              <FormattedMessage id="layout.footer.officialWebsite" />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} style={{ paddingRight: '16px', paddingLeft: '16px' }}>
            <div className={styles.footerText}>
              <FormattedMessage id="layout.footer.equalOportunity" defaultMessage=" " />
            </div>
            <div className={styles.footerText}>
              <FormattedMessage id="layout.footer.auxiliarityAids" defaultMessage=" " />
            </div>
            <div className={styles.footerText} style={{ paddingBottom: 18 }}>
              <FormattedMessage id="layout.footer.tdd" defaultMessage=" " />
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={styles.footerText}>
              <FormattedMessage id="layout.footer.feedbackText" />
              <a
                href={`mailto:${vars.footerEmail.slice(1, -1)}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'white', paddingBottom: 8, '&:active': { color: grey[100] } }}
                onClick={() => trackEvent('FOOTER_EMAIL')}
              >
                <FormattedMessage id="layout.footer.feedbackLink" />
              </a>
              .
              <FormattedMessage id="layout.footer.feedbackAfterLink" defaultMessage=" " />
            </div>
            <div className={styles.footerText}>
              <a
                rel="noreferrer noopener"
                style={{ color: 'white', paddingBottom: 8, '&:active': { color: grey[100] } }}
                href={`/#${urlPrefix}/terms-of-use`}
                target="_blank"
                onClick={() => trackEvent('FOOTER_TERMS')}
              >
                <FormattedMessage id="layout.footer.privacy" />
              </a>
            </div>
            <div className={styles.footerText}>
              <a
                rel="noreferrer noopener"
                style={{ color: 'white', paddingBottom: 8, '&:active': { color: grey[100] } }}
                href={`#${urlPrefix}/credits-and-citations`}
                target="_blank"
                onClick={() => trackEvent('FOOTER_CREDITS')}
              >
                <FormattedMessage id="layout.footer.credits" />
              </a>
            </div>
            <div className={styles.footerText}>
              <a
                rel="noreferrer noopener"
                style={{ color: 'white', paddingBottom: 8, '&:active': { color: grey[100] } }}
                href={`#${urlPrefix}/about`}
                target="_blank"
                onClick={() => trackEvent('FOOTER_ABOUT')}
              >
                <FormattedMessage id="about.linkText" />
              </a>
            </div>
            <div className={styles.footerText}>
              <a
                rel="noreferrer noopener"
                style={{ color: 'white', paddingBottom: 8, '&:active': { color: grey[100] } }}
                href="/top-jobs.html#/top-jobs"
                target="_blank"
                onClick={() => trackEvent('FOOTER_TOPJOBS')}
              >
                <FormattedMessage id="layout.footer.topJobs" />
              </a>
            </div>
            <div className={styles.footerText}>
              <Link
                to={`${urlPrefix}/benefits-finder`}
                style={{ color: 'white', paddingBottom: 8, '&:active': { color: grey[100] } }}
                onClick={() => {
                  genericApiRequest('post', '/api/vp2/events', {
                    data: {
                      action: 'event',
                      name: 'BFAccess_Footer',
                      location: window.location.hash,
                      item: {},
                    },
                  });
                  dispatch(resetSurvey());
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="app.benefitsFinder.header" />
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
      <hr className={styles.separator} />
      <Container maxWidth="lg">
        <Grid container className={styles.footerContentBottom} spacing={0}>
          <Grid item xs={12} sm={8}></Grid>
          <Grid item xs={12} sm={4}>
            <div className={styles.footerCopy}>
              <FormattedMessage id="layout.footer.copy" />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
