import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getRecommendedJobs, updateJob } from '../../API';

const getJobsQueryKey = (filters) => [{ entity: 'jobs', ...filters }];

const paginatedJobsQueryFn = async ({ queryKey, pageParam = 0 }) => {
  const {
    filter_by_name,
    filter_by_tag,
    filter_by_soc,
    filter_by_city,
    since_date_posted,
    distance_max,
    salary_min,
    salary_max,
    zipcode,
  } = queryKey[0];

  const data = await getRecommendedJobs([], {
    limit: 10,
    offset: pageParam * 10,
    ...(filter_by_name && filter_by_name.length ? { filter_by_name } : {}),
    ...(filter_by_tag && filter_by_tag.length ? { filter_by_tag } : {}),
    ...(filter_by_soc && filter_by_soc.length ? { filter_by_soc: filter_by_soc.map((c) => c.soc).join('|') } : {}),
    ...(filter_by_city && filter_by_city.length ? { filter_by_city: filter_by_city.join('|') } : {}),
    ...(since_date_posted ? { since_date_posted } : {}),
    ...(distance_max !== 100 && zipcode?.length === 5 ? { distance_max, zipcode } : {}),
    ...(salary_min > 0 ? { salary_min } : {}),
    ...(salary_max > 0 ? { salary_max } : {}),
  });
  return data;
};

export const useInfiniteJobs = (filters) => {
  return useInfiniteQuery({
    queryFn: paginatedJobsQueryFn,
    retry: false,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length < 10) {
        return undefined;
      }
      return pages.flat(1).length / 10;
    },
    queryKey: getJobsQueryKey(filters),
  });
};

export const useRateJob = (filters) => {
  const queryClient = useQueryClient();
  const queryKey = getJobsQueryKey(filters);

  return useMutation({
    mutationFn: ({ job, rating }) => {
      return updateJob(job, rating);
    },
    onMutate: async ({ job, rating }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousJobs = queryClient.getQueryData(queryKey);

      // Optimistically update with the new rating
      queryClient.setQueryData(queryKey, (old) => {
        const clone = structuredClone(old);
        const index = clone.pages.flat(1).findIndex((oldJob) => oldJob.guid === job.guid);

        clone.pages.flat(1)[index].rating = rating;

        return clone;
      });

      // Return a context object with the snapshotted value
      return { previousJobs };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context.previousJobs);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
