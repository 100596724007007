import { css } from '@emotion/css';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import cssVars from 'styles/vars.module.scss';

/* Material UI and other UI Dependencies */
import { lighten } from '@mui/material';

const styles = {
  header: css({
    fontSize: '16px',
  }),
  disclaimer: css(
    {
      background: lighten(cssVars.mediumGray, 0.5),
      fontSize: '15px',
      borderRadius: '10px',
      padding: '10px 20px 20px',
    },
    { label: 'disclaimer' }
  ),
};

export default function Disclaimer() {
  const intl = useIntl();
  return (
    <div className={styles.disclaimer}>
      <h2 className={styles.header}>{intl.formatMessage({ id: 'app.benefitsFinder.disclaimerHeader' })}</h2>
      {intl.formatMessage({ id: 'app.benefitsFinder.disclaimer' })}
    </div>
  );
}
