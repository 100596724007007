import { createSlice } from '@reduxjs/toolkit';

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    job_experience: {},
    education: {},
  },
  reducers: {
    setJobExperience: (state, action) => {
      state.job_experience = action.payload;
    },
    setEducation: (state, action) => {
      state.education = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setJobExperience, setEducation } = onboardingSlice.actions;

export default onboardingSlice.reducer;
