import React from 'react';
import NumberFormat from 'react-number-format';

export default function ZIPCodeInput(props) {
  const { inputRef, onChange, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      displayType={'input'}
      type={'text'}
      format="#####"
      placeholder="#####"
      mask="_"
      allowNegative={false}
      allowLeadingZeros={true}
      isNumericString={true}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
}
