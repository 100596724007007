import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

/* i18n */
import { useIntl, FormattedMessage } from 'react-intl';

/* Redux */
import { useDispatch } from 'react-redux';
import { setLoading } from 'app/slices/appSlice';

/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import cn from 'classnames';

/* Material UI and other UI Dependencies */
import { Box, Typography } from '@mui/material';
import MetaContent from 'components/layout/MetaContent';
import OnboardingHeader from 'components/layout/OnboardingHeader';

/* Analytics */
import { trackEvent } from 'lib/analytics';

function LoginHI() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { loginWithRedirect } = useAuth0();

  const handleClick = (provider) => {
    return () => {
      dispatch(setLoading(true));
      trackEvent(`LOGIN_${provider.toUpperCase()}`);
      // see https://community.auth0.com/t/request-for-federated-logout-support-for-the-auth0-oidc-connection-type/72574
      // prompt: login was needed as a workaround to logoff with OIDC
      // connection goes direct to social login: https://community.auth0.com/t/how-to-skip-aut0-login-page-and-directly-redirect-in-to-social-login-site/28211
      loginWithRedirect({ prompt: 'login', connection: provider.toLowerCase() });
    };
  };

  return (
    <>
      <MetaContent intlDescription={intl.formatMessage({ id: 'recommendations.title' })} />
      <OnboardingHeader />
      <div className={styles.loginFlow}>
        <div className={styles.loginFlow__formContainer}>
          <div className={styles.loginFlow__form}>
            <Typography align="center" variant="h1" gutterBottom>
              <FormattedMessage default="Sign in to your account" id="recommendations.title" />
            </Typography>
            <Typography align="center" variant="body1" gutterBottom>
              {intl.formatMessage(
                { id: 'forms.signIn.subtitle' },
                {
                  toolName: 'Hawai’i Career Acceleration Navigator',
                  state: 'Hawai’i',
                }
              )}
            </Typography>
            <Typography align="center" variant="body1" gutterBottom>
              {intl.formatMessage({ id: 'forms.signIn.subtitleLine2' })}
            </Typography>
          </div>
          <hr className={layoutStyles.separatorBold} />
          <div className={styles.signInContainerSingleColumn}>
            <div className={styles.socialSignInContainer}>
              <Typography variant="h3" component="h2" align="center">
                {intl.formatMessage({ id: 'forms.signIn.hawaiiUnemploymentAccount' })}
              </Typography>
              <Typography align="center" gutterBottom style={{ fontSize: 15 }}>
                {false ? intl.formatMessage({ id: 'forms.signIn.preferredSignInOption' }) : null}
              </Typography>
              <div
                role="button"
                className={cn(styles.signInButton, styles.signInButton__UI)}
                onClick={handleClick('HUI')}
              >
                {intl.formatMessage({ id: 'forms.signIn.signInWithUI' })}
              </div>
              <div align="center" style={{ display: 'flex', justifyContent: 'center' }}>
                <label className={styles.infoMessageDark}>
                  {intl.formatMessage({ id: 'forms.signIn.haveUIClaimNeedAccount' })}{' '}
                  <a
                    className={styles.fakeLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://huiclaims.hawaii.gov/#/register"
                  >
                    {intl.formatMessage({ id: 'forms.signIn.clickHere' })}
                  </a>
                </label>
              </div>
              {process.env.REACT_APP_B2C_PROVIDER !== 'FALSE' ? (
                <>
                  <Box mt={3} />
                  <Box maxWidth={450} margin="0 auto">
                    <Typography variant="h3" component="h2" align="center">
                      {intl.formatMessage({ id: 'forms.signIn.dontHaveUIAccount' })}
                    </Typography>
                  </Box>
                  <div
                    role="button"
                    className={cn(styles.signInButton, styles.signInButton__HI)}
                    onClick={handleClick(process.env.REACT_APP_B2C_PROVIDER)}
                  >
                    {intl.formatMessage({ id: 'forms.signIn.signInWithHI' })}
                  </div>
                  <Box mt={1} />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginHI;
