/* Styles */
import styles from 'styles/card-placeholder.module.scss';
import cn from 'classnames';

/* Assets */
import thumbsUp from 'assets/images/ThumbsUpGray.svg';
import thumbsDown from 'assets/images/ThumbsDownGray.svg';
import arrowRight from 'assets/images/Arrow_RightLight.svg';

export default function CardPlaceholder() {
  return (
    <div className={styles.cardPlaceholder}>
      <div className={cn(styles.title, styles.animatedBackground)} />
      <div className={styles.likesMatchTags}>
        <div className={styles.thumbs}>
          <span className={styles.thumbsContainer}>
            <img alt="Thumbs up" src={thumbsUp} className={cn(styles.thumbsButton, styles.outlinedThumbs)} />
          </span>
          <span className={styles.thumbsContainer}>
            <img alt="Thumbs down" src={thumbsDown} className={cn(styles.thumbsButton, styles.outlinedThumbs)} />
          </span>
        </div>
        <div className={cn(styles.tag, styles.animatedBackground)} />
        <div className={cn(styles.tag, styles.animatedBackground)} />
      </div>
      <div className={styles.range}>
        <div className={cn(styles.rangeValue, styles.animatedBackground)} />
      </div>
      <div className={styles.buttonContainer}>
        <div className={cn(styles.button, styles.animatedBackground)}>
          <img alt="Arrow" src={arrowRight} className={styles.arrowRight} />
        </div>
      </div>
    </div>
  );
}
