export const mergeRecommendations = (recommendations_url, response, resolve, reject) => {
  // Helper function to merge data into API response from recommendations stored in S3
  axios
    .get(recommendations_url)
    .then((rec_response) => {
      let rec_data = rec_response.data;
      if (rec_data.careers !== undefined) {
        response.careers = rec_data.careers;
      }
      if (rec_data.jobs !== undefined) {
        response.jobs = rec_data.jobs;
      }
      if (rec_data.training !== undefined) {
        response.training = rec_data.training;
      }
      resolve(response);
    })
    .catch((error) => {
      console.log('error retrieving from S3', error);
      // S3 data may have expired, so we should regenerate recommendations
      resolve(response);
    });
};
