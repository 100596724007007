import { css, cx } from '@emotion/css';
import CheckIcon from '@mui/icons-material/Check';
import { Stepper, Step, StepLabel, StepConnector } from '@mui/material';

/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useSelector } from 'react-redux';

/* Styles */
import cssVars from 'styles/vars.module.scss';

const styles = {
  root: css(
    {
      backgroundColor: 'transparent',
      zIndex: 1,
      color: 'white',
      width: '24px',
      height: '24px',
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      border: `solid 3px ${cssVars.mediumGray}`,
      boxSizing: 'border-box',
    },
    { label: 'root' }
  ),
  stepperContainer__Onboarding: css(
    {
      maxWidth: '393px',
      margin: '0 auto',
    },
    { label: 'stepper-container__onboarding' }
  ),
  onboardingRoot: css(
    {
      padding: '0 !important',
      position: 'relative',
      left: '-4px',
    },
    { label: 'onboarding-root' }
  ),
  active: css(
    {
      backgroundColor: 'white',
      borderColor: cssVars.progressGreen,
      svg: {
        display: 'none',
      },
    },
    { label: 'active' }
  ),
  completed: css(
    {
      backgroundColor: cssVars.progressGreen,
      borderColor: cssVars.progressGreen,
    },
    { label: 'completed' }
  ),
  connectorActive: css(
    {
      backgroundColor: cssVars.progressGreen,
    },
    { label: 'connector-active' }
  ),
  connectorCompleted: css(
    {
      backgroundColor: cssVars.progressGreen,
    },
    { label: 'connector-completed' }
  ),
  connectorRoot: css(
    {
      left: 'calc(-50% + 12px) !important',
      right: 'calc(50% + 12px) !important',
    },
    { label: 'connector-root' }
  ),
  connectorLine: css(
    {
      height: '3px',
      border: 0,
      backgroundColor: cssVars.mediumGray,
      borderRadius: '1px',
    },
    { label: 'connector-line' }
  ),
  connectorLineThin: css(
    {
      height: '1px',
      border: 0,
      borderColor: cssVars.mediumGray,
      backgroundColor: 'transparent',
      position: 'relative',
      left: '8px',
    },
    { label: 'connector-line-thin' }
  ),
  alternativeLabel: css(
    {
      backgroundColor: 'transparent !important',
      padding: '24px 0 10px!important',
    },
    { label: 'alternative-label' }
  ),
  stepLabel: css({
    color: `${cssVars.darkGray} !important`,
    fontSize: '12px !important',
    lineHeight: '18px !important',
    margin: '16px auto 0 !important',
    maxWidth: '84px',
  }),
  stepLabelOnboarding: css({
    color: `${cssVars.darkGray} !important`,
    fontSize: '12px !important',
    lineHeight: '18px !important',
    margin: '16px auto 0 !important',
    maxWidth: '84px',
    position: 'relative',
    left: '8px',
    bottom: '8px',
  }),
  stepRoot: css({
    '@media screen and (min-width: 768px)': {
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
    },
  }),
};

const ExperienceStepper = ({ didUploadResume, defaultLikedSkills = false }) => {
  const intl = useIntl();
  const { likedSkills, skillsReloading } = useSelector((state) => state.skills);
  const didRateNineSkills = defaultLikedSkills ? defaultLikedSkills.length >= 10 : likedSkills.length >= 10;
  const activeStep = 2 + (didRateNineSkills && !skillsReloading ? 1 : 0) + (didUploadResume ? 1 : 0);
  const steps =
    didRateNineSkills && !didUploadResume
      ? [
          intl.formatMessage({ id: 'experience.steps.signedUp', description: 'string' }),
          intl.formatMessage({ id: 'experience.steps.lastJob', description: 'string' }),
          intl.formatMessage({ id: 'experience.steps.rated', description: 'string' }),
          intl.formatMessage({ id: 'experience.steps.resume', description: 'string' }),
        ]
      : [
          intl.formatMessage({ id: 'experience.steps.signedUp', description: 'string' }),
          intl.formatMessage({ id: 'experience.steps.lastJob', description: 'string' }),
          intl.formatMessage({ id: 'experience.steps.resume', description: 'string' }),
          intl.formatMessage({ id: 'experience.steps.rated', description: 'string' }),
        ];

  function StepIcon(props) {
    const { active, completed } = props;
    return (
      <div
        className={cx(styles.root, {
          [styles.active]: active,
          [styles.completed]: completed,
        })}
      >
        {active || completed ? <CheckIcon /> : null}
      </div>
    );
  }

  return (
    <Stepper
      classes={{
        alternativeLabel: styles.alternativeLabel,
      }}
      alternativeLabel
      activeStep={activeStep}
      connector={
        <StepConnector
          classes={{
            root: styles.connectorRoot,
            active: styles.connectorActive,
            completed: styles.connectorCompleted,
            line: styles.connectorLine,
          }}
        />
      }
    >
      {steps.map((label) => (
        <Step
          classes={{
            root: styles.stepRoot,
          }}
          key={label}
        >
          <StepLabel
            StepIconComponent={StepIcon}
            classes={{
              label: styles.stepLabel,
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default ExperienceStepper;
