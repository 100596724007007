/*-- Routing --*/
import { Route, Switch, Redirect } from 'react-router-dom';

/* Redux */
import { useSelector } from 'react-redux';

/*-- Layout --*/
import RecommendationsHeader from 'components/RecommendationsHeader';

/*-- Pages --*/
import LoadingRecommendations from 'components/LoadingRecommendations';
import Careers from 'components/Careers';
import Jobs from 'components/Jobs';
import Training from 'components/Training';
import Experience from 'components/Experience';
import Ratings from 'components/Ratings';

import JobSearchActivity from 'components/JobSearchActivity';
import FinancialHelp from 'components/FinancialHelp';
import { Chat } from 'shared/components';

const AuthenticatedPagesSwitch = () => {
  const { loadingRecommendations, user_settings, urlPrefix, preferredName } = useSelector((state) => state.app);

  return loadingRecommendations ? (
    <LoadingRecommendations />
  ) : (
    <>
      <RecommendationsHeader />
      <Switch>
        <Route path={[`/careers`, `/:lang/careers`]}>
          <Careers />
        </Route>
        <Route path={[`/jobs`, `/:lang/jobs`]}>
          <Jobs />
        </Route>
        <Route path={[`/training`, `/:lang/training`]}>
          <Training />
        </Route>
        <Route path={[`/experience`, `/:lang/experience`]}>
          <Experience />
        </Route>
        <Route path={['/job-search-activity', '/:lang/job-search-activity']}>
          {user_settings.add_job_search_activity_hero_button ? (
            <JobSearchActivity />
          ) : (
            <Redirect to={`${urlPrefix}/careers`} />
          )}
        </Route>
        <Route path={['/financial-help', '/:lang/financial-help']}>
          {user_settings.view_benefits_for_job_seekers_button ? (
            <FinancialHelp />
          ) : (
            <Redirect to={`${urlPrefix}/careers`} />
          )}
        </Route>
        <Route path={[`/ratings`, `/:lang/ratings`]}>
          <Ratings />
        </Route>
        <Route path={['/onboarding', '/:lang/onboarding']}>
          <Redirect to={`${urlPrefix}/careers`} />
        </Route>
        <Route>
          <Redirect to={`${urlPrefix}/careers`} />
        </Route>
      </Switch>
      <Chat preferredName={preferredName} />
    </>
  );
};

export default AuthenticatedPagesSwitch;
