import { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

/* Styles */
import styles from 'styles/layout.module.scss';
import filterStyles from 'styles/filter-input.module.scss';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import arrowDown from 'assets/images/Arrow_Dropdown.svg';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';

import { logoHeaderUrl } from 'styles/vars-hi.module.scss';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const LANGUAGES = {
  en: 'English',
  es: 'Spanish',
  zh: 'Chinese (Simplified) - 简体中文',
  ja: 'Japanese - 日本',
  tl: 'Tagalog',
  ko: 'Korean',
  vi: 'Vietnamese',
  ilo: 'Ilocano',
  'not-listed': 'My language is not listed',
};

const LanguagesSelect = ({ locale, resetLanguage }) => {
  const [languagesOpen, setLanguagesOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const languageSelect = (e) => {
    const language = e.target.value;
    if (language !== 'not-listed') {
      resetLanguage(e);
      return;
    } else {
      trackEvent('I_MENU_NOTLISTED');
    }
    setDialogOpen(true);
  };

  return (
    <>
      <FormControl
        className={cn({
          [filterStyles.languagesFormControl]: true,
          [filterStyles.ratingsSortingFocused]: languagesOpen,
          [styles.newHeroLanguagesDesktop]: true,
        })}
      >
        <span className={filterStyles.selectLabel}>{LANGUAGES[locale]}</span>
        <Select
          disableUnderline
          labelId="laguanges-select-label"
          id="languages-select"
          value={locale}
          onChange={languageSelect}
          onOpen={() => setLanguagesOpen(true)}
          onClose={() => setLanguagesOpen(false)}
          input={<Input />}
          renderValue={() => ''}
          IconComponent={(_props) => <img alt="Arrow down" className={filterStyles.arrowDown} src={arrowDown} />}
          MenuProps={{
            PaperProps: {
              className: filterStyles.selectMenu,
            },
            getContentAnchorEl: null,
          }}
          classes={{
            root: filterStyles.selectRoot,
            select: filterStyles.selectSelect,
          }}
          sx={{
            backgroundColor: 'white',
          }}
        >
          {Object.keys(LANGUAGES).map((k) => (
            <MenuItem key={k} value={k}>
              <span className={filterStyles.checkboxLabel}>{LANGUAGES[k]}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LanguagesDialog open={dialogOpen} setOpen={setDialogOpen} />
    </>
  );
};

LanguagesSelect.propTypes = {
  locale: PropTypes.string,
  resetLanguage: PropTypes.func,
};

function LanguagesDialog({ open, setOpen }) {
  const isSmall = useMediaQuery('(max-width:767px)');

  return (
    <Dialog maxWidth="md" open={open} fullWidth onClose={() => setOpen(false)}>
      <DialogContent>
        <Box display="flex" justifyContent="center" pt={3} pb={3}>
          <img alt="Logo" style={{ maxWidth: 270 }} src={logoHeaderUrl.slice(1, -1)} />
        </Box>
        <Box mb={2} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
          <Typography variant="h5" component="h2" style={{ color: '#000' }} align="center">
            中文 – CHINESE CANTONESE
          </Typography>
          <Typography variant="body2" align="center">
            您需要其它語言嗎？如有需要, 請致電 (
            <a className={styles.link} href="tel:+18085868877" rel="nofollow">
              808-586-8877
            </a>
            ), 我們會提供免費翻譯服務 (Traditional)
          </Typography>
        </Box>
        <Box mb={2} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
          <Typography variant="h5" component="h2" style={{ color: '#000' }} align="center">
            中文 – CHINESE MANDARIN
          </Typography>
          <Typography variant="body2" align="center">
            您需要其它語言嗎？如有需要, 請致電  (
            <a className={styles.link} href="tel:+18085868877" rel="nofollow">
              808-586-8877
            </a>
            ), 我們會提供免費翻譯服務 (Traditional)
          </Typography>
        </Box>
        <Box mb={2} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
          <Typography variant="h5" component="h2" style={{ color: '#000' }} align="center">
            KAPASEN CHUUK: CHUUKESE
          </Typography>
          <Typography variant="body2" align="center">
            En mi niit alilis lon pwal eu kapas? Sipwe angei emon chon chiaku ngonuk ese kamo. Kokori  (
            <a className={styles.link} href="tel:+18085868877" rel="nofollow">
              808-586-8877
            </a>
            )  omw kopwe ureni kich meni kapas ka ani.
          </Typography>
        </Box>
        <Box mb={2} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
          <Typography variant="h5" component="h2" style={{ color: '#000' }} align="center">
            ŌLELO HAWAIʻI: HAWAIIAN
          </Typography>
          <Typography variant="body2" align="center">
            Makemake `oe i kokua i pili kekahi `olelo o na `aina `e? Makemake la maua i ki`i `oe mea unuhi manuahi. E
            kelepona (
            <a className={styles.link} href="tel:+18085868877" rel="nofollow">
              808-586-8877
            </a>
            ) `oe ia la kaua a e ha`ina `oe ia la maua mea `olelo o na `aina `e.
          </Typography>
        </Box>
        <Box mb={2} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
          <Typography variant="h5" component="h2" style={{ color: '#000' }} align="center">
            한국어: KOREAN
          </Typography>
          <Typography variant="body2" align="center">
            다른언어로 도움이 필요하십니까? 저희가 무료로 통역을 제공합니다. (
            <a className={styles.link} href="tel:+18085868877" rel="nofollow">
              808-586-8877
            </a>
            ) 로 전화해서 사용하는 언어를 알려주십시요
          </Typography>
        </Box>
        <Box mb={2} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
          <Typography variant="h5" component="h2" style={{ color: '#000' }} align="center">
            KAJIN MAJÔL: MARSHALLESE
          </Typography>
          <Typography variant="body2" align="center">
            Kwoj aikuij ke jiban kin juon bar kajin? Kim naj lewaj juon am dri ukok eo ejjelok wonen. Kirtok  (
            <a className={styles.link} href="tel:+18085868877" rel="nofollow">
              808-586-8877
            </a>
            ) im kwalok non kim kajin ta eo kwo melele im kenono kake.
          </Typography>
        </Box>
        <Box mb={2} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
          <Typography variant="h5" component="h2" style={{ color: '#000' }} align="center">
            GAGANA SAMOA: SAMOAN
          </Typography>
          <Typography variant="body2" align="center">
            E te mana’o mia se fesosoani i se isi gagana? Matou te fesosoani e ave atu fua se faaliliu upu mo oe. Vili
            mai i le numera lea (
            <a className={styles.link} href="tel:+18085868877" rel="nofollow">
              808-586-8877
            </a>
            ) pea e mana’o mia se fesosoani mo se faaliliu upu.
          </Typography>
        </Box>
        <Box mb={2} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
          <Typography variant="h5" component="h2" style={{ color: '#000' }} align="center">
            ภาษาไทย:THAI
          </Typography>
          <Typography variant="body2" align="center">
            คุณต้องการความช่วยเหลือทางด้านภาษาหรือไม่ ทางเราจะจัดหาล่ามฟรีให้คุณ โทรที่เบอร์ (
            <a className={styles.link} href="tel:+18085868877" rel="nofollow">
              808-586-8877
            </a>
            ) และบอกเราว่าคุณพูดภาษาอะไร
          </Typography>
        </Box>
        <Box mb={2} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
          <Typography variant="h5" component="h2" style={{ color: '#000' }} align="center">
            TIẾNG VIỆT: VIETNAMESE
          </Typography>
          <Typography variant="body2" align="center">
            Bạn có cần giúp đỡ bằng ngôn ngữ khác không ? Chúng tôi se yêu cầu một người thông dịch viên miễn phí cho
            bạn. Gọi (
            <a className={styles.link} href="tel:+18085868877" rel="nofollow">
              808-586-8877
            </a>
            ) nói cho chúng tôi biết bạn dùng ngôn ngữ nào.
          </Typography>
        </Box>
        <Box mb={2} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
          <Typography variant="h5" component="h2" style={{ color: '#000' }} align="center">
            VISAYAN: CEBUANO
          </Typography>
          <Typography variant="body2" align="center">
            Gakinahanglan ka ba ug tabang sa imong pinulongan? Amo kang mahatagan ug libre nga maghuhubad. Tawag sa (
            <a className={styles.link} href="tel:+18085868877" rel="nofollow">
              808-586-8877
            </a>
            ) aron magpahibalo kung unsa ang imong sinulti-han.
          </Typography>
        </Box>
        <Box mb={5} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
          <Typography variant="body2" align="center">
            You have the right to an interpreter at no cost to you.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mb={2} pr={2}>
          <Button onClick={() => setOpen(false)} color="primary" variant="contained">
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

LanguagesDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default LanguagesSelect;
