import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import {
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  CircularProgress,
} from '@mui/material';

import { apiRequest } from 'lib/API';

import { PhoneNumberInput } from 'shared/components';
import { DatePicker } from '@mui/x-date-pickers';

/**
 * i18n
 */
import { useIntl } from 'react-intl';

/* Analytics */
import { trackEvent } from 'lib/analytics';

import styles from 'styles/apply-job-form.module.scss';
import onbStyles from 'styles/onboarding.module.scss';
import layoutStyles from 'styles/layout.module.scss';

import BrowseDocuments from 'components/BrowseDocuments';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function AttendJobSeminar({
  addActivityItem,
  setOpen,
  updateActivityItem,
  setGeneralActivity,
  selectedActivity,
  editting = false,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [refreshConter, setRefreshConter] = useState(0);
  const [documentsLinks, setDocumentsLinks] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    setRefreshConter(refreshConter + 1);
    if (selectedActivity.upload_documents && selectedActivity.upload_documents.length > 0) {
      setDocumentsLinks([...selectedActivity.upload_documents]);
    }
  }, [selectedActivity, editting]);

  useEffect(() => {
    if (documentsLinks.length > 0) {
      documentsLinks.forEach((doc, idx) => {
        apiRequest(
          'get',
          `/users/me/worksearch/?worksearch_id=${encodeURIComponent(
            selectedActivity.worksearch_id
          )}&file_uuid=${encodeURIComponent(doc.file_uuid)}`
        ).then((res) => {
          setDocumentsLinks([
            ...documentsLinks.slice(0, idx),
            { ...doc, downloadUrl: res.download_url },
            ...documentsLinks.slice(idx + 1),
          ]);
        });
      });
    }
  }, [documentsLinks]);

  const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/; // eslint-disable-line no-useless-escape
  const emailRegExp = /^\S+@\S+\.\w\w+$/;

  const validationSchema = yup.object({
    company_name: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.companyNameRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.companyNameRequired' })),
    contact_phone_number: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumberRequired' }))
      .matches(phoneRegExp, intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumberRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumberRequired' })),
    contact_email: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .matches(emailRegExp, intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    activity_date: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    type_of_seminar: yup.array().min(1, intl.formatMessage({ id: 'forms.onboarding.minSelected' }, { length: 1 })),
    result_of_seminar: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.resultOfContactRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.resultOfContactRequired' })),
  });

  const toggleCheckbox = (currentValues, key) => {
    const valuesSet = new Set(currentValues);
    if (valuesSet.has(key)) {
      valuesSet.delete(key);
    } else {
      valuesSet.add(key);
    }
    return [...valuesSet];
  };

  const defaultValues = {
    company_name: '',
    contact_name: '',
    contact_phone_number: '',
    contact_email: '',
    activity_date: new Date(),
    type_of_seminar: [],
    result_of_seminar: '',
    notes: '',
    upload_documents: [],
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={
        selectedActivity
          ? {
              ...selectedActivity,
              upload_documents: [],
              activity_date: new Date(
                selectedActivity.activity_date.slice(0, 4),
                selectedActivity.activity_date.slice(5, 7) - 1,
                selectedActivity.activity_date.slice(8, 10)
              ),
            }
          : defaultValues
      }
      onSubmit={async (values) => {
        setSubmitting(true);
        let year = values.activity_date.getFullYear();
        let month = values.activity_date.getMonth() + 1;
        let day = values.activity_date.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        const vals4submit = {
          ...values,
          activity_type: 'attend_job_seminar',
          activity_date: `${year}-${month}-${day}`,
        };
        vals4submit.upload_documents = values.upload_documents.map((doc) => ({
          filename: doc.name,
          upload_contents: doc.contents,
        }));
        if (editting) {
          trackEvent('WEEK_VIEWACTIVITY_SAVE');
        } else {
          trackEvent('WEEK_SAVEACTIVITY_SEMINAR');
        }
        apiRequest(editting ? 'put' : 'post', '/users/me/worksearch/', { data: { activity: vals4submit } })
          .then(({ activity, status }) => {
            if (editting) {
              updateActivityItem(activity);
            } else {
              addActivityItem(activity);
            }
            setGeneralActivity(status);
            setOpen(false);
            setSubmitting(false);
          })
          .catch((err) => {
            setSubmitting(false);
            console.log(err);
          });
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => {
        function isValidDate(d) {
          return d instanceof Date && !isNaN(d);
        }
        const handleDateChange = (date) => {
          if (isValidDate(date)) {
            setFieldValue('activity_date', date);
          } else {
            setFieldValue('activity_date', '');
          }
        };
        return (
          <form onSubmit={handleSubmit} className={styles.loginFlow__form}>
            {submitting ? (
              <Box m={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                <div className={styles.fields}>
                  <div>
                    <TextField
                      InputLabelProps={{ required: true }}
                      disabled={submitting || (editting && !editMode)}
                      error={touched.company_name && Boolean(errors.company_name)}
                      fullWidth
                      helperText={touched.company_name && errors.company_name}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.companyName' })}
                      margin="normal"
                      name="company_name"
                      classes={{ root: styles.textFieldRoot }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.company_name}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.companyName' }),
                      }}
                    />
                  </div>
                  <div style={{ position: 'relative' }}>
                    <TextField
                      error={touched.contact_name && Boolean(errors.contact_name)}
                      disabled={submitting || (editting && !editMode)}
                      fullWidth
                      helperText={touched.contact_name && errors.contact_name}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.contactName' })}
                      margin="normal"
                      name="contact_name"
                      classes={{ root: styles.textFieldRoot }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.contact_name}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.contactName' }),
                      }}
                    />
                    <HelpOutlineIcon className={styles.helperTextIcon} />
                    <span className={cn(styles.fieldDescription, styles.fieldDescriptionSemiClose)}>
                      {intl.formatMessage({ id: 'jobSearchActivity.form.contactNameSub' })}
                    </span>
                  </div>
                  <div>
                    <TextField
                      InputLabelProps={{ required: true }}
                      disabled={submitting || (editting && !editMode)}
                      error={touched.contact_phone_number && Boolean(errors.contact_phone_number)}
                      fullWidth
                      helperText={touched.contact_phone_number && errors.contact_phone_number}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumber' })}
                      name="contact_phone_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      classes={{ root: styles.textFieldRoot }}
                      type="text"
                      value={values.contact_phone_number}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumber' }),
                      }}
                      InputProps={{
                        inputComponent: PhoneNumberInput,
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      InputLabelProps={{ required: true }}
                      disabled={submitting || (editting && !editMode)}
                      error={touched.contact_email && Boolean(errors.contact_email)}
                      fullWidth
                      helperText={touched.contact_email && errors.contact_email}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.contactEmail' })}
                      name="contact_email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      classes={{ root: styles.textFieldRoot }}
                      type="text"
                      value={values.contact_email}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.contactEmail' }),
                      }}
                    />
                  </div>
                  <Box>
                    <DatePicker
                      slots={{
                        textField: (props) => (
                          <TextField
                            title={intl.formatMessage({ id: 'jobSearchActivity.form.dateOfSeminar' })}
                            variant="filled"
                            InputLabelProps={{ required: true }}
                            {...props}
                          />
                        ),
                      }}
                      disabled={submitting || (editting && !editMode)}
                      format="MM/dd/yyyy"
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.dateOfSeminar' })}
                      value={values.activity_date}
                      onChange={handleDateChange}
                    />
                  </Box>
                  <div style={{ position: 'relative' }}>
                    <TextField
                      InputLabelProps={{ required: true }}
                      disabled={submitting || (editting && !editMode)}
                      error={touched.result_of_seminar && Boolean(errors.result_of_seminar)}
                      fullWidth
                      helperText={touched.result_of_seminar && errors.result_of_seminar}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.resultOfSeminar' })}
                      name="result_of_seminar"
                      classes={{ root: styles.textFieldRoot }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.result_of_seminar}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfSeminar' }),
                      }}
                    />
                    <HelpOutlineIcon className={styles.helperTextIconClose} />
                    <span className={cn(styles.fieldDescription, styles.fieldDescriptionClose)}>
                      {intl.formatMessage({ id: 'jobSearchActivity.form.canInclude' })}
                    </span>
                  </div>
                </div>
                <div className={styles.checksAndRadios}>
                  <div className={styles.checks}>
                    <span
                      className={cn({
                        [styles.fieldLabel]: true,
                        [styles.fieldLabel__errored]: touched.type_of_seminar && errors.type_of_seminar,
                      })}
                    >
                      {intl.formatMessage({ id: 'jobSearchActivity.form.typeOfActivity' })}*
                    </span>
                    <span className={cn(styles.fieldDescription, styles.labelPadding)}>
                      {intl.formatMessage({ id: 'jobSearchActivity.form.allThatApply' })}
                    </span>
                    <FormControl classes={{ root: onbStyles.formControlRoot }}>
                      <FormControlLabel
                        labelPlacement="end"
                        classes={{ root: onbStyles.formControlLabelRoot, label: onbStyles.formControlLabeLabel }}
                        label={intl.formatMessage({ id: 'jobSearchActivity.form.inPerson' })}
                        control={
                          <Checkbox
                            className={styles.checkBoxRoot}
                            disabled={submitting || (editting && !editMode)}
                            checked={values.type_of_seminar.includes('in_person')}
                            color="primary"
                            onChange={() =>
                              handleChange({
                                target: {
                                  name: 'type_of_seminar',
                                  value: toggleCheckbox(values.type_of_seminar, 'in_person'),
                                },
                              })
                            }
                            name="type_of_seminar"
                          />
                        }
                      />
                    </FormControl>
                    <FormControl classes={{ root: onbStyles.formControlRoot }}>
                      <FormControlLabel
                        labelPlacement="end"
                        classes={{ root: onbStyles.formControlLabelRoot, label: onbStyles.formControlLabeLabel }}
                        label={intl.formatMessage({ id: 'jobSearchActivity.form.virtual' })}
                        control={
                          <Checkbox
                            className={styles.checkBoxRoot}
                            disabled={submitting || (editting && !editMode)}
                            checked={values.type_of_seminar.includes('virtual')}
                            color="primary"
                            onChange={() =>
                              handleChange({
                                target: {
                                  name: 'type_of_seminar',
                                  value: toggleCheckbox(values.type_of_seminar, 'virtual'),
                                },
                              })
                            }
                            name="type_of_seminar"
                          />
                        }
                      />
                    </FormControl>
                    <FormControl classes={{ root: onbStyles.formControlRoot }}>
                      <FormControlLabel
                        labelPlacement="end"
                        classes={{ root: onbStyles.formControlLabelRoot, label: onbStyles.formControlLabeLabel }}
                        label={intl.formatMessage({ id: 'jobSearchActivity.form.telephone' })}
                        control={
                          <Checkbox
                            className={styles.checkBoxRoot}
                            disabled={submitting || (editting && !editMode)}
                            checked={values.type_of_seminar.includes('telephone')}
                            color="primary"
                            onChange={() =>
                              handleChange({
                                target: {
                                  name: 'type_of_seminar',
                                  value: toggleCheckbox(values.type_of_seminar, 'telephone'),
                                },
                              })
                            }
                            name="type_of_seminar"
                          />
                        }
                      />
                    </FormControl>
                    <div style={{ position: 'relative', top: '-27px', left: '-26px' }}>
                      {touched.type_of_seminar && errors.type_of_seminar && (
                        <FormHelperText classes={{ root: onbStyles.tosHelperTextRoot }}>
                          {errors.type_of_seminar}
                        </FormHelperText>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.boxes}>
                  <div>
                    <Box pt={2} pb={1}>
                      <span className={styles.fieldLabel}>
                        {intl.formatMessage({ id: 'jobSearchActivity.form.uploadDocuments' })}
                      </span>
                    </Box>
                    <Field
                      name="upload_documents"
                      disabled={submitting || (editting && !editMode)}
                      component={BrowseDocuments}
                    />
                    <div className={styles.downloadLinks}>
                      {documentsLinks.map((doc) =>
                        doc.downloadUrl ? (
                          <a key={doc.downloadUrl} href={doc.downloadUrl}>
                            {doc.filename}
                          </a>
                        ) : (
                          <span key={JSON.stringify(doc)}>...</span>
                        )
                      )}
                    </div>
                  </div>
                  <div>
                    <Box pt={2} pb={1}>
                      <span className={styles.fieldLabel}>
                        {intl.formatMessage({ id: 'jobSearchActivity.form.notes' })}
                      </span>
                    </Box>
                    <TextField
                      id="filled-textarea"
                      disabled={submitting || (editting && !editMode)}
                      placeholder={intl.formatMessage({ id: 'jobSearchActivity.form.notes' })}
                      multiline
                      rows={5}
                      variant="outlined"
                      InputProps={{ style: { fontSize: 12, height: '112px' } }}
                      value={values.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="notes"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.notes' }),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <Box display="flex" justifyContent="space-between" mt={4}>
              <a
                href="https://labor.hawaii.gov/wdc/american-job-centers/"
                target="_blank"
                rel="noopener noreferrer"
                className={layoutStyles.link}
              >
                {intl.formatMessage({ id: 'jobSearchActivity.form.meetWithCoach' })}
              </a>
              <Box display="flex">
                <Box mr={1}>
                  <Button
                    color="primary"
                    disabled={submitting}
                    size="large"
                    onClick={() => setOpen(false)}
                    variant="contained"
                  >
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })
                    )}
                  </Button>
                </Box>
                {editting && !editMode ? (
                  <Button
                    color="secondary"
                    disabled={submitting}
                    size="large"
                    variant="contained"
                    onClick={(evt) => {
                      evt.preventDefault();
                      trackEvent('WEEK_VIEWACTIVITY_EDIT');
                      setEditMode(true);
                    }}
                  >
                    {intl.formatMessage({ id: 'layout.header.edit' })}
                  </Button>
                ) : (
                  <Button color="secondary" disabled={submitting} size="large" type="submit" variant="contained">
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'jobSearchActivity.form.save' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'jobSearchActivity.form.save' })
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}

AttendJobSeminar.propTypes = {
  addActivityItem: PropTypes.func,
  setOpen: PropTypes.func,
  updateActivityItem: PropTypes.func,
  setGeneralActivity: PropTypes.func,
  selectedActivity: PropTypes.any,
  editting: PropTypes.bool,
};

export default AttendJobSeminar;
