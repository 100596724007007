/* Material UI and other UI Dependencies */
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

/* i18n Dependencies */
import { useIntl } from 'react-intl';

import withStyles from '@mui/styles/withStyles';
import { css } from '@emotion/css';
import cssVars from 'styles/vars.module.scss';

const bordersDict = {
  nj: cssVars.primaryColorMain,
};
const backgroundsDict = {
  nj: 'white',
  wi: cssVars.secondaryColorMain,
};
const activeBackgroundsDict = {
  nj: cssVars.primaryColorMain,
  wi: cssVars.secondaryColorDark,
};
const fontColorsDict = {
  nj: cssVars.primaryColorMain,
  wi: cssVars.black,
};
const activeFontColorsDict = {
  nj: 'white',
  wi: cssVars.white,
};

const makeStyles = (locality) => ({
  toggleButton: css(
    {
      transition: 'background-color 250ms',
      textTransform: 'none !important',
      border: locality in bordersDict ? `solid 1px ${bordersDict[locality]} !important` : 'none',
      '&:not(.Mui-selected)': {
        backgroundColor:
          locality in backgroundsDict ? backgroundsDict[locality] : `${cssVars.secondaryColorMain} !important`,
      },
      span: {
        color:
          locality in fontColorsDict ? fontColorsDict[locality] : locality === 'wi' ? cssVars.white : cssVars.darkGray,
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: `${
            locality in activeBackgroundsDict ? activeBackgroundsDict[locality] : cssVars.secondaryColorDark
          } !important`,
          span: {
            color: `${
              locality in activeFontColorsDict
                ? activeFontColorsDict[locality]
                : locality === 'wi'
                ? cssVars.white
                : cssVars.darkGray
            } !important`,
          },
        },
      },
    },
    { label: 'toggle-button-root' }
  ),
  selected: css(
    {
      backgroundColor: `${
        locality in activeBackgroundsDict ? activeBackgroundsDict[locality] : cssVars.secondaryColorDark
      } !important`,
      span: {
        color: `${locality in activeFontColorsDict ? activeFontColorsDict[locality] : 'black'} !important`,
      },
    },
    { label: 'selected' }
  ),
});

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    border: 'none',
    padding: '2px 8px',
    '&:not(:last-child)': {
      marginRight: 8,
      marginTop: 8,
      marginBottom: 8,
    },
    '&:not(:first-child)': {
      borderRadius: 4,
    },
    '&:first-child': {
      borderRadius: 4,
    },
  },
}))(ToggleButtonGroup);

const JobsToggleButtons = ({ value = '', setValue = () => {} }) => {
  const intl = useIntl();

  const locality = process.env.REACT_APP_LOCALITY;
  const styles = makeStyles(locality);

  const handleChange = (event, newFormats) => {
    if (newFormats) {
      setValue(newFormats);
    } else {
      setValue('');
    }
  };

  return (
    <div style={{ margin: 0 }}>
      {intl.formatMessage({ id: 'jobs.filters.focusOnJobs' })}
      <StyledToggleButtonGroup size="small" exclusive value={value} onChange={handleChange} aria-label="text alignment">
        <ToggleButton classes={{ root: styles.toggleButton, selected: styles.selected }} value="currentCareer">
          {intl.formatMessage({ id: 'jobs.filters.inMyCareer' })}
        </ToggleButton>
        <ToggleButton classes={{ root: styles.toggleButton, selected: styles.selected }} value="similarCareer">
          {intl.formatMessage({ id: 'jobs.filters.similarToMyCareer' })}
        </ToggleButton>
        <ToggleButton classes={{ root: styles.toggleButton, selected: styles.selected }} value="newCareer">
          {intl.formatMessage({ id: 'jobs.filters.newCareer' })}
        </ToggleButton>
      </StyledToggleButtonGroup>
    </div>
  );
};

export default JobsToggleButtons;
