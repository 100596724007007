import { jsPDF } from 'jspdf';
import { formatISO, parse, format } from 'date-fns';

/* i18n */
import { useIntl } from 'react-intl';

export function getTo(fromStr) {
  const year = fromStr.slice(0, 4);
  const month = fromStr.slice(5, 7);
  const day = fromStr.slice(8, 10);
  const from = new Date(year, month - 1, day);
  from.setDate(from.getDate() + 6);
  return from.toISOString().slice(0, 10);
}

export function useFormatDateRange() {
  const intl = useIntl();
  const months = {
    '01': intl.formatMessage({ id: 'jobSearchActivity.january' }),
    '02': intl.formatMessage({ id: 'jobSearchActivity.february' }),
    '03': intl.formatMessage({ id: 'jobSearchActivity.march' }),
    '04': intl.formatMessage({ id: 'jobSearchActivity.april' }),
    '05': intl.formatMessage({ id: 'jobSearchActivity.may' }),
    '06': intl.formatMessage({ id: 'jobSearchActivity.june' }),
    '07': intl.formatMessage({ id: 'jobSearchActivity.july' }),
    '08': intl.formatMessage({ id: 'jobSearchActivity.august' }),
    '09': intl.formatMessage({ id: 'jobSearchActivity.september' }),
    10: intl.formatMessage({ id: 'jobSearchActivity.october' }),
    11: intl.formatMessage({ id: 'jobSearchActivity.november' }),
    12: intl.formatMessage({ id: 'jobSearchActivity.december' }),
  };
  function formatDateRange(from, to) {
    if (!from || !to) {
      return '';
    }
    const year1 = from.substr(0, 4);
    const month1 = from.substr(5, 2);
    const day1 = from.substr(8, 2);
    const year2 = to.substr(0, 4);
    const month2 = to.substr(5, 2);
    const day2 = to.substr(8, 2);

    if (year1 !== year2) {
      return `${months[month1]} ${parseInt(day1, 10)}, ${year1}-${months[month2]} ${parseInt(day2, 10)}, ${year2}`;
    } else if (month1 !== month2) {
      return `${months[month1]} ${parseInt(day1, 10)}-${months[month2]} ${parseInt(day2, 10)}, ${year1}`;
    }
    return `${months[month1]} ${parseInt(day1, 10)}-${parseInt(day2, 10)}, ${year1}`;
  }
  return formatDateRange;
}

export function useExportActivitySummary(fieldsDict, activitiesDict) {
  const intl = useIntl();
  const formatDateRange = useFormatDateRange();

  const boolVal = (val) => {
    return val
      ? intl.formatMessage({ id: 'jobSearchActivity.form.yes' })
      : intl.formatMessage({ id: 'jobSearchActivity.form.no' });
  };

  return function (title, imageUrl, activities, imageWidth = 12, imageHeight = 12, siteUrl = '') {
    const weeksObj = activities.reduce((acc, curr) => {
      const week = curr.week_start_date,
        to = getTo(week);
      if (!acc[week]) {
        acc[week] = { from: week, to, required: 3, items: [curr] };
      } else {
        acc[week].items.push(curr);
      }
      return acc;
    }, {});
    const weeks = Object.keys(weeksObj).sort((a, b) => {
      return new Date(b) - new Date(a);
    });
    const weekArr = weeks.map((w) => weeksObj[w]);
    const doc = new jsPDF();
    const docWidth = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.getHeight();
    doc.addImage(imageUrl, 'PNG', docWidth - 8 - imageWidth, 5, imageWidth, imageHeight);
    doc.setFontSize(20);
    doc.setFont('helvetica', 'bold');
    doc.text(title, 11, 14);
    doc.line(0, 20, docWidth, 20);
    doc.line(0, docHeight - 20, docWidth, docHeight - 20);
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');
    doc.text(`${intl.formatMessage({ id: 'jobSearchActivity.page' })} 1`, docWidth - 24, docHeight - 9);
    doc.text(siteUrl, 14, docHeight - 9);

    let counter = 0;
    let accMargin = 23;
    let page = 1;

    weekArr.forEach((w) => {
      counter++;
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(14);
      doc.text(formatDateRange(w.from, w.to), 11, counter * 7 + accMargin);
      accMargin += 2;
      doc.setFontSize(12);

      w.items.forEach((i) => {
        const originalDate = parse(i.activity_date, 'yyyy-MM-dd', new Date());
        const formattedDate = format(originalDate, 'MM-dd-yyyy');
        counter++;
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
        const splitDescription = doc.splitTextToSize(
          `• ${formattedDate}: ${activitiesDict[i.activity_type]}`,
          docWidth - 20
        );
        doc.text(splitDescription, 11, counter * 7 + accMargin);

        let {
          activity_date, // eslint-disable-line no-unused-vars
          activity_type, // eslint-disable-line no-unused-vars
          worksearch_id, // eslint-disable-line no-unused-vars
          upload_documents, // eslint-disable-line no-unused-vars
          created_on, // eslint-disable-line no-unused-vars
          updated_on, // eslint-disable-line no-unused-vars
          week_start_date, // eslint-disable-line no-unused-vars
          ...rest
        } = i;
        accMargin += 2;

        Object.keys(rest)
          .filter((k) => i[k])
          .sort()
          .forEach((k) => {
            if (
              ['date_of_application', 'date_of_interview', 'date_of_job_fair', 'date_of_workshop'].includes(k) ||
              !fieldsDict[k]
            ) {
              return;
            }
            if (k.startsWith('time_of_')) {
              const timeDate = new Date(i[k]);
              if (timeDate.getTime() !== timeDate.getTime()) {
                return;
              }
              i[k] = `${timeDate.getHours()}:${timeDate.getMinutes()} Hrs`;
            }
            counter++;
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(12);
            const boldText = fieldsDict[k];
            const normalText =
              i[k] === true || i[k] === false
                ? boolVal(i[k])
                : i[k].includes('_')
                ? fieldsDict[i[k]]
                : Array.isArray(i[k])
                ? i[k].map((item) => (item.includes('_') ? fieldsDict[item] : item)).join(', ')
                : i[k];
            const boldTextWidth = doc.getStringUnitWidth(boldText) * 4.4; // 16 is the font size
            const splitItem = doc.splitTextToSize(normalText, docWidth - 14 - boldTextWidth);
            const secondSplitItem = doc.splitTextToSize(splitItem.slice(1).join(' '), docWidth - 14);

            doc.text(`${boldText}: `, 14, counter * 7 + accMargin);

            doc.setFont('helvetica', 'normal');
            doc.setFontSize(11);
            doc.text(splitItem.slice(0, 1), 15 + boldTextWidth, counter * 7 + accMargin);

            if (counter >= 31) {
              counter = 0;
              accMargin = 10;
              doc.addPage('a4', 'p');
              page += 1;
              doc.line(0, docHeight - 20, docWidth, docHeight - 20);
              doc.text(`${intl.formatMessage({ id: 'jobSearchActivity.page' })} ${page}`, docWidth - 24, docHeight - 9);
              doc.text(siteUrl, 14, docHeight - 9);
            }

            //doc.text(splitItem, 14, counter * 7 + accMargin);
            if (splitItem.length > 1) {
              if (counter !== 0) {
                counter += 0.7;
              }
              doc.text(secondSplitItem, 15, counter * 7 + accMargin);
            }
            if (secondSplitItem.length > 1) {
              counter += (secondSplitItem.length - 1) * 0.66;
            }
            if (counter >= 31) {
              counter = 0;
              accMargin = 10;
              doc.addPage('a4', 'p');
              page += 1;
              doc.line(0, docHeight - 20, docWidth, docHeight - 20);
              doc.text(`${intl.formatMessage({ id: 'jobSearchActivity.page' })} ${page}`, docWidth - 24, docHeight - 9);
              doc.text(siteUrl, 14, docHeight - 9);
            }
          });
        accMargin += 4;
      });
    });
    doc.save(`activity_summary_${formatISO(new Date())}.pdf`);
  };
}
