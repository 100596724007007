import { useEffect, useRef } from 'react';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { darken } from 'polished';
import PropTypes from 'prop-types';
//import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListIcon from '../images/icons/funnel.svg';
import cssVars from 'styles/vars.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '0 !important',
    padding: '0px 4px 0 0',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${cssVars.mediumGray}`,
    '&:hover': {
      backgroundColor: cssVars.lightGray,
    },
    '&:active': {
      backgroundColor: darken(0.1, cssVars.lightGray),
    },
    '@media screen and (max-width: 899px)': {
      justifyContent: 'flex-start',
      paddingLeft: 18,
    },
  },
  input: {
    marginLeft: '0px',
    fontSize: '12px',
    '@media screen and (max-width: 767px)': {
      fontSize: '16px',
    },
  },
  iconButton: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '7px 5px 7px 0',
    position: 'relative',
    top: '1px',
  },
}));

export default function FiltersButton({ onClick = () => {}, setAnchorEl = () => {} }) {
  const intl = useIntl();
  const classes = useStyles();
  const ref = useRef(null);
  useEffect(() => {
    setAnchorEl(ref.current);
  });

  return (
    <Paper component="div" className={classes.root} onClick={onClick} ref={ref}>
      <span className={classes.iconButton} aria-label="search">
        <img
          src={FilterListIcon}
          style={{ width: '16px', padding: '3px 0 0' }}
          alt={intl.formatMessage({ id: 'jobs.filters.filters' })}
        />
      </span>
      <span className={classes.input}>{intl.formatMessage({ id: 'jobs.filters.filters' })}</span>
    </Paper>
  );
}

FiltersButton.propTypes = {
  onClick: PropTypes.func,
  setAnchorEl: PropTypes.func,
};
