import { useEffect } from 'react';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Analytics */
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import { Typography, Box, Container } from '@mui/material';
import CloseTabButton from 'components/CloseTabButton';

const TermsOfUseES = ({ locale }) => {
  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('TERMSOFUSE');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
    return () => {
      console.log('Leaving Terms of Use');
    };
  }, []);

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <CloseTabButton className={layoutStyles.closeButton} />
          <Typography variant="h1" gutterBottom>
            Condiciones de Uso y Política de Privacidad
          </Typography>
          <hr className={layoutStyles.separator} />
          <Box m={4} />
          <Typography variant="body2" component="p" gutterBottom>
            Bienvenido al Navegador de Aceleración Profesional de Hawái (“HI CAN”)!
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Estamos tomando todas las precauciones, incluidos nuestros mejores esfuerzos para cumplir con todas las
            leyes y reglamentos estatales y federales pertinentes relacionados con la privacidad, para garantizar que su
            información sea lo más segura posible.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Al visitar y utilizar este sitio web, sus subpáginas y contenidos (denominados colectivamente, este sitio
            web), usted acepta las Condiciones de uso y la Política de privacidad.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Elegibilidad
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            <p>
              Al usar este sitio web, usted garantiza que tiene 18 años de edad o más y que tiene la autoridad legal
              para aceptar estos Términos de uso.
            </p>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Uso de la información
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            El Departamento de Trabajo y Relaciones Industriales de Hawái y/o sus agentes/contratistas/socios utilizarán
            los registros del estado de Hawái y la información que usted proporcione cuando se inscriba en HI CAN para
            ponerle en contacto con oportunidades personalizadas de empleo y formación.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            También podemos utilizar y compartir la información proporcionada si es razonablemente necesario para
            cumplir la ley o un proceso legal (incluida una orden o citación judicial o gubernamental); detectar,
            prevenir o solucionar de cualquier otro modo problemas técnicos, de fraude o de seguridad; hacer cumplir
            tanto las Condiciones de Uso como la Política de Privacidad; proteger los derechos, la propiedad o la
            seguridad del estado de Hawái, de nuestros usuarios y/o del público; o de otro modo con su consentimiento
            expreso.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            La información que proporcione puede considerarse un “registro gubernamental”, tal y como se define en el
            Capítulo 92F de los Estatutos Revisados de Hawái, Definiciones generales. El acceso, incluido el acceso
            público, a los registros gubernamentales se rige principalmente por el Capítulo 92F de los Estatutos
            Revisados de Hawái. Además, puede haber otras leyes que exijan el acceso público a la información
            proporcionada.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Al inscribirse en HI CAN, acepta que el Departamento y/o sus contratistas utilicen la información anterior,
            así como cualquier dato asociado que haya puesto a disposición del Departamento, para crear recomendaciones
            personalizadas de empleo y oportunidades profesionales para usted. Asimismo, acepta que el Departamento y/o
            sus contratistas y socios podrán contactarlo y/o le enviarle comunicaciones que pueden incluir, sin
            limitación: información importante sobre oportunidades de contratación laboral, información sobre puestos de
            trabajo disponibles, oportunidades de formación profesional, oportunidades de educación y otros recursos y
            oportunidades relacionados con COVID-19 o las competencias.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Al proporcionar su número de teléfono móvil, usted acepta específicamente que el Departamento le envíe
            comunicaciones a su teléfono móvil que pueden incluir, sin limitación: información importante relativa a los
            programas del Seguro de Desempleo, oportunidades de contratación laboral que pueden ser adecuadas para
            usted, información sobre puestos de trabajo disponibles que pueden ser adecuados para usted y oportunidades
            de formación profesional disponibles.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Puede optar por permitir que el Departamento comparta su información de contacto directamente con
            empresarios y/o reclutadores que puedan estar interesados en contratarle en función de sus aptitudes,
            intereses y experiencia. Si elige este servicio, acepta que el Departamento y/o sus agentes/contratistas
            compartan su información de contacto, empleo anterior e historial educativo, y una recomendación sobre su
            idoneidad para un puesto de trabajo o programa de formación directamente con empresarios y agencias de
            contratación, colocación y gubernamentales de Hawái con el único fin de ponerse en contacto con usted en
            relación con oportunidades de contratación o formación que puedan ser adecuadas para sus aptitudes y
            formación.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Aviso de seguridad y vigilancia
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Si navega por este sitio web para leer, imprimir o descargar información, es posible que se recopile
            información de navegación.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Por motivos de seguridad del sitio, todo el tráfico de la red se supervisa para identificar intentos no
            autorizados de cargar o modificar información, o de llevar a cabo actividades delictivas. En relación con
            investigaciones judiciales autorizadas y de conformidad con procesos legales requeridos, la información de
            navegación puede utilizarse para ayudar a obtener información personal.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Además, para proteger este sitio web, así como el sistema informático asociado que lo respalda, frente a
            usos no autorizados y para garantizar el correcto funcionamiento del sistema informático, las personas que
            accedan a este sitio web y al sistema informático asociado están sujetas a que el personal autorizado por el
            estado de Hawái para hacerlo supervise y grabe todas sus actividades. Cualquier persona que utilice este
            sitio web y el sistema informático asociado consiente expresamente a dicha supervisión y se le informa que
            si dicha supervisión revela pruebas de un posible abuso o actividad delictiva, el personal del estado de
            Hawái puede proporcionar los resultados de dicha supervisión a los funcionarios apropiados. Los intentos no
            autorizados de cargar o modificar el contenido del sitio web, o de causar daños de cualquier otra forma a
            este sitio web o al sistema informático, están estrictamente prohibidos y pueden ser objeto de sanción por
            la legislación aplicable.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Modificaciones sin previo aviso
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Este sitio web es dinámico y cambiará con el tiempo sin previo aviso. Por lo tanto, los usuarios de este
            sitio web son plenamente responsables de comprobar por sí mismos la exactitud, integridad, actualidad e
            idoneidad de los contenidos encontrados en el sitio web.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Además, nos reservamos el derecho a modificar las Condiciones de uso y la Política de privacidad en
            cualquier momento. Le recomendamos que revise periódicamente las Condiciones de uso y la Política de
            privacidad. El uso continuado de HI CAN tras cualquier modificación de la página web, de las Condiciones de
            Uso y de la Política de Privacidad constituye su aceptación de dichas modificaciones.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Exclusión de garantías
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Este sitio web se proporciona “TAL CUAL” y sin garantías de ningún tipo. En la máxima medida permitida por
            la ley, el estado de Hawái, incluidas cada agencia, funcionario o empleado del estado de Hawái, renuncia a
            todas las garantías, expresas o implícitas, incluidas, entre otras, las garantías de comerciabilidad e
            idoneidad para un fin determinado, con respecto a este sitio web; esta exención de garantías incluye, entre
            otras, las exenciones relativas a lo siguiente: (1) que el funcionamiento de este sitio web será
            ininterrumpido y libre de errores; (2) que serán corregidos cualquier defecto u otros errores en este sitio
            web; y (3) que este sitio web así como el sistema informático están o estarán libres de virus u otros
            componentes dañinos.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Además, ni el estado de Hawái ni ninguna agencia, funcionario o empleado del estado de Hawái hace ninguna
            declaración, garantía o aval en cuanto a: (1) la exactitud, integridad, vigencia o idoneidad de la
            información suministrada a través de este sitio web; (2) el uso o los resultados del uso de este sitio web;
            y (3) la exactitud, fiabilidad, disponibilidad o integridad o utilidad de los contenidos de los sitios web
            creados y mantenidos por personas ajenas al estado de Hawái y enlazados a o desde este sitio web.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Limitación de responsabilidad
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Bajo ninguna circunstancia, incluyendo la negligencia, pero sin limitarse a ella, el estado de Hawái ni
            ninguna agencia, funcionario o empleado del estado de Hawái será responsable de ningún daño especial,
            consecuente y/o incidental que pudiere resultar del uso o la imposibilidad de acceder o usar este sitio web,
            incluso si el estado de Hawái hubiere sido advertido de la posibilidad de tales daños. Es posible que la
            legislación aplicable no permita la limitación o exclusión de responsabilidad o de daños incidentales o
            consecuentes, por lo que es posible que la limitación o exclusión anterior no se aplique en su caso. En
            ningún caso la responsabilidad total del estado de Hawái hacia usted por todos los daños, pérdidas y causas
            de acción (ya sea en contrato, agravio (incluyendo, entre otros, la negligencia, o de otra manera) excederá
            la cantidad pagada por usted, en su caso, para acceder a este sitio web.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Aviso de derechos de autor
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Queda prohibida la duplicación o utilización de cualquier contenido de este sitio web con fines comerciales
            o de cualquier forma que pudiere dar la impresión de aprobación oficial por parte del estado de Hawái.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Enlaces a sitios y páginas web externos
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            <p>
              Este sitio web puede contener enlaces de hipertexto a sitios y páginas externas que contienen información
              creada y mantenida por organizaciones públicas y privadas distintas del estado de Hawái. El estado de
              Hawái podrá crear dichos enlaces de hipertexto si determina que el establecimiento del enlace externo será
              coherente con la ayuda o el fomento del propósito de este sitio web, que es promover de manera
              significativa y diligente el servicio público a ciudadanos y empresas mediante lo siguiente:
            </p>
            <ul>
              <li>ampliar el acceso de las empresas y los ciudadanos a los servicios y la información del gobierno;</li>
              <li>
                ofrecer un proceso fácil y cómodo para que las empresas y los ciudadanos realicen transacciones en línea
                con el gobierno del estado;
              </li>
              <li>
                acelerar el desarrollo y la prestación de un mayor volumen de servicios gubernamentales en línea de
                calidad;
              </li>
              <li>mejorar el nivel de servicio al cliente del gobierno del estado; y</li>
              <li>ampliar los servicios de gobierno electrónico a los ciudadanos de ciudades y condados.</li>
            </ul>
            <p>
              Asimismo, el estado de Hawái podrá crear enlaces de hipertexto con fines informativos, es decir, cuando el
              sitio web externo enlazado proporcione información útil y valiosa a los visitantes de este sitio web, o
              cuando el sitio web externo enlazado esté obligado o autorizado por ley.
            </p>
            <p>
              El estado de Hawái, a su entera discreción, determinará si el sitio web externo cumple con el propósito de
              este sitio web o para los fines informativos especificados. La inclusión de un enlace de hipertexto a un
              sitio web externo no supone la aprobación de ningún producto o servicio ofrecido o mencionado en el sitio
              web enlazado, de las organizaciones que patrocinan dicho sitio web ni de ninguna opinión que pueda
              expresarse o mencionarse en el sitio web.
            </p>
            <p>
              El estado de Hawái podrá eliminar o sustituir estos enlaces de hipertexto a sitios y páginas externas a su
              entera discreción, en cualquier momento y sin previo aviso. En caso de que descubra problemas o tenga
              dudas sobre el formato, exactitud, actualidad o integridad de un sitio externo enlazado, póngase en
              contacto con la organización responsable del sitio externo enlazado: el estado de Hawái no controla ni es
              responsable de ningún sitio y página externos enlazados.
            </p>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Descargo de responsabilidad
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Cualquier referencia en este sitio web a cualquier producto, proceso o servicio comercial específico por su
            nombre comercial, marca registrada, fabricante u otro, no constituye ni implica su aprobación, recomendación
            o favorecimiento por parte del estado de Hawái.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Jurisdicción
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Cualquier persona que decida acceder y utilizar este sitio web se somete a la jurisdicción del Estado de
            Hawái.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Estos términos se regirán e interpretarán de acuerdo con las leyes del Estado de Hawái. Cualquier disputa
            que surja de o en relación con estos términos, o el acceso y uso de este sitio web se decidirá bajo las
            leyes de y en los tribunales estatales en el Estado de Hawái, y por la presente acepta y se somete a la
            jurisdicción personal de dichos tribunales con el fin de dirimir dicha disputa.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Otros Sitios Web
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Este sitio web puede contener enlaces de hipertexto a otros sitios y páginas web que contienen información
            creada y mantenida por otras organizaciones públicas y privadas. Si decide visitar estos sitios enlazados,
            debe comprobar las declaraciones de privacidad de estos otros sitios web. El estado de Hawái no es
            responsable del contenido proporcionado en estos otros sitios web.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Divisibilidad
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Si un tribunal competente determina que alguna de las disposiciones de las Condiciones de Uso y la Política
            de Privacidad es ilegal, nula o inaplicable, dicha disposición se considerará independiente del resto de las
            condiciones y no afectará a la validez y aplicabilidad del resto de las disposiciones.
          </Typography>
          <Box m={3} />
        </Container>
      </div>
    </>
  );
};

export default TermsOfUseES;
