import PropTypes from 'prop-types';
import { css } from '@emotion/css';
import { propOr } from 'ramda';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { updateKey } from 'app/slices/benefitsFinderSlice';

/* Material UI and other UI Dependencies */
import NumberFormat from 'react-number-format';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import cssVars from 'styles/vars.module.scss';

const styles = {
  title: css(
    {
      fontSize: '18px',
    },
    { label: 'title' }
  ),
  input: css(
    {
      border: `1px solid ${cssVars.mediumGray} !important`,
      height: '40px',
      padding: '1px 10px',
      borderRadius: '4px',
    },
    { label: 'input' }
  ),
  error: css({
    color: cssVars.errorColorMain,
    fontSize: 15,
    paddingTop: 8,
  }),
};

export default function NumericQuestion({ name, title, touched, error }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const benefitsResults = useSelector((state) => state.benefitsFinder.results);
  const inputValue = propOr('', name, benefitsResults);

  const handleChange = (v) => {
    dispatch(updateKey({ key: name, value: v.value }));
  };

  return (
    <div>
      <h2 className={styles.title}>{title}</h2>
      <div>
        <NumberFormat
          displayType={'input'}
          type={'text'}
          allowNegative={false}
          allowLeadingZeros={false}
          isNumericString={true}
          onValueChange={handleChange}
          value={inputValue}
          className={styles.input}
        />
        {touched && error ? <div className={styles.error}>{intl.formatMessage({ id: error })}</div> : null}
      </div>
    </div>
  );
}

NumericQuestion.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
};
