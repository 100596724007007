import { useRef, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useHistory, Link as ReactLink, useLocation } from 'react-router-dom';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* Styles */
import styles from 'styles/layout.module.scss';
import drawerStyles from 'styles/drawer.module.scss';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setLocale, setDrawerOpen, setLoadingLanguage } from 'app/slices/appSlice';
import { setOpenContactInfo } from 'app/slices/experienceSlice';

/* API */
import { useAuth0 } from '@auth0/auth0-react';

/* Assets */
import riplVars from 'styles/vars.module.scss';
import hiVars from 'styles/vars-hi.module.scss';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Material UI and other UI Dependencies */
import Container from '@mui/material/Container';
import { Search as SearchIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Hidden, ListItem } from '@mui/material';
import Box from '@mui/material/Box';

import useMediaQuery from '@mui/material/useMediaQuery';
import HamburgerMenu from 'react-hamburger-menu';

/* API */
import { updateExperience } from 'shared/src/lib/API/experience';

/* Colors */
import colors from 'styles/vars.module.scss';
import LanguagesSelect from 'components/LanguagesSelect';
import { useQueryClient } from '@tanstack/react-query';

const varsDict = {
  ripl: riplVars,
  hi: hiVars,
};

const Header = ({ locale, loggedIn, signOut }) => {
  const dispatch = useDispatch();
  const showHamburger = useMediaQuery('(max-width:767px)');
  const mobileSearchInputEl = useRef(null);
  const [hamOpen, setHamOpen] = useState(false);
  const { isAuthenticated } = useAuth0();
  const queryClient = useQueryClient();

  const intl = useIntl();
  const urlPrefix = getUrlPrefix(intl.locale);

  let history = useHistory();
  const location = useLocation();
  const user_settings = useSelector((state) => state.app.user_settings);
  const needsOnboarding = useSelector((state) => state.app.needsOnboarding);
  const worksearch_status = useSelector((state) => state.worksearch.status);
  const locality = process.env.REACT_APP_LOCALITY;
  const vars = locality in varsDict ? varsDict[locality] : varsDict.ripl;
  if (vars.headerLogoHref) {
    vars.headerLogoHref = vars.headerLogoHref.replace(/['"]+/g, '');
  }

  const languageEventDict = {
    en: 'ENGLISH',
    es: 'ESPANOL',
    zh: 'CHINESE',
    ja: 'JAPANESE',
    tl: 'TAGALOG',
    ko: 'KOREAN',
    vi: 'VIETNAMESE',
    ilo: 'ILOKANO',
  };

  const resetLanguage = async (e) => {
    e.preventDefault();
    const language = e.target.value;
    if (typeof window !== 'undefined') {
      localStorage.setItem('lng', language);
      trackEvent(`I_MENU_${languageEventDict[language]}`);
      const newPath =
        language === 'en'
          ? `/#${location.pathname.substring(1 + intl.locale.length)}`
          : intl.locale === 'en'
          ? `/#/${language}${location.pathname}`
          : `/#/${language}${location.pathname.substring(1 + intl.locale.length)}`;
      dispatch(setLocale(language));
      dispatch(setLoadingLanguage(true));
      window.location.href = newPath;
      if (isAuthenticated) {
        await updateExperience({ language }).catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
      }
      queryClient.resetQueries({
        predicate: (query) => {
          if (newPath.includes('contact-info')) {
            return false;
          }
          return newPath.includes('onboarding') ? query.queryKey[0] !== '/users/me/experience/' : true;
        },
      });
      dispatch(setLoadingLanguage(false));
    }
  };

  const formik = useFormik({
    initialValues: {
      query: '',
    },
    onSubmit: async ({ query }) => {
      if (query.trim() === '') {
        return false;
      }
      history.push(`${urlPrefix}/coach?query=${query}`);
    },
  });

  const handleDrawerOpen = (e) => {
    e.preventDefault();
    dispatch(setDrawerOpen(true));
  };
  const handleMobileMagnifierClick = () => {
    const element = mobileSearchInputEl.current;
    element.focus();
  };
  const handleBlur = (...params) => {
    formik.resetForm();
    formik.handleBlur(...params);
  };
  const handleHireNet = () => {
    window.open('https://www.hirenethawaii.com/vosnet/Default.aspx');
  };
  const handleSignOut = () => {
    const eventName = 'I_HAMBURGER_SIGNOUT';
    trackEvent(eventName);
    signOut();
  };

  const handleEditClick = () => {
    dispatch(setOpenContactInfo(true));
    history.push(`${urlPrefix}/experience`);
  };

  return (
    <div>
      <div
        className={cn({
          [styles.header]: true,
          [styles.header__alt]: loggedIn,
          [styles.header__alt__mobileMenuOpen]: hamOpen,
        })}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {process.env.REACT_APP_JOB_COACH === 'true' ? (
            <form onSubmit={formik.handleSubmit}>
              <FilledInput
                id="filled-adornment-mobile-search"
                type="text"
                name="query"
                value={formik.values.query}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                classes={{ root: styles.mobileSearchInputRoot, focused: styles.inputFocused }}
                disableUnderline
                inputProps={{
                  ref: mobileSearchInputEl,
                  title: intl.formatMessage({ id: 'layout.submitCoachSearch' }),
                }}
              />
            </form>
          ) : null}

          {loggedIn && process.env.REACT_APP_JOB_COACH === 'true' ? (
            <IconButton
              onClick={handleMobileMagnifierClick}
              color="primary"
              aria-label={intl.formatMessage({ id: 'layout.submitCoachSearch' })}
              component="span"
              classes={{ root: styles.mobileSearchButton }}
              size="large"
            >
              <SearchIcon style={{ fontSize: 23 }} />
            </IconButton>
          ) : (
            <Box px={1} display="inline-block" />
          )}

          <Hidden smDown>
            {'logoHeaderUrl' in vars ? (
              <div className={styles.logoContainer}>
                <img className={styles.headerLogo} src={vars.logoHeaderUrl.slice(1, -1)} alt={vars.organizationName} />
              </div>
            ) : null}
          </Hidden>
          <Hidden smDown={!isAuthenticated}>
            {'secondLogoHeaderUrl' in vars ? (
              <Box className={cn(styles.logoContainer, styles.desktopCentered)}>
                <img
                  className={cn({
                    [styles.headerSecondLogo]: true,
                  })}
                  src={vars.secondLogoHeaderUrl.slice(1, -1)}
                  alt={vars.organizationName}
                />
              </Box>
            ) : null}
          </Hidden>

          <Box display="flex" alignItems="center" flexGrow={1} justifyContent="flex-end">
            {/* Language Selector */}
            {!showHamburger || !loggedIn ? <LanguagesSelect locale={locale} resetLanguage={resetLanguage} /> : null}

            {/* Hamburger */}
            {loggedIn ? (
              <>
                {showHamburger ? (
                  <div className={styles.hamburgerButton__container}>
                    <HamburgerMenu
                      className={styles.hamburgerButton}
                      isOpen={hamOpen}
                      menuClicked={() => setHamOpen(!hamOpen)}
                      width={27}
                      height={14}
                      strokeWidth={3}
                      rotate={0}
                      color={colors.actionBlue}
                      borderRadius={0}
                      animationDuration={0.5}
                    />
                    <div className={styles.hamburgerButton__text}>
                      {intl.formatMessage({ id: hamOpen ? 'layout.hamburger.close' : 'layout.hamburger.menu' })}
                    </div>
                  </div>
                ) : (
                  <span
                    className={cn({
                      [styles.link]: true,
                      [styles.infoIcon__container]: true,
                    })}
                    onClick={handleDrawerOpen}
                  >
                    <HamburgerMenu
                      className={styles.hamburgerButton}
                      menuClicked={() => {}}
                      isOpen={false}
                      width={27}
                      height={14}
                      strokeWidth={3}
                      rotate={0}
                      color={colors.actionBlue}
                      borderRadius={0}
                      animationDuration={0.5}
                    />
                  </span>
                )}
                {process.env.REACT_APP_JOB_COACH === 'true' && (
                  <form onSubmit={formik.handleSubmit}>
                    <FilledInput
                      id="filled-adornment-search"
                      type="text"
                      name="query"
                      value={formik.values.query}
                      onBlur={handleBlur}
                      onChange={formik.handleChange}
                      classes={{ root: styles.searchInputRoot, focused: styles.inputFocused }}
                      disableUnderline
                      endAdornment={
                        <label className={styles.searchFieldLabel} htmlFor="filled-adornment-search">
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        </label>
                      }
                      inputProps={{
                        title: intl.formatMessage({ id: 'layout.askJobCoach' }),
                      }}
                    />
                  </form>
                )}
              </>
            ) : null}
          </Box>
        </Container>
      </div>
      {showHamburger ? (
        <div
          className={cn({
            [styles.mobileMenu]: true,
            [styles.mobileMenuOpen]: hamOpen,
          })}
        >
          <div className={styles.mobileMenu__languagesContainer}>
            <LanguagesSelect locale={locale} resetLanguage={resetLanguage} />
          </div>

          <ListItem>
            <div style={{ padding: '10px 0px' }}>
              <h4
                style={{
                  textTransform: 'uppercase',
                  marginBlockStart: '0',
                  marginBlockEnd: '0',
                  marginBottom: '10px',
                  color: 'white',
                }}
              >
                {intl.formatMessage({ id: 'layout.menu.additionalResources' })}
              </h4>
              <span className={styles.menuDrawerLink} onClick={handleHireNet}>
                <span
                  className={cn(styles.menuDrawerLink__text)}
                  style={{ color: 'white', textTransform: 'uppercase', fontSize: '14px' }}
                >
                  HireNet Hawaii
                </span>
                &nbsp;
                <img
                  alt={intl.formatMessage({ id: 'accessibility.externalLink' })}
                  src="/images/external-link-alt-solid.svg"
                  width="14"
                  height="13"
                  style={{ filter: 'invert(100%)' }}
                />
              </span>
            </div>
          </ListItem>

          <div
            className="divider-menu"
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: '#2E6AD1',
            }}
          ></div>

          {!needsOnboarding ? (
            <>
              <ul>
                <h4
                  style={{
                    textTransform: 'uppercase',
                    marginBlockStart: '0',
                    marginBlockEnd: '0',
                    marginBottom: '10px',
                    color: 'white',
                    marginTop: '10px',
                  }}
                >
                  {intl.formatMessage({ id: 'layout.menu.shortcuts' })}
                </h4>
                <li className={styles.mobileMenu__item}>
                  <ReactLink to={`${urlPrefix}/careers`} onClick={() => setHamOpen(false)}>
                    <span className={styles.mobileMenu__link}>{intl.formatMessage({ id: 'layout.menu.careers' })}</span>
                  </ReactLink>
                </li>
                <li className={styles.mobileMenu__item}>
                  <ReactLink to={`${urlPrefix}/jobs`} onClick={() => setHamOpen(false)}>
                    <span className={styles.mobileMenu__link}>{intl.formatMessage({ id: 'layout.menu.jobs' })}</span>
                  </ReactLink>
                </li>
                <li className={styles.mobileMenu__item}>
                  <ReactLink to={`${urlPrefix}/training`} onClick={() => setHamOpen(false)}>
                    <span className={styles.mobileMenu__link}>
                      {intl.formatMessage({ id: 'layout.menu.training' })}
                    </span>
                  </ReactLink>
                </li>
                <li className={styles.mobileMenu__item}>
                  <ReactLink to={`${urlPrefix}/experience`} onClick={() => setHamOpen(false)}>
                    <span className={styles.mobileMenu__link}>
                      {intl.formatMessage({ id: 'layout.menu.experience' })}
                    </span>
                  </ReactLink>
                </li>
                <li className={styles.mobileMenu__item}>
                  <ReactLink to={`${urlPrefix}/ratings`} onClick={() => setHamOpen(false)}>
                    <span className={styles.mobileMenu__link}>
                      {intl.formatMessage({ id: 'layout.menu.savedItems' })}
                    </span>
                  </ReactLink>
                </li>
                {process.env.REACT_APP_JOB_COACH === 'true' && (
                  <li className={styles.mobileMenu__item}>
                    <ReactLink to={`${urlPrefix}/coach`} onClick={() => setHamOpen(false)}>
                      <span className={styles.mobileMenu__link}>{intl.formatMessage({ id: 'layout.menu.coach' })}</span>
                    </ReactLink>
                  </li>
                )}
              </ul>

              <div
                className="divider-menu"
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#2E6AD1',
                }}
              ></div>

              {user_settings.add_job_search_activity_hero_button ||
              user_settings.view_benefits_for_job_seekers_button ? (
                <ul className={styles.mobileMenu__items}>
                  {user_settings.add_job_search_activity_hero_button ? (
                    <li className={cn(styles.mobileMenu__item, styles.noTextTransform)}>
                      <ReactLink to={`${urlPrefix}/job-search-activity`} onClick={() => setHamOpen(false)}>
                        <span className={styles.mobileMenu__link}>
                          {user_settings.add_job_search_activity_hero_button_bell &&
                          ((worksearch_status?.weekly_activities_required !== 0 &&
                            worksearch_status?.weekly_activities_required >
                              worksearch_status?.weekly_activities_completed) ||
                            worksearch_status?.ssn_required) ? (
                            <span className={styles.bellIcon__container}>
                              <NotificationsIcon className={styles.bellIcon} />
                              <span className={styles.bellIcon__number}>
                                {worksearch_status?.ssn_required
                                  ? 3
                                  : worksearch_status?.weekly_activities_required -
                                    worksearch_status?.weekly_activities_completed}
                              </span>
                            </span>
                          ) : null}
                          {intl.formatMessage({ id: 'recommendations.addActivity' })}
                        </span>
                      </ReactLink>
                    </li>
                  ) : null}
                  {user_settings.view_benefits_for_job_seekers_button ? (
                    <li className={cn(styles.mobileMenu__item, styles.noTextTransform)}>
                      <ReactLink to={`${urlPrefix}/financial-help`} onClick={() => setHamOpen(false)}>
                        <span
                          className={styles.mobileMenu__link}
                          style={{ fontSize: '14px', textTransform: 'uppercase' }}
                        >
                          {intl.formatMessage({ id: 'layout.hamburger.viewFinancialHelp' })}
                        </span>
                      </ReactLink>
                    </li>
                  ) : null}
                </ul>
              ) : null}

              <div
                className="divider-menu"
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#2E6AD1',
                }}
              ></div>

              <ul className={styles.mobileMenu__items}>
                <li
                  className={cn(styles.mobileMenu__item, styles.noTextTransform)}
                  style={{ fontSize: '14px', textTransform: 'uppercase', color: 'white' }}
                >
                  <span className={cn(styles.mobileMenu__item, styles.noTextTransform)} onClick={handleEditClick}>
                    {intl.formatMessage({ id: 'layout.header.editProfile' })}
                  </span>
                </li>
                <li className={cn(styles.mobileMenu__item, styles.noTextTransform)}>
                  <span
                    className={cn(styles.mobileMenu__link, drawerStyles.menuDrawerLink__text__link)}
                    onClick={handleSignOut}
                    style={{ fontSize: '14px', textTransform: 'uppercase' }}
                  >
                    {intl.formatMessage({ id: 'layout.menu.signOut' })}
                  </span>
                </li>
              </ul>
            </>
          ) : (
            ''
          )}
        </div>
      ) : null}
    </div>
  );
};

Header.propTypes = {
  locale: PropTypes.string,
  loggedIn: PropTypes.bool,
  signOut: PropTypes.func,
};

export default Header;
