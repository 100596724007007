import { apiRequest } from 'lib/API';

export const trackEvent = (eventName, item = {}) => {
  return new Promise((resolve, reject) => {
    if (typeof item != 'object') {
      item = { value: item };
    }
    apiRequest('post', '/events', {
      data: {
        action: 'event',
        name: eventName,
        item: item,
        location: window.location.hash,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
