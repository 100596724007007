import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

/* Redux */
import { useSelector } from 'react-redux';

/* Material UI and other UI Dependencies */
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

/* Styles */
import { css } from '@emotion/css';

const makeStyles = () => ({
  closeButton: css({
    float: 'right',
    position: 'relative',
    top: 0,
    padding: '40px',
  }),
});

const CloseTabButton = ({ inlineStyle = {}, className = '' }) => {
  const history = useHistory();
  const styles = makeStyles();
  const { urlPrefix } = useSelector((state) => state.app);

  const handleClose = () => {
    if (history.length === 1) {
      window.open('about:blank', '_self');
      window.close();
    }
    history.push(`${urlPrefix}/`);
  };

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={handleClose}
      style={inlineStyle}
      className={`${styles.closeButton} ${className}`}
      endIcon={<CloseIcon />}
    >
      CLOSE
    </Button>
  );
};

CloseTabButton.propTypes = {
  inlineStyle: PropTypes.object,
  className: PropTypes.string,
};

export default CloseTabButton;
