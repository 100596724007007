import { css } from '@emotion/css';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { propOr, difference } from 'ramda';
import withStyles from '@mui/styles/withStyles';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import cssVars from 'styles/vars.module.scss';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const CustomAutocomplete = withStyles({
  tag: {
    borderRadius: 0,
    backgroundColor: cssVars.tagSkill,
    height: 22,
    position: 'relative',
    zIndex: 0,
    '& .MuiChip-label': {
      color: cssVars.darkGray,
      fontWeight: 700,
      fontSize: '10px',
      textTransform: 'uppercase',
    },
    '& .MuiChip-deleteIcon': {
      width: '14px',
      height: '14px',
    },
  },
  endAdornment: {
    top: 'initial',
    bottom: '9px',
  },
})(Autocomplete);

export default function GenericAutocomplete({ options, label, isDisabled, setLocationsFilter, activeOptions }) {
  const intl = useIntl();

  return (
    <CustomAutocomplete
      multiple
      disableClearable
      disableCloseOnSelect={true}
      id={'add-skills'}
      options={options}
      disabled={isDisabled}
      value={activeOptions}
      onChange={(_, newValue) => {
        setLocationsFilter(newValue);
      }}
      classes={{
        input: css({ flex: '0 1 100%' }),
        paper: css({
          border: `solid 1px ${cssVars.mediumGray}`,
          margin: '0 !important',
          borderRadius: '0 !important',
        }),
      }}
      renderInput={(params) => (
        <TextField {...params} variant="filled" label={activeOptions.length ? '' : label} placeholder={''} />
      )}
    />
  );
}
