import PropTypes from 'prop-types';
import { useState } from 'react';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { genericApiRequest } from 'lib/API';

/* Styles */
import { css } from '@emotion/css';
import cssVars from 'styles/vars.module.scss';

/* Material UI and other UI Dependencies */
import { Box, Typography, Container } from '@mui/material';
import LandingPage from './LandingPage';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Results from './Results';
import Progress from './SurveyComponents/Progress';
import OnboardingHeader from 'components/layout/OnboardingHeader';

const styles = {
  content: css({
    padding: '0 0 50px',
  }),
  survey: css({
    backgroundColor: cssVars.white,
    padding: '40px 0 20px',
  }),
};

function trackBenefitsEvent(name, item = {}) {
  if (!window || !window.location) {
    return;
  }
  genericApiRequest('post', '/api/vp2/events', {
    data: {
      action: 'event',
      name,
      location: window.location.hash,
      item,
    },
  });
}

const BenefitsFinder = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const intl = useIntl();

  return (
    <div className={styles.content}>
      <OnboardingHeader />
      <Container maxWidth="lg" className={styles.survey}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={1}>
          <Typography variant="h1">{intl.formatMessage({ id: 'app.benefitsFinder.header' })}</Typography>
          <Typography variant="body2">{intl.formatMessage({ id: 'app.benefitsFinder.subHeader' })}</Typography>
        </Box>
        <Progress currentStep={currentStep} />
        {currentStep === 0 && (
          <LandingPage trackEvent={trackBenefitsEvent} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        )}
        {currentStep === 1 && (
          <Section1 trackEvent={trackBenefitsEvent} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        )}
        {currentStep === 2 && (
          <Section2 trackEvent={trackBenefitsEvent} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        )}
        {currentStep === 3 && (
          <Section3 trackEvent={trackBenefitsEvent} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        )}
        {currentStep === 4 && (
          <Results trackEvent={trackBenefitsEvent} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        )}
      </Container>
    </div>
  );
};

BenefitsFinder.propTypes = {
  loadingJobs: PropTypes.bool,
  hasLinkParams: PropTypes.bool,
  jobList: PropTypes.array,
};

export default BenefitsFinder;
