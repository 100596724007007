import React from 'react';

/* Styles */
import styles from 'styles/login.module.scss';

/* i18n */
import { FormattedMessage } from 'react-intl';

/* Material UI and other UI Dependencies */
import { Divider, Typography, Box } from '@mui/material';
import OnboardingHeader from 'components/layout/OnboardingHeader';
import MetaContent from 'components/layout/MetaContent';
import CloseTabButton from 'components/CloseTabButton';

function About() {
  return (
    <>
      <MetaContent />
      <OnboardingHeader />
      <div className={styles.loginFlow}>
        <article className={styles.loginFlow__formContainer}>
          <header className={styles.loginFlow__form}>
            <Typography align="center" variant="h1" component="h1" gutterBottom>
              <FormattedMessage id="about.title" />
            </Typography>
          </header>
          <Divider />
          <div className={styles.signInContainerSingleColumn}>
            <Typography align="center" variant="body1" component="p" className={styles.socialSignInContainer}>
              <FormattedMessage id="about.about" />
            </Typography>
          </div>
          <Box component="footer" align="center" pb={3}>
            <CloseTabButton />
          </Box>
        </article>
      </div>
    </>
  );
}

export default About;
