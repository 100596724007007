import { useEffect } from 'react';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Analytics */
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import { Typography, Box, Container } from '@mui/material';
import CloseTabButton from 'components/CloseTabButton';

const TermsOfUseZH = () => {
  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('TERMSOFUSE');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <CloseTabButton className={layoutStyles.closeButton} />
          <Typography variant="h1" gutterBottom>
            使用条款和隐私政策
          </Typography>
          <hr className={layoutStyles.separator} />
          <Box m={4} />
          <Typography variant="body2" component="p" gutterBottom>
            欢迎使用Hawai‘i Career Acceleration Navigator（“HI CAN”）！
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            我们正在采取一切预防措施，包括尽力遵守与隐私有关的所有相关州和联邦法律法规，以确保您的信息尽可能安全。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            通过访问和使用本网站及其子页面和内容（统称为本网站），您同意使用条款和隐私政策。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            资格
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            <p>使用本网站，即表示您保证您已年满 18 周岁，并拥有接受这些使用条款的法律权力。</p>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            信息使用
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            夏威夷劳工和工业关系部和/或其代理商/承包商/合作伙伴将使用夏威夷州记录和您在注册HI
            CAN时提供的信息，为您提供个性化的就业和培训机会。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            我们可能还会在合理必要的情况下使用和共享提供的信息，以遵守法律或法律程序（包括法院或政府命令或传票）；检测、防止或以其他方式解决欺诈、安全或技术问题；执行使用条款和隐私政策；保护夏威夷州、我们的用户和/或公众的权利、财产或安全；或经过您的明确同意进行其他用途。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            您提供的信息可能被视为《夏威夷修订法典》第92F章《总定义》中定义的“政府记录”。政府记录的访问，包括公众访问，主要由《夏威夷修订法典》第92F章控制。此外，可能还有其他法律要求对提供的信息进行公开访问。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            通过注册HI
            CAN，您同意允许部门和/或其承包商使用上述信息，以及您已向部门提供的任何相关数据，为您创建个性化的职业和就业机会建议。您进一步同意，部门和/或其承包商和合作伙伴可能会与您联系和/或发送包括但不限于以下内容的通讯：有关工作招聘机会的重要信息，可用工作的信息，技能培训机会，教育机会和其他与COVID-19或技能相关的资源和机会。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            通过提供您的手机号码，您特别同意允许部门向您的手机发送通讯，其中可能包括但不限于以下内容：有关失业保险计划的重要信息，适合您的工作招聘机会的信息，适合您的可用工作的信息以及可用的技能培训机会。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            您可以选择允许部门直接与可能对您的技能、兴趣和经验感兴趣的雇主和/或招聘人员分享您的联系信息。如果您选择此服务，则同意允许部门和/或其代理/承包商与夏威夷雇主、招聘、就业和政府机构直接分享您的联系信息、过往就业和教育经历以及关于您的工作适配性的推荐，仅为联系您提供可能适合您技能和背景的雇佣或技能培训机会的目的。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            安全与监控通知
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            如果您浏览本网站以阅读、打印或下载信息，则可能会收集导航信息。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            为了保护网站安全，所有网络流量都会被监控，以识别未经授权的尝试上传或更改信息，或进行其他犯罪活动。在授权的执法调查和根据必要的法律程序，导航信息可能会被用于协助获取个人信息。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            此外，为了保护本网站以及支持本网站的计算机系统不受未经授权的使用，以及确保计算机系统正常运行，访问本网站和相关计算机系统的个人将受到夏威夷州授权人员的所有活动被监控和记录。任何使用本网站和相关计算机系统的人都明确同意进行此类监控，并被告知如果此类监控揭示出可能的滥用或犯罪活动的证据，夏威夷州人员可能会将此类监控的结果提供给适当的官员。未经授权的尝试上传或更改网站内容，或以其他方式损坏本网站或计算机系统均是严格禁止的，并可能受到适用法律的惩罚。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            不经事先通知就更改条款
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            本网站是动态的，将随着时间变化而不经通知而更改。因此，本网站的用户完全负责检查网站上的内容的准确性、完整性、时效性和适用性。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            此外，我们保留随时修改使用条款和隐私政策的权利。我们鼓励您定期审查使用条款和隐私政策。您继续使用 HI
            CAN，即表示您同意对网站、使用条款和隐私政策进行修改。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            免责声明
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            本网站是 “按原样”
            本网站按“原样”提供，不提供任何形式的保证。根据法律的最大限度，夏威夷州，包括夏威夷州的每个机构、官员或雇员，在本网站方面均不提供任何明示或暗示的保证，包括但不限于适销性和适用于特定用途的保证；这些保证包括但不限于以下免责声明：(1)本网站的运行将不间断和无错误；(2)本网站上的任何缺陷或其他错误将被纠正；以及(3)本网站以及计算机系统是或将是免疫病毒或其他有害组件的。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            此外，夏威夷州或夏威夷州的任何机构、官员或雇员均不表示、保证或保证以下内容：(1)通过本网站提供的信息的准确性、完整性、时效性或适用性；(2)使用或使用本网站的结果；以及(3)由夏威夷州以外的人创建和维护的网站的内容的准确性、可靠性、可用性、完整性或有用性，并与本网站相连或从本网站连接。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            责任限制
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            在任何情况下，包括但不限于疏忽，夏威夷州及其任何机构、官员或雇员都不对因使用或无法访问或使用本网站而可能产生的特殊、间接和/或附带性损害承担责任，即使夏威夷州已被告知可能存在此类损害。适用法律可能不允许限制或排除责任或附带或间接损害赔偿，因此上述限制或排除可能不适用于您。在任何情况下，夏威夷州对您的全部损害、损失和诉因（无论是合同、侵权（包括但不限于疏忽）或其他原因）的总责任，均不超过您为访问本网站支付的金额（如果有的话）。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            版权声明
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            禁止将本网站的任何内容用于商业目的或以任何可能给予夏威夷州官方认可印象的方式使用。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            外部网站和页面的链接
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            <p>
              本网站可能包含指向由夏威夷州以外的公共和私人组织创建和维护的信息的超文本链接。夏威夷州可能创建这些超文本链接，如果它确定建立外部链接将符合此网站的目的，即通过以下方式显著而认真地促进公共服务，服务于公民和企业：
            </p>
            <ul>
              <li>扩大企业和公民访问政府服务和政府信息的渠道 </li>
              <li>为企业和公民提供在州政府在线进行交易的便捷流程 </li>
              <li>加速提供更多优质在线政府服务的开发和交付； </li>
              <li>提高州政府向公众提供的客户服务水平；以及 </li>
              <li>向市县政府的公民提供电子政府服务。 </li>
            </ul>
            <p>
              此外，夏威夷州可能出于信息目的创建超文本链接，即在外部链接提供对本网站访问者有用和有价值的信息或者在外部链接要求或得到法律授权的情况下。
            </p>
            <p>
              夏威夷州将自行决定外部网站是否符合此网站的目的或指定的信息目的。包含指向外部网站的超文本链接不意味着对所链接的网站上提供或引用的任何产品或服务、赞助此类网站的组织或任何可能表达或引用的观点的认可。
            </p>
            <p>这些指向外部网站和页面的超文本链接可能随时被夏威夷州自行决定予以删除或更换而不需通知。</p>
            <p>
              如果您发现与指向外部网站的格式、准确性、及时性或完整性有关的问题或关切，请与负责链接外部网站的组织联系。夏威夷州不控制外部网站和页面，也不对其负责。
            </p>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            免责声明
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            本网站所提到的任何商业产品、流程或服务的特定商标、制造商或其他方面的参考并不构成或暗示其得到夏威夷州的认可、推荐或偏爱。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            管辖权
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            选择访问和使用本网站的任何人都受夏威夷州的管辖。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            这些条款应按照夏威夷州的法律进行解释和执行。任何因这些条款、访问和使用本网站而产生的争议应在夏威夷州的州法院根据该州法律裁决，并且您在此同意和提交到这些法院的个人管辖权以解决此类争议。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            其他网站
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            本网站可能包含指向其他公共和私人组织创建和维护的网站和页面的超文本链接。如果您决定访问这些链接站点，则应检查这些其他网站的隐私声明。夏威夷州不对这些其他网站提供的内容负责。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            可分性
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            如果上述使用条款和隐私政策中的任何条款被具有管辖权的法院裁定为非法、无效或不可执行，则该条款应被视为可分离的，不影响其余条款的有效性和可执行性。
          </Typography>
          <Box m={3} />
        </Container>
      </div>
    </>
  );
};

export default TermsOfUseZH;
