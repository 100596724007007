import cn from 'classnames';
import { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { difference } from 'ramda';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import filterStyles from 'styles/filter-input.module.scss';
import ratingsStyles from 'styles/ratings.module.scss';

/* Assets */
import arrowDown from 'assets/images/Arrow_Dropdown.svg';
import thumbsUp from 'assets/images/heart_plus_FILL0.svg';
import thumbsDown from 'assets/images/heart_minus_FILL0.svg';
import thumbsUpFilled from 'assets/images/heart_plus_FILL1.svg';
import thumbsDownFilled from 'assets/images/heart_minus_FILL1.svg';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setCareerPathsFilterValues } from 'app/slices/jobsSlice';
import { setCurrentTab, setActiveFilters } from 'app/slices/ratingsSlice';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';

/* UI Components */
import { useSnackbar } from 'notistack';
import { Tag, RecommendationsCard } from 'shared/components';

import { useInfiniteRatings, useRateItem } from 'shared/hooks';
import { Button, CircularProgress } from '@mui/material';
import ScrollToTopButton from 'components/ScrollToTopButton';

const Ratings = () => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const intl = useIntl();
  const urlPrefix = getUrlPrefix(intl.locale);

  const dispatch = useDispatch();
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { currentTab, availableFilters, activeFilters } = useSelector((state) => state.ratings);
  const { loadingLanguage } = useSelector((state) => state.app);

  const translatedAvailableFilters = availableFilters.map((f) => ({
    title: intl.formatMessage({ id: `ratings.filters.${f}`, description: 'string' }),
    value: f,
  }));
  const translatedFiltersDict = availableFilters.reduce((prev, curr) => {
    return { ...prev, [curr]: intl.formatMessage({ id: `ratings.filters.${curr}` }) };
  }, {});

  const filters = { filter_by_type: activeFilters, currentTab };

  const ratingsQuery = useInfiniteRatings(filters);
  const rateItem = useRateItem(filters);

  const isFilterActive = activeFilters.length > 0;

  const allRatings = ratingsQuery.isSuccess ? ratingsQuery.data.pages.flat(1) : [];
  const responses = allRatings.slice(0, 10);

  /**
   * This additional response items will be used with the 'ShowMore' button
   * if there are no filters applied
   */
  const more = allRatings.slice(10);

  const showMore = () => {
    trackEvent('RATINGS_MORE');
    ratingsQuery.fetchNextPage();
  };

  const removeFilter = (tagId) => (e) => {
    e.preventDefault();
    const newActiveFilters = activeFilters.filter((f) => f !== tagId);
    dispatch(setActiveFilters(newActiveFilters));
  };

  const clearAllFilters = () => {
    dispatch(setActiveFilters([]));
  };

  const showJobs = (item) => {
    dispatch(setCareerPathsFilterValues([{ title: item?.title, soc: item?.soc }]));
    history.push(`${urlPrefix}/jobs`);
  };

  const handleButtonClick = (item) => () => {
    const { type } = item;
    const actionDict = {
      career: 'RATINGS_CAREER_JOBS',
      job: 'RATINGS_JOB_APPLY',
      training: 'RATINGS_TRAINING_APPLY',
    };
    trackEvent(actionDict[type], item);
    // Show jobs when clicking on a careers' card
    if (type === 'career') {
      showJobs(item);
    }
  };

  const handleTagClick = (item) => () => {
    const { type } = item;
    if (type === 'career') {
      const eventName = 'RATINGS_CAREER_CHICLET';
      trackEvent(eventName, item);
      //TODO: Expand drawer here
    }
  };

  const handleLikeClick = (item) => () => {
    const newRating = item.rating === 1 ? 0 : 1;
    const { type } = item;

    rateItem.mutate(
      { item, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
      }
    );

    if (newRating !== 'liked') {
      const eventName = `RATINGS_${type === 'skill' ? 'SKILLS' : type.toUpperCase()}_THUMBSUP`;
      trackEvent(eventName, item);
    }
  };

  const handleDislikeClick = (item) => () => {
    const newRating = item.rating === -1 ? 0 : -1;
    const { type } = item;

    rateItem.mutate(
      { item, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
      }
    );

    if (newRating !== 'disliked') {
      const eventName = `RATINGS_${type === 'skill' ? 'SKILLS' : type.toUpperCase()}_THUMBSDOWN`;
      trackEvent(eventName, item);
    }
  };

  const handleThumbsUpTabClick = () => {
    const eventName = 'RATINGS_TOGGLE_THUMBSUP';
    trackEvent(eventName);
    dispatch(setCurrentTab('up'));
  };

  const handleThumbsDownTabClick = () => {
    const eventName = 'RATINGS_TOGGLE_THUMBSDOWN';
    trackEvent(eventName);
    dispatch(setCurrentTab('down'));
  };

  const handleFiltersChange = ({ target: { value: newValue } }) => {
    const filterDict = {
      career: 'RATINGS_FILTER_BYCAREERS',
      job: 'RATINGS_FILTER_BYJOBS',
      training: 'RATINGS_FILTER_BYTRAINING',
    };
    const filtersDifference = difference(newValue, activeFilters);
    if (filtersDifference.length > 0) {
      trackEvent(filterDict[filtersDifference[0]]);
    }
    dispatch(setActiveFilters(newValue));
  };

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('RATINGS');
    TimeMe.startTimer();
    return () => {
      console.log('Leaving Ratings');
    };
  }, []);

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <div></div>
          <div className={ratingsStyles.likesFilters}>
            <div>
              <span className={ratingsStyles.thumbsButton} onClick={handleThumbsUpTabClick}>
                <img
                  alt="Thumbs Up"
                  src={currentTab === 'up' ? thumbsUpFilled : thumbsUp}
                  style={{ filter: currentTab === 'down' ? 'grayscale(100%) opacity(64%)' : undefined }}
                  className={cn(ratingsStyles.thumbsImage, ratingsStyles.defaultThumbs)}
                />
                <img
                  alt="Thumbs Up"
                  src={thumbsUpFilled}
                  style={{ filter: currentTab === 'down' ? 'grayscale(100%) opacity(64%)' : undefined }}
                  className={cn(ratingsStyles.thumbsImage, ratingsStyles.filledThumbs)}
                />
              </span>
              <span className={ratingsStyles.thumbsButton} onClick={handleThumbsDownTabClick}>
                <img
                  alt="Thumbs Down"
                  src={currentTab === 'down' ? thumbsDownFilled : thumbsDown}
                  style={{ filter: currentTab === 'up' ? 'grayscale(100%) opacity(64%)' : undefined }}
                  className={cn(ratingsStyles.thumbsImage, ratingsStyles.defaultThumbs)}
                />
                <img
                  alt="Thumbs Down"
                  src={thumbsDownFilled}
                  style={{ filter: currentTab === 'up' ? 'grayscale(100%) opacity(64%)' : undefined }}
                  className={cn(ratingsStyles.thumbsImage, ratingsStyles.filledThumbs)}
                />
              </span>
              <div>
                <Typography variant="h1" component="h2">
                  {intl.formatMessage({
                    id: 'ratings.thumbsUpTitle',
                  })}
                </Typography>
              </div>
            </div>
            <div className={ratingsStyles.filters}>
              <FormControl
                className={cn({
                  [filterStyles.formControl]: true,
                  [filterStyles.ratingsFiltersFocused]: filtersOpen,
                })}
              >
                <span className={filterStyles.selectLabel}>
                  {intl.formatMessage({ id: 'ratings.filter', description: 'string' })}
                </span>
                <Select
                  multiple
                  name="Filter Ratings"
                  disableUnderline
                  labelId="filters-select-label"
                  id="filters-select"
                  value={activeFilters}
                  onChange={handleFiltersChange}
                  onOpen={() => setFiltersOpen(true)}
                  onClose={() => setFiltersOpen(false)}
                  open={filtersOpen}
                  input={<Input />}
                  renderValue={() => ''}
                  IconComponent={() => <img alt="Arrow down" className={filterStyles.arrowDown} src={arrowDown} />}
                  MenuProps={{
                    PaperProps: {
                      className: filterStyles.selectMenu,
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  }}
                  classes={{
                    root: filterStyles.selectRoot,
                    select: filterStyles.selectSelect,
                  }}
                  sx={{
                    backgroundColor: 'white',
                  }}
                >
                  {translatedAvailableFilters.map((filter) => (
                    <MenuItem key={filter.value} value={filter.value}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        classes={{
                          root: filterStyles.checkboxRoot,
                        }}
                        style={{ marginRight: -3, marginLeft: 0, padding: '5px 9px 7px 4px' }}
                        checked={activeFilters.indexOf(filter.value) > -1}
                      />
                      <span className={filterStyles.checkboxLabel}>{filter.title}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <hr className={layoutStyles.separator} />
          <Box m={4} />
          {loadingLanguage || ratingsQuery.isLoading ? (
            <>
              <Box m={3} />
              <Box m={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            </>
          ) : null}
          {!loadingLanguage && responses.length === 0 && !isFilterActive && !ratingsQuery.isLoading && (
            <>
              <Box m={3} />
              <Box align="center" className={ratingsStyles.emptyStateContainer}>
                <Typography variant="body2" gutterBottom>
                  {intl.formatMessage(
                    {
                      id: currentTab === 'up' ? 'ratings.thumbsUpEmptyStateText1' : 'ratings.thumbsDownEmptyStateTitle',
                    },
                    {
                      icon: (
                        <img alt="Thumbs Down" src={thumbsDown} style={{ width: 20, position: 'relative', top: 4 }} />
                      ),
                    }
                  )}
                </Typography>
                {currentTab === 'up' ? (
                  <Typography variant="body2" gutterBottom>
                    {intl.formatMessage(
                      {
                        id: 'ratings.thumbsUpEmptyStateText2',
                      },
                      {
                        icon: (
                          <img alt="Thumbs Up" src={thumbsUp} style={{ width: 20, position: 'relative', top: 4 }} />
                        ),
                      }
                    )}
                  </Typography>
                ) : null}
              </Box>
            </>
          )}
          {!loadingLanguage && isFilterActive > 0 && (
            <>
              <div className={filterStyles.tags}>
                <Typography variant="h5">{intl.formatMessage({ id: 'recommendations.filterBy' })}</Typography>
                {activeFilters.map((f) => (
                  <Tag key={f} text={translatedFiltersDict[f]} onClick={removeFilter(f)} type="filter" />
                ))}
                <span className={cn(layoutStyles.link, filterStyles.clearAll)} onClick={clearAllFilters}>
                  {intl.formatMessage({ id: 'recommendations.clearAllFilters' })}
                </span>
              </div>

              <Box m={4} />
              {responses.length === 0 ? (
                <Typography variant="body2">{intl.formatMessage({ id: 'ratings.noRatings' })}</Typography>
              ) : null}
            </>
          )}

          {!loadingLanguage &&
            responses.map((item) => (
              <Fragment key={item.id}>
                <Box m={3} />

                <RecommendationsCard
                  type={item.type}
                  item={item}
                  rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
                  ratingsPage
                  onButtonClick={handleButtonClick(item)}
                  onLikeClick={handleLikeClick(item)}
                  onDislikeClick={handleDislikeClick(item)}
                  onTagClick={handleTagClick(item)}
                />
              </Fragment>
            ))}
          {!loadingLanguage && ratingsQuery.isFetched && more.length > 0 && (
            <>
              <hr className={layoutStyles.separator} />
              <Box m={4} />
              {more.map((item) => (
                <RecommendationsCard
                  key={item.id}
                  type={item.type}
                  item={item}
                  rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
                  ratingsPage
                  onButtonClick={handleButtonClick(item)}
                  onLikeClick={handleLikeClick(item)}
                  onDislikeClick={handleDislikeClick(item)}
                  onTagClick={handleTagClick(item)}
                />
              ))}
            </>
          )}
          <div className={styles.bottomBar}>
            <span className={styles.backToTopPlaceholder} />
            {!loadingLanguage && ratingsQuery.isFetched && ratingsQuery.hasNextPage ? (
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                onClick={showMore}
                disabled={ratingsQuery.isFetching}
              >
                {intl.formatMessage({ id: 'ratings.showMore', description: 'string' })}
              </Button>
            ) : (
              <span />
            )}
            <ScrollToTopButton toTopEvent="RATINGS_TOP" />
          </div>
        </Container>
      </div>
    </>
  );
};

export default Ratings;
