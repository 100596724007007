import { css, cx } from '@emotion/css';

/* Styles */
import cssVars from 'styles/vars.module.scss';

/* i18n */
import { useIntl } from 'react-intl';
import skillsJson from 'i18n/skills.json';

/* Material UI and other UI Dependencies */
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const eFn = () => {};

const skillsDict = skillsJson.reduce((skDict, skill) => {
  return { ...skDict, [skill.en]: skill };
}, {});

const skillsButton = css`
  cursor: pointer;
  background-color: ${cssVars.white};
  display: inline-block;
  position: relative;
  transition: color 0.35s;
  padding: 0 5.5px;
  color: ${cssVars.actionBlue};
  font-weight: 700;
  &:hover {
    color: ${cssVars.actionBlueHover};
    .skillButtonIcon {
      color: ${cssVars.actionBlueHover};
    }
  }
  &:active {
    top: 1px;
    transform: scale(0.95);
  }
  &:first-child {
    padding-bottom: 3px;
  }
`;
const disabledButton = css`
  color: ${cssVars.mediumGray};
  cursor: initial;
  &:hover {
    color: ${cssVars.mediumGray};
    .skillButtonIcon {
      color: ${cssVars.mediumGray};
    }
  }
  &:active {
    top: 0;
    transform: scale(1);
  }
  .skillButtonIcon {
    color: ${cssVars.mediumGray};
    &:hover {
      color: ${cssVars.mediumGray};
    }
  }
`;
const skillButtonIcon = css`
  color: ${cssVars.actionBlue};
  transition: all 0.35s;
  position: relative;
  top: 6px;
  left: 2px;
  &:hover {
    color: ${cssVars.actionBlueHover};
  }
`;

const SkillCard = ({
  item,
  align = 'center',
  onLikeClick = () => {},
  onDislikeClick = () => {},
  addLabel = '',
  removeLabel = '',
  disabled = false,
}) => {
  const intl = useIntl();
  const locality = process.env.REACT_APP_LOCALITY;

  const handleThumbKeypress = (e, cb) => {
    if (e.key === 'Enter') {
      cb();
    }
  };

  return (
    <div
      className={css`
        border-left: 3px solid ${cssVars.cardAccentExperience};
        padding: 37px 19px 31px;
        background-color: ${cssVars.white};
        ${locality === 'nj' &&
        `
        border-right: solid 1px ${cssVars.lightGray};
        border-top: solid 1px ${cssVars.lightGray};
        border-bottom: solid 1px ${cssVars.lightGray};
      `}
        ${['hi', 'nj'].includes(locality) &&
        `
        h4 {
          overflow-x: hidden;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
        }
      `}
      `}
    >
      <Typography variant="h4" align={align} gutterBottom lang="de">
        {item.title in skillsDict && skillsDict[item.name][intl.locale]
          ? skillsDict[item.name][intl.locale]
          : item.name}
      </Typography>
      <Box
        className={css`
          padding-top: 12px;
        `}
        align={align}
      >
        <span
          className={cx({ [skillsButton]: true, [disabledButton]: disabled })}
          tabIndex={0}
          onKeyPress={(e) => handleThumbKeypress(e, true ? eFn : onLikeClick)}
          onClick={disabled ? eFn : onLikeClick}
        >
          <AddCircleOutlineIcon className={skillButtonIcon} /> <span>{addLabel}</span>
        </span>
        <span
          className={cx({ [skillsButton]: true, [disabledButton]: disabled })}
          tabIndex={0}
          onKeyPress={(e) => handleThumbKeypress(e, disabled ? eFn : onDislikeClick)}
          onClick={disabled ? eFn : onDislikeClick}
        >
          <RemoveCircleOutlineIcon className={skillButtonIcon} /> <span>{removeLabel}</span>
        </span>
      </Box>
    </div>
  );
};

export default SkillCard;
