import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { propOr } from 'ramda';

/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { updateKey } from 'app/slices/benefitsFinderSlice';

/* Styles */
import recStyles from 'styles/recommendations-list.module.scss';

/* Material UI and other UI Dependencies */
import { Box, Button } from '@mui/material';
import Collapsible from './SurveyComponents/Collapsible';
import MultiSelectQuestion from './SurveyComponents/MultiSelectQuestion';

const styles = {
  header: css({
    fontSize: '24px',
    margin: '4px 0 4px',
  }),
};

const BenefitsFinderSection1 = ({ currentStep, setCurrentStep, trackEvent }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [touched, setTouched] = useState(false);
  const [q1Errors, setQ1Errors] = useState('');
  const [q2Errors, setQ2Errors] = useState('');

  const benefitsResults = useSelector((state) => state.benefitsFinder.results);
  const receivingBenefitsValue = propOr([], 'currently_receiving_benefits', benefitsResults);
  const supportInterestedInValue = propOr([], 'support_interested_in', benefitsResults);

  const removeVal = (valToRemove) => {
    const newValues = new Set(supportInterestedInValue);
    newValues.delete(valToRemove);
    dispatch(updateKey({ key: 'support_interested_in', value: Array.from(newValues) }));
  };

  const submit = () => {
    setTouched(true);
    if (!q1Errors && !q2Errors) {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    //Display Logic
    if (receivingBenefitsValue.includes('snap') && supportInterestedInValue.includes('snap')) {
      removeVal('snap');
    }
    if (receivingBenefitsValue.includes('quest') && supportInterestedInValue.includes('quest')) {
      removeVal('quest');
    }
    if (receivingBenefitsValue.includes('child_care') && supportInterestedInValue.includes('child_care')) {
      removeVal('child_care');
    }
    if (receivingBenefitsValue.length > 1 && receivingBenefitsValue.includes('dont_receive')) {
      if (receivingBenefitsValue.slice(-1)[0] === 'dont_receive') {
        dispatch(updateKey({ key: 'currently_receiving_benefits', value: ['dont_receive'] }));
      } else {
        dispatch(
          updateKey({
            key: 'currently_receiving_benefits',
            value: receivingBenefitsValue.filter((v) => v !== 'dont_receive'),
          })
        );
      }
    }
    //Validation
    if (!receivingBenefitsValue.length) {
      setQ1Errors('app.benefitsFinder.validation.atLeastOne');
      return;
    }
    setQ1Errors('');
  }, [JSON.stringify(receivingBenefitsValue)]);

  useEffect(() => {
    if (!supportInterestedInValue.length) {
      setQ2Errors('app.benefitsFinder.validation.atLeastOne');
      return;
    }
    setQ2Errors('');
  }, [JSON.stringify(supportInterestedInValue)]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  return (
    <div className={recStyles.content}>
      <span>{intl.formatMessage({ id: 'app.benefitsFinder.section1.label' })}</span>
      <h2 className={styles.header}>{intl.formatMessage({ id: 'app.benefitsFinder.section1.header' })}</h2>
      <Box m={3} />
      <Collapsible
        title={intl.formatMessage({ id: 'app.benefitsFinder.section1.collapsibleTitle' })}
        text={intl.formatMessage({ id: 'app.benefitsFinder.section1.collapsibleText' })}
      />
      <Box m={6} />
      <MultiSelectQuestion
        name="currently_receiving_benefits"
        touched={touched}
        error={q1Errors}
        title={intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.title' })}
        options={[
          { value: 'quest', text: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option3' }) },
          { value: 'snap', text: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option1' }) },
          { value: 'tanf_taonf', text: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option2' }) },
          { value: 'child_care', text: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option4' }) },
          { value: 'dont_receive', text: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option5' }) },
        ]}
      />
      <Box m={6} />
      <MultiSelectQuestion
        name="support_interested_in"
        touched={touched}
        error={q2Errors}
        title={intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.title' })}
        options={[
          ...(!receivingBenefitsValue.includes('quest')
            ? [{ value: 'quest', text: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option3' }) }]
            : []),
          ...(!receivingBenefitsValue.includes('snap')
            ? [{ value: 'snap', text: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option1' }) }]
            : []),
          { value: 'tanf_taonf', text: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option2' }) },
          ...(!receivingBenefitsValue.includes('child_care')
            ? [
                {
                  value: 'child_care',
                  text: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option4' }),
                },
              ]
            : []),
        ]}
      />
      <Box m={4} />
      <Box display="flex" justifyContent="space-between">
        <Button
          color="primary"
          size="large"
          variant="contained"
          type="button"
          onClick={() => {
            trackEvent('BF_Section1_Back');
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< '}
          {intl.formatMessage({ id: 'forms.onboarding.back' })}
        </Button>
        <Button
          color="primary"
          size="large"
          variant="contained"
          type="button"
          onClick={() => {
            trackEvent('BF_Section1_Next');
            submit();
          }}
        >
          {intl.formatMessage({ id: 'forms.onboarding.next' })}
          {' >'}
        </Button>
      </Box>
    </div>
  );
};

BenefitsFinderSection1.propTypes = {
  setCurrentStep: PropTypes.func,
  currentStep: PropTypes.number,
  trackEvent: PropTypes.func,
};

export default BenefitsFinderSection1;
