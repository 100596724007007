import cn from 'classnames';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import financialStyles from 'styles/financial-help-card.module.scss';

/* API */
import { genericApiRequest } from 'lib/API';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { resetSurvey } from 'app/slices/benefitsFinderSlice';

/* Analytics */
import TimeMe from 'timeme.js';
import { trackEvent } from 'lib/analytics';

/* Material UI and other UI Dependencies */
import { Box, useMediaQuery, Typography, Container } from '@mui/material';
import FinancialHelpCard from 'components/FinancialHelpCard';
import Button from '@mui/material/Button';
import ScrollToTopButton from 'components/ScrollToTopButton';

/* Assets */
import foodIcon from 'assets/images/icon_food_help.svg';
import cashIcon from 'assets/images/icon_cash_help.svg';
import medicalIcon from 'assets/images/icon_medical_help.svg';
import SNAP from 'assets/images/SNAP.png';
import TANF from 'assets/images/TANF.png';
import MEDQUEST from 'assets/images/MEDQUEST.png';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const FinancialHelp = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const user_settings = useSelector((state) => state.app.user_settings);
  const intl = useIntl();
  const urlPrefix = getUrlPrefix(intl.locale);
  const isSmall = useMediaQuery('(max-width:767px)');

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('FINANCIAL_HELP');
    TimeMe.startTimer();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    return () => {
      console.log('Leaving Financial Help');
    };
  }, []);

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <div className={styles.header__content}>
            <Typography variant="h1" component="h2" gutterBottom>
              {intl.formatMessage({ id: 'financialHelp.title' })}
            </Typography>
            <hr className={layoutStyles.separator} />
            <Box m={4} />
            <Typography variant="h3" gutterBottom>
              {intl.formatMessage({ id: 'financialHelp.subtitle' })}
            </Typography>
            <Typography variant="h3" gutterBottom>
              {intl.formatMessage(
                { id: 'app.financialHelp.benefitsFinderMessage' },
                {
                  link: (
                    <Link
                      to={`${urlPrefix}/benefits-finder`}
                      className={layoutStyles.link}
                      onClick={() => {
                        genericApiRequest('post', '/api/vp2/events', {
                          data: {
                            action: 'event',
                            name: 'BFAccess_ResourcesPage',
                            location: window.location.hash,
                            item: {},
                          },
                        });
                        dispatch(resetSurvey());
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {intl.formatMessage({ id: 'app.financialHelp.benefitsFinderLink' })}
                    </Link>
                  ),
                }
              )}
            </Typography>
            <Box m={3} />
            {user_settings.financial_benefits_groceries ? (
              <>
                <FinancialHelpCard
                  title={intl.formatMessage({ id: 'financialHelp.groceries.title' })}
                  description={intl.formatMessage({ id: 'financialHelp.groceries.description' })}
                  toQualifyTitle={intl.formatMessage({ id: 'financialHelp.groceries.toQualify' })}
                  toQualifyItems={[
                    intl.formatMessage({ id: 'financialHelp.groceries.qualification1' }),
                    intl.formatMessage({ id: 'financialHelp.groceries.qualification2' }),
                    intl.formatMessage({ id: 'financialHelp.groceries.qualification3' }),
                  ]}
                  image={foodIcon}
                  imageStyles={{ width: isSmall ? 45.87 : 69.45 }}
                  imageDescription={intl.formatMessage({ id: 'financialHelp.groceries.imageDescription' })}
                  closeEvent="SNAP_CLOSE"
                  learnHowToApplyEvent="SNAP_LEARN_APPLY"
                  expandEvent="SNAP_EXPAND"
                >
                  <img
                    src={SNAP}
                    style={{ width: 48 }}
                    alt={intl.formatMessage({ id: 'financialHelp.groceries.title' })}
                  />
                  <Box mb={1} />
                  <h3 className={cn(financialStyles.toQualifyTitle, financialStyles.noMargin)}>
                    {intl.formatMessage({ id: 'financialHelp.snap.whatIs' })}
                  </h3>
                  <Typography variant="body2" component="p" gutterBottom>
                    {intl.formatMessage({ id: 'financialHelp.snap.whatIsP' })}
                  </Typography>
                  <Box mb={4} />
                  <h3 className={cn(financialStyles.toQualifyTitle, financialStyles.noMargin)}>
                    {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmitTitle' })}
                  </h3>
                  <ol className={financialStyles.noMargin}>
                    <li>
                      <Typography variant="body2">
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://pais-benefits.dhs.hawaii.gov/"
                        >
                          {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit1a' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit1b' })}</span>
                        {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit1c' }).trim().length > 0 ? (
                          <a
                            target="_blank"
                            className={layoutStyles.link}
                            rel="noopener noreferrer"
                            href="https://pais-benefits.dhs.hawaii.gov/"
                          >
                            {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit1c' })}
                          </a>
                        ) : null}
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        <span>{intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit2a' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://humanservices.hawaii.gov/bessd/files/2020/02/SNAP_Application_2019.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit2b' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit2c' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://humanservices.hawaii.gov/wp-content/uploads/2021/06/DHS-1240-Final-List-of-PCs.pdf-03-2021.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit2d' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit2e' })}</span>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        <span>{intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit3a' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://humanservices.hawaii.gov/bessd/files/2020/02/SNAP_Application_2019.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit3b' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit3c' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://humanservices.hawaii.gov/wp-content/uploads/2021/06/DHS-1240-Final-List-of-PCs.pdf-03-2021.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit3d' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit3e' })}</span>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        <span>{intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit4a' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://humanservices.hawaii.gov/bessd/files/2020/02/SNAP_Application_2019.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit4b' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit4c' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://humanservices.hawaii.gov/wp-content/uploads/2021/06/DHS-1240-Final-List-of-PCs.pdf-03-2021.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit4d' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit4e' })}</span>
                      </Typography>
                    </li>
                  </ol>
                  <Box mb={4} />
                  <Typography variant="body2" style={{ fontStyle: 'italic' }} component="p" gutterBottom>
                    {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmitNotes' })}
                  </Typography>
                  <Box mb={4} />
                  <div>
                    <Button
                      color="secondary"
                      size="large"
                      type="submit"
                      variant="contained"
                      endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                      style={{ marginRight: 10 }}
                      href="https://pais-benefits.dhs.hawaii.gov/"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => trackEvent('SNAP_APPLY')}
                    >
                      {intl.formatMessage({ id: 'financialHelp.snap.apply' })}
                    </Button>
                    <Button
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                      endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                      href="https://humanservices.hawaii.gov/bessd/snap/"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => trackEvent('SNAP_LEARNMORE')}
                    >
                      {intl.formatMessage({ id: 'financialHelp.snap.learnMore' })}
                    </Button>
                  </div>
                </FinancialHelpCard>
                <Box mb={5} />
              </>
            ) : null}
            {user_settings.financial_benefits_bills_and_expenses ? (
              <>
                <FinancialHelpCard
                  title={intl.formatMessage({ id: 'financialHelp.billsAndExpenses.title' })}
                  description={intl.formatMessage({ id: 'financialHelp.billsAndExpenses.description' })}
                  toQualifyTitle={intl.formatMessage({ id: 'financialHelp.billsAndExpenses.toQualify' })}
                  toQualifyItems={[
                    intl.formatMessage({ id: 'financialHelp.billsAndExpenses.qualification1' }),
                    intl.formatMessage({ id: 'financialHelp.billsAndExpenses.qualification2' }),
                  ]}
                  image={cashIcon}
                  imageStyles={{ width: isSmall ? 53.63 : 80.32 }}
                  imageDescription={intl.formatMessage({ id: 'financialHelp.billsAndExpenses.imageDescription' })}
                  closeEvent="TANF_CLOSE"
                  learnHowToApplyEvent="TANF_LEARN_APPLY"
                  expandEvent="TANF_EXPAND"
                >
                  <img
                    src={TANF}
                    style={{ width: 115 }}
                    alt={intl.formatMessage({ id: 'financialHelp.billsAndExpenses.title' })}
                  />
                  <Box mb={1} />
                  <h3 className={cn(financialStyles.toQualifyTitle, financialStyles.noMargin)}>
                    {intl.formatMessage({ id: 'financialHelp.tanf.whatIs' })}
                  </h3>
                  <Typography variant="body2" component="p" gutterBottom>
                    {intl.formatMessage({ id: 'financialHelp.tanf.whatIsP' })}
                  </Typography>
                  <Box mb={4} />
                  <h3 className={cn(financialStyles.toQualifyTitle, financialStyles.noMargin)}>
                    {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmitTitle' })}
                  </h3>
                  <ol className={financialStyles.noMargin}>
                    <li>
                      <Typography variant="body2">
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://pais-benefits.dhs.hawaii.gov/"
                        >
                          {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit1a' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit1b' })}</span>
                        {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit1c' }).trim().length > 0 ? (
                          <a
                            target="_blank"
                            className={layoutStyles.link}
                            rel="noopener noreferrer"
                            href="https://pais-benefits.dhs.hawaii.gov/"
                          >
                            {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit1c' })}
                          </a>
                        ) : null}
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        <span>{intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit2a' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://humanservices.hawaii.gov/bessd/files/2020/02/SNAP_Application_2019.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit2b' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit2c' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://humanservices.hawaii.gov/wp-content/uploads/2021/06/DHS-1240-Final-List-of-PCs.pdf-03-2021.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit2d' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit2e' })}</span>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        <span>{intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit3a' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://humanservices.hawaii.gov/bessd/files/2020/02/SNAP_Application_2019.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit3b' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit3c' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://humanservices.hawaii.gov/wp-content/uploads/2021/06/DHS-1240-Final-List-of-PCs.pdf-03-2021.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit3d' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit3e' })}</span>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        <span>{intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit4a' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://humanservices.hawaii.gov/bessd/files/2020/02/SNAP_Application_2019.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit4b' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit4c' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://humanservices.hawaii.gov/wp-content/uploads/2021/06/DHS-1240-Final-List-of-PCs.pdf-03-2021.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit4d' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit4e' })}</span>
                      </Typography>
                    </li>
                  </ol>
                  <Box mb={4} />
                  <Typography variant="body2" style={{ fontStyle: 'italic' }} component="p" gutterBottom>
                    {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmitNotes' })}
                  </Typography>
                  <Box mb={4} />
                  <div>
                    <Button
                      color="secondary"
                      size="large"
                      type="submit"
                      variant="contained"
                      href="https://pais-benefits.dhs.hawaii.gov/"
                      target="_blank"
                      rel="noopener noreferrer"
                      endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                      style={{ marginRight: 10 }}
                      onClick={() => trackEvent('TANF_APPLY')}
                    >
                      {intl.formatMessage({ id: 'financialHelp.snap.apply' })}
                    </Button>
                    <Button
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                      href="https://humanservices.hawaii.gov/bessd/tanf/"
                      target="_blank"
                      rel="noopener noreferrer"
                      endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                      onClick={() => trackEvent('TANF_LEARNMORE')}
                    >
                      {intl.formatMessage({ id: 'financialHelp.snap.learnMore' })}
                    </Button>
                  </div>
                </FinancialHelpCard>
                <Box mb={5} />
              </>
            ) : null}
            {user_settings.financial_benefits_medical_care ? (
              <>
                <FinancialHelpCard
                  title={intl.formatMessage({ id: 'financialHelp.medicalCare.title' })}
                  description={
                    <>
                      {intl.formatMessage({ id: 'financialHelp.medicalCare.description' })}
                      <p>
                        {intl.formatMessage({ id: 'financialHelp.medicalCare.description2.1' })}
                        <a
                          className={layoutStyles.link}
                          href="https://medical.mybenefits.hawaii.gov/web/kolea/home-page/"
                          target="_blank"
                          rel="noreferrer noopener"
                          onClick={() => trackEvent('MEDQUEST_PREASSESSMENT')}
                        >
                          {intl.formatMessage({ id: 'financialHelp.medicalCare.description2.2' })}
                        </a>
                        {intl.formatMessage({ id: 'financialHelp.medicalCare.description2.3' })}
                      </p>
                    </>
                  }
                  toQualifyTitle={intl.formatMessage({ id: 'financialHelp.medicalCare.toQualify' })}
                  toQualifyItems={[
                    intl.formatMessage({ id: 'financialHelp.medicalCare.qualification1' }),
                    intl.formatMessage({ id: 'financialHelp.medicalCare.qualification2' }),
                  ]}
                  image={medicalIcon}
                  imageStyles={{ width: isSmall ? 52 : 73 }}
                  imageDescription={intl.formatMessage({ id: 'financialHelp.medicalCare.imageDescription' })}
                  closeEvent="MEDQUEST_CLOSE"
                  learnHowToApplyEvent="MEDQUEST_LEARN_APPLY"
                  expandEvent="MEDQUEST_EXPAND"
                >
                  <img
                    src={MEDQUEST}
                    style={{ width: 50 }}
                    alt={intl.formatMessage({ id: 'financialHelp.medicalCare.title' })}
                  />
                  <Box mb={1} />
                  <h3 className={cn(financialStyles.toQualifyTitle, financialStyles.noMargin)}>
                    {intl.formatMessage({ id: 'financialHelp.medQuest.whatIs' })}
                  </h3>
                  <Typography variant="body2" component="p" gutterBottom>
                    {intl.formatMessage({ id: 'financialHelp.medQuest.whatIsP' })}
                  </Typography>
                  <Box mb={4} />
                  <h3 className={cn(financialStyles.toQualifyTitle, financialStyles.noMargin)}>
                    {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmitTitle' })}
                  </h3>
                  <ol className={financialStyles.noMargin}>
                    <li>
                      <Typography variant="body2">
                        <a
                          href="https://medical.mybenefits.hawaii.gov/web/kolea/home-page"
                          rel="noopener noreferrer"
                          target="_blank"
                          className={layoutStyles.link}
                        >
                          {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit1' })}
                        </a>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit2' })}
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        <span>{intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit3a' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://mybenefits.hawaii.gov/wp-content/uploads/2020/06/DHS_1100_Rev_12_17_v_2_FINAL_fillable.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit3b' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit3c' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="http://humanservices.hawaii.gov/wp-content/uploads/2013/09/MQD-Application-Benefit-Offices.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit3d' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit3e' })}</span>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        <span>{intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit4a' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://mybenefits.hawaii.gov/wp-content/uploads/2020/06/DHS_1100_Rev_12_17_v_2_FINAL_fillable.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit4b' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit4c' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="http://humanservices.hawaii.gov/wp-content/uploads/2013/09/MQD-Application-Benefit-Offices.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit4d' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit4e' })}</span>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        <span>{intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit5a' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="https://mybenefits.hawaii.gov/wp-content/uploads/2020/06/DHS_1100_Rev_12_17_v_2_FINAL_fillable.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit5b' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit5c' })}</span>
                        <a
                          target="_blank"
                          className={layoutStyles.link}
                          rel="noopener noreferrer"
                          href="http://humanservices.hawaii.gov/wp-content/uploads/2013/09/MQD-Application-Benefit-Offices.pdf"
                        >
                          {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit5d' })}
                        </a>
                        <span>{intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit5e' })}</span>
                      </Typography>
                    </li>
                  </ol>
                  <Box mb={4} />
                  <Typography variant="body2" style={{ fontStyle: 'italic' }} component="p" gutterBottom>
                    {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmitNotes' })}{' '}
                    <a
                      target="_blank"
                      className={layoutStyles.link}
                      rel="noopener noreferrer"
                      href="http://humanservices.hawaii.gov/wp-content/uploads/2013/09/MQD-Application-Benefit-Offices.pdf"
                    >
                      {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmitNotesLink' })}
                    </a>
                  </Typography>
                  <Box mb={4} />
                  <div>
                    <Button
                      color="secondary"
                      size="large"
                      type="submit"
                      variant="contained"
                      href="https://medical.mybenefits.hawaii.gov/web/kolea/home-page/"
                      target="_blank"
                      rel="noopener noreferrer"
                      endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                      style={{ marginRight: 10 }}
                      onClick={() => trackEvent('MEDQUEST_APPLY')}
                    >
                      {intl.formatMessage({ id: 'financialHelp.snap.apply' })}
                    </Button>
                    <Button
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                      href="https://medquest.hawaii.gov/"
                      target="_blank"
                      rel="noopener noreferrer"
                      endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                      onClick={() => trackEvent('MEDQUEST_LEARNMORE')}
                    >
                      {intl.formatMessage({ id: 'financialHelp.snap.learnMore' })}
                    </Button>
                  </div>
                </FinancialHelpCard>
                <Box mb={5} />
              </>
            ) : null}
            <div className={styles.bottomBar}>
              <span className={styles.backToTopPlaceholder} />
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                onClick={() => {
                  trackEvent('MOREOPTIONS_RETURN_CAREERPATHS');
                  history.push(`${urlPrefix}/careers`);
                }}
              >
                {intl.formatMessage({ id: 'financialHelp.returnToCareers' })}
              </Button>
              <ScrollToTopButton toTopEvent="MOREOPTIONS_BACKTOTOP" />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

FinancialHelp.propTypes = {
  width: PropTypes.string,
};

export default FinancialHelp;
