import { Fragment } from 'react';
/* Styles */
import styles from 'styles/goals-cards.module.scss';
import cn from 'classnames';

/* Material UI and other UI Dependencies */
import { Typography, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

/* i18n */
import { useIntl } from 'react-intl';

/* Assets */
import IllustrationMoney from '../images/Illustration_Money.svg';
import IllustrationTraining from '../images/Illustration_Training.svg';
import IllustrationBriefCase from '../images/Illustration_Briefcase.svg';

const illustrationDict = {
  boostEarnings: IllustrationMoney,
  similarSkills: IllustrationTraining,
  manyJobs: IllustrationBriefCase,
};

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: 'black',
  },
  tooltip: {
    backgroundColor: '#151515',
  },
}));

const GoalCard = ({ type, onClick = () => {}, active = false, onboarding = true }) => {
  const intl = useIntl();
  const classes = useStylesBootstrap();
  const goals = ['boostEarnings', 'similarSkills', 'manyJobs'];

  function content() {
    return (
      <div
        className={cn({
          [styles.card]: true,
          [styles.active]: active,
        })}
        onClick={onClick}
        onKeyDown={(keyEvent) => {
          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            onClick();
          }
        }}
        tabIndex={0}
      >
        {type in illustrationDict ? (
          <img
            alt="Goal"
            className={cn({
              [styles.goalsImage]: true,
              [styles.moneyImage]: type === 'boostEarnings',
              [styles.trainingImage]: type === 'similarSkills',
              [styles.briefcaseImage]: type === 'manyJobs',
            })}
            src={illustrationDict[type]}
          />
        ) : null}
        <Typography variant="h5" component="h2" align="center">
          {intl.formatMessage({ id: `onboarding.goals.${!onboarding && type === 'notSure' ? 'notSureAlt' : type}` })}
        </Typography>
      </div>
    );
  }

  return goals.includes(type) ? (
    <Tooltip
      title={
        <Fragment>
          <span style={{ fontSize: 14 }}>{intl.formatMessage({ id: `onboarding.goals.${type}.tooltip` })}</span>
        </Fragment>
      }
      placement="top"
      arrow
      classes={classes}
    >
      {content()}
    </Tooltip>
  ) : (
    content()
  );
};

export default GoalCard;
