/*-- Routing --*/
import { Route, Switch, Redirect } from 'react-router-dom';

/* API */
import { useAuth0 } from '@auth0/auth0-react';

/* Redux */
import { useSelector } from 'react-redux';

/*-- Pages --*/
import OnboardingContact from 'components/forms/OnboardingContact';
import OnboardingResumeUpload from 'components/forms/OnboardingResumeUpload';
import OnboardingExperience from 'components/forms/OnboardingExperience';
import OnboardingEducation from 'components/forms/OnboardingEducation';
import OnboardingGoals from 'components/forms/OnboardingGoals';

/*-- Components --*/
import OnboardingHeader from 'components/layout/OnboardingHeader';

/*-- Styles --*/
import styles from 'styles/login.module.scss';

const OnboardingPagesSwitch = () => {
  const { user } = useAuth0();
  const { needsContactInfo, needsOnboarding, urlPrefix } = useSelector((state) => state.app);

  return (
    <>
      <OnboardingHeader />
      <article className={styles.loginFlow}>
        <div className={styles.loginFlow__formContainer}>{render(needsContactInfo, needsOnboarding)}</div>
      </article>
    </>
  );

  function render(needsContactInfo, needsOnboarding) {
    if (needsContactInfo) {
      return (
        <Switch>
          <Route path={['/onboarding/contact-info', '/:lang/onboarding/contact-info']}>
            <>
              <div className={styles.loginFlow}>
                <div className={styles.loginFlow__formContainer}>
                  <OnboardingContact user={user} />
                </div>
              </div>
            </>
          </Route>
          <Route>
            <Redirect to={`${urlPrefix}/onboarding/contact-info`} />
          </Route>
        </Switch>
      );
    } else if (needsOnboarding) {
      return (
        <>
          <div className={styles.loginFlow}>
            <div className={styles.loginFlow__formContainer}>
              <Switch>
                <Route path={['/onboarding/resume-upload', '/:lang/onboarding/resume-upload']}>
                  <OnboardingResumeUpload />
                </Route>
                <Route path={['/onboarding/experience', '/:lang/onboarding/experience']}>
                  <OnboardingExperience />
                </Route>
                <Route path={['/onboarding/education', '/:lang/onboarding/education']}>
                  <OnboardingEducation />
                </Route>
                <Route path={['/onboarding/goals', '/:lang/onboarding/goals']}>
                  <OnboardingGoals />
                </Route>
                <Route>
                  <Redirect to={`${urlPrefix}/onboarding/resume-upload`} />
                </Route>
              </Switch>
            </div>
          </div>
        </>
      );
    }
  }
};

export default OnboardingPagesSwitch;
