import { useEffect } from 'react';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Analytics */
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import { Typography, Box, Container, Button } from '@mui/material';
import CloseTabButton from 'components/CloseTabButton';

const TermsOfUseTL = ({ locale }) => {
  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('TERMSOFUSE');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
    return () => {
      console.log('Leaving Terms of Use');
    };
  }, []);

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <CloseTabButton className={layoutStyles.closeButton} />
          <Typography variant="h1" gutterBottom>
            Mga Tuntunin sa Paggamit at Patakaran sa Privacy
          </Typography>
          <hr className={layoutStyles.separator} />
          <Box m={4} />
          <Typography variant="body2" component="p" gutterBottom>
            Maligayang pagdating sa Hawai‘i Career Acceleration Navigator (“HI CAN”)!
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Ginagawa namin ang bawat pag-iingat, kabilang ang paggawa ng aming pinakamahusay na pagsisikap na sumunod sa
            lahat ng mga nauukol na batas at regulasyon ng estado at pederal na nauugnay sa privacy, para matiyak na ang
            inyong impormasyon ay ligtas hangga't maaari.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Sa pagbisita at paggamit sa website na ito, sa mga subpage nito, at nilalaman (sama-samang kinikilala
            bilang, ang website na ito), sumasang-ayon kayo sa Mga Tuntunin ng Paggamit at Patakaran sa Privacy.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Kwalipikasyon
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Sa pamamagitan ng paggamit ng website na ito, ginagarantiyahan mo na ikaw ay 18 taong gulang o mas matanda
            at may ligal na awtoridad na pumasok sa Mga Tuntunin ng Paggamit.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Paggamit ng Impormasyon
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Ang Hawai'i Department of Labor and Industrial Relations at/o ang mga ahente/kontratista/kapartner nito ay
            gagamit ng mga talaan ng Estado ng Hawai'i at ng impormasyong ibibigay ninyo kapag nagparehistro kayo para
            sa HI CAN para ikonekta kayo sa isinapersonal na pagkakataon sa mga trabaho at pagsasanay.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Maaari rin naming gamitin at ibahagi ang ibinigay na impormasyon bilang makatwirang kinakailangan para
            sumunod sa batas o sa isang legal na proseso (kabilang ang isang utos o subpoena ng hukuman o pamahalaan);
            para matukoy, maiwasan, o kung hindi man ay matugunan ang pandaraya, seguridad o teknikal na mga isyu; para
            ipatupad ang Mga Tuntunin ng Paggamit at Patakaran sa Privacy; para protektahan ang mga karapatan, ari-arian
            o kaligtasan ng Estado ng Hawaii, ang aming mga gumagamit, at/o ang publiko; o kung hindi man ay may malinaw
            na pahintulot ninyo.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Ang impormasyong ibibigay ninyo ay maaaring ituring na isang "tala ng pamahalaan" tulad ng tinukoy sa
            Kabanata 92F, Mga Binagong Batas ng Hawaiʻi, Mga Pangkalahatang kahulugan. Ang pag-access, kabilang ang
            pampublikong pag-access, sa mga talaan ng pamahalaan ay pangunahing kinokontrol ng Kabanata 92F, Mga
            Binagong Batas ng Hawaiʻi. Bilang karagdagan, maaaring may iba pang mga batas na nangangailangan ng
            pampublikong access sa ibinigay na impormasyon.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Sa pagpaparehistro para sa HI CAN, sumasang-ayon kayong payagan ang Departamento at/o ang mga kontratista
            nito na gamitin ang impormasyon sa itaas, gayundin ang anumang nauugnay na data na ginawa ninyong available
            sa Departamento, para gumawa ng mga isinapersonal na mga rekomendasyon ng pagkakataon sa trabaho at karera
            para sa inyo. Sumasang-ayon din kayo na ang Departamento at/o ang mga kontratista at kasosyo nito ay
            maaaring makipag-ugnayan sa inyo at/o magpadala sa inyo ng mga komunikasyon na maaaring kasama, nang walang
            limitasyon: mahalagang impormasyon tungkol sa mga pagkakataon ng pag-recruit sa trabaho, impormasyon sa mga
            available na trabaho, mga pagkakataon ng pagsasanay sa kasanayan, mga pagkakataon sa edukasyon, at iba pang
            mga mapagkukunan at pagkakataong nauugnay sa kasanayan sa COVID-19.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Sa pagbibigay ng numero ng inyong cell phone, partikular kayong sumasang-ayon na payagan ang Departamento na
            magpadala ng mga komunikasyon sa inyong cell phone na maaaring kasama, nang walang limitasyon: mahalagang
            impormasyon tungkol sa mga programa ng Unemployment Insurance, mga pagkakataon sa paghahanap ng trabaho na
            maaaring angkop para sa inyo, impormasyon sa mga available na trabaho na maaaring maging angkop para sa
            inyo, at magagamit na mga pagkakataon ng pagsasanay sa kasanayan.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Maaari ninyong piliin na payagan ang Departamento na ibahagi ang inyong impormasyon sa pakikipag-ugnayan
            nang direkta sa mga tagapag-empleyo at/o mga recruiter na maaaring interesadong kunin kayo batay sa inyong
            mga kasanayan, interes at karanasan. Kung pipiliin ninyo ang serbisyong ito, sumasang-ayon kayong
            pahintulutan ang Departamento at/o ang mga ahente/kontratista nito na ibahagi ang inyong impormasyon sa
            pakikipag-ugnayan, nakaraang trabaho at kasaysayan ng edukasyon, at isang rekomendasyon tungkol sa inyong
            pagkakaangkop sa isang trabaho o programa sa pagsasanay nang direkta sa mga tagapag-empleyo at
            tagapag-recruit ng Hawai'i, sa placement, at sa mga ahensya ng gobyerno para lamang sa layuning
            makipag-ugnayan sa inyo tungkol sa pagkuha o mga pagkakataon sa pagsasanay sa kasanayan na maaaring akma
            para sa inyong mga kasanayan at background.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Abiso sa Seguridad at Pagsubaybay
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Kung nagba-browse kayo sa website na ito para magbasa, mag-print, o mag-download ng impormasyon, maaaring
            kolektahin ang impormasyon sa pag-navigate.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Para sa mga layunin ng seguridad ng site, ang lahat ng trapiko sa network ay sinusubaybayan para matukoy ang
            mga hindi awtorisadong pagtatangka na mag-upload o magbago ng impormasyon, o kung hindi man ay magsagawa ng
            kriminal na aktibidad. Kaugnay ng mga awtorisadong imbestigasyon sa pagpapatupad ng batas at alinsunod sa
            kinakailangang legal na proseso, maaaring gamitin ang impormasyon sa pag-navigate para tumulong sa pagkuha
            ng personal na impormasyon.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Dagdag pa rito, para protektahan ang website na ito pati na rin ang nauugnay na computer system na
            sumusuporta sa website mula sa hindi awtorisadong paggamit at para matiyak na ang computer system ay
            gumagana nang maayos, ang mga indibidwal na nag-a-access sa website na ito at ang nauugnay na computer
            system ay napapailalim sa pagsubaybay at pagtatala ng lahat ng kanilang mga aktibidad ng mga tauhan ng
            Estado ng Hawaiʻi na awtorisadong gawin ito. Ang sinumang gumagamit ng website na ito at ang nauugnay na
            sistema ng computer ay malinaw na pumapayag sa nasabing pagsubaybay at pinapayuhan na kung ang nasabing
            pagsubaybay ay nagpapakita ng ebidensya ng posibleng pang-aabuso o kriminal na aktibidad, maaaring ibigay ng
            mga tauhan ng Estado ng Hawaiʻi ang mga resulta ng nasabing pagsubaybay sa mga naaangkop na opisyal. Ang mga
            hindi awtorisadong pagtatangka na i-upload o baguhin ang nilalaman ng website, o kung hindi man ay magdulot
            ng pinsala sa website na ito o sa computer system ay mahigpit na ipinagbabawal at maaaring maparusahan sa
            ilalim ng naaangkop na batas.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Pagbabago na Walang Abiso
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Ang website na ito ay pabago-bago at magbabago sa paglipas ng panahon nang walang abiso. Kung kaya ang mga
            user ng website na ito ay ganap na responsable para sa pagsusuri sa pagiging tama, pagiging kumpleto,
            pagiging napapanahon, at pagiging angkop ng nilalaman na matatagpuan sa website.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Bilang karagdagan, inilalaan namin ang karapatang baguhin ang Mga Tuntunin ng Paggamit at Patakaran sa
            Privacy anumang oras. Hinihikayat namin kayong suriin ang Mga Tuntunin ng Paggamit at Patakaran sa Privacy
            sa isang regular na batayan. Ang inyong patuloy na paggamit ng HI CAN kasunod ng anumang mga pagbabago sa
            website, ang Mga Tuntunin ng Paggamit, at Patakaran sa Privacy ay bumubuo ng inyong kasunduan sa nasabing
            mga pagbabago.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Pagtatatwa ng Mga Warranty
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Ang website na ito ay ibinibigay ng "AS IS" at walang anumang uri ng mga warranty. Sa buong lawak ng batas,
            ang Estado ng Hawaiʻi, kabilang ang bawat ahensya, opisyal, o empleyado ng Estado ng Hawaiʻi, ay itinatanggi
            ang lahat ng mga warranty, ipinahayag man o ipinahiwatig, kabilang ngunit hindi limitado sa mga garantiya ng
            pagiging mapagkalakal at kaangkupan para sa isang partikular na layunin, na may kinalaman sa website na ito;
            kasama sa pagtatanggi na ito ng mga warranty ngunit hindi limitado sa mga pagtatanggi patungkol sa
            sumusunod: (1) na ang pag-andar ng website na ito ay hindi maaantala at walang error; (2) na ang anumang mga
            depekto o iba pang mga error sa website na ito ay itatama; at (3) na ang website na ito pati na rin ang
            computer system ay libre o magiging libre sa mga virus o iba pang nakakapinsalang bahagi.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Bilang karagdagan, ang Estado ng Hawaiʻi o alinmang ahensya, opisyal, o empleyado ng Estado ng Hawaiʻi ay
            hindi gumagawa ng anumang mga representasyon, garantiya, o warranty tungkol sa: (1) pagiging tama, pagiging
            kumpleto, pagiging napapanahon, at pagiging angkop ng impormasyong ibinigay sa website na ito; (2) paggamit
            ng o ang mga resulta ng paggamit ng website na ito; at (3) pagiging tama, pagiging maaasahan o kumpleto o
            pagiging kapaki-pakinabang ng nilalaman ng mga website na ginawa at pinananatili ng mga tao maliban sa
            Estado ng Hawaiʻi at naka-link sa o mula sa website na ito.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Limitasyon ng Pananagutan
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Hindi sa anumang pagkakataon, kabilang ngunit hindi limitado sa, kapabayaan, na ang Estado ng Hawaiʻi o
            alinmang ahensya, opisyal, o empleyado ng Estado ng Hawaiʻi ay mananagot para sa anumang espesyal,
            kinahinatnan at/o mga insidenteng pinsala na maaaring magresulta mula sa paggamit ng o ang kawalan ng
            kakayahan na i-access o gamitin ang website na ito, kahit na ang Estado ng Hawaiʻi ay pinayuhan tungkol sa
            posibilidad ng nasabing mga pinsala. Maaaring hindi payagan ng naaangkop na batas ang limitasyon o
            pagbubukod ng pananagutan o nagkataon o kinahinatnan na pinsala, kaya maaaring hindi nalalapat sa iyo ang
            limitasyon o pagbubukod sa itaas. Hindi sa anumang pagkakataon na ang kabuuang pananagutan ng Estado ng
            Hawaiʻi sa iyo para sa lahat ng pinsala, pagkalugi, at dahilan ng pagkilos (sa kontrata man, tort (kasama
            ngunit hindi limitado sa kapabayaan, o kung hindi man) ay lalampas sa halagang ibinayad ninyo, kung mayroon
            man, para i-access ang website na ito.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Abiso sa Copyright
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Ang pagkopya o paggamit ng anumang nilalaman mula sa website na ito para sa komersyal na layunin o sa
            anumang paraan na malamang na magbigay ng impresyon ng opisyal na pag-apruba ng Estado ng Hawaiʻi ay
            ipinagbabawal.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Mga Link sa Mga Panlabas na Website at Pahina
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            <p>
              Ang website na ito ay maaaring maglaman ng mga hypertext na link sa mga panlabas na site at pahina na
              naglalaman ng impormasyong ginawa at pinapanatili ng mga pampubliko at pribadong organisasyon maliban sa
              Estado ng Hawaiʻi. Ang mga hypertext link na ito ay maaaring gawin ng Estado ng Hawaiʻi kung matukoy nito
              na ang pagtatatag ng panlabas na link ay magiging pare-pareho sa pagtulong o pagpapasulong sa layunin ng
              website na ito na makabuluhang at masigasig na isulong ang pampublikong serbisyo sa mga mamamayan at
              negosyo sa pamamagitan ng:
            </p>
            <ul>
              <li>pagpapalawak ng access sa negosyo at mamamayan sa mga serbisyo at impormasyon ng pamahalaan;</li>
              <li>
                pag-aalok ng madali at maginhawang proseso para sa mga negosyo at mamamayan para magsagawa ng mga
                transaksyon sa pamahalaan ng Estado online;
              </li>
              <li>
                pagpapabilis ng pag-unlad at paghahatid ng mas maraming dekalidad, online na mga serbisyo ng pamahalaan;
              </li>
              <li>pagpapabuti ng antas ng serbisyo sa kostumer mula sa pamahalaan ng Estado; at</li>
              <li>
                pagpapalawak ng mga serbisyong elektroniko ng pamahalaan sa mga mamamayan ng mga lungsod at pamahalaan
                ng county.
              </li>
            </ul>
            <p>
              Bilang karagdagan, ang mga hypertext na link ay maaaring gawin ng Estado ng Hawaiʻi para sa mga layuning
              pang-impormasyon, na kung saan ang naka-link na panlabas na website ay magbibigay ng kapaki-pakinabang at
              mahalagang impormasyon sa mga bisita sa website na ito, o kung saan ang naka-link na panlabas na website
              ay kinakailangan o pinahintulutan ng batas.
            </p>
            <p>
              Ang Estado ng Hawaiʻi, sa sarili nitong pagpapasya, ay tutukuyin kung ang panlabas na website ay
              nakakatugon sa layunin ng website na ito o para sa mga tinukoy na layuning pang-impormasyon. Ang pagsasama
              ng isang hypertext na link sa isang panlabas na website ay hindi inilaan bilang isang pag-endorso ng
              anumang mga produkto o serbisyo na inaalok o isinasangguni sa naka-link na website, ang mga organisasyong
              nag-iisponsor ng naturang website, o anumang mga pananaw na maaaring ipahayag o isangguni sa website.
            </p>
            <p>
              Ang mga hypertext na link na ito sa mga panlabas na site at pahina ay maaaring alisin o palitan sa
              sariling pagpapasya ng Estado ng Hawaiʻi, anumang oras nang walang abiso. Kung sakaling makatuklas kayo ng
              mga problema sa o may mga alalahanin tungkol sa format, pagiging tama, pagiging maagap, o pagiging
              kumpleto ng isang naka-link na panlabas na site, mangyaring makipag-ugnayan sa organisasyong responsable
              para sa naka-link na panlabas na site – Ang Estado ng Hawaiʻi ay hindi kumokontrol at hindi ito mananagot
              para sa anumang naka-link na mga panlabas na site at pahina.
            </p>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Pagtatanggi ng Pag-endorso
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Anumang pagsangguni sa website na ito sa anumang partikular na komersyal na produkto, proseso, o serbisyo sa
            pamamagitan ng trade name, trademark, tagagawa, o kung hindi man, ay hindi bumubuo o nagpapahiwatig ng
            pag-endorso, rekomendasyon, o pagpabor dito ng Estado ng Hawaiʻi.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Hurisdiksyon
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Ang sinumang taong pipiliing i-access at gamitin ang website na ito ay napapailalim sa hurisdiksyon ng
            Estado ng Hawaiʻi.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Ang mga tuntuning ito ay pamamahalaan at bigyang-kahulugan alinsunod sa mga batas ng Estado ng Hawaiʻi. Ang
            anumang pagtatalo na magmumula sa o nauugnay sa mga tuntuning ito, o pag-access at paggamit ng website na
            ito ay dapat pagpasiyahan sa ilalim ng mga batas ng at sa mga hukuman ng estado sa Estado ng Hawaiʻi, at sa
            pamamagitan nito ay pumapayag kayo at nagpapailalim sa personal na hurisdiksyon ng mga nasabing hukuman.
            para sa layunin ng paghatol sa nasabing pagtatalo.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Ibang Mga Website
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Ang website na ito ay maaaring maglaman ng mga hypertext na link sa ibang mga website at pahina na
            naglalaman ng impormasyong ginawa at pinapanatili ng ibang mga pampubliko at pribadong organisasyon. Kung
            magpasya kang bisitahin ang mga naka-link na site na ito, dapat ninyong suriin ang mga pahayag sa privacy ng
            iba pang mga website na ito. Ang Estado ng Hawaiʻi ay walang pananagutan para sa nilalamang ibinibigay sa
            ibang mga website na ito.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Paghihiwalay
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Kung ang anumang probisyon sa Mga Tuntunin ng Paggamit at Patakaran sa Pagkapribado sa itaas ay matutukoy na
            labag sa batas, walang bisa, o hindi maipapatupad ng korte na may karampatang hurisdiksyon, ang probisyong
            iyon ay ituring na maaaring ihiwalay sa mga natitirang tuntunin at hindi makakaapekto sa bisa at kakayahang
            maipatupad ng mga natitirang probisyon.
          </Typography>
          <Box m={3} />
        </Container>
      </div>
    </>
  );
};

export default TermsOfUseTL;
