import { useHistory } from 'react-router-dom';
import { useState } from 'react';

/* Styles */
import styles from 'styles/login.module.scss';
import industryCardsStyles from 'styles/industry-cards.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* API */
import { updateExperience } from 'shared/src/lib/API/experience';
import { apiRequest } from 'lib/API';

/* Material UI and other UI Dependencies */
import { Typography, Button, useMediaQuery } from '@mui/material';
import OnboardingSteps from 'components/OnboardingSteps';
import { Box, CircularProgress } from '@mui/material';
import { GoalCard } from 'shared/components';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Redux */
import { useDispatch } from 'react-redux';
import { setLoadingRecommendations, setNeedsOnboarding, setSurvey } from 'app/slices/appSlice';
import { setTagsActive } from 'app/slices/jobsSlice';
import { setGoal } from 'app/slices/careersSlice';
import { setActivities, setGuid, setStatus, setLoaded } from 'app/slices/workSearchSlice';

export default function OnboardingGoals() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const urlPrefix = getUrlPrefix(intl.locale);
  const isSmall = useMediaQuery('(max-width:767px)');
  const [isLoading, setIsLoading] = useState(false);

  const handleCardClick = async (goal) => {
    try {
      dispatch(setLoadingRecommendations(true));
      setIsLoading(true);
      trackEvent('ONBOARDING_FOCUS', goal);
      dispatch(setGoal(goal));

      await updateExperience({ goal });

      dispatch(setNeedsOnboarding(false));
      if (goal === 'currentCareer') {
        dispatch(setTagsActive('currentCareer'));
        history.push(`${urlPrefix}/jobs`);
      } else {
        history.push(`${urlPrefix}/careers`);
      }

      // Remove item from localStorage with key 'onboarding_guid'
      if (localStorage) {
        localStorage.removeItem('onboarding_guid');
        localStorage.removeItem('ui_profile');
      }

      dispatch(setSurvey({ loaded: true, completed: false }));
      apiRequest('GET', '/users/me/worksearch/').then((res) => {
        if (res.status && res.status.worksearch_enabled) {
          dispatch(setActivities(res.activities));
          dispatch(setGuid(res.guid));
          dispatch(setStatus(res.status));
          dispatch(setLoaded(true));
        } else if (res.status) {
          dispatch(setStatus(res.status));
        }
      });
    } catch (error) {
      console.log('Error while saving goal:', error);
    } finally {
      dispatch(setLoadingRecommendations(false));
    }
  };

  const goals = ['currentCareer', 'newCareer', 'notSure'];

  return (
    <>
      <div className={styles.loginFlow__form__onboarding}>
        <Box px={3} className={onboardingStyles.goalsTitle}>
          <Typography align="center" variant="h1" gutterBottom>
            {intl.formatMessage({ id: 'onboarding.goals.title', description: 'string' })}
          </Typography>
        </Box>
        <OnboardingSteps activeStep={2} />
      </div>
      <hr className={layoutStyles.separatorBold} />
      {isLoading ? (
        <Box m={2} pb={6} pt={4} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <div className={onboardingStyles.goalsContainer}>
          <Box style={{ textAlign: 'center', lineHeight: '22px' }} pt={1} {...(isSmall ? null : { px: 5 })}>
            {intl.formatMessage({ id: 'onboarding.goals.youCanChange' })}
          </Box>
          <div className={industryCardsStyles.container}>
            {goals.map((i) => (
              <GoalCard type={i} key={i} onClick={() => handleCardClick(i)} />
            ))}
          </div>
          <Box mb={2} />
          <div style={{ marginTop: isSmall ? '2.5em' : 0 }}>
            <Button
              color="secondary"
              size="large"
              variant="contained"
              style={{ marginRight: 10 }}
              onClick={() => history.push(`${urlPrefix}/onboarding/education`)}
            >
              {intl.formatMessage({ id: 'forms.onboarding.back' })}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
