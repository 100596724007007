import { useState } from 'react';
import { css, cx } from '@emotion/css';
import { FormControl, Select, Input, MenuItem, Checkbox } from '@mui/material';

/* Styles */
import cssVars from 'styles/vars.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* Assets */
import arrowDown from 'assets/images/Arrow_Dropdown.svg';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const styles = {
  ratingsFiltersFocused: css(
    {
      '@media screen and (max-width: 767px)': {
        height: '251px',
      },
    },
    { label: 'ratings-filters-focused' }
  ),
  selectLabel: css(
    {
      position: 'absolute',
      zIndex: 1,
      fontSize: 12,
      color: cssVars.darkGray,
      left: 13,
      top: 13,
      pointerEvents: 'none',
      '@media screen and (max-width: 767px)': {
        fontSize: 16,
      },
    },
    { label: 'select-label' }
  ),
  arrowDown: css(
    {
      width: 15,
      position: 'absolute',
      top: 18,
      right: 14,
      zIndex: 2,
      pointerEvents: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    { label: 'arrow-down' }
  ),
  selectMenu: css(
    {
      boxSizing: 'border-box',
      width: '100% !important',
      borderRadius: '0 !important',
      borderLeft: `solid 1px ${cssVars.mediumGray}`,
      borderRight: `solid 1px ${cssVars.mediumGray}`,
      borderBottom: `solid 1px ${cssVars.mediumGray}`,

      '@media screen and (min-width: 768px)': {
        width: '203px !important',
      },
    },
    { label: 'select-menu' }
  ),
  selectRoot: css(
    {
      boxSizing: 'border-box',
      border: `solid 1px ${cssVars.mediumGray} !important`,
      borderRadius: 0,
      height: 40,
      width: '100%',
      padding: 12,
      fontSize: 12,
      left: -1,
      '&:focus': {
        outline: 'none',
      },
      '&::placeholder': {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: cssVars.darkGray,
        opacity: 1 /* Firefox */,
      },
      '&:-ms-input-placeholder': {
        /* Internet Explorer 10-11 */ color: cssVars.darkGray,
      },
      '&::-ms-input-placeholder': {
        /* Microsoft Edge */ color: cssVars.darkGray,
      },
    },
    { label: 'select-root' }
  ),
  checkboxRoot: css(
    {
      color: 'black !important',
    },
    { label: 'checkbox-root' }
  ),
  checkboxLabel: css(
    {
      color: cssVars.darkGray,
      fontSize: 12,
      lineHeight: '18px',
      whiteSpace: 'break-spaces',
    },
    { label: 'checkbox-label' }
  ),
};

const options = [
  { value: 'boostEarnings', title: 'onboarding.goals.boostEarnings' },
  { value: 'similarSkills', title: 'onboarding.goals.similarSkills' },
  { value: 'manyJobs', title: 'careers.tags.manyJobsToggle' },
];

export default function CardPlaceholder({ activeFilters, handleFiltersChange }) {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const intl = useIntl();
  return (
    <FormControl
      sx={{
        width: '100% !important',
        marginBottom: '0 !important',

        '@media screen and (min-width: 768px)': {
          width: '203px !important',
        },
      }}
      className={cx({
        [styles.ratingsFiltersFocused]: filtersOpen,
      })}
    >
      <span className={styles.selectLabel}>{intl.formatMessage({ id: 'careers.filter', description: 'string' })}</span>
      <Select
        multiple
        name="Filter Ratings"
        disableUnderline
        labelId="filters-select-label"
        id="filters-select"
        value={activeFilters}
        onChange={(evt) => handleFiltersChange(evt.target.value)}
        onOpen={() => setFiltersOpen(true)}
        onClose={() => setFiltersOpen(false)}
        open={filtersOpen}
        input={<Input />}
        renderValue={() => ''}
        IconComponent={() => <img alt="Arrow down" className={styles.arrowDown} src={arrowDown} />}
        MenuProps={{
          PaperProps: {
            className: styles.selectMenu,
          },
          getContentAnchorEl: null,
          disableScrollLock: true,
        }}
        classes={{
          root: styles.selectRoot,
        }}
        sx={{
          backgroundColor: 'white',
        }}
      >
        {options.map((filter) => (
          <MenuItem key={filter.value} value={filter.value}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              classes={{
                root: styles.checkboxRoot,
              }}
              style={{ marginRight: -3, marginLeft: 0, padding: '5px 9px 7px 4px' }}
              checked={activeFilters.indexOf(filter.value) > -1}
            />
            <span className={styles.checkboxLabel}>{intl.formatMessage({ id: filter.title })}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
