import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDropzone } from 'react-dropzone';
import { Alert, AlertTitle } from '@mui/material';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { bytesToSize } from 'lib/utils';
import { connect } from 'formik';

/* Styles */
import styles from 'styles/browse-documents.module.scss';
import applyJobStyles from 'styles/apply-job-form.module.scss';

/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  info: {
    marginTop: 8,
  },
  actions: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: 16,
    },
  },
}));

function BrowseDocuments({ className, onDrop, disabled, ...props }) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const intl = useIntl();
  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (disabled) {
        return;
      }
      // Read file contents and store base64 encoded data to
      // File object in 'contents' attribute
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          file.contents = reader.result;
          const tokens = file.name.split('.');
          let ext = '';
          if (tokens.length > 1) {
            file.ext = '.' + tokens.pop();
            ext = file.ext;
          }
          if (onDrop) {
            onDrop(reader.result, ext);
          }
        };
        reader.readAsDataURL(file);
      });
      setFiles(acceptedFiles);

      const {
        field: { name },
        form: { setFieldValue },
      } = props;

      setFieldValue(name, acceptedFiles);
    },
    [props, onDrop]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections, maxSize } = useDropzone({
    onDrop: handleDrop,
    accept:
      'text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf',
    minSize: 0,
    maxSize: 10000000,
    maxFiles: 1,
    multiple: false,
    disabled: disabled,
  });

  const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].size > maxSize;

  const fileRejectionItems = fileRejections.map(({ file }) => (
    <Alert severity="error" key={file.path}>
      <AlertTitle>
        {file.path} - {file.size} bytes
      </AlertTitle>
      <Typography variant="body1" gutterBottom={0}>
        <FormattedMessage id="forms.enroll.fileFormats" defaultMessage="Must be a (.doc, .docx, .pdf, .txt) format" />
      </Typography>
    </Alert>
  ));

  return (
    <div className={cn(classes.root, className)} {...props}>
      <Box
        className={cn({
          [styles.dropZone]: true,
          [styles.dropZoneDisabled]: disabled,
        })}
        {...getRootProps()}
        sx={{
          backgroundColor: (theme) => (isDragActive ? theme.palette.action.active : 'transparent'),
          opacity: isDragActive ? 0.5 : 1,
        }}
      >
        <input {...getInputProps()} title={intl.formatMessage({ id: 'jobSearchActivity.form.uploadPlaceholder' })} />
        <div>
          <span className={applyJobStyles.fieldDescription}>
            <FormattedMessage id="jobSearchActivity.form.uploadPlaceholder" />
          </span>
          <Box mt={4} align="left">
            <Button color="primary" size="large" variant="contained" disabled={disabled} type="button">
              {intl.formatMessage({ id: 'experience.form.browse', description: 'string' })}
            </Button>
          </Box>
        </div>
      </Box>
      {files.length > 0 && (
        <>
          <List className={styles.list}>
            {files.map((file, i) => (
              <ListItem divider={i < files.length - 1} key={i}>
                <ListItemIcon styles={{ textAlign: 'center' }}>
                  <FileCopyIcon />
                </ListItemIcon>
                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{ variant: 'caption' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                  secondary={bytesToSize(file.size)}
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
      {isFileTooLarge && (
        <Alert severity="error">
          <FormattedMessage id="forms.enroll.uploadErrorTooLarge" defaultMessage="File is too large" />
        </Alert>
      )}
      {fileRejections && <div>{fileRejectionItems}</div>}
    </div>
  );
}

BrowseDocuments.propTypes = {
  className: PropTypes.string,
  onDrop: PropTypes.func,
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    name: PropTypes.string,
  }),
  form: PropTypes.shape({
    setValue: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
};

export default connect(BrowseDocuments);
