import apiRequest from './wrapper';

/**
 * @method : GET
 * @path   : /users/me/experience
 * @desc   : get experience
 */
export const getExperience = (pathParam, params) => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/users/me/experience/', { params }, pathParam)
      .then((response) => {
        console.log('from get experience', response);
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = 'getExperience: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = 'getExperience: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = 'getExperience: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          console.error(errorMessage);
          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          console.error('getExperience: No response was received from the server');
          reject(new Error('getExperience: No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('getExperience', error.message);
          reject(error);
        }
      });
  });
};

/**
 * @method : PUT
 * @path   : /users/me/experience
 * @desc   : Update experience
 */
export const updateExperience = (values, params) => {
  return new Promise((resolve, reject) => {
    apiRequest('put', '/users/me/experience/', { data: values, ...(params ? { params } : {}) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { status, data } = error.response;
          let errorMessage;

          if (status === 401) {
            // handle unauthorized error
            errorMessage = 'updateExperience: You are not authorized to perform this action';
          } else if (status === 404) {
            // handle not found error
            errorMessage = 'updateExperience: The resource you are trying to access was not found';
          } else if (status >= 500) {
            // handle server error
            errorMessage = 'updateExperience: An error occurred on the server';
          } else {
            errorMessage = data.message;
          }

          console.error(errorMessage);
          reject(new Error(errorMessage));
        } else if (error.request) {
          // The request was made but no response was received
          console.error('updateExperience: No response was received from the server');
          reject(new Error('updateExperience: No response was received from the server'));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('updateExperience:', error.message);
          reject(error);
        }
      });
  });
};
