/**
 * Returns a Date object from a string
 * @param {*} str string with format 'yyyy-mm-dd' or 'yyyymmdd'
 */
export function getDateFromString(str) {
  return new Date(str.slice(0, 4), (str.includes('-') ? str.slice(5, 7) : str.slice(4, 6)) - 1);
}

export function getLastExperience(jobExperience) {
  let lastExperience = jobExperience[0];
  jobExperience.forEach((item) => {
    const lastExperienceDate = getDateFromString(lastExperience.start_date);
    const itemDate = getDateFromString(item.start_date);
    if (lastExperience.soc === item.soc && itemDate < lastExperienceDate) {
      lastExperience = item;
    }
  });
  return lastExperience;
}
