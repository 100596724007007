import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

PhoneNumberInput.propTypes = {
  inputRef: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default function PhoneNumberInput(props) {
  const { inputRef, onChange, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      displayType={'input'}
      type={'tel'}
      format="(###) ###-####"
      placeholder="(###) ###-####"
      mask="_"
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
}
