import { css, cx } from '@emotion/css';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { trackEvent } from 'lib/analytics';
import { equals, propOr } from 'ramda';
import PropTypes from 'prop-types';

import {
  TextField,
  Button,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import cssVars from 'styles/vars.module.scss';

/* Redux */
import { useDispatch } from 'react-redux';
import { setEducation } from 'app/slices/onboardingSlice';

/* Utils */
import { preventSubmitOnEnter } from 'shared/utils';

const makeStyles = () => ({
  textFieldWhite: css(
    {
      backgroundColor: `${cssVars.white} !important`,
      borderRadius: '3px 3px 0 0 !important',
      input: {
        backgroundColor: cssVars.white,
        borderRadius: '4px 4px 0 0',
      },
    },
    { label: 'text-field-white' }
  ),
  submitBtn__container: css(
    {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 16,
    },
    { label: 'submit-button__container' }
  ),
  formContainer: css(
    {
      maxWidth: '568px',
      margin: '0 auto',
    },
    { label: 'form-container' }
  ),
  formBorder: css(
    {
      border: `solid 1px ${cssVars.midLightGray}`,
      padding: '8px',
      position: 'relative',
      boxSizing: 'border-box',
      minHeight: '301px',
      '@media screen and (min-width: 672px)': {
        padding: '1.15em',
        paddingBottom: '1em',
      },
    },
    { label: 'form-border' }
  ),
});

EducationForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object,
    setValues: PropTypes.func,
    handleSubmit: PropTypes.func,
    touched: PropTypes.object,
    errors: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
  }),
  uploadedResume: PropTypes.bool,
  classNames: PropTypes.any,
  submitting: PropTypes.bool,
  children: PropTypes.any,
  submitLabel: PropTypes.string,
  onboarding: PropTypes.bool,
  valueChanged: PropTypes.func,
  formTitle: PropTypes.string,
  choseToStay: PropTypes.bool,
};

export default function EducationForm({
  formik,
  uploadedResume = false,
  classNames,
  submitting,
  children,
  submitLabel,
  onboarding = false,
  valueChanged = () => {},
  formTitle = '',
  choseToStay,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const [educationLevelOpen, setEducationLevelOpen] = useState(false);
  const [initialFormikValues, setInitialFormikValues] = useState({ ...formik.values });
  const [keysWithChanges, setKeysWithChanges] = useState([]);

  const history = useHistory();
  const isSmall = useMediaQuery('(max-width:767px)');
  const locality = process.env.REACT_APP_LOCALITY;
  const styles = makeStyles();

  const headerProps =
    locality === 'nj'
      ? {
          variant: onboarding ? 'h4' : 'h5',
          gutterBottom: true,
          component: 'h2',
        }
      : {
          align: onboarding ? 'center' : 'left',
          variant: 'h5',
          gutterBottom: true,
          component: 'h2',
        };

  useEffect(() => {
    const handler = () => {
      setEducationLevelOpen(false);
    };
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);
  useEffect(() => {
    if (!formik.values.empty && initialFormikValues.empty) {
      setInitialFormikValues({ ...formik.values });
    }
  }, [formik.values]);

  const educationOptions = [
    { value: '11', title: intl.formatMessage({ id: 'forms.onboarding.education.noDiploma' }) },
    { value: '11.5', title: intl.formatMessage({ id: 'forms.onboarding.education.someHighSchool' }) },
    { value: '12', title: intl.formatMessage({ id: 'forms.onboarding.education.diploma' }) },
    { value: '13', title: intl.formatMessage({ id: 'forms.onboarding.education.someCollege' }) },
    { value: '14', title: intl.formatMessage({ id: 'forms.onboarding.education.assocDegree' }) },
    { value: '16', title: intl.formatMessage({ id: 'forms.onboarding.education.bachDegree' }) },
    { value: '16.5', title: intl.formatMessage({ id: 'forms.onboarding.education.someGraduate' }) },
    { value: '17', title: intl.formatMessage({ id: 'forms.onboarding.education.mastersDegree' }) },
    { value: '20', title: intl.formatMessage({ id: 'forms.onboarding.education.advancedDegree' }) },
  ];

  const handleDiffs = (fieldName, evt) => {
    const initialValues = initialFormikValues;
    const currentValues = { ...formik.values, [fieldName]: evt.target.value };
    if (equals(initialValues, currentValues)) {
      setKeysWithChanges([]);
      valueChanged(false);
    } else {
      const differentKeys = [];
      for (const key in initialValues) {
        if (initialValues[key] !== currentValues[key]) {
          differentKeys.push(key);
        }
      }
      setKeysWithChanges(differentKeys);
      valueChanged(true);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        formik.handleSubmit(e);
        setInitialFormikValues({ ...formik.values });
        valueChanged(false);
        dispatch(setEducation({}));
        setKeysWithChanges([]);
      }}
      className={classNames}
    >
      {locality !== 'hi' && onboarding && (
        <>
          <Box mb={2}>
            <Typography {...headerProps}>
              {intl.formatMessage({
                id:
                  formTitle !== ''
                    ? formTitle
                    : uploadedResume
                    ? 'forms.onboarding.education.addEdit'
                    : 'forms.onboarding.education.add',
              })}
            </Typography>
          </Box>
          {uploadedResume && (
            <>
              <p className={styles.formContainer}>
                {intl.formatMessage({ id: 'forms.onboarding.education.weFilled' })}
              </p>
              <Box mb={1} />
            </>
          )}
        </>
      )}
      <div className={cx({ [styles.formBorder]: !onboarding })}>
        <FormControl
          variant="filled"
          error={
            (!!formik.touched.degree && !!formik.errors.degree) || (choseToStay && keysWithChanges.includes('degree'))
          }
        >
          <InputLabel id="education-level-label">
            {intl.formatMessage({ id: 'forms.onboarding.educationLevel.titlecase' })}
            <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
          </InputLabel>
          <Select
            name="degree"
            labelId="education-level-label"
            id="education-level"
            classes={{ root: onboarding ? null : styles.textFieldWhite }}
            value={formik.values.degree}
            onChange={(e) => {
              formik.handleChange(e);
              handleDiffs('degree', e);
              if (onboarding) {
                trackEvent('ONBOARDING_EDUCATION_LEVEL', e.target.value);
              }
            }}
            onKeyDown={preventSubmitOnEnter}
            onBlur={formik.handleBlur}
            onOpen={() => setEducationLevelOpen(true)}
            onClose={() => setEducationLevelOpen(false)}
            open={educationLevelOpen}
            MenuProps={{
              disableScrollLock: true,
              PaperProps: {
                variant: 'outlined',
              },
            }}
          >
            {educationOptions.map((eo) => (
              <MenuItem key={eo.value} value={eo.value} style={{ ...(locality === 'nj' && { fontSize: 14 }) }}>
                {eo.title}
              </MenuItem>
            ))}
          </Select>
          {!!formik.touched.degree && !!formik.errors.degree && (
            <FormHelperText>
              {formik.errors.degree ? intl.formatMessage({ id: formik.errors.degree }) : ''}
            </FormHelperText>
          )}
        </FormControl>
        {formik.values.degree > 12 && (
          <TextField
            error={
              (formik.touched.school_name && Boolean(formik.errors.school_name)) ||
              (choseToStay && keysWithChanges.includes('school_name'))
            }
            fullWidth
            helperText={formik.touched.school_name && formik.errors.school_name}
            label={intl.formatMessage({ id: 'forms.onboarding.education.schoolName' })}
            name="school_name"
            classes={{ root: onboarding ? null : styles.textFieldWhite }}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              handleDiffs('school_name', e);
            }}
            onKeyDown={preventSubmitOnEnter}
            type="text"
            value={formik.values.school_name}
            variant="filled"
            inputProps={{
              title: intl.formatMessage({ id: 'forms.onboarding.education.schoolName' }),
            }}
          />
        )}
        {formik.values.degree > 12 && (
          <TextField
            error={
              (!!formik.touched.field && !!formik.errors.field) || (choseToStay && keysWithChanges.includes('field'))
            }
            fullWidth
            helperText={formik.touched.field && formik.errors.field}
            label={intl.formatMessage({ id: 'forms.onboarding.education.fos' })}
            name="field"
            classes={{ root: onboarding ? null : styles.textFieldWhite }}
            style={{
              ...(!onboarding && {
                marginBottom: 3,
              }),
            }}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              handleDiffs('field', e);
            }}
            onKeyDown={preventSubmitOnEnter}
            type="text"
            value={formik.values.field}
            variant="filled"
            inputProps={{
              title: intl.formatMessage({ id: 'forms.onboarding.education.fos' }),
            }}
          />
        )}
        <div>
          <Box mb={2} />
          <Typography {...headerProps}>
            {intl.formatMessage({
              id: onboarding ? 'onboarding.education.addAdditional' : 'onboarding.education.addOrUpdateAdditional',
            })}
          </Typography>
          <Box mb={1} />
          <TextField
            error={
              (!!formik.touched.additional_education && !!formik.errors.additional_education) ||
              (choseToStay && keysWithChanges.includes('additional_education'))
            }
            fullWidth
            helperText={formik.touched.additional_education && formik.errors.additional_education}
            label={intl.formatMessage({ id: 'onboarding.education.addAdditionalLabel' })}
            name="additional_education"
            classes={{ root: onboarding ? null : styles.textFieldWhite }}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              handleDiffs('additional_education', e);
            }}
            onKeyDown={preventSubmitOnEnter}
            type="text"
            value={formik.values.additional_education}
            variant="filled"
            inputProps={{
              title: intl.formatMessage({ id: 'onboarding.education.addAdditionalLabel' }),
            }}
          />
        </div>
        {children}
      </div>
      <div
        className={styles.submitBtn__container}
        style={{ display: 'flex', justifyContent: isSmall ? 'space-between' : 'flex-end' }}
      >
        {onboarding ? (
          <Button
            color={locality === 'nj' ? 'primary' : 'secondary'}
            disabled={submitting}
            size="large"
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => {
              dispatch(setEducation(formik?.values));
              history.push(`${urlPrefix}/onboarding/experience`);
            }}
          >
            {intl.formatMessage({ id: 'forms.onboarding.back' })}
          </Button>
        ) : null}
        <Button
          color={locality === 'nj' ? 'primary' : 'secondary'}
          disabled={submitting}
          size="large"
          type="submit"
          variant="contained"
        >
          {submitting ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">{intl.formatMessage({ id: 'forms.signIn.loading' })}</span>
            </div>
          ) : (
            submitLabel
          )}
        </Button>
      </div>
    </form>
  );
}
