import { css } from '@emotion/css';

/* Styles */
import cssVars from 'styles/vars.module.scss';

const styles = {
  footerLink: css({
    color: cssVars.white,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:visited': {
      color: cssVars.white,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '&:hover': {
      color: cssVars.lightGray,
      cursor: 'pointer',
    },
    '&:active': {
      color: cssVars.mediumGray,
    },
  }),
};

const CareersNewUserPopupContents = ({ intl, history }) => {
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  const handleRedirect = (route) => {
    history.push(`${urlPrefix}/${route}`);
  };
  return (
    <>
      {intl.formatMessage({ id: 'careers.newUserPopup1' })}
      <br />
      <span className={styles.footerLink} onClick={() => handleRedirect('experience')}>
        {intl.formatMessage({ id: 'careers.newUserPopup2' })}
      </span>
      &nbsp;
      {intl.formatMessage({ id: 'careers.newUserPopup3' })}
    </>
  );
};

export default CareersNewUserPopupContents;
