/* Styles */
import layoutStyles from 'styles/layout.module.scss';
import cn from 'classnames';

export default function OnboardingHeader() {
  return (
    <>
      <div className={layoutStyles.header__blueBar} />
      <div className={layoutStyles.colorsBar}>
        <div className={cn(layoutStyles.colorsBar__red, layoutStyles.colorsBar__color)}></div>
        <div className={cn(layoutStyles.colorsBar__green, layoutStyles.colorsBar__color)}></div>
        <div className={cn(layoutStyles.colorsBar__orange, layoutStyles.colorsBar__color)}></div>
        <div className={cn(layoutStyles.colorsBar__blue, layoutStyles.colorsBar__color)}></div>
      </div>
    </>
  );
}
