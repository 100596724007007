import PropTypes from 'prop-types';
import { css } from '@emotion/css';
import { propOr } from 'ramda';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { updateKey } from 'app/slices/benefitsFinderSlice';

import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { lighten } from '@mui/material';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import cssVars from 'styles/vars.module.scss';

const styles = {
  title: css(
    {
      fontSize: '18px',
    },
    { label: 'title' }
  ),
  grouped: css(
    {
      margin: '6px !important',
      border: `1px solid ${cssVars.mediumGray} !important`,
      borderRadius: '4px !important',
      padding: '10px 20px !important',
      '& span': {
        color: cssVars.darkGray,
      },
      '&.Mui-selected': {
        border: `solid 1px ${cssVars.navy} !important`,
        backgroundColor: `${lighten(cssVars.blue, 0.75)} !important`,
        span: {
          color: `${cssVars.navy} !important`,
          fontWeight: 700,
        },
        '&:focus': {
          outline: `0.25rem solid ${lighten('#2687e8', 0.05)}`,
          outlineOffset: 2,
        },
      },
    },
    { label: 'grouped' }
  ),
  error: css({
    color: cssVars.errorColorMain,
    fontSize: 15,
  }),
};

export default function SingleChoiceQuestion({ name, title, options, touched, error }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const benefitsResults = useSelector((state) => state.benefitsFinder.results);
  const values = propOr('', name, benefitsResults);

  const handleChange = (_, v) => {
    dispatch(updateKey({ key: name, value: v }));
  };

  return (
    <div>
      <h2 className={styles.title}>{title}</h2>
      <div>
        <ToggleButtonGroup
          color="primary"
          size="small"
          value={values}
          exclusive
          onChange={handleChange}
          aria-label={name}
          classes={{ grouped: styles.grouped }}
        >
          {options.map((option) => {
            return (
              <ToggleButton key={option.value} value={option.value} aria-label={option.text}>
                {option.text}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>

        {touched && error ? <div className={styles.error}>{intl.formatMessage({ id: error })}</div> : null}
      </div>
    </div>
  );
}

SingleChoiceQuestion.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};
