import { css, cx } from '@emotion/css';
import PropTypes from 'prop-types';

/* Styles */
import cssVars from 'styles/vars.module.scss';

/* Assets */
import { Close } from '../images/icons';

const makeStyles = ({ locality, backgroundColor, backgroundHover }) => ({
  tag: css(
    {
      display: 'inline-block',
      transition: 'all .35s',
      position: 'relative',
    },
    { label: 'tag' }
  ),
  text: css(
    {
      backgroundColor,
      fontSize: '10px',
      fontWeight: '700',
      lineHeight: '1.5em',
      color: cssVars.darkGray,
      textTransform: 'uppercase',
      padding: '4px 14px',
      '&:hover': {
        backgroundColor: backgroundHover,
      },
    },
    { label: 'text' }
  ),
  hasCloseButton: css(
    {
      //if there's a close button
      '&:hover': {
        span: {
          backgroundColor: backgroundHover, //the background color changes on hover to indicate a possible action
          '&:hover': {
            backgroundColor, //but not if the hover is over the text rather than just the "X" button
          },
        },
      },
    },
    { label: 'has-close-button' }
  ),
  textSkill: css({
    ...(locality === 'nj' && {
      border: `solid 1px ${cssVars.darkGray}`,
      borderRadius: '12px',
    }),
  }),
  careerType: css(
    {
      pointerEvents: 'none !important',
    },
    { label: 'career-type' }
  ),
  skillType: css(
    {
      marginBottom: ' 8px',
      marginRight: '4px',
    },
    { label: 'skill-type' }
  ),
  filterType: css({
    marginBottom: '8px',
  }),
  closeLink: css(
    {
      display: 'inline',
      border: 'none',
      backgroundColor: 'transparent',
      padding: 0,
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: '14px',
      margin: 'auto 0',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    { label: 'close-link' }
  ),
  closeButton: css(
    {
      width: '9.25px',
      height: '9.25px',
      ...(['ripl'].includes(locality) && {
        position: 'relative',
        top: '1px',
        left: '4px',
      }),
    },
    { label: 'close-button' }
  ),
  textCloseButton: css(
    {
      paddingRight: '30px',
      '&:hover': {
        cursor: 'default',
      },
    },
    { label: 'text-close-button' }
  ),
});

const Tag = ({ text, type, onClick = () => {}, clickable = true }) => {
  const locality = process.env.REACT_APP_LOCALITY;

  // Background colors
  const bgDict = { career: cssVars.tagCareer, job: cssVars.tagJob, skill: cssVars.tagSkill, filter: cssVars.filterTag };
  const bgHoverDict = {
    career: cssVars.tagCareer,
    job: cssVars.tagJobHover,
    skill: cssVars.tagSkillHover,
    filter: cssVars.filterTagHover,
  };

  const styles = makeStyles({ locality, backgroundColor: bgDict[type], backgroundHover: bgHoverDict[type] });
  const hasCloseButton = ['filter', 'skill'].includes(type) && clickable;
  const divAttributes = hasCloseButton
    ? {}
    : {
        onClick,
        role: clickable ? 'button' : '',
      };
  const divStyleClass = styles[`${type}Type`];

  return (
    <div
      className={cx({
        [styles.tag]: true,
        [styles.hasCloseButton]: hasCloseButton,
        [divStyleClass]: true,
        [styles.notClickable]: !clickable,
      })}
      {...divAttributes}
    >
      <span
        className={cx({
          [styles.text]: true,
          [styles.textSkill]: type === 'skill',
          [styles.textCloseButton]: hasCloseButton,
        })}
      >
        {text}
      </span>
      {hasCloseButton && (
        <button className={styles.closeLink} onClick={onClick}>
          <img alt="Close" className={styles.closeButton} src={Close} />
        </button>
      )}
    </div>
  );
};

Tag.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf(['career', 'job', 'skill', 'filter']),
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  overrideClassName: PropTypes.string,
};

export default Tag;
