import { css } from '@emotion/css';

/* Styles */
import cssVars from 'styles/vars.module.scss';

const makeStyles = () => ({
  linkContainer: css({
    lineHeight: '1.1rem',
    display: 'inline-flex',
    flexFlow: 'row wrap',
    span: {
      marginRight: '.25em',
    },
  }),
  inlineLink: css({
    lineHeight: '1.1rem',
    color: cssVars.actionBlue,
    fontWeight: 700,
    textDecoration: 'underline',
    position: 'relative',
    '&::after': {
      position: 'absolute',
      left: '100%',
      marginLeft: '.5px',
      content: '"."',
      fontWeight: 'normal',
      textDecoration: 'none',
    },
    '&:hover': {
      color: cssVars.actionBlueHover,
      cursor: 'pointer',
    },
  }),
});

//example: OnboardingContact terms and conditions link in NJ
const TextWithInlineLink = ({ text, linkText, linkHref = null, onLinkClickHandler = () => {} }) => {
  const styles = makeStyles();

  return (
    <span className={styles.linkContainer}>
      <span>{text}</span>
      <a href={linkHref} className={styles.inlineLink} onClick={onLinkClickHandler}>
        {linkText}
      </a>
    </span>
  );
};

export default TextWithInlineLink;
