import { useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import PropTypes from 'prop-types';

/* Styles */
import styles from 'styles/login.module.scss';

/* API */
import { updateUserData } from 'shared/src/lib/API';

/* Material UI and other UI Dependencies */
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  useMediaQuery,
  TextField,
  IconButton,
} from '@mui/material';
import SSNInput from 'components/inputs/FullSSNInput';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setStatus } from 'app/slices/workSearchSlice';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* i18n */
import { useIntl } from 'react-intl';

export default function SSNDialog({ open, setOpen, refreshCounter, setRefreshCounter }) {
  const intl = useIntl();
  const isSmall = useMediaQuery('(max-width:767px)');
  const [ssnType, setSsnType] = useState('text');
  const [submitting, setSubmitting] = useState(false);
  const [contactError, setContactError] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const worksearch_status = useSelector((state) => state.worksearch.status);

  const validationSchema = yup.object({
    ssnfull: yup
      .string(intl.formatMessage({ id: 'forms.onboarding.fullSocialSecurityNumber', description: 'string' }))
      .matches(/^[0-9]+$/, intl.formatMessage({ id: 'forms.onboarding.onlyDigits', description: 'string' }))
      .min(9, intl.formatMessage({ id: 'forms.onboarding.exactlyXDigits', description: 'string' }, { amount: 9 }))
      .max(9, intl.formatMessage({ id: 'forms.onboarding.exactlyXDigits', description: 'string' }, { amount: 9 }))
      .required(intl.formatMessage({ id: 'forms.onboarding.fullSocialSecurityNumberRequired', description: 'string' })),
  });

  const initialValues = {
    ssnfull: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setContactError('');
      setSubmitting(true);
      trackEvent('SSN_POPUP_SUBMIT');
      updateUserData(values)
        .then(function () {
          setOpen(false);
          setRefreshCounter(refreshCounter + 1);
          dispatch(setStatus({ ...worksearch_status, ssn_required: false }));
          setSubmitting(false);
        })
        .catch(function () {
          setSubmitting(false);
        });
    },
  });

  return (
    <Dialog maxWidth="sm" open={open} fullWidth onClose={() => {}}>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Box mt={1} mb={3}>
            <Typography variant="h5" component="h2" style={{ color: '#000' }} align="center">
              {intl.formatMessage({ id: 'jobSearchActivity.title' })}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" component="p" style={{ fontSize: 14, padding: '0 16px 0' }} align="left">
              {intl.formatMessage({ id: 'jobSearchActivity.ssnDialogCopy' })}
            </Typography>
          </Box>
          <Box pt={2} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
            <div style={{ position: 'relative', width: '100%' }}>
              <TextField
                FormHelperTextProps={{
                  className: styles.helperText,
                }}
                classes={{ root: styles.fullWidthInput }}
                error={formik.touched.ssnfull && Boolean(formik.errors.ssnfull)}
                name="ssnfull"
                type={ssnType}
                variant="filled"
                fullWidth
                helperText={formik.touched.ssnfull && formik.errors.ssnfull}
                autoComplete="off"
                label={intl.formatMessage({ id: 'forms.onboarding.fullSocialSecurityNumber' })}
                value={formik.values.ssnfull}
                onChange={formik.handleChange}
                setSsnType={setSsnType}
                InputProps={{
                  inputComponent: SSNInput,
                }}
                inputProps={{
                  title: intl.formatMessage({ id: 'forms.onboarding.fullSocialSecurityNumber' }),
                  onFocus: () => {
                    setSsnType('text');
                  },
                  onBlur: () => {
                    setSsnType('password');
                  },
                }}
              />
              <IconButton
                style={{ position: 'absolute', right: '0', top: '0' }}
                onClick={() => {
                  setSsnType(ssnType === 'password' ? 'text' : 'password');
                }}
                size="large"
              >
                {ssnType === 'text' ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </div>
            {contactError.length > 0 && (
              <div className={styles.errorMessage__container}>
                <label className={styles.errorMessage}>{contactError}</label>
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box mb={2}>
            <Button disabled={submitting} onClick={() => history.goBack()} color="primary" variant="contained">
              {intl.formatMessage({ id: 'jobSearchActivity.goBack' })}
            </Button>
          </Box>
          <Box mb={2} pr={2}>
            <Button color="secondary" disabled={submitting} size="large" type="submit" variant="contained">
              {intl.formatMessage({ id: 'experience.form.save' })}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}

SSNDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refreshCounter: PropTypes.number,
  setRefreshCounter: PropTypes.func,
};
