import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';

import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/**
 * i18n
 */
import { useIntl } from 'react-intl';

function LeavingPageDialog({ when, onOK, onCancel, setChoseToStay = () => {} }) {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const locality = process.env.REACT_APP_LOCALITY;
  const intl = useIntl();

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
      setChoseToStay(true);
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return (
    <Dialog maxWidth="xs" open={showPrompt} fullWidth onClose={handleCancel} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title">{intl.formatMessage({ id: 'leavingDialog.title' })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{intl.formatMessage({ id: 'leavingDialog.subtitle' })}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancel}>
          {intl.formatMessage({ id: 'leavingDialog.stay' })}
        </Button>
        <Button variant="contained" onClick={handleOK} color="primary">
          {intl.formatMessage({ id: 'leavingDialog.leave' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LeavingPageDialog;
