import { css, cx } from '@emotion/css';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import LoadingModal from '../LoadingModal';
import { Field, Formik } from 'formik';

/* Redux */
import { useDispatch } from 'react-redux';
import { setNeedsContactInfo, setContactUpdate } from 'app/slices/appSlice';

/* Material UI and other UI Dependencies */
import { useMediaQuery, Button } from '@mui/material';
import { useSnackbar } from 'notistack';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { postUpload } from 'lib/API';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const styles = {
  resumeUploadContainer: css(
    {
      maxWidth: '325px',
      margin: '0 auto',
      gridArea: 'drop',
    },
    { label: 'resume-upload-container' }
  ),
  resumeUploadContainerExperience: css(
    {
      // Styles applied only on Experience page
      maxWidth: 'initial',
      '& div[role="button"]': {
        minHeight: 301,
      },
    },
    { label: 'resume-upload-container--experience' }
  ),
};

import UploadResume from '../UploadResume';

export default function UploadForm({
  redirect = true,
  cb = () => {},
  showNext = false,
  handleBackClick,
  lastUpdated,
  showImage = true,
  showRecommended = true,
}) {
  const [uploading, setUploading] = useState(false);
  const isSmall = useMediaQuery('(max-width:767px)');
  const locality = process.env.REACT_APP_LOCALITY;

  const dispatch = useDispatch();

  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const trackClick = () => {
    if (redirect) {
      // Redirect is true during onboarding
      trackEvent('ONBOARDING_RESUME_BROWSE');
    } else {
      trackEvent('EXPERIENCE_BROWSE');
    }
  };

  const padZero = (num) => {
    return num < 10 ? `0${num}` : num;
  };
  const handleDrop = (contents, ext) => {
    const today = new Date();
    const activity_date = today.getFullYear() + '-' + padZero(today.getMonth() + 1) + '-' + padZero(today.getDate());
    trackEvent('ONBOARDING_RESUME_UPLOAD');
    setUploading(true);
    cb({ uploading: true });
    postUpload({ contents, ext, type: 'resume', activity_date })
      .then(function (response) {
        setUploading(false);
        if (redirect) {
          history.push(`${urlPrefix}/onboarding/experience`);
        }
        cb(response);
      })
      .catch(function () {
        setUploading(false);
        enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
      });
  };

  return (
    <>
      <LoadingModal open={uploading} message={intl.formatMessage({ id: 'experience.uploading' })} />
      <Formik enableReinitialize={true} initialValues={{ files: [] }} onSubmit={() => {}}>
        {({ handleSubmit }) => {
          return (
            <form style={{ width: '100%' }} onSubmit={handleSubmit}>
              <div
                className={cx({
                  [styles.resumeUploadContainer]: true,
                  [styles.resumeUploadContainerExperience]: !redirect,
                })}
              >
                <Field
                  name="files"
                  onDrop={handleDrop}
                  component={UploadResume}
                  handleClick={trackClick}
                  lastUpdated={lastUpdated}
                  showImage={showImage}
                  showRecommended={showRecommended}
                />
              </div>
            </form>
          );
        }}
      </Formik>
      <div style={{ display: 'flex', marginTop: isSmall ? 10 : 24, justifyContent: 'space-between' }}>
        {redirect ? (
          <>
            <Button
              color={locality === 'nj' ? 'primary' : 'secondary'}
              disabled={uploading}
              size="large"
              variant="contained"
              style={{ marginRight: 10 }}
              onClick={
                handleBackClick
                  ? handleBackClick
                  : () => {
                      dispatch(setNeedsContactInfo(true));
                      dispatch(setContactUpdate(true));
                      history.push(`${urlPrefix}/onboarding/contact-info`);
                    }
              }
            >
              {intl.formatMessage({ id: 'forms.onboarding.back' })}
            </Button>
            {showNext ? (
              <Button
                color={locality === 'nj' ? 'primary' : 'secondary'}
                disabled={uploading}
                size="large"
                variant="contained"
                onClick={() => {
                  history.push(`${urlPrefix}/onboarding/experience`);
                }}
              >
                {uploading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">{intl.formatMessage({ id: 'forms.signIn.loading' })}</span>
                  </div>
                ) : (
                  intl.formatMessage({ id: 'forms.onboarding.next' })
                )}
              </Button>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}

UploadForm.propTypes = {
  redirect: PropTypes.bool,
  cb: PropTypes.func,
  handleBackClick: PropTypes.func,
  showNext: PropTypes.bool,
  lastUpdated: PropTypes.string,
  showImage: PropTypes.bool,
  showRecommended: PropTypes.bool,
};
