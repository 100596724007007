import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { pathOr } from 'ramda';

/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';
import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { useQuery } from '@tanstack/react-query';

/* Redux */
import { useDispatch } from 'react-redux';
import { setJobExperience, setEducation } from 'app/slices/onboardingSlice';

/* Assets */
import ArrowRight from 'assets/images/Arrow_RightLight.svg';
import IllustrationGears from 'assets/images/Illustration_Gears.svg';
import IllustrationArchery from 'assets/images/Illustration_Archery.svg';
import IllustrationResume from 'assets/images/Illustration_Resume.svg';

/* Material UI and other UI Dependencies */
import OnboardingSteps from 'components/OnboardingSteps';
import { Box, Typography, CircularProgress } from '@mui/material';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* UI Components */
import { UploadForm } from 'shared/components';

/* Utils */
import { getTodayMmDdYyyy } from 'shared/utils';

export default function OnboardingResumeUpload() {
  const dispatch = useDispatch();
  const [uploadedResume, setUploadedResume] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [loadedExperience, setLoadedExperience] = useState(false);
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const experienceQuery = useQuery({ queryKey: ['/users/me/experience/'], retry: false, retryOnMount: false });

  const resumeUpdated = (value) => {
    if (value.uploading) {
      return false;
    }
    setUploadedResume(true);
    setLastUpdated(getTodayMmDdYyyy());
    dispatch(setJobExperience({}));
    dispatch(setEducation({}));
  };

  useEffect(() => {
    if (['success', 'error'].includes(experienceQuery.status)) {
      setLoadedExperience(true);
    }
    if (experienceQuery.status === 'success') {
      if (pathOr(false, ['data', 'has_resume'], experienceQuery)) {
        setUploadedResume(true);
      }
      if (pathOr(false, ['data', 'last_updated_resume'], experienceQuery)) {
        const lastUpdated = pathOr('', ['data', 'last_updated_resume'], experienceQuery);
        setLastUpdated(`${lastUpdated.slice(4, 6)}-${lastUpdated.slice(6, 8)}-${lastUpdated.slice(0, 4)}`);
      }
    }
  }, [experienceQuery.status, experienceQuery.data]);

  return (
    <>
      <div className={styles.loginFlow__form__onboarding}>
        <Box px={3}>
          <Typography align="center" variant="h1" gutterBottom>
            {intl.formatMessage({ id: 'onboarding.resume.title', description: 'string' })}
          </Typography>
        </Box>
        <OnboardingSteps activeStep={1} />
      </div>
      <hr className={layoutStyles.separatorBold} />
      {loadedExperience ? (
        <div className={onboardingStyles.resumeStepContainer}>
          <div className={onboardingStyles.imagesContainer}>
            <div className={onboardingStyles.imageContainer}>
              <img alt="Resume" className={cn(onboardingStyles.imageResume)} src={IllustrationResume} />
              <div className={onboardingStyles.imageTextContainer}>
                <Typography align="center" variant="caption" gutterBottom>
                  {intl.formatMessage({ id: 'onboarding.resume.weTake', description: 'string' })}
                </Typography>
              </div>
            </div>
            <img alt="Arrow" className={cn(onboardingStyles.imageArrow)} src={ArrowRight} />
            <div className={onboardingStyles.imageContainer}>
              <img alt="Gears" className={cn(onboardingStyles.imageGears)} src={IllustrationGears} />
              <div className={onboardingStyles.imageTextContainer}>
                <Typography align="center" variant="caption" gutterBottom>
                  {intl.formatMessage({ id: 'onboarding.resume.analyze', description: 'string' })}
                </Typography>
              </div>
            </div>
            <img alt="Arrow" className={cn(onboardingStyles.imageArrow)} src={ArrowRight} />
            <div className={onboardingStyles.imageContainer}>
              <img alt="Archery" className={cn(onboardingStyles.imageArchery)} src={IllustrationArchery} />
              <div className={onboardingStyles.imageTextContainer}>
                <Typography align="center" variant="caption" gutterBottom>
                  {intl.formatMessage({ id: 'onboarding.resume.generateRecommendations', description: 'string' })}
                </Typography>
              </div>
            </div>
          </div>
          <div className={onboardingStyles.resumeLinkContainer}>
            {!uploadedResume || !lastUpdated ? (
              <>
                <Typography align="center" variant="body1">
                  {intl.formatMessage({ id: 'onboarding.resume.noResume', description: 'string' })}
                </Typography>
                <Link
                  to={`${urlPrefix}/onboarding/experience`}
                  className={cn(layoutStyles.link, onboardingStyles.enterInfoLink)}
                  onClick={() => trackEvent('ONBOARDING_RESUME_MANUALENTRY')}
                >
                  {intl.formatMessage({ id: 'onboarding.resume.enterInfo', description: 'string' })}
                </Link>
              </>
            ) : null}
          </div>
          <div className={onboardingStyles.resumeFullTextContainer}>
            <Typography align="center" variant="caption" gutterBottom>
              {intl.formatMessage({ id: 'onboarding.resume.fullText', description: 'string' })}
            </Typography>
          </div>
          <UploadForm cb={resumeUpdated} showNext={lastUpdated} lastUpdated={lastUpdated} />
        </div>
      ) : (
        <Box m={2} pb={6} pt={4} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
