export const formatTimestamp = (date) => {
  return (
    date.getUTCFullYear() +
    ('0' + (date.getUTCMonth() + 1)).slice(-2) +
    ('0' + date.getUTCDate()).slice(-2) +
    ('0' + date.getUTCHours()).slice(-2) +
    ('0' + date.getUTCMinutes()).slice(-2)
  );
};

export const passwordTests = {
  lowercase: /.*[a-z]+.*/,
  uppercase: /.*[A-Z]+.*/,
  // Special character list from:
  // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
  special: /.*[\^$*.\[\]{}\(\)?"!@#%&/\\,><\':;|_~`=+\-]+.*/, //eslint-disable-line no-useless-escape
  digit: /.*[\d]+.*/,
  length: 8,
  nospaces: /^\S+$/,
};

/**
 * Covert bytes to a larger size
 * Usage: In the upload file field
 *
 * @param {*} bytes
 * @param {*} decimals
 */
export const bytesToSize = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
