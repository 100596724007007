import PropTypes from 'prop-types';
import { css } from '@emotion/css';
import { propOr } from 'ramda';
import { useState, useEffect } from 'react';

/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { resetSurvey } from 'app/slices/benefitsFinderSlice';

/* Hooks */
import useBenefitsDisplayLogic, { useDownloadResults } from 'hooks/useBenefitsDisplayLogic';

/* Styles */
import recStyles from 'styles/recommendations-list.module.scss';
import financialStyles from 'styles/financial-help-card.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import cssVars from 'styles/vars.module.scss';

/* Material UI and other UI Dependencies */
import { Box, Button, Typography, Collapse, useMediaQuery } from '@mui/material';
import Collapsible from './SurveyComponents/Collapsible';
import ResultsCard from './ResultsCard';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/* Assets */
import foodIcon from 'assets/images/icon_food_help.svg';
import cashIcon from 'assets/images/icon_cash_help.svg';
import medicalIcon from 'assets/images/icon_medical_help.svg';
import childCareIcon from 'assets/images/child_care.svg';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const styles = {
  header: css({
    fontSize: '24px',
    margin: '4px 0 4px',
  }),
  answersBox: css({
    backgroundColor: cssVars.mediumGray,
    border: `solid 2px ${cssVars.darkGray}`,
    borderRadius: 4,
    marginTop: 8,
    padding: '0 20px 9px',
    '& svg': {
      fontSize: '28px !important',
      position: 'relative',
      top: '8px',
      color: cssVars.actionBlue,
    },
  }),
  answerText: css({
    paddingLeft: 20,
  }),
};

function capitalize(word) {
  const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
  return capitalized.split('_').join(' ');
}

const BenefitsFinderResults = ({ setCurrentStep, trackEvent }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const downloadResults = useDownloadResults();
  const [showAnswers, setShowAnswers] = useState(false);
  const width = useMediaQuery('(max-width:599px)')
    ? 'xs'
    : useMediaQuery('(max-width:899px)')
    ? 'sm'
    : useMediaQuery('(max-width:1199px)')
    ? 'md'
    : useMediaQuery('(max-width:1535px)')
    ? 'lg'
    : 'xl';

  const benefitsResults = useSelector((state) => state.benefitsFinder.results);
  const liveInHawaiiValue = propOr('', 'live_in_hawaii', benefitsResults);
  const blindOrDisabledValue = propOr('', 'you_or_anyone_blind_disabled', benefitsResults);
  const youAnyoneGt65MedicareValue = propOr('', 'you_or_anyone_gt65_medicare_long_term', benefitsResults);
  const howManyChildrenValue = parseInt(propOr('0', 'how_many_children', benefitsResults), 10);
  const youOrAnyoneOver60Value = propOr('', 'you_or_anyone_over_60', benefitsResults);
  const youOrAnyone1860Value = propOr('', 'you_or_anyone_18_60', benefitsResults);
  const youOrAnyoneBlindDisabledValue = propOr('', 'you_or_anyone_blind_disabled', benefitsResults);
  const howManyLte21Value = parseInt(propOr('0', 'how_many_people_lte_21', benefitsResults), 10);
  const sixtyOne64Value = parseInt(propOr('0', 'how_many_people_61_64', benefitsResults), 10);
  const howManyLte18Value = parseInt(propOr('0', 'how_many_children_lte_18', benefitsResults), 10);
  const allApplyLte18Value = propOr([], 'all_apply_for_18_or_younger', benefitsResults);
  const youAnyonePregnantValue = propOr('', 'you_or_anyone_pregnant', benefitsResults);
  const youAnyone9MonthsValue = propOr('', 'you_or_anyone_9th_month', benefitsResults);
  const currentlyWorkingValue = propOr('', 'currently_working', benefitsResults);
  const receivingChildProtectiveValue = propOr('', 'receiving_child_protective_services', benefitsResults);
  const whyArentYouWorkingValue = propOr([], 'why_arent_you_working', benefitsResults);
  const receivingBenefitsValue = propOr([], 'currently_receiving_benefits', benefitsResults);

  const quest1 =
    liveInHawaiiValue === 'yes' && (blindOrDisabledValue === 'yes' || youAnyoneGt65MedicareValue === 'yes');
  const quest2 = liveInHawaiiValue === 'yes' && blindOrDisabledValue === 'no' && youAnyoneGt65MedicareValue === 'no';
  const questEligibility = !receivingBenefitsValue.includes('quest') && (quest1 || quest2);
  const snapEligibility =
    !receivingBenefitsValue.includes('snap') &&
    liveInHawaiiValue === 'yes' &&
    (howManyChildrenValue > 0 ||
      youOrAnyoneOver60Value === 'yes' ||
      youOrAnyoneBlindDisabledValue === 'yes' ||
      howManyLte21Value > 0);
  const tanfTaonfEligibility =
    !receivingBenefitsValue.includes('tanf_taonf') &&
    liveInHawaiiValue === 'yes' &&
    ((youOrAnyoneOver60Value === 'yes' && sixtyOne64Value > 0) ||
      youOrAnyoneBlindDisabledValue === 'yes' ||
      youOrAnyone1860Value === 'yes' ||
      (howManyLte18Value > 0 &&
        (allApplyLte18Value.includes('parent_or_relative_caring') ||
          allApplyLte18Value.includes('you_are_under_18'))) ||
      (allApplyLte18Value.includes('none') && youAnyonePregnantValue === 'yes') ||
      (currentlyWorkingValue === 'no' &&
        (whyArentYouWorkingValue.includes('physical_psycological_disability') ||
          whyArentYouWorkingValue.includes('prefer_not_to_say'))));
  const child1 =
    !receivingBenefitsValue.includes('child_care') &&
    liveInHawaiiValue === 'yes' &&
    howManyLte18Value > 0 &&
    (allApplyLte18Value.includes('child_us_citizen_requires_child_care') ||
      allApplyLte18Value.includes('child_under_13_requires_child_care') ||
      allApplyLte18Value.includes('child_between_13_18_requires_child_care')) &&
    (currentlyWorkingValue === 'yes' || receivingChildProtectiveValue === 'yes');
  const child2 =
    liveInHawaiiValue === 'yes' &&
    howManyLte18Value > 0 &&
    (allApplyLte18Value.includes('child_us_citizen_requires_child_care') ||
      allApplyLte18Value.includes('child_under_13_requires_child_care') ||
      allApplyLte18Value.includes('child_between_13_18_requires_child_care')) &&
    currentlyWorkingValue === 'no' &&
    whyArentYouWorkingValue.includes('attending_training_or_education');
  const childCareEligibility = child1 || child2;

  const interestedInValue = propOr([], 'support_interested_in', benefitsResults);
  const interestedInQuest = interestedInValue.includes('quest');
  const interestedInSnap = interestedInValue.includes('snap');
  const interestedInTanf = interestedInValue.includes('tanf_taonf');
  const interestedInChildCare = interestedInValue.includes('child_care');

  const numberOfPrograms =
    (questEligibility ? 1 : 0) +
    (snapEligibility ? 1 : 0) +
    (tanfTaonfEligibility ? 1 : 0) +
    (childCareEligibility ? 1 : 0);

  const numberOfProgramsInterested =
    (questEligibility && interestedInQuest ? 1 : 0) +
    (snapEligibility && interestedInSnap ? 1 : 0) +
    (tanfTaonfEligibility && interestedInTanf ? 1 : 0) +
    (childCareEligibility && interestedInChildCare ? 1 : 0);

  const numberNotEligible =
    (!questEligibility && interestedInQuest ? 1 : 0) +
    (!snapEligibility && interestedInSnap ? 1 : 0) +
    (!tanfTaonfEligibility && interestedInTanf ? 1 : 0) +
    (!childCareEligibility && interestedInChildCare ? 1 : 0);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  const { youAnyonePregnantDisplay } = useBenefitsDisplayLogic();

  return (
    <div className={recStyles.content}>
      <span>{intl.formatMessage({ id: 'app.benefitsFinder.results.label' })}</span>
      <h2 className={styles.header}>
        {intl.formatMessage(
          {
            id:
              numberOfPrograms === 0
                ? 'app.benefitsFinder.results.notElegibleHeader'
                : numberOfPrograms > 1
                ? 'app.benefitsFinder.results.header'
                : 'app.benefitsFinder.results.headerSingular',
          },
          { n: numberOfPrograms }
        )}
      </h2>
      <Box m={3} />
      <Collapsible
        title={intl.formatMessage({ id: 'app.benefitsFinder.results.collapsibleTitle' })}
        text={
          <>
            <span>{intl.formatMessage({ id: 'app.benefitsFinder.results.collapsibleText1' })}</span>
            <span>{intl.formatMessage({ id: 'app.benefitsFinder.results.collapsibleText2' })}</span>
          </>
        }
      />

      {/* Interested and eligible */}
      {numberOfProgramsInterested > 0 ? (
        <Typography variant="subtitle1" style={{ paddingTop: 20, paddingBottom: 16 }}>
          {intl.formatMessage({ id: 'app.benefitsFinder.results.subHeader2' })}
        </Typography>
      ) : null}
      {questEligibility && interestedInQuest ? ( // quest
        <QuestCard
          liveInHawaiiValue={liveInHawaiiValue}
          blindOrDisabledValue={blindOrDisabledValue}
          youAnyoneGt65MedicareValue={youAnyoneGt65MedicareValue}
          width={width}
          trackEvent={trackEvent}
        />
      ) : null}
      {snapEligibility && interestedInSnap ? ( //groceries
        <SnapCard
          liveInHawaiiValue={liveInHawaiiValue}
          howManyChildrenValue={howManyChildrenValue}
          youOrAnyoneOver60Value={youOrAnyoneOver60Value}
          blindOrDisabledValue={blindOrDisabledValue}
          howManyLte21Value={howManyLte21Value}
          width={width}
          trackEvent={trackEvent}
        />
      ) : null}
      {tanfTaonfEligibility && interestedInTanf ? ( //bills and expenses
        <TanfCard
          liveInHawaiiValue={liveInHawaiiValue}
          sixtyOne64Value={sixtyOne64Value}
          youOrAnyone1860Value={youOrAnyone1860Value}
          blindOrDisabledValue={blindOrDisabledValue}
          howManyLte18Value={howManyLte18Value}
          youAnyonePregnantDisplay={youAnyonePregnantDisplay}
          youAnyonePregnantValue={youAnyonePregnantValue}
          youAnyone9MonthsValue={youAnyone9MonthsValue}
          currentlyWorkingValue={currentlyWorkingValue}
          whyArentYouWorkingValue={whyArentYouWorkingValue}
          width={width}
          trackEvent={trackEvent}
        />
      ) : null}
      {childCareEligibility && interestedInChildCare ? ( //Child care
        <ChildCareCard
          liveInHawaiiValue={liveInHawaiiValue}
          howManyLte18Value={howManyLte18Value}
          currentlyWorkingValue={currentlyWorkingValue}
          whyArentYouWorkingValue={whyArentYouWorkingValue}
          receivingChildProtectiveValue={receivingChildProtectiveValue}
          allApplyLte18Value={allApplyLte18Value}
          width={width}
          trackEvent={trackEvent}
        />
      ) : null}
      <Box mb={4} />

      {/* Eligible but not interested */}
      {numberOfPrograms - numberOfProgramsInterested > 0 && numberOfProgramsInterested > 0 ? (
        <Typography variant="subtitle1" style={{ paddingTop: 20, paddingBottom: 16 }}>
          {intl.formatMessage({ id: 'app.benefitsFinder.results.elegibleNotInterested' })}
        </Typography>
      ) : null}
      {questEligibility && !interestedInQuest ? ( // quest
        <QuestCard
          liveInHawaiiValue={liveInHawaiiValue}
          blindOrDisabledValue={blindOrDisabledValue}
          youAnyoneGt65MedicareValue={youAnyoneGt65MedicareValue}
          width={width}
          trackEvent={trackEvent}
        />
      ) : null}
      {snapEligibility && !interestedInSnap ? ( //groceries
        <SnapCard
          liveInHawaiiValue={liveInHawaiiValue}
          howManyChildrenValue={howManyChildrenValue}
          youOrAnyoneOver60Value={youOrAnyoneOver60Value}
          blindOrDisabledValue={blindOrDisabledValue}
          howManyLte21Value={howManyLte21Value}
          width={width}
          trackEvent={trackEvent}
        />
      ) : null}
      {tanfTaonfEligibility && !interestedInTanf ? ( //bills and expenses
        <TanfCard
          liveInHawaiiValue={liveInHawaiiValue}
          sixtyOne64Value={sixtyOne64Value}
          youOrAnyone1860Value={youOrAnyone1860Value}
          blindOrDisabledValue={blindOrDisabledValue}
          howManyLte18Value={howManyLte18Value}
          youAnyonePregnantDisplay={youAnyonePregnantDisplay}
          youAnyonePregnantValue={youAnyonePregnantValue}
          youAnyone9MonthsValue={youAnyone9MonthsValue}
          currentlyWorkingValue={currentlyWorkingValue}
          whyArentYouWorkingValue={whyArentYouWorkingValue}
          width={width}
          trackEvent={trackEvent}
        />
      ) : null}
      {childCareEligibility && !interestedInChildCare ? ( //Child care
        <ChildCareCard
          liveInHawaiiValue={liveInHawaiiValue}
          howManyLte18Value={howManyLte18Value}
          currentlyWorkingValue={currentlyWorkingValue}
          whyArentYouWorkingValue={whyArentYouWorkingValue}
          receivingChildProtectiveValue={receivingChildProtectiveValue}
          allApplyLte18Value={allApplyLte18Value}
          width={width}
          trackEvent={trackEvent}
        />
      ) : null}
      <Box mb={4} />

      {numberNotEligible > 0 ? (
        <>
          <Box mb={6} />
          <Typography variant="subtitle1" style={{ paddingTop: 20 }}>
            {intl.formatMessage({
              id:
                numberNotEligible === 1
                  ? 'app.benefitsFinder.results.notElegibleSingle'
                  : 'app.benefitsFinder.results.notElegibleMultiple',
            })}
          </Typography>
          <Box mb={4} />
        </>
      ) : null}
      {!questEligibility && interestedInQuest ? ( // not elegible quest
        <>
          <ResultsCard
            title={intl.formatMessage({ id: 'app.benefitsFinder.results.quest.title' })}
            description={<div>{intl.formatMessage({ id: 'app.benefitsFinder.results.quest.paragraph' })}</div>}
            toQualifyTitle={
              liveInHawaiiValue === 'no'
                ? intl.formatMessage({ id: 'app.benefitsFinder.results.notElegibleQuest' })
                : ''
            }
            toQualifyItems={[
              ...(liveInHawaiiValue === 'no'
                ? [intl.formatMessage({ id: 'app.benefitsFinder.results.dontMeetResidency' })]
                : []),
            ]}
            image={medicalIcon}
            imageStyles={{ width: ['sm', 'xs'].includes(width) ? 52 : 73 }}
            imageDescription={intl.formatMessage({ id: 'financialHelp.medicalCare.imageDescription' })}
            closeEvent="MEDQUEST_CLOSE"
            learnHowToApplyEvent="MEDQUEST_LEARN_APPLY"
            expandEvent="MEDQUEST_EXPAND"
          >
            <Box mb={4} />
            <div>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                href="https://medquest.hawaii.gov/en/members-applicants/quest-integration-coverage/medical-benefits.html"
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                style={{ marginRight: 10 }}
                onClick={() => trackEvent('BF_NotEligible_QUEST_Learn')}
              >
                {intl.formatMessage({ id: 'financialHelp.snap.learnMore' })}
              </Button>
              <Button
                color="secondary"
                size="large"
                type="submit"
                variant="contained"
                href="https://medical.mybenefits.hawaii.gov/register.html"
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                onClick={() => trackEvent('BF_NotEligible_QUEST_Apply')}
              >
                {intl.formatMessage({ id: 'financialHelp.snap.apply' })}
              </Button>
            </div>
          </ResultsCard>
          <Box mb={4} />
        </>
      ) : null}
      {!snapEligibility && interestedInSnap ? ( //not elegible groceries
        <>
          <ResultsCard
            title={intl.formatMessage({ id: 'app.benefitsFinder.results.snap.title' })}
            description={<div>{intl.formatMessage({ id: 'app.benefitsFinder.results.snap.paragraph' })}</div>}
            toQualifyTitle={
              liveInHawaiiValue === 'no' ? intl.formatMessage({ id: 'app.benefitsFinder.results.notElegibleSnap' }) : ''
            }
            toQualifyItems={[
              ...(liveInHawaiiValue === 'no'
                ? [intl.formatMessage({ id: 'app.benefitsFinder.results.dontMeetResidency' })]
                : []),
            ]}
            image={foodIcon}
            imageStyles={{ width: ['sm', 'xs'].includes(width) ? 45.87 : 69.45 }}
            imageDescription={intl.formatMessage({ id: 'financialHelp.groceries.imageDescription' })}
            closeEvent="SNAP_CLOSE"
            learnHowToApplyEvent="SNAP_LEARN_APPLY"
            expandEvent="SNAP_EXPAND"
          >
            <Box mb={4} />
            <div>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                href="https://humanservices.hawaii.gov/bessd/snap/"
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                style={{ marginRight: 10 }}
                onClick={() => trackEvent('BF_NotEligible_SNAP_Learn')}
              >
                {intl.formatMessage({ id: 'financialHelp.snap.learnMore' })}
              </Button>
              <Button
                color="secondary"
                size="large"
                type="submit"
                variant="contained"
                href="https://pais-benefits.dhs.hawaii.gov/"
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                onClick={() => trackEvent('BF_NotEligible_SNAP_Apply')}
              >
                {intl.formatMessage({ id: 'financialHelp.snap.apply' })}
              </Button>
            </div>
          </ResultsCard>
          <Box mb={4} />
        </>
      ) : null}
      {!tanfTaonfEligibility && interestedInTanf ? ( //not elegible bills and expenses
        <>
          <ResultsCard
            title={intl.formatMessage({ id: 'app.benefitsFinder.results.tanf.title' })}
            description={<div>{intl.formatMessage({ id: 'app.benefitsFinder.results.tanf.paragraph' })}</div>}
            toQualifyTitle={
              liveInHawaiiValue === 'no' ? intl.formatMessage({ id: 'app.benefitsFinder.results.notElegibleTanf' }) : ''
            }
            toQualifyItems={[
              ...(liveInHawaiiValue === 'no'
                ? [intl.formatMessage({ id: 'app.benefitsFinder.results.dontMeetResidency' })]
                : []),
            ]}
            image={cashIcon}
            imageStyles={{ width: ['sm', 'xs'].includes(width) ? 53.63 : 80.32 }}
            imageDescription={intl.formatMessage({ id: 'financialHelp.billsAndExpenses.imageDescription' })}
            closeEvent="TANF_CLOSE"
            learnHowToApplyEvent="TANF_LEARN_APPLY"
            expandEvent="TANF_EXPAND"
          >
            <Box mb={4} />
            <div>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                href="https://humanservices.hawaii.gov/bessd/tanf/"
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => trackEvent('BF_NotEligible_TANF_Learn')}
              >
                {intl.formatMessage({ id: 'app.benefitsFinder.results.learnMoreTanf' })}
              </Button>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                href="https://humanservices.hawaii.gov/bessd/aabd/"
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => trackEvent('BF_NotEligible_AABD_Learn')}
              >
                {intl.formatMessage({ id: 'app.benefitsFinder.results.learnMoreAabd' })}
              </Button>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                href="https://humanservices.hawaii.gov/bessd/ga/"
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => trackEvent('BF_NotEligible_GA_Learn')}
              >
                {intl.formatMessage({ id: 'app.benefitsFinder.results.learnMoreGeneralAssistance' })}
              </Button>
              <Button
                color="secondary"
                size="large"
                type="submit"
                variant="contained"
                href="https://pais-benefits.dhs.hawaii.gov/"
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                onClick={() => trackEvent('BF_NotEligible_Cash_Apply')}
              >
                {intl.formatMessage({ id: 'app.benefitsFinder.results.applyCashAssistance' })}
              </Button>
            </div>
          </ResultsCard>
          <Box mb={5} />
        </>
      ) : null}
      {!childCareEligibility && interestedInChildCare ? ( //not eligible Child care
        <>
          <ResultsCard
            title={intl.formatMessage({ id: 'app.benefitsFinder.results.childCare.title' })}
            description={<div>{intl.formatMessage({ id: 'app.benefitsFinder.results.childCare.paragraph' })}</div>}
            toQualifyTitle={
              liveInHawaiiValue === 'no' || howManyLte18Value === 0
                ? intl.formatMessage({ id: 'app.benefitsFinder.results.notElegibleChildCare' })
                : ''
            }
            toQualifyItems={[
              ...(liveInHawaiiValue === 'no'
                ? [intl.formatMessage({ id: 'app.benefitsFinder.results.dontMeetResidency' })]
                : []),
              ...(howManyLte18Value === 0
                ? [intl.formatMessage({ id: 'app.benefitsFinder.results.notElegibleChildCare2' })]
                : []),
            ]}
            image={childCareIcon}
            imageStyles={{ width: ['sm', 'xs'].includes(width) ? 53.63 : 84 }}
            imageDescription={intl.formatMessage({ id: 'app.benefitsFinder.results.childCareDesc' })}
            closeEvent="TANF_CLOSE"
            learnHowToApplyEvent="TANF_LEARN_APPLY"
            expandEvent="TANF_EXPAND"
          >
            <Box mb={4} />
            <div>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                href="https://humanservices.hawaii.gov/bessd/ccch-subsidies/what-are-child-care-subsidies/"
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                style={{ marginRight: 10 }}
                onClick={() => trackEvent('BF_NotEligible_ChildCare_Learn')}
              >
                {intl.formatMessage({ id: 'financialHelp.snap.learnMore' })}
              </Button>
              <Button
                color="secondary"
                size="large"
                type="submit"
                variant="contained"
                href="https://humanservices.hawaii.gov/bessd/files/2023/06/DHS-911-Single-Application-for-CC-05-2023-Fillable-Final.pdf?bcs-agent-scanner=fe581cda-2af2-384a-a79e-4421fe196154"
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                onClick={() => trackEvent('BF_NotEligible_ChildCare_Apply')}
              >
                {intl.formatMessage({ id: 'financialHelp.snap.apply' })}
              </Button>
            </div>
          </ResultsCard>
          <Box mb={5} />
        </>
      ) : null}

      {/* Eligible but not interested and number of eligile interested = 0 */}
      {numberOfPrograms > 0 && numberOfProgramsInterested === 0 ? (
        <Typography variant="subtitle1" style={{ paddingTop: 20, paddingBottom: 16 }}>
          {intl.formatMessage({ id: 'app.benefitsFinder.results.otherPrograms' })}
        </Typography>
      ) : null}
      {questEligibility && numberOfProgramsInterested === 0 ? ( // quest
        <QuestCard
          liveInHawaiiValue={liveInHawaiiValue}
          blindOrDisabledValue={blindOrDisabledValue}
          youAnyoneGt65MedicareValue={youAnyoneGt65MedicareValue}
          width={width}
          trackEvent={trackEvent}
        />
      ) : null}
      {snapEligibility && numberOfProgramsInterested === 0 ? ( //groceries
        <SnapCard
          liveInHawaiiValue={liveInHawaiiValue}
          howManyChildrenValue={howManyChildrenValue}
          youOrAnyoneOver60Value={youOrAnyoneOver60Value}
          blindOrDisabledValue={blindOrDisabledValue}
          howManyLte21Value={howManyLte21Value}
          width={width}
          trackEvent={trackEvent}
        />
      ) : null}
      {tanfTaonfEligibility && numberOfProgramsInterested === 0 ? ( //bills and expenses
        <TanfCard
          liveInHawaiiValue={liveInHawaiiValue}
          sixtyOne64Value={sixtyOne64Value}
          youOrAnyone1860Value={youOrAnyone1860Value}
          blindOrDisabledValue={blindOrDisabledValue}
          howManyLte18Value={howManyLte18Value}
          youAnyonePregnantDisplay={youAnyonePregnantDisplay}
          youAnyonePregnantValue={youAnyonePregnantValue}
          youAnyone9MonthsValue={youAnyone9MonthsValue}
          currentlyWorkingValue={currentlyWorkingValue}
          whyArentYouWorkingValue={whyArentYouWorkingValue}
          width={width}
          trackEvent={trackEvent}
        />
      ) : null}
      {childCareEligibility && numberOfProgramsInterested === 0 ? ( //Child care
        <ChildCareCard
          liveInHawaiiValue={liveInHawaiiValue}
          howManyLte18Value={howManyLte18Value}
          currentlyWorkingValue={currentlyWorkingValue}
          whyArentYouWorkingValue={whyArentYouWorkingValue}
          receivingChildProtectiveValue={receivingChildProtectiveValue}
          allApplyLte18Value={allApplyLte18Value}
          width={width}
          trackEvent={trackEvent}
        />
      ) : null}
      <Box mb={4} />
      <div className={styles.answersBox}>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ paddingTop: 12, cursor: 'pointer' }}
          onClick={() => {
            trackEvent('BF_Responses_View');
            setShowAnswers(!showAnswers);
          }}
        >
          {intl.formatMessage({ id: 'app.benefitsFinder.results.subHeader1' })}
          {showAnswers ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Typography>
        <Collapse in={showAnswers}>
          <UserResponses />
        </Collapse>
      </div>
      <p>
        {intl.formatMessage(
          { id: 'app.benefitsFinder.results.download' },
          {
            link: (
              <span onClick={downloadResults} className={layoutStyles.link}>
                {intl.formatMessage({ id: 'app.benefitsFinder.results.here' })}
              </span>
            ),
          }
        )}
      </p>

      <Box mb={4} />

      <Box display="flex" justifyContent="space-between">
        <div></div>
        <Button
          color="primary"
          size="large"
          variant="contained"
          type="button"
          onClick={() => {
            dispatch(resetSurvey());
            setCurrentStep(0);
          }}
        >
          {intl.formatMessage({ id: 'app.benefitsFinder.results.retake' })}
        </Button>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Typography variant="subtitle1" style={{ paddingTop: 20, paddingBottom: 12 }}>
          {intl.formatMessage({ id: 'layout.menu.additionalResources' })}
        </Typography>
        <p style={{ marginTop: 0, marginBottom: 8 }}>
          {intl.formatMessage({ id: 'app.benefitsFinder.additionalResources.subtitle' })}
        </p>
        <ul style={{ margin: 0 }}>
          <li>
            <p>
              <a rel="noopener noreferrer" target="_blank" href="https://auw211.org/" className={layoutStyles.link}>
                {intl.formatMessage({ id: 'app.benefitsFinder.additionalResources.alohaLink' })}
              </a>
              : {intl.formatMessage({ id: 'app.benefitsFinder.additionalResources.alohaText' })}
            </p>
          </li>
          <li>
            <b>{intl.formatMessage({ id: 'app.benefitsFinder.additionalResources.utilitiesTitle' })}</b>
            <ul style={{ margin: 0, paddingLeft: 20, paddingTop: 10 }}>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.fcc.gov/broadbandbenefit"
                  className={layoutStyles.link}
                >
                  {intl.formatMessage({ id: 'app.benefitsFinder.additionalResources.emergencyBroadBand' })}
                </a>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://hawaiifoodbank.org/"
                className={layoutStyles.link}
              >
                {intl.formatMessage({ id: 'app.benefitsFinder.additionalResources.foodBankLink' })}
              </a>
              : {intl.formatMessage({ id: 'app.benefitsFinder.additionalResources.foodBankText' })}
            </p>
          </li>
          <li>
            <p>
              <a rel="noopener noreferrer" target="_blank" href="http://healthecare.gov/" className={layoutStyles.link}>
                {intl.formatMessage({ id: 'app.benefitsFinder.additionalResources.healthCareLink' })}
              </a>
              : {intl.formatMessage({ id: 'app.benefitsFinder.additionalResources.healthCareText' })}
            </p>
          </li>
        </ul>
      </Box>
    </div>
  );
};

function QuestCard({ liveInHawaiiValue, blindOrDisabledValue, youAnyoneGt65MedicareValue, width, trackEvent }) {
  const intl = useIntl();
  return (
    <>
      <ResultsCard
        title={intl.formatMessage({ id: 'app.benefitsFinder.results.quest.title' })}
        description={<div>{intl.formatMessage({ id: 'app.benefitsFinder.results.quest.paragraph' })}</div>}
        toQualifyTitle={intl.formatMessage({ id: 'app.benefitsFinder.results.quest.subtitle' })}
        toQualifyItems={[
          ...(liveInHawaiiValue === 'yes'
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.quest.item1' })]
            : []),
          ...(blindOrDisabledValue === 'yes'
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.quest.item2' })]
            : []),
          ...(youAnyoneGt65MedicareValue === 'yes'
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.quest.item3' })]
            : []),
        ]}
        image={medicalIcon}
        imageStyles={{ width: ['sm', 'xs'].includes(width) ? 52 : 73 }}
        imageDescription={intl.formatMessage({ id: 'financialHelp.medicalCare.imageDescription' })}
        closeEvent="MEDQUEST_CLOSE"
        learnHowToApplyEvent="MEDQUEST_LEARN_APPLY"
        expandEvent="MEDQUEST_EXPAND"
      >
        <Box mb={4} />
        <div>
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            href="https://medquest.hawaii.gov/en/members-applicants/quest-integration-coverage/medical-benefits.html"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
            style={{ marginRight: 10 }}
            onClick={() => trackEvent('BF_Eligible_QUEST_Learn')}
          >
            {intl.formatMessage({ id: 'financialHelp.snap.learnMore' })}
          </Button>
          <Button
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
            href="https://medical.mybenefits.hawaii.gov/register.html"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
            onClick={() => trackEvent('BF_Eligible_QUEST_Apply')}
          >
            {intl.formatMessage({ id: 'financialHelp.snap.apply' })}
          </Button>
        </div>
      </ResultsCard>
      <Box mb={4} />
    </>
  );
}
QuestCard.propTypes = {
  liveInHawaiiValue: PropTypes.string,
  blindOrDisabledValue: PropTypes.string,
  youAnyoneGt65MedicareValue: PropTypes.string,
  width: PropTypes.string,
  trackEvent: PropTypes.func,
};

function TanfCard({
  liveInHawaiiValue,
  sixtyOne64Value,
  youOrAnyone1860Value,
  blindOrDisabledValue,
  howManyLte18Value,
  youAnyonePregnantDisplay,
  youAnyonePregnantValue,
  youAnyone9MonthsValue,
  currentlyWorkingValue,
  whyArentYouWorkingValue,
  width,
  trackEvent,
}) {
  const intl = useIntl();
  return (
    <>
      <ResultsCard
        title={intl.formatMessage({ id: 'app.benefitsFinder.results.tanf.title' })}
        description={<div>{intl.formatMessage({ id: 'app.benefitsFinder.results.tanf.paragraph' })}</div>}
        toQualifyTitle={intl.formatMessage({ id: 'app.benefitsFinder.results.tanf.subtitle' })}
        toQualifyItems={[
          ...(liveInHawaiiValue === 'yes' ? [intl.formatMessage({ id: 'app.benefitsFinder.results.tanf.item1' })] : []),
          ...(sixtyOne64Value > 0 || youOrAnyone1860Value === 'yes'
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.tanf.item2' })]
            : []),
          ...(blindOrDisabledValue === 'yes'
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.tanf.item3' })]
            : []),
          ...(howManyLte18Value > 0 ? [intl.formatMessage({ id: 'app.benefitsFinder.results.tanf.item4' })] : []),
          ...(youAnyonePregnantDisplay && youAnyonePregnantValue === 'yes' && youAnyone9MonthsValue === 'yes'
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.tanf.item5' })]
            : []),
          ...(currentlyWorkingValue === 'no' && whyArentYouWorkingValue.includes('physical_psycological_disability')
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.tanf.item6' })]
            : []),
        ]}
        image={cashIcon}
        imageStyles={{ width: ['sm', 'xs'].includes(width) ? 53.63 : 80.32 }}
        imageDescription={intl.formatMessage({ id: 'financialHelp.billsAndExpenses.imageDescription' })}
        closeEvent="TANF_CLOSE"
        learnHowToApplyEvent="TANF_LEARN_APPLY"
        expandEvent="TANF_EXPAND"
      >
        <Box mb={4} />
        <div>
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            href="https://humanservices.hawaii.gov/bessd/tanf/"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
            style={{ marginRight: 10, marginBottom: 10 }}
            onClick={() => trackEvent('BF_Eligible_TANF_Learn')}
          >
            {intl.formatMessage({ id: 'app.benefitsFinder.results.learnMoreTanf' })}
          </Button>
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            href="https://humanservices.hawaii.gov/bessd/aabd/"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
            style={{ marginRight: 10, marginBottom: 10 }}
            onClick={() => trackEvent('BF_Eligible_AABD_Learn')}
          >
            {intl.formatMessage({ id: 'app.benefitsFinder.results.learnMoreAabd' })}
          </Button>
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            href="https://humanservices.hawaii.gov/bessd/ga/"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
            style={{ marginRight: 10, marginBottom: 10 }}
            onClick={() => trackEvent('BF_Eligible_GA_Learn')}
          >
            {intl.formatMessage({ id: 'app.benefitsFinder.results.learnMoreGeneralAssistance' })}
          </Button>
          <Button
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
            href="https://pais-benefits.dhs.hawaii.gov/"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
            onClick={() => trackEvent('BF_Eligible_Cash_Apply')}
          >
            {intl.formatMessage({ id: 'app.benefitsFinder.results.applyCashAssistance' })}
          </Button>
        </div>
      </ResultsCard>
      <Box mb={5} />
    </>
  );
}
TanfCard.propTypes = {
  liveInHawaiiValue: PropTypes.string,
  sixtyOne64Value: PropTypes.string,
  youOrAnyone1860Value: PropTypes.string,
  blindOrDisabledValue: PropTypes.string,
  howManyLte18Value: PropTypes.string,
  youAnyonePregnantDisplay: PropTypes.bool,
  youAnyonePregnantValue: PropTypes.string,
  youAnyone9MonthsValue: PropTypes.string,
  currentlyWorkingValue: PropTypes.string,
  whyArentYouWorkingValue: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.string,
  trackEvent: PropTypes.func,
};

function ChildCareCard({
  liveInHawaiiValue,
  howManyLte18Value,
  currentlyWorkingValue,
  whyArentYouWorkingValue,
  receivingChildProtectiveValue,
  allApplyLte18Value,
  width,
  trackEvent,
}) {
  const intl = useIntl();
  return (
    <>
      <ResultsCard
        title={intl.formatMessage({ id: 'app.benefitsFinder.results.childCare.title' })}
        description={<div>{intl.formatMessage({ id: 'app.benefitsFinder.results.childCare.paragraph' })}</div>}
        toQualifyTitle={intl.formatMessage({ id: 'app.benefitsFinder.results.childCare.subtitle' })}
        toQualifyItems={[
          ...(liveInHawaiiValue === 'yes'
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.childCare.item1' })]
            : []),
          ...(howManyLte18Value &&
          parseInt(howManyLte18Value, 10) > 0 &&
          allApplyLte18Value.includes('child_us_citizen_requires_child_care')
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.childCare.item2' })]
            : []),
          ...(howManyLte18Value &&
          parseInt(howManyLte18Value, 10) > 0 &&
          allApplyLte18Value.includes('child_under_13_requires_child_care')
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.childCare.item3' })]
            : []),
          ...(howManyLte18Value &&
          parseInt(howManyLte18Value, 10) > 0 &&
          allApplyLte18Value.includes('child_between_13_18_requires_child_care')
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.childCare.item4' })]
            : []),
          ...(howManyLte18Value > 0 ? [intl.formatMessage({ id: 'app.benefitsFinder.results.childCare.item5' })] : []),
          ...(currentlyWorkingValue === 'yes'
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.childCare.item6' })]
            : []),
          ...(currentlyWorkingValue === 'no' && whyArentYouWorkingValue.includes('attending_training_or_education')
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.childCare.item7' })]
            : []),
          ...(receivingChildProtectiveValue === 'yes'
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.childCare.item8' })]
            : []),
        ]}
        image={childCareIcon}
        imageStyles={{ width: ['sm', 'xs'].includes(width) ? 53.63 : 84 }}
        imageDescription={intl.formatMessage({ id: 'app.benefitsFinder.results.childCareDesc' })}
        closeEvent="TANF_CLOSE"
        learnHowToApplyEvent="TANF_LEARN_APPLY"
        expandEvent="TANF_EXPAND"
      >
        <Box mb={4} />
        <div>
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            href="https://humanservices.hawaii.gov/bessd/ccch-subsidies/what-are-child-care-subsidies/"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
            style={{ marginRight: 10 }}
            onClick={() => trackEvent('BF_Eligible_ChildCare_Learn')}
          >
            {intl.formatMessage({ id: 'financialHelp.snap.learnMore' })}
          </Button>
          <Button
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
            href="https://humanservices.hawaii.gov/bessd/files/2023/06/DHS-911-Single-Application-for-CC-05-2023-Fillable-Final.pdf?bcs-agent-scanner=fe581cda-2af2-384a-a79e-4421fe196154"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
            onClick={() => trackEvent('BF_Eligible_ChildCare_Apply')}
          >
            {intl.formatMessage({ id: 'financialHelp.snap.apply' })}
          </Button>
        </div>
      </ResultsCard>
      <Box mb={5} />
    </>
  );
}
ChildCareCard.propTypes = {
  liveInHawaiiValue: PropTypes.string,
  howManyLte18Value: PropTypes.string,
  currentlyWorkingValue: PropTypes.string,
  whyArentYouWorkingValue: PropTypes.arrayOf(PropTypes.string),
  receivingChildProtectiveValue: PropTypes.string,
  allApplyLte18Value: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.string,
  trackEvent: PropTypes.func,
};

function SnapCard({
  liveInHawaiiValue,
  howManyChildrenValue,
  youOrAnyoneOver60Value,
  blindOrDisabledValue,
  howManyLte21Value,
  width,
  trackEvent,
}) {
  const intl = useIntl();
  return (
    <>
      <ResultsCard
        title={intl.formatMessage({ id: 'app.benefitsFinder.results.snap.title' })}
        description={<div>{intl.formatMessage({ id: 'app.benefitsFinder.results.snap.paragraph' })}</div>}
        toQualifyTitle={intl.formatMessage({ id: 'app.benefitsFinder.results.snap.subtitle' })}
        toQualifyItems={[
          ...(liveInHawaiiValue === 'yes' ? [intl.formatMessage({ id: 'app.benefitsFinder.results.snap.item1' })] : []),
          ...(howManyChildrenValue > 0 ? [intl.formatMessage({ id: 'app.benefitsFinder.results.snap.item2' })] : []),
          ...(youOrAnyoneOver60Value === 'yes'
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.snap.item3' })]
            : []),
          ...(blindOrDisabledValue === 'yes'
            ? [intl.formatMessage({ id: 'app.benefitsFinder.results.snap.item4' })]
            : []),
          ...(howManyLte21Value > 0 ? [intl.formatMessage({ id: 'app.benefitsFinder.results.snap.item5' })] : []),
        ]}
        image={foodIcon}
        imageStyles={{ width: ['sm', 'xs'].includes(width) ? 45.87 : 69.45 }}
        imageDescription={intl.formatMessage({ id: 'financialHelp.groceries.imageDescription' })}
        closeEvent="SNAP_CLOSE"
        learnHowToApplyEvent="SNAP_LEARN_APPLY"
        expandEvent="SNAP_EXPAND"
      >
        <Box mb={4} />
        <div>
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            href="https://humanservices.hawaii.gov/bessd/snap/"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
            style={{ marginRight: 10 }}
            onClick={() => trackEvent('BF_Eligible_SNAP_Learn')}
          >
            {intl.formatMessage({ id: 'financialHelp.snap.learnMore' })}
          </Button>
          <Button
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
            href="https://pais-benefits.dhs.hawaii.gov/"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
            onClick={() => trackEvent('BF_Eligible_SNAP_Apply')}
          >
            {intl.formatMessage({ id: 'financialHelp.snap.apply' })}
          </Button>
        </div>
      </ResultsCard>
      <Box mb={4} />
    </>
  );
}
SnapCard.propTypes = {
  liveInHawaiiValue: PropTypes.string,
  howManyChildrenValue: PropTypes.number,
  youOrAnyoneOver60Value: PropTypes.string,
  blindOrDisabledValue: PropTypes.string,
  howManyLte21Value: PropTypes.number,
  width: PropTypes.string,
  trackEvent: PropTypes.func,
};

function UserResponses() {
  const intl = useIntl();
  const benefitsResults = useSelector((state) => state.benefitsFinder.results);
  const lte18Value = propOr('', 'how_many_children_lte_18', benefitsResults);
  const receivingBenefitsDict = {
    snap: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option1' }),
    tanf_taonf: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option2' }),
    quest: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option3' }),
    child_care: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option4' }),
    dont_receive: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option5' }),
  };
  const supportInterestedInDict = {
    snap: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option1' }),
    tanf_taonf: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option2' }),
    quest: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option3' }),
    child_care: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option4' }),
    monthly_cash: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option5' }),
  };
  const allApply18Dict = {
    parent_or_relative_caring: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option1' }),
    child_us_citizen_requires_child_care: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option2' }),
    child_under_13_requires_child_care: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option3' }),
    child_between_13_18_requires_child_care: intl.formatMessage({
      id: 'app.benefitsFinder.section2.question10.option4',
    }),
    you_are_under_18: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option5' }),
    none: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option6' }),
  };
  const whyNotWorkingDict = {
    physical_psycological_disability: intl.formatMessage({ id: 'app.benefitsFinder.section3.question2.option1' }),
    attending_training_or_education: intl.formatMessage({ id: 'app.benefitsFinder.section3.question2.option2' }),
    prefer_not_to_say: intl.formatMessage({ id: 'app.benefitsFinder.section3.question2.option3' }),
  };

  const {
    liveInHawaiiDisplay,
    blindDisabledDisplay,
    youAnyoneGt65Display,
    manyChildrenAdultsDisplay,
    youAnyoneGt60Display,
    sixtyOne64Display,
    lte21Display,
    youOrAny1860Display,
    lte18Display,
    youAnyonePregnantDisplay,
    currentlyWorkingDisplay,
    receivingChildProtectiveDisplay,
  } = useBenefitsDisplayLogic();
  return (
    <div>
      <Box mb={3}>
        <h3 className={financialStyles.toQualifyTitle}>
          {intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.title' })}
        </h3>
        <ul>
          {propOr([], 'currently_receiving_benefits', benefitsResults).map((o, i) => (
            <li key={i}>{receivingBenefitsDict[o]}</li>
          ))}
        </ul>
      </Box>
      <Box mb={3}>
        <h3 className={financialStyles.toQualifyTitle}>
          {intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.title' })}
        </h3>
        <ul>
          {propOr([], 'support_interested_in', benefitsResults).map((o, i) => (
            <li key={i}>{supportInterestedInDict[o]}</li>
          ))}
        </ul>
      </Box>
      {liveInHawaiiDisplay ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.title' })}
          </h3>
          <span className={styles.answerText}>{capitalize(propOr('', 'live_in_hawaii', benefitsResults))}</span>
        </Box>
      ) : null}
      {manyChildrenAdultsDisplay ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section2.question2.title' })}
          </h3>
          <span className={styles.answerText}>{propOr('', 'how_many_children', benefitsResults)}</span>
        </Box>
      ) : null}
      {youAnyoneGt60Display ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section2.question3.title' })}
          </h3>
          <span className={styles.answerText}>{capitalize(propOr('', 'you_or_anyone_over_60', benefitsResults))}</span>
        </Box>
      ) : null}
      {propOr([], 'you_or_anyone_over_60', benefitsResults) === 'yes' && sixtyOne64Display ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section2.question4.title' })}
          </h3>
          <span className={styles.answerText}>{propOr('', 'how_many_people_61_64', benefitsResults)}</span>
        </Box>
      ) : null}
      {blindDisabledDisplay ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section2.question5.title' })}
          </h3>
          <span className={styles.answerText}>
            {capitalize(propOr('', 'you_or_anyone_blind_disabled', benefitsResults))}
          </span>
        </Box>
      ) : null}
      {youAnyoneGt65Display ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section2.question6.title' })}
          </h3>
          <span className={styles.answerText}>
            {capitalize(propOr('', 'you_or_anyone_gt65_medicare_long_term', benefitsResults))}
          </span>
        </Box>
      ) : null}
      {youOrAny1860Display ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section2.question7.title' })}
          </h3>
          <span className={styles.answerText}>{capitalize(propOr('', 'you_or_anyone_18_60', benefitsResults))}</span>
        </Box>
      ) : null}
      {lte21Display ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section2.question8.title' })}
          </h3>
          <span className={styles.answerText}>{propOr('', 'how_many_people_lte_21', benefitsResults)}</span>
        </Box>
      ) : null}
      {lte18Display ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section2.question9.title' })}
          </h3>
          <span className={styles.answerText}>{propOr('', 'how_many_children_lte_18', benefitsResults)}</span>
        </Box>
      ) : null}
      {lte18Display && lte18Value && parseInt(lte18Value, 10) > 0 ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.title' }, { response: lte18Value })}
          </h3>
          <ul>
            {propOr([], 'all_apply_for_18_or_younger', benefitsResults).map((o, i) => (
              <li key={i}>{allApply18Dict[o]}</li>
            ))}
          </ul>
        </Box>
      ) : null}
      {youAnyonePregnantDisplay ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section2.question11.title' })}
          </h3>
          <span className={styles.answerText}>{capitalize(propOr('', 'you_or_anyone_pregnant', benefitsResults))}</span>
        </Box>
      ) : null}
      {youAnyonePregnantDisplay && propOr('', 'you_or_anyone_pregnant', benefitsResults) === 'yes' ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section2.question12.title' })}
          </h3>
          <span className={styles.answerText}>
            {capitalize(propOr('', 'you_or_anyone_9th_month', benefitsResults))}
          </span>
        </Box>
      ) : null}
      {currentlyWorkingDisplay ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section3.question1.title' })}
          </h3>
          <span className={styles.answerText}>{capitalize(propOr('', 'currently_working', benefitsResults))}</span>
        </Box>
      ) : null}
      {youAnyonePregnantDisplay && propOr('', 'currently_working', benefitsResults) === 'no' ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section3.question2.title' })}
          </h3>
          <ul>
            {propOr([], 'why_arent_you_working', benefitsResults).map((o, i) => (
              <li key={i}>{whyNotWorkingDict[o]}</li>
            ))}
          </ul>
        </Box>
      ) : null}
      {receivingChildProtectiveDisplay ? (
        <Box mb={3}>
          <h3 className={financialStyles.toQualifyTitle}>
            {intl.formatMessage({ id: 'app.benefitsFinder.section3.question3.title' })}
          </h3>
          <span className={styles.answerText}>
            {capitalize(propOr('', 'receiving_child_protective_services', benefitsResults))}
          </span>
        </Box>
      ) : null}
    </div>
  );
}

BenefitsFinderResults.propTypes = {
  setCurrentStep: PropTypes.func,
  currentStep: PropTypes.number,
  width: PropTypes.number,
  trackEvent: PropTypes.func,
};

export default BenefitsFinderResults;
