import axios from 'axios';

// Axios wrapper functions:
// See https://axios-http.com/docs/req_config
export const apiRequest = (method, path, params = {}) => {
  const config = {
    method: method,
    url: `${process.env.REACT_APP_API_ENDPOINT}${path}`,
    responseType: 'json',
    ...params,
  };

  return axios
    .request(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const apiRequestV1 = (method, path, params = {}) => {
  const config = {
    method: method,
    url: `/api/v1${path}`,
    responseType: 'json',
    ...params,
  };

  return axios
    .request(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const genericApiRequest = (method, path, params = {}) => {
  const config = {
    method: method,
    url: path,
    responseType: 'json',
    ...params,
  };
  return axios
    .request(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

// Auth not required for these:
const publicAPIs = new Set(['/api/v1/topjobs', '/vp2/public/token']);

// Adds access token to header:
export const configureAuth = (getAccessToken) => {
  axios.interceptors.request.use(
    async (req) => {
      if (
        (req.url.startsWith(process.env.REACT_APP_API_ENDPOINT) || req.url.startsWith('/api/v1')) &&
        !publicAPIs.has(req.url)
      ) {
        const accessToken = await getAccessToken();
        if (accessToken) {
          // use custom header as work around for Safari bug
          // see https://stackoverflow.com/questions/61411520/safari-http-request-header-is-not-reflecting-on-axios-interceptors-change
          req.headers['x-access-token'] = `Bearer ${accessToken}`;
        } else {
          // No token available, so cancel API request
          return {
            ...req,
            cancelToken: new axios.CancelToken((cancel) => cancel('cancel unauthorized request')),
          };
        }
      }
      return req;
    },
    async (err) => {
      return Promise.reject(err);
    }
  );
};

// API calls:

export const getProfile = (params) => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/profile', { params })
      .then((response) => {
        if (response.recommendations_url !== undefined) {
          mergeRecommendations(response.recommendations_url, response, resolve, reject);
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSettings = (params) => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/users/me/settings', { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const mergeRecommendations = (recommendations_url, response, resolve) => {
  // Helper function to merge data into API response from recommendations stored in S3
  axios
    .get(recommendations_url)
    .then((rec_response) => {
      let rec_data = rec_response.data;
      if (rec_data.careers !== undefined) {
        response.careers = rec_data.careers;
      }
      if (rec_data.jobs !== undefined) {
        response.jobs = rec_data.jobs;
      }
      if (rec_data.training !== undefined) {
        response.training = rec_data.training;
      }
      resolve(response);
    })
    .catch((error) => {
      console.log('error retrieving from S3', error);
      // S3 data may have expired, so we should regenerate recommendations
      resolve(response);
    });
};

export const getJobSearchActivity = (from, to) => {
  return new Promise((resolve, reject) => {
    apiRequest('get', '/profile', {
      params: {
        download: 'csv',
        feature: 'activity',
        from,
        to,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const rateItem = (id, rating, item) => {
  return new Promise((resolve, reject) => {
    apiRequest('post', '/profile', {
      data: {
        action: 'rating',
        id: id,
        rating: rating,
        item: item,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postChat = (values) => {
  return new Promise((resolve, reject) => {
    apiRequestV1('post', '/chat', {
      data: values,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postGUID = (values, isUpdate = false) => {
  return new Promise((resolve, reject) => {
    apiRequest(isUpdate ? 'put' : 'post', isUpdate ? '/users/me/' : '/users/', {
      data: values,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postUpload = (values) => {
  return new Promise((resolve, reject) => {
    apiRequest('post', '/users/me/resume/', {
      data: values,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postProfile = (values) => {
  return new Promise((resolve, reject) => {
    apiRequestV1('post', '/profile', {
      data: values,
    })
      .then((response) => {
        if (response.recommendations_url !== undefined) {
          mergeRecommendations(response.recommendations_url, response, resolve, reject);
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const askJobCoach = (query) => {
  return new Promise((resolve, reject) => {
    apiRequestV1('post', '/search', {
      data: {
        query: query,
      },
    })
      .then((response) => {
        resolve({ search_results: response });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
