import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

DOBInput.propTypes = {
  inputRef: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
};
export default function DOBInput(props) {
  const { inputRef, onChange, name, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      name={name}
      getInputRef={inputRef}
      displayType={'input'}
      type={'text'}
      format="##/##/####"
      placeholder="MM/DD/YYYY"
      mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.formattedValue,
          },
        });
      }}
    />
  );
}
