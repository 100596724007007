import { css } from '@emotion/css';

const styles = {
  modal: css(
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    { label: 'modal' }
  ),
  modalPaper: css(
    {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '30px 40px 45px',
      maxWidth: '90vw',
    },
    { label: 'modal-paper' }
  ),
};

import { Box, Typography, CircularProgress, Modal, Backdrop, Fade } from '@mui/material';

export default function LoadingModal({ open, message }) {
  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={() => {}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={styles.modalPaper}>
          <Typography variant="body2" align="center">
            {message}
          </Typography>
          <Box m={2} />
          <CircularProgress />
        </div>
      </Fade>
    </Modal>
  );
}
