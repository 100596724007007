import { createSlice } from '@reduxjs/toolkit';

export const careersSlice = createSlice({
  name: 'careers',
  initialState: {
    results: [],
    pageSize: 10,
    page: 0,
    goal: '',
    tagsActive: [],
    keywordFilter: '',
  },
  reducers: {
    setCareers: (state, action) => {
      state.results = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setGoal: (state, action) => {
      state.goal = action.payload;
    },
    setTagsActive: (state, action) => {
      state.tagsActive = action.payload;
    },
    setKeywordFilter: (state, action) => {
      state.keywordFilter = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCareers, setPage, setGoal, setTagsActive, setKeywordFilter } = careersSlice.actions;

export default careersSlice.reducer;
