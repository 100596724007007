import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { languages, getUrlPrefix } from 'i18n/config';
import { useLocation } from 'react-router-dom';

/*-- SEO Reference materials from Google:
Meta tags that Google understands
https://developers.google.com/search/docs/advanced/crawling/special-tags

Tell Google about localized versions of your page
https://developers.google.com/search/docs/advanced/crawling/localized-versions#html

Understand the JavaScript SEO basics
https://developers.google.com/search/docs/advanced/javascript/javascript-seo-basics
*/

export const config = {
  ripl: {
    title: 'Career Compass',
    description:
      'Career Compass is a digital hub connecting unemployment insurance claimants and jobseekers to high-impact career pathways.',
    icons: {
      favicon: '/images/ripl.favicon',
    },
  },
  hi: {
    title: 'HI CAN: Hawaii Career Acceleration Navigator',
    description:
      'The Hawaii Career Acceleration Navigator is a digital hub connecting unemployment insurance claimants and jobseekers to high-impact career pathways.',
    icons: {
      favicon: '/images/plumeria.ico',
      appleTouchIcon: '/images/logo-hican-stacked-rgb.jpg',
    },
  },
};

const MetaContent = ({ pageTitle = '', intlDescription = '', customMetaObjectList = {} }) => {
  const environment = process.env.REACT_APP_LOCALITY;
  const { locale } = useIntl();
  const { pathname } = useLocation();

  const { title, description, icons, ...otherMetaContent } = config[environment];

  const renderOptionalMetaContent = (objectList) =>
    Object.keys(objectList).length
      ? Object.entries(objectList).map((k, v) => (
          <>
            <meta key={k} name={k} content={v} />
          </>
        ))
      : null;

  return (
    <Helmet>
      <title>{pageTitle || title}</title>
      <meta name="description" content={intlDescription || description} />
      {languages.map((lang) => (
        <link
          rel="alternate"
          href={`${window.location.origin}${getUrlPrefix(lang)}${pathname.replace(`/${locale}`, '')}`}
          hrefLang={lang}
          key={lang}
        />
      ))}
      {renderOptionalMetaContent(otherMetaContent)};{renderOptionalMetaContent(customMetaObjectList)};
      <link rel="icon" href={'favicon' in icons ? icons.favicon : config.ripl.favicon} />
      <link
        rel="apple-touch-icon"
        href={'appleTouchIcon' in icons ? icons.appleTouchIcon : config.ripl.appleTouchIcon}
      />
    </Helmet>
  );
};

MetaContent.propTypes = {
  pageTitle: PropTypes.string,
  intlDescription: PropTypes.string,
  customMetaObjectList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

export default MetaContent;
