import { useEffect } from 'react';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Analytics */
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import { Typography, Box, Container } from '@mui/material';
import CloseTabButton from 'components/CloseTabButton';

const TermsOfUseJA = () => {
  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('TERMSOFUSE');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <CloseTabButton className={layoutStyles.closeButton} />
          <Typography variant="h1" gutterBottom>
            利用規約とプライバシーポリシー
          </Typography>
          <hr className={layoutStyles.separator} />
          <Box m={4} />
          <Typography variant="body2" component="p" gutterBottom>
            ハワイキャリアアクセラレーションナビゲーター(「HI CAN」)へようこそ!
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            当社は、お客様の情報が可能な限り安全であることを保証するために、プライバシーに関連するすべての関連する州および連邦の法律および規制を遵守するために最善の努力を払うなど、あらゆる予防措置を講じています。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            このWebサイト、そのサブページ、およびコンテンツ(総称してこのWebサイトと呼びます)にアクセスして使用することにより、利用規約とプライバシーポリシーに同意したことになります。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            適格性
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            <p>
              この ウェブサイトを使用することによって、18
              歳以上であり、これらの利用規約を承諾する法的権限を持っていることを保証することになります。
            </p>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            情報の利用
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            ハワイ労働労使関係省および/またはその代理人/請負業者/パートナーは、ハワイ州の記録と、登録時に提供された情報を使用します。
            HI CANは、パーソナライズされた雇用とトレーニングの機会にあなたをつなぎます。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            また、法律または法的手続き(裁判所または政府の命令または召喚状を含む)を遵守するために合理的に必要な場合、提供された情報を使用および共有する場合があります。詐欺、セキュリティ、または技術的な問題を検出、防止、またはその他の方法で対処するため。利用規約とプライバシーポリシーを実施するため。ハワイ州、当社のユーザー、および/または公衆の権利、財産、または安全を保護するため。またはそれ以外の場合、お客様の明示的な同意がある場合。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            あなたが提供する情報は、第92F章、ハワイ改正法、一般的な定義で定義されている「政府の記録」と見なされる場合があります。政府記録へのパブリックアクセスを含むアクセスは、主にハワイ改正法第92F章によって管理されています。さらに、提供された情報へのパブリックアクセスを要求する他の法律が存在する場合があります。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            HI
            CANに登録することにより、部門および/またはその請負業者が上記の情報、および部門が利用できるようにした関連データを使用して、パーソナライズされた仕事とキャリアの機会の推奨事項を作成することを許可することに同意するものとします。さらに、部門および/またはその請負業者およびパートナーが、求人機会に関する重要な情報、利用可能な仕事に関する情報、スキルトレーニングの機会、教育の機会、およびその他のCOVID-19またはスキル関連のリソースと機会を含むがこれらに限定されない通信に連絡および/または送信する場合があることに同意します。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            あなたの携帯電話番号を提供することにより、あなたは部門があなたの携帯電話に通信を送信することを許可することに特に同意するものとします:失業保険プログラムに関する重要な情報、あなたに適しているかもしれない求人の機会、あなたに適しているかもしれない利用可能な仕事に関する情報、および利用可能なスキルトレーニングの機会。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            あなたは、部門があなたのスキル、興味、経験に基づいてあなたを雇うことに関心があるかもしれない雇用主および/または採用担当者とあなたの連絡先情報を直接共有することを許可することを選択できます。このサービスを選択した場合、あなたは、部門および/またはその代理人/請負業者が、あなたの連絡先情報、過去の雇用および教育歴、およびあなたの仕事またはトレーニングプログラムへの適合性に関する推奨事項を、あなたのスキルと経歴に適した可能性のある採用またはスキルトレーニングの機会についてあなたに連絡する目的でのみ、ハワイの雇用主および採用、配置、および政府機関と直接共有することを許可することに同意するものとします。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            セキュリティと監視に関する通知
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            このWebサイトを閲覧して情報を読んだり、印刷したり、ダウンロードしたりすると、ナビゲーション情報が収集される場合があります。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            サイトのセキュリティ目的で、すべてのネットワークトラフィックが監視され、情報のアップロードや変更、またはその他の犯罪行為の不正な試みを特定します。認可された法執行機関の調査に関連して、必要な法的手続きに従って、ナビゲーション情報は個人情報の取得を支援するために使用される場合があります。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            さらに、このWebサイトおよびWebサイトをサポートする関連するコンピューターシステムを不正使用から保護し、コンピューターシステムが適切に機能していることを確認するために、このWebサイトおよび関連するコンピューターシステムにアクセスする個人は、ハワイ州によって許可された担当者によってすべての活動を監視および記録する必要があります。.このウェブサイトおよび関連するコンピューターシステムを使用する人は誰でも、そのような監視に明示的に同意し、そのような監視によって虐待または犯罪行為の可能性の証拠が明らかになった場合、ハワイ州の職員はそのような監視の結果を適切な当局に提供することができることを通知されます。ウェブサイトのコンテンツをアップロードまたは変更しようとする不正な試み、またはこのウェブサイトまたはコンピューターシステムに損害を与える試みは固く禁じられており、適用法の下で罰せられる場合があります。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            予告なく変更
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            このウェブサイトは動的であり、予告なしに時間の経過とともに変更されます。したがって、このウェブサイトのユーザーは、ウェブサイトにあるコンテンツの正確性、完全性、最新性、および適合性を確認する全責任を負います。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            さらに、当社は利用規約およびプライバシーポリシーをいつでも変更する権利を留保します。利用規約とプライバシーポリシーを定期的に確認することをお勧めします。ウェブサイト、利用規約、およびプライバシーポリシーの変更後もHI
            CANを継続して使用することは、そのような変更に同意したものとみなされます。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            保証の否認
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            このウェブサイトは「現状有姿」で提供され、いかなる種類の保証もありません。法律の最大限の範囲で、ハワイ州の各機関、役員、または従業員を含むハワイ州は、このウェブサイトに関する商品性および特定目的への適合性の保証を含むがこれらに限定されない、明示または黙示のすべての保証を否認します。
            この保証の免責事項には、以下に関する免責事項が含まれますが、これらに限定されません。
            (1)このウェブサイトの機能が中断せず、エラーがないこと。(2)本ウェブサイト上の欠陥またはその他のエラーが修正されること、および(3)本ウェブサイトおよびコンピュータシステムにウイルスまたはその他の有害なコンポーネントが含まれていないこと、またはこれから発生すること。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            さらに、ハワイ州もハワイ州の機関、役員、または従業員のいずれも、(1)このウェブサイトを通じて提供される情報の正確性、完全性、最新性、または適合性に関していかなる表明、保証、または保証も行いません。(2)
            本ウェブサイトの利用または利用結果(3)ハワイ州以外の人物によって作成および維持され、このウェブサイトにリンクされている、またはこのウェブサイトからリンクされているウェブサイトのコンテンツの正確性、信頼性、可用性、完全性または有用性。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            責任の制限
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            過失を含むがこれに限定されないいかなる状況においても、ハワイ州またはハワイ州の機関、役員、または従業員は、たとえハワイ州が
            そのような損害の可能性について知らされています。適用法により、責任または偶発的または結果的な損害の制限または除外が許可されていない場合があるため、上記の制限または除外がお客様に適用されない場合があります。いかなる場合も、すべての損害、損失、および訴訟原因(契約、不法行為(過失を含むがこれに限定されない)にかかわらず)に対するハワイ州のお客様に対する責任の総額は、本ウェブサイトにアクセスするためにお客様が支払った金額を超えないものとします。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            著作権表示
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            このウェブサイトのコンテンツを商業目的で、またはハワイ州による公式承認の印象を与える可能性のある方法で複製または使用することは禁止されています。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            外部のウェブサイトやページへのリンク
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            <p>
              このウェブサイトには、ハワイ州以外の公的および私的組織によって作成および維持されている情報を含む外部サイトおよびページへのハイパーテキストリンクが含まれている場合があります。これらのハイパーテキストリンクは、
              外部リンクの確立が、市民や企業への公共サービスを大幅かつ熱心に促進するというこのWebサイトの目的を支援または促進することと一致すると判断した場合に、ハワイ州によって作成される場合があります。
            </p>
            <ul>
              <li>政府サービスと政府情報への企業と市民のアクセスを拡大する。</li>
              <li>企業や市民が州政府とオンラインで取引を行うための簡単で便利なプロセスを提供します。</li>
              <li>質の高いオンライン政府サービスの開発と提供を加速する。</li>
              <li>州政府からの顧客サービスのレベルを向上させる。そして</li>
              <li>電子政府サービスを市および郡政府の市民に拡大する。</li>
            </ul>
            <p>
              さらに、ハイパーテキストリンクは、情報提供の目的、つまり、リンクされた外部WebサイトがこのWebサイトの訪問者に有用で価値のある情報を提供する場合、またはリンクされた外部Webサイトが法律で要求または許可されている場合に、ハワイ州によって作成される場合があります。
            </p>
            <p>
              ハワイ州は、独自の裁量により、外部ウェブサイトがこのウェブサイトの目的を満たしているかどうか、または特定の情報提供目的を満たしているかどうかを判断します。外部ウェブサイトへのハイパーテキストリンクを含めることは、リンクされたウェブサイトで提供または参照されている製品またはサービス、そのようなウェブサイトを後援する組織、またはウェブサイトで表現または参照される可能性のある見解を推奨することを意図したものではありません。
            </p>
            <p>
              外部サイトおよびページへのこれらのハイパーテキストリンクは、ハワイ州の独自の裁量により、予告なしにいつでも削除または置き換えられる場合があります。
            </p>
            <p>
              リンク先の外部サイトの形式、正確性、適時性、または完全性に関する問題を発見した場合、または懸念がある場合は、リンク先の外部サイトを担当する組織に連絡してください-ハワイ州はリンクされた外部サイトおよびページを管理も責任も負いません。
            </p>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            保証の免責事項
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            このウェブサイトで、商号、商標、メーカー、またはその他の方法で特定の商用製品、プロセス、またはサービスに言及している場合でも、ハワイ州による承認、推奨、または支持を構成または暗示するものではありません。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            管轄
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            このウェブサイトにアクセスして使用することを選択した人は、ハワイ州の管轄権の対象となります。
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            本規約は、ハワイ州の法律に準拠し、同法に従って解釈されるものとします。本規約、または本ウェブサイトへのアクセスおよび使用に起因または関連する紛争は、ハワイ州の州裁判所の法律および州裁判所で決定されるものとし、お客様は、かかる紛争を裁定する目的で、かかる裁判所の対人管轄権に同意し、これに従うものとします。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            他のウェブサイト
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            このウェブサイトには、他の公的および私的組織によって作成および維持されている情報を含む他のウェブサイトおよびページへのハイパーテキストリンクが含まれている場合があります。これらのリンク先サイトにアクセスする場合は、これらの他のWebサイトのプライバシーに関する声明を確認する必要があります。ハワイ州は、これらの他のウェブサイトで提供されるコンテンツについて責任を負いません。
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            分離可能性
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            上記の利用規約およびプライバシーポリシーのいずれかの条項が、管轄裁判所によって違法、無効、または執行不能であると判断された場合、その条項は残りの条件から分離可能であると見なされ、残りの条項の有効性および執行可能性に影響を与えないものとします。
          </Typography>
          <Box m={3} />
        </Container>
      </div>
    </>
  );
};

export default TermsOfUseJA;
