import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { apiRequestV1 } from 'lib/API';
import queryString from 'query-string';
import { useAuth0 } from '@auth0/auth0-react';
import { css, cx } from '@emotion/css';
import { darken } from 'polished';

/* Redux */
import { useDispatch } from 'react-redux';
import { setLoading } from 'app/slices/appSlice';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import cardStyles from 'styles/recommendations-header.module.scss';
import '../../../styles/top-jobs.css';

/* Analytics */
import TimeMe from 'timeme.js';
import { trackEvent } from 'lib/analytics';

/* Assets */
import vars from 'styles/vars.module.scss';
import arrowRight from 'assets/images/Arrow_RightWhite.svg';

/* Material UI and other UI Dependencies */
import { Box, CircularProgress, Typography, Container, Divider } from '@mui/material';
import CloseTabButton from 'components/CloseTabButton';
import RecommendationsCard from '../../RecommendationsCard';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="xs" />;

const localStyles = {
  callToAction: css(
    {
      display: 'flex',
      justifyContent: 'space-between',
      '@media screen and (max-width: 767px)': {
        flexDirection: 'column',
      },
    },
    { label: 'call-to-action' }
  ),
  cardContent: css(
    {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: 24,
      '@media screen and (max-width: 767px)': {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    { label: 'card-content' }
  ),
  hicanLogo: css({
    width: '298px',
    paddingTop: '6px',
    paddingBottom: '11px',
  }),
  copy: css({
    fontSize: '14px',
    color: vars.navy,
    fontWeight: '700',
  }),
  rightSide: css({
    display: 'flex',
    alignItems: 'flex-end',
    paddingRight: 24,
    '@media screen and (max-width: 767px)': {
      justifyContent: 'flex-end',
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
  }),
  signInButton: css({
    color: 'white',
    maxWidth: '385px',
    textAlign: 'center',
    padding: '15px',
    borderRadius: '8px',
    fontWeight: '700',
    margin: '11px auto 20px',
    backgroundColor: '#60a8a1',
    position: 'relative',
    top: '4px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: `${darken(0.08, '#60a8a1')}`,
    },
    '&:active': css({
      backgroundColor: `${darken(0.12, '#60a8a1')}`,
      transition: 'none',
    }),
  }),
};

const TopJobs = ({ endpoint = '/topjobs', requestFn = apiRequestV1 }) => {
  const [loadingJobs, setLoadingJobs] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [loadedToken, setLoadedToken] = useState(false);
  const [token, setToken] = useState('');

  const queryParams = window.location.hash.split('?', 2)[1];
  const { source, timestamp, user_id, signature } = queryString.parse(queryParams);
  const hasLinkParams =
    source !== undefined && timestamp !== undefined && user_id !== undefined && signature !== undefined;

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName(hasLinkParams ? 'UI_CERT_JOBS' : 'TOPJOBS');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
    return () => {
      console.log('Leaving Top Jobs');
    };
  }, []);

  useEffect(() => {
    if (!loadedToken && hasLinkParams) {
      setTimeout(() => {
        trackEvent('UI_CERT_PAGE_LOADED');
      }, 2000);
      return requestFn('GET', 'api/vp2/public/token', {
        params: {
          source,
          timestamp,
          user_id,
          signature,
        },
      }).then(({ access_token }) => {
        setToken(access_token);
        setLoadedToken(true);
      });
    }
    if (loadedToken) {
      return requestFn('GET', endpoint, { token }).then(({ jobs, profile }) => {
        setJobs(jobs.map(({ tags, ...rest }) => rest));
        if (localStorage) {
          localStorage.setItem('ui_profile', JSON.stringify(profile));
        }
        setLoadingJobs(false);
      });
    }
    if (!loadedToken && !hasLinkParams) {
      requestFn('GET', endpoint).then(({ jobs }) => {
        setJobs(jobs);
        setLoadingJobs(false);
      });
    }
  }, [hasLinkParams, loadedToken]);

  return <TopJobsView loadingJobs={loadingJobs} jobList={jobs} hasLinkParams={hasLinkParams} />;
};

TopJobs.propTypes = {
  endpoint: PropTypes.string,
  requestFn: PropTypes.func,
};

export default TopJobs;

const TopJobsContents = ({ loadingJobs, jobList = [], hasLinkParams }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { loginWithRedirect } = useAuth0();
  const handleApplyClick = () => {
    if (hasLinkParams) {
      trackEvent('UI_CERT_JOBS_LEARN_MORE_CLICKED');
    }
  };
  const handleClick = () => {
    if (hasLinkParams) {
      trackEvent('UI_CERT_JOBS_SIGN_IN_WITH_UI_CLICKED');
    }
    dispatch(setLoading(true));
    loginWithRedirect({ prompt: 'login', connection: 'hui' });
  };

  return (
    <div className={styles.content}>
      <Container maxWidth="lg">
        {hasLinkParams && !loadingJobs ? (
          <Box mt={2} mb={3}>
            <Box />
            <div className={cx(cardStyles.header__content, localStyles.callToAction)}>
              <div className={localStyles.cardContent}>
                <img
                  className={localStyles.hicanLogo}
                  src={vars.secondLogoHeaderUrl.slice(1, -1)}
                  alt={vars.organizationName}
                />
                <span className={localStyles.copy}>{intl.formatMessage({ id: 'uiCertJobs.callToAction.text' })}</span>
              </div>
              <div className={localStyles.rightSide}>
                <div className={localStyles.signInButton} onClick={handleClick}>
                  {intl.formatMessage({ id: 'forms.signIn.signInWithUI' })}
                  &nbsp;
                  <img
                    alt="Arrow right"
                    src={arrowRight}
                    className={cx([styles.arrowRight], 'prerender_arrow_right')}
                  />
                </div>
              </div>
            </div>
          </Box>
        ) : null}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h1" gutterBottom>
            {intl.formatMessage({ id: hasLinkParams ? 'uiCertJobs.title' : 'topJobs.title' })}
          </Typography>
          {!hasLinkParams ? <CloseTabButton className={styles.closeButtonNoHeight} /> : null}
        </Box>
        <Divider />
        {loadingJobs ? (
          <Box m={7} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <div>
            {jobList.length > 0 ? (
              <>
                <Box m={4} />
                {jobList.map((item, itemIndex) => (
                  <RecommendationsCard
                    key={`${item.id}-${itemIndex}`}
                    type="topjob"
                    item={item}
                    link={item.apply}
                    onButtonClick={() => handleApplyClick(item)}
                    rangeLabel={intl.formatMessage({ id: 'careers.salaryRange' })}
                  />
                ))}
              </>
            ) : null}
          </div>
        )}
      </Container>
    </div>
  );
};

TopJobsContents.propTypes = {
  loadingJobs: PropTypes.bool,
  hasLinkParams: PropTypes.bool,
  jobList: PropTypes.array,
};

const TopJobsView = TopJobsContents;
