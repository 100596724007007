import { propOr, pathOr } from 'ramda';

/* Core Dependencies */
import { useEffect } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollToTopOnPathChange } from 'components/ScrollToTopButton';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

/* i18n Dependencies */
import { useIntl } from 'react-intl';

/* UI Dependencies */
import { ThemeProvider } from '@mui/material/styles';
import Layout from 'components/layout';
import theme from 'styles/ui-theme';

/* API */
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';
import { deviceDetect } from 'react-device-detect';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setGoal } from 'app/slices/careersSlice';
import { setCurrentSoc, setTagsActive } from 'app/slices/jobsSlice';
import { setExperienceForm, setExperienceHasResume } from 'app/slices/experienceSlice';
import { setActivities, setGuid, setStatus, setLoaded } from 'app/slices/workSearchSlice';
import {
  setLoading,
  setNeedsContactInfo,
  setNeedsOnboarding,
  setPreferredName,
  setUserSettings,
  setSurvey,
} from 'app/slices/appSlice';

/* Pages */
import {
  About,
  LoginAuth0,
  LoginHI,
  TermsOfUse,
  TermsOfUseES,
  TermsOfUseJA,
  TermsOfUseZH,
  TermsOfUseTL,
  CreditsCitations,
} from 'components/pages/unauthenticated';
import OnboardingPagesSwitch from 'components/pages/onboarding/OnboardingPagesSwitch';
import AuthenticatedPagesSwitch from 'components/pages/authenticated/AuthenticatedPagesSwitch';

/* UI Components */
import Drawer from 'components/Drawer';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import BenefitsFinder from 'components/pages/unauthenticated/BenefitsFinder/index';
import { TopJobs } from 'shared/components';
import { configureAuthNew, apiRequest } from 'shared/API';
import { apiRequestV1, genericApiRequest } from 'lib/API';

function App() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const locale = intl.locale;

  const { getAccessTokenSilently, isAuthenticated, isLoading: auth0_loading, logout, user: auth0_user } = useAuth0();

  const { loading, needsContactInfo, needsOnboarding, unrecoverableError } = useSelector((state) => state.app);

  const signOut = () => {
    dispatch(setLoading(true));
    logout({
      returnTo: window.location.origin,
      federated: true,
    });
    dispatch(setLoading(false));
  };

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently();
    } catch (e) {
      console.warn('Failed to get access token', e);
    }
    return null;
  };

  useEffect(() => {
    configureAuthNew(getAccessToken);
    if (!auth0_loading && isAuthenticated) {
      trackEvent('LOAD', {
        device: deviceDetect(),
        referrer: document.referrer,
        path: window.location.hash,
        language: navigator.language || navigator.userLanguage,
        localtime: Date(),
        username: auth0_user.sub,
      });
    } else {
      if (!auth0_loading) {
        dispatch(setLoading(false));
      }
    }
  }, [auth0_loading, isAuthenticated]);

  const idQuery = useQuery({
    queryKey: ['/users/me'],
    enabled: isAuthenticated,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  const additionalRequests = isAuthenticated && idQuery.isSuccess;

  const experienceQuery = useQuery({
    queryKey: ['/users/me/experience/', locale],
    enabled: additionalRequests,
    refetchOnWindowFocus: false,
  });

  const settingsQuery = useQuery({
    queryKey: ['/users/me/settings'],
    enabled: additionalRequests,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (idQuery.isSuccess && !experienceQuery.isLoading && experienceQuery.isSuccess) {
      dispatch(setPreferredName(propOr('', 'first_name', idQuery.data)));
      const has_experience =
        experienceQuery.isSuccess &&
        typeof experienceQuery.data.job_experience != 'undefined' &&
        experienceQuery.data.job_experience.length > 0;
      const has_education = pathOr(false, ['education', '0', 'degree'], experienceQuery.data);
      const has_goal = propOr(false, 'goal', experienceQuery.data);

      dispatch(setExperienceForm(experienceQuery.data));
      dispatch(setExperienceHasResume(experienceQuery.data.has_resume));

      if (has_experience) {
        const currentSoc = pathOr('', ['data', 'job_experience', 0, 'soc'], experienceQuery);
        if (currentSoc !== '') {
          dispatch(setCurrentSoc(currentSoc));
          apiRequest('get', `/careers/${currentSoc}`).then((response) => {
            if ('title' in response) {
              dispatch(setExperienceForm({ soc_code: response.soc, soc_title: response.title }));
            }
          });
        }
      }
      if (has_goal) {
        dispatch(setGoal(has_goal));
        if (has_goal === 'currentCareer') {
          dispatch(setTagsActive('currentCareer'));
        }
      }
      if (!has_experience || !has_education || !has_goal) {
        dispatch(setNeedsOnboarding(true));
      }
      dispatch(setLoading(false));
    }

    if (idQuery.isError) {
      dispatch(setNeedsOnboarding(true));
      dispatch(setNeedsContactInfo(true));
      dispatch(setLoading(false));
    }
  }, [idQuery.isSuccess, idQuery.isError, experienceQuery.isSuccess, experienceQuery.isLoading]);

  useEffect(() => {
    if (idQuery.isSuccess && !settingsQuery.isFetching && settingsQuery.isSuccess) {
      dispatch(setUserSettings(settingsQuery.data));
    }
  }, [idQuery.isSuccess, settingsQuery.isSuccess, settingsQuery.isFetching]);

  useEffect(() => {
    if (idQuery.isSuccess) {
      apiRequest('GET', '/users/me/worksearch/').then((res) => {
        if (res.status && res.status.worksearch_enabled) {
          dispatch(setActivities(res.activities));
          dispatch(setGuid(res.guid));
          dispatch(setStatus(res.status));
          dispatch(setLoaded(true));
        } else if (res.status) {
          dispatch(setStatus(res.status));
        }
      });
      apiRequestV1('GET', '/profile').then((res) => {
        if (typeof res.survey !== 'undefined') {
          dispatch(setSurvey({ loaded: true, ...res.survey }));
        }
      });
    }
  }, [idQuery.isSuccess]);

  useEffect(() => {
    /**
     * Configuration of idle time before we track
     * the user as inactive
     */
    TimeMe.initialize({
      idleTimeoutInSeconds: 300, // seconds
    });
  }, []);

  useEffect(() => {
    TimeMe.callWhenUserLeaves(() => {
      const timeReport = TimeMe.getTimeOnAllPagesInSeconds();
      timeReport.forEach(({ pageName, timeOnPage }) => {
        if (pageName !== 'default-page-name') {
          trackEvent(`TIME_${pageName}_PAGETOTAL`, { timeOnPage: timeOnPage.toString() });
        }
      });
      const timeActiveSession = timeReport.reduce((prev, { timeOnPage }) => prev + timeOnPage, 0);
      trackEvent('TIME_ACTIVE_SESSION', { timeOnPage: timeActiveSession.toString() });
    });

    TimeMe.callWhenUserReturns(() => {
      TimeMe.resetAllRecordedPageTimes();
    });
  }, []);

  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  function loginComponent() {
    const locality = process.env.REACT_APP_LOCALITY;
    if (locality === 'hi') {
      return <LoginHI urlPrefix={urlPrefix} />;
    } else {
      return <LoginAuth0 />;
    }
  }

  function render() {
    if (unrecoverableError) {
      return <ErrorMessage />;
    } else if (loading || auth0_loading) {
      return <Loading />;
    } else if (!isAuthenticated) {
      return (
        <Switch>
          <Route path={['/login', '/:lang/login']}>{loginComponent()}</Route>
          <Route>
            <Redirect to={`${urlPrefix}/login`} />
          </Route>
        </Switch>
      );
    } else if (needsContactInfo || needsOnboarding) {
      return <OnboardingPagesSwitch />;
    } else {
      return <AuthenticatedPagesSwitch />;
    }
  }

  return (
    <HashRouter
      getUserConfirmation={() => {
        /** Empty callback so the browser prompt is blocked */
      }}
    >
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Layout locale={intl.locale} loggedIn={isAuthenticated} signOut={signOut}>
            <ScrollToTopOnPathChange />
            <Switch>
              <Route path={['/terms-of-use', '/:lang/terms-of-use']}>
                {intl.locale === 'en' ? <TermsOfUse /> : null}
                {intl.locale === 'es' ? <TermsOfUseES /> : null}
                {intl.locale === 'ja' ? <TermsOfUseJA /> : null}
                {intl.locale === 'zh' ? <TermsOfUseZH /> : null}
                {intl.locale === 'tl' ? <TermsOfUseTL /> : null}
              </Route>
              <Route path={['/credits-and-citations', '/:lang/credits-and-citations']}>
                <CreditsCitations />
              </Route>
              <Route exact path={['/top-jobs', '/:lang/top-jobs']}>
                <TopJobs />
              </Route>
              <Route exact path={['/benefits-finder', '/:lang/benefits-finder']}>
                <BenefitsFinder />
              </Route>
              <Route path={['/ui-cert-jobs', '/:lang/ui-cert-jobs']}>
                <TopJobs endpoint="api/vp2/public/profile" requestFn={genericApiRequest} />
              </Route>
              <Route path={['/about', '/:lang/about']}>
                <About />
              </Route>
              <Route>{render()}</Route>
            </Switch>
            <Drawer anchor="right" signOut={signOut} />
          </Layout>
        </LocalizationProvider>
      </ThemeProvider>
    </HashRouter>
  );
}

export default App;
