import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

/* Styles */
import cssVars from 'styles/vars.module.scss';

const CustomAutocomplete = withStyles({
  tag: {
    borderRadius: 0,
    backgroundColor: cssVars.tagSkill,
    height: 22,
    position: 'relative',
    zIndex: 0,
    '& .MuiChip-label': {
      color: cssVars.darkGray,
      fontWeight: 700,
      fontSize: '10px',
      textTransform: 'uppercase',
    },
    '& .MuiChip-deleteIcon': {
      width: '14px',
      height: '14px',
    },
  },
  endAdornment: {
    top: 'initial',
    bottom: '9px',
  },
})(Autocomplete);

export default function GenericAutocompleteV2({
  options,
  label,
  isDisabled,
  handleChange,
  activeOptions,
  getOptionLabel = (option) => option,
}) {
  return (
    <CustomAutocomplete
      fullWidth
      multiple
      disableClearable
      disableCloseOnSelect={true}
      id={'add-skills'}
      options={options}
      getOptionLabel={getOptionLabel}
      disabled={isDisabled}
      value={activeOptions}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} variant="filled" label={activeOptions?.length ? '' : label} placeholder={''} />
      )}
    />
  );
}

GenericAutocompleteV2.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string.isRequired),
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
  activeOptions: PropTypes.arrayOf(PropTypes.string.isRequired),
};
