/* Redux */
import { useSelector } from 'react-redux';
import { propOr } from 'ramda';
import { jsPDF } from 'jspdf';
import { formatISO } from 'date-fns';

/* i18n */
import { useIntl } from 'react-intl';

function capitalize(word) {
  const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
  return capitalized.split('_').join(' ');
}

const useBenefitsDisplayLogic = () => {
  const benefitsResults = useSelector((state) => state.benefitsFinder.results);
  const supportInterestedInValue = propOr([], 'support_interested_in', benefitsResults);

  const questConditions = supportInterestedInValue.includes('quest');
  const snapConditions = supportInterestedInValue.includes('snap');
  const tanfConditions = supportInterestedInValue.includes('tanf_taonf');
  const childCareConditions = supportInterestedInValue.includes('child_care');

  const liveInHawaiiDisplay = questConditions || snapConditions || tanfConditions || childCareConditions;
  const blindDisabledDisplay = questConditions || snapConditions || tanfConditions;
  const youAnyoneGt65Display = questConditions;
  const manyChildrenAdultsDisplay = snapConditions;
  const youAnyoneGt60Display = snapConditions || tanfConditions;
  const sixtyOne64Display = tanfConditions;
  const lte21Display = snapConditions;
  const youOrAny1860Display = tanfConditions;
  const lte18Display = tanfConditions || childCareConditions;
  const youAnyonePregnantDisplay = tanfConditions;
  const youAnyone9MonthsDisplay = tanfConditions;
  const currentlyWorkingDisplay = tanfConditions || childCareConditions;
  const receivingChildProtectiveDisplay = childCareConditions;

  return {
    liveInHawaiiDisplay,
    blindDisabledDisplay,
    youAnyoneGt65Display,
    manyChildrenAdultsDisplay,
    youAnyoneGt60Display,
    sixtyOne64Display,
    lte21Display,
    youOrAny1860Display,
    lte18Display,
    youAnyonePregnantDisplay,
    youAnyone9MonthsDisplay,
    currentlyWorkingDisplay,
    receivingChildProtectiveDisplay,
  };
};

export function useDownloadResults() {
  const intl = useIntl();
  const benefitsResults = useSelector((state) => state.benefitsFinder.results);
  const lte18Value = propOr('', 'how_many_children_lte_18', benefitsResults);
  const receivingBenefitsDict = {
    snap: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option1' }),
    tanf_taonf: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option2' }),
    quest: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option3' }),
    child_care: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option4' }),
    dont_receive: intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.option5' }),
  };
  const supportInterestedInDict = {
    snap: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option1' }),
    tanf_taonf: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option2' }),
    quest: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option3' }),
    child_care: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option4' }),
    monthly_cash: intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.option5' }),
  };
  const allApply18Dict = {
    parent_or_relative_caring: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option1' }),
    child_us_citizen_requires_child_care: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option2' }),
    child_under_13_requires_child_care: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option3' }),
    child_between_13_18_requires_child_care: intl.formatMessage({
      id: 'app.benefitsFinder.section2.question10.option4',
    }),
    you_are_under_18: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option5' }),
    none: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option6' }),
  };
  const whyNotWorkingDict = {
    physical_psycological_disability: intl.formatMessage({ id: 'app.benefitsFinder.section3.question2.option1' }),
    attending_training_or_education: intl.formatMessage({ id: 'app.benefitsFinder.section3.question2.option2' }),
    prefer_not_to_say: intl.formatMessage({ id: 'app.benefitsFinder.section3.question2.option3' }),
  };

  const {
    liveInHawaiiDisplay,
    blindDisabledDisplay,
    youAnyoneGt65Display,
    manyChildrenAdultsDisplay,
    youAnyoneGt60Display,
    sixtyOne64Display,
    lte21Display,
    youOrAny1860Display,
    lte18Display,
    youAnyonePregnantDisplay,
    currentlyWorkingDisplay,
    receivingChildProtectiveDisplay,
  } = useBenefitsDisplayLogic();

  return function exportResults() {
    const doc = new jsPDF();
    const docWidth = doc.internal.pageSize.getWidth();
    doc.setFontSize(20);
    doc.setFont('helvetica', 'bold');
    doc.text(intl.formatMessage({ id: 'app.benefitsFinder.results.subHeader1' }), 11, 14);
    doc.line(0, 20, docWidth, 20);
    doc.setFontSize(12);

    let counter = 0;
    let accMargin = 35;

    const addPage = () => {
      counter = 0;
      accMargin = 16;
      doc.addPage('a4', 'p');
    };

    const addTitle = (titleStr) => {
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(14);
      const splitDescription = doc.splitTextToSize(titleStr, docWidth - 20);
      doc.text(splitDescription, 11, counter * 7 + accMargin);
      accMargin += 6;

      if (splitDescription.length && splitDescription.length > 1) {
        counter++;
      }

      if (counter >= 21) {
        addPage();
      }
    };

    const addResponse = (responseStr) => {
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(11);

      const splitDescription = doc.splitTextToSize(responseStr, docWidth - 20);
      doc.text(splitDescription, 11, counter * 7 + accMargin);
      accMargin += 4;
      counter++;
      if (counter >= 20) {
        addPage();
      }
    };
    const addList = (items) => {
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(11);
      items.forEach((item) => {
        const splitDescription = doc.splitTextToSize(`• ${item}`, docWidth - 20);
        doc.setFont('helvetica', 'normal');
        doc.text(splitDescription, 11, counter * 7 + accMargin);
        doc.setFontSize(11);
        counter++;
        if (splitDescription.length && splitDescription.length > 1) {
          accMargin += 4;
        }
        if (counter >= 21) {
          addPage();
        }
      });
      accMargin += 4;
    };

    addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section1.question1.title' }));
    addList(propOr([], 'currently_receiving_benefits', benefitsResults).map((o) => receivingBenefitsDict[o]));

    addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section1.question2.title' }));
    addList(propOr([], 'support_interested_in', benefitsResults).map((o) => supportInterestedInDict[o]));

    if (liveInHawaiiDisplay) {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.title' }));
      addResponse(capitalize(propOr('', 'live_in_hawaii', benefitsResults)));
    }

    if (manyChildrenAdultsDisplay) {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section2.question2.title' }));
      addResponse(propOr('', 'how_many_children', benefitsResults));
    }

    if (youAnyoneGt60Display) {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section2.question3.title' }));
      addResponse(capitalize(propOr('', 'you_or_anyone_over_60', benefitsResults)));
    }

    if (propOr([], 'you_or_anyone_over_60', benefitsResults) === 'yes' && sixtyOne64Display) {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section2.question4.title' }));
      addResponse(propOr('', 'how_many_people_61_64', benefitsResults));
    }

    if (blindDisabledDisplay) {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section2.question5.title' }));
      addResponse(capitalize(propOr('', 'you_or_anyone_blind_disabled', benefitsResults)));
    }

    if (youAnyoneGt65Display) {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section2.question6.title' }));
      addResponse(capitalize(propOr('', 'you_or_anyone_gt65_medicare_long_term', benefitsResults)));
    }

    if (youOrAny1860Display) {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section2.question7.title' }));
      addResponse(capitalize(propOr('', 'you_or_anyone_18_60', benefitsResults)));
    }

    if (lte21Display) {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section2.question8.title' }));
      addResponse(propOr('', 'how_many_people_lte_21', benefitsResults));
    }

    if (lte18Display) {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section2.question9.title' }));
      addResponse(propOr('', 'how_many_children_lte_18', benefitsResults));
    }

    if (lte18Display && lte18Value && parseInt(lte18Value, 10) > 0) {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.title' }, { response: lte18Value }));
      addList(propOr([], 'all_apply_for_18_or_younger', benefitsResults).map((o) => allApply18Dict[o]));
    }

    if (youAnyonePregnantDisplay) {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section2.question11.title' }));
      addResponse(capitalize(propOr('', 'you_or_anyone_pregnant', benefitsResults)));
    }

    if (youAnyonePregnantDisplay && propOr('', 'you_or_anyone_pregnant', benefitsResults) === 'yes') {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section2.question12.title' }));
      addResponse(capitalize(propOr('', 'you_or_anyone_9th_month', benefitsResults)));
    }

    if (currentlyWorkingDisplay) {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section3.question1.title' }));
      addResponse(capitalize(propOr('', 'currently_working', benefitsResults)));
    }

    if (youAnyonePregnantDisplay && propOr('', 'currently_working', benefitsResults) === 'no') {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section3.question2.title' }));
      addList(propOr([], 'why_arent_you_working', benefitsResults).map((o) => whyNotWorkingDict[o]));
    }

    if (receivingChildProtectiveDisplay) {
      addTitle(intl.formatMessage({ id: 'app.benefitsFinder.section3.question3.title' }));
      addResponse(capitalize(propOr('', 'receiving_child_protective_services', benefitsResults)));
    }

    doc.save(`information_summary_${formatISO(new Date())}.pdf`);
  };
}

export default useBenefitsDisplayLogic;
