import { createSlice } from '@reduxjs/toolkit';

export const jobSearchSlice = createSlice({
  name: 'jobSearch',
  initialState: {
    general: {
      currentWeek: {
        from: '2021-10-10',
        to: '2021-10-10',
        total: 3,
        completed: 2,
      },
      overall: {
        from: '2021-09-26',
        to: '2021-10-16',
        total: 9,
        completed: 8,
      },
    },
    activity: [
      {
        from: '2021-12-25',
        to: '2022-01-18',
        required: 3,
        items: [
          {
            state: 'COMPLETE',
            title: 'Responded to an Employer Inquiry on HI CAN',
            date: '2021-10-14',
            id: '1',
          },
          {
            state: 'COMPLETE',
            title: 'Scheduled a meeting with a Job Coach',
            date: '2021-10-14',
            id: '2',
          },
        ],
      },
      {
        from: '2021-10-10',
        to: '2021-10-16',
        required: 3,
        items: [
          {
            state: 'COMPLETE',
            title: 'Responded to an Employer Inquiry on HI CAN',
            date: '2021-10-14',
            id: '3',
          },
          {
            state: 'COMPLETE',
            title: 'Responded to an Employer Inquiry on HI CAN',
            date: '2021-10-14',
            id: '4',
          },
          {
            state: 'COMPLETE',
            title: 'Scheduled a meeting with a Job Coach',
            date: '2021-10-14',
            id: '5',
          },
        ],
      },
      {
        from: '2021-10-03',
        to: '2021-10-09',
        required: 3,
        items: [
          {
            state: 'COMPLETE',
            title: 'Responded to an Employer Inquiry on HI CAN',
            date: '2021-10-08',
            id: '6',
          },
          {
            state: 'COMPLETE',
            title: 'Applied for a job',
            date: '2021-10-05',
            id: '7',
          },
          {
            state: 'COMPLETE',
            title: 'Applied for a job',
            date: '2021-10-05',
            id: '8',
          },
        ],
      },
      {
        from: '2021-09-26',
        to: '2021-10-02',
        required: 3,
        items: [
          {
            state: 'COMPLETE',
            title: 'Applied for a job',
            date: '2021-09-27',
            id: '9',
          },
          {
            state: 'COMPLETE',
            title: 'Scheduled a meeting with a Job Coach',
            date: '2021-09-27',
            id: '10',
          },
          {
            state: 'COMPLETE',
            title: 'Uploaded your resume on HI CAN',
            date: '2021-09-27',
            id: '11',
          },
        ],
      },
    ],
  },
  reducers: {
    setGeneral: (state, action) => {
      state.general = action.payload;
    },
    setActivity: (state, action) => {
      state.activity = action.payload;
    },
    addActivity: (state, { payload: { idx, activity } }) => {
      state.activity[idx].items = [activity].concat(state.activity[idx].items);
      state.general.currentWeek.completed += 1;
      state.general.overall.completed += 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setGeneral, setActiveSoc, addActivity } = jobSearchSlice.actions;

export default jobSearchSlice.reducer;
