/* Styles */
import makeStyles from '@mui/styles/makeStyles';
import vars from 'styles/vars.module.scss';

/* Material UI and other UI Dependencies */
import { CircularProgress, Box } from '@mui/material';

const useStylesCircularProgress = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: '#eaeaf0',
  },
  top: {
    color: vars.actionBlueHover,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
}));

function CustomCircularProgress(props) {
  const classes = useStylesCircularProgress();
  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={120}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={120}
        thickness={4}
        {...props}
      />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

export default function Loading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CustomCircularProgress />
    </div>
  );
}

export const LoadingSmall = () => {
  return (
    <Box m={2} display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
};
