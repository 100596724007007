import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* Material UI and other UI Dependencies */
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CloseTabButton = ({ inlineStyle = {}, className = '' }) => {
  const history = useHistory();
  const intl = useIntl();
  const urlPrefix = getUrlPrefix(intl.locale);

  const handleClose = () => {
    if (history.length === 1) {
      window.open('', '_self').close();
    }
    history.push(`${urlPrefix}/`);
  };

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={handleClose}
      style={inlineStyle}
      className={className}
      endIcon={<CloseIcon />}
    >
      CLOSE
    </Button>
  );
};

CloseTabButton.propTypes = {
  inlineStyle: PropTypes.object,
  className: PropTypes.string,
};

export default CloseTabButton;
