import { useState, useRef } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

/* Styles */
import styles from 'styles/financial-help-careers-card.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* API */
import { postProfile } from 'lib/API';

/* UI Elements */
import { Typography, Box } from '@mui/material';
import foodIcon from 'assets/images/icon_food_help.svg';
import cashIcon from 'assets/images/icon_cash_help.svg';
import medicalIcon from 'assets/images/icon_medical_help.svg';
import financialHelpIcon from 'assets/images/icon_financial_benefits.svg';
import moreIcon from 'assets/images/more.svg';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';

import { useDispatch, useSelector } from 'react-redux';
import { setFinancialHelp } from 'app/slices/financialHelpSlice';

/* Menu */
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import { trackEvent } from 'lib/analytics';

FinancialHelpCareersCard.propTypes = {
  toQualifyTitle: PropTypes.string,
};

export default function FinancialHelpCareersCard({ toQualifyTitle }) {
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const intl = useIntl();
  const preferredName = useSelector((state) => state.app.preferredName);
  const title = intl.formatMessage({ id: 'careers.financialHelpCard.title' }, { name: preferredName });
  const dispatch = useDispatch();
  let history = useHistory();
  const urlPrefix = getUrlPrefix(intl.locale);

  const handleMenuClose = (event, action) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
    if (action === 'away') {
      return;
    }
    const newState = {
      already_enrolled: action === 'already_enrolled',
      not_interested: action === 'not_interested',
    };
    const eventDict = {
      already_enrolled: 'FINANCIAL_HELP_ENROLLED',
      not_interested: 'FINANCIAL_HELP_NOTINTERESTED',
    };
    trackEvent(eventDict[action]);
    postProfile({
      action: 'financial_help',
      ...newState,
    }).then(() => {
      dispatch(setFinancialHelp(newState));
    });
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  const handleMenuToggle = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  return (
    <article className={styles.card}>
      <div className={styles.top}>
        <div className={styles.imageSection}>
          <div className={styles.imageWrapper}>
            <img src={financialHelpIcon} alt={title} style={{ width: 77.9 }} />
          </div>
        </div>
        <div className={styles.textSection}>
          <span className={styles.iconButton} onClick={handleMenuToggle}>
            <img
              src={moreIcon}
              style={{ width: 24 }}
              className={styles.addIcon}
              alt={intl.formatMessage({ id: 'jobs.showMore' })}
            />
          </span>
          <Popper
            open={menuOpen}
            anchorEl={anchorRef.current}
            style={{ position: 'absolute', left: 'calc(100% - 176px)', top: 32 }}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper square>
                  <ClickAwayListener onClickAway={(e) => handleMenuClose(e, 'away')}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                      style={{ padding: '4px 0' }}
                    >
                      <MenuItem onClick={(e) => handleMenuClose(e, 'already_enrolled')}>
                        <span className={layoutStyles.link} style={{ fontSize: 12 }}>
                          {intl.formatMessage({ id: 'careers.financialHelpCard.menu.imEnrolled' })}
                        </span>
                      </MenuItem>
                      <MenuItem onClick={(e) => handleMenuClose(e, 'not_interested')}>
                        <span className={layoutStyles.link} style={{ fontSize: 12 }}>
                          {intl.formatMessage({ id: 'careers.financialHelpCard.menu.notInterested' })}
                        </span>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Typography variant="h4" component="h2" style={{ color: 'white' }} gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" style={{ color: 'white' }} gutterBottom>
            {intl.formatMessage({ id: 'careers.financialHelpCard.p' })}
          </Typography>
          <span className={styles.toQualifyTitle}>{toQualifyTitle}</span>
          <div
            className={cn({
              [styles.showMoreContainer]: true,
              [styles.drawerOpen]: open,
            })}
          >
            <Box mb={3} />
            <span
              className={layoutStyles.linkWhite}
              onClick={() => {
                trackEvent('FINANCIAL_HELP_SHOWMORE');
                setOpen(true);
              }}
            >
              + {intl.formatMessage({ id: 'careers.financialHelpCard.showMore' })}
            </span>
          </div>
        </div>
      </div>
      <div
        className={cn({
          [styles.learnMore]: true,
          [styles.open]: open,
        })}
      >
        <div />
        <div className={styles.textInternalSection}>
          <hr className={styles.expanderSeparator} />
          <Typography variant="h5" component="h2" style={{ color: 'white' }} gutterBottom>
            {intl.formatMessage({ id: 'financialHelp.subtitle' })}
          </Typography>
          <Box mb={1} />
          <div className={styles.squares}>
            <div className={styles.benefitCard}>
              <div className={styles.image}>
                <img
                  src={foodIcon}
                  style={{ width: 42.29 }}
                  alt={intl.formatMessage({ id: 'financialHelp.groceries.title' })}
                />
              </div>
              <Typography variant="h5" component="h2" style={{ color: 'white' }} gutterBottom>
                {intl.formatMessage({ id: 'careers.financialHelpCard.groceriesTitle' })}
              </Typography>
              <Typography variant="body2" style={{ color: 'white', maxWidth: 172 }} gutterBottom>
                {intl.formatMessage({ id: 'careers.financialHelpCard.groceriesText' })}
              </Typography>
            </div>
            <div className={styles.benefitCard}>
              <div className={styles.image}>
                <img
                  src={cashIcon}
                  style={{ width: 59.26 }}
                  alt={intl.formatMessage({ id: 'financialHelp.billsAndExpenses.title' })}
                />
              </div>
              <Typography variant="h5" component="h2" style={{ color: 'white' }} gutterBottom>
                {intl.formatMessage({ id: 'careers.financialHelpCard.cashTitle' })}
              </Typography>
              <Typography variant="body2" style={{ color: 'white', maxWidth: 172 }} gutterBottom>
                {intl.formatMessage({ id: 'careers.financialHelpCard.cashText' })}
              </Typography>
            </div>
            <div className={styles.benefitCard}>
              <div className={styles.image}>
                <img
                  src={medicalIcon}
                  style={{ width: 48 }}
                  alt={intl.formatMessage({ id: 'financialHelp.medicalCare.title' })}
                />
              </div>
              <Typography variant="h5" component="h2" style={{ color: 'white' }} gutterBottom>
                {intl.formatMessage({ id: 'careers.financialHelpCard.medicalCareTitle' })}
              </Typography>
              <Typography variant="body2" style={{ color: 'white', maxWidth: 172 }} gutterBottom>
                {intl.formatMessage({ id: 'careers.financialHelpCard.medicalCareText' })}
              </Typography>
            </div>
          </div>
          <Box mt={3} />
          <hr className={styles.expanderSeparator} />
          <Typography variant="h4" component="h2" style={{ color: 'white' }} gutterBottom>
            {intl.formatMessage({ id: 'careers.financialHelpCard.snapTitle' })}
          </Typography>
          <Typography variant="body2" style={{ color: 'white' }} gutterBottom>
            {intl.formatMessage({ id: 'careers.financialHelpCard.snapText' })}
          </Typography>
          <Box mt={2} />
          <div>
            <Button
              color="secondary"
              size="large"
              type="submit"
              variant="contained"
              href="https://pais-benefits.dhs.hawaii.gov/"
              rel="noopener noreferrer"
              target="_blank"
              endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
              style={{ marginRight: 10, marginBottom: 10 }}
              onClick={() => trackEvent('FINANCIAL_HELP_SNAP_APPLY')}
            >
              {intl.formatMessage({ id: 'careers.financialHelpCard.apply' })}
            </Button>
            <Button
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              href="https://humanservices.hawaii.gov/bessd/snap/"
              rel="noopener noreferrer"
              target="_blank"
              endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
              style={{ marginRight: 10, marginBottom: 10 }}
              onClick={() => trackEvent('FINANCIAL_HELP_SNAP_VIEW_MORE')}
            >
              {intl.formatMessage({ id: 'careers.financialHelpCard.learnMore' })}
            </Button>
            <Button
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                trackEvent('FINANCIAL_HELP_SNAP_VIEW_HELP');
                history.push(`${urlPrefix}/financial-help`);
              }}
              endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
              className={styles.financialHelpButton}
            >
              {intl.formatMessage({ id: 'careers.financialHelpCard.viewFinancialHelp' })}
            </Button>
          </div>
          <Box mt={3} />
          <hr className={styles.expanderSeparator} />
          <Typography variant="h4" component="h2" style={{ color: 'white' }} gutterBottom>
            {intl.formatMessage({ id: 'careers.financialHelpCard.tanfTitle' })}
          </Typography>
          <Typography variant="body2" style={{ color: 'white' }} gutterBottom>
            {intl.formatMessage({ id: 'careers.financialHelpCard.tanfText' })}
          </Typography>
          <Box mt={2} />
          <div>
            <Button
              color="secondary"
              size="large"
              type="submit"
              variant="contained"
              href="https://pais-benefits.dhs.hawaii.gov/"
              rel="noopener noreferrer"
              target="_blank"
              endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
              style={{ marginRight: 10, marginBottom: 10 }}
              onClick={() => trackEvent('FINANCIAL_HELP_TANF_APPLY')}
            >
              {intl.formatMessage({ id: 'careers.financialHelpCard.apply' })}
            </Button>
            <Button
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              href="https://humanservices.hawaii.gov/bessd/tanf/"
              rel="noopener noreferrer"
              target="_blank"
              endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
              style={{ marginRight: 10, marginBottom: 10 }}
              onClick={() => trackEvent('FINANCIAL_HELP_TANF_VIEW_MORE')}
            >
              {intl.formatMessage({ id: 'careers.financialHelpCard.learnMore' })}
            </Button>
            <Button
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                trackEvent('FINANCIAL_HELP_TANF_VIEW_HELP');
                history.push(`${urlPrefix}/financial-help`);
              }}
              endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
              className={styles.financialHelpButton}
            >
              {intl.formatMessage({ id: 'careers.financialHelpCard.viewFinancialHelp' })}
            </Button>
          </div>
          <Box mt={3} />
          <hr className={styles.expanderSeparator} />
          <Typography variant="h4" component="h2" style={{ color: 'white' }} gutterBottom>
            {intl.formatMessage({ id: 'careers.financialHelpCard.medicalTitle' })}
          </Typography>
          <Typography variant="body2" style={{ color: 'white' }} gutterBottom>
            {intl.formatMessage({ id: 'careers.financialHelpCard.medicalText' })}
          </Typography>
          <Box mt={2} />
          <div>
            <Button
              color="secondary"
              size="large"
              type="submit"
              variant="contained"
              href="https://medical.mybenefits.hawaii.gov/web/kolea/home-page/"
              rel="noopener noreferrer"
              target="_blank"
              endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
              style={{ marginRight: 10, marginBottom: 10 }}
              onClick={() => trackEvent('FINANCIAL_HELP_MEDQUEST_APPLY')}
            >
              {intl.formatMessage({ id: 'careers.financialHelpCard.apply' })}
            </Button>
            <Button
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              href="https://medquest.hawaii.gov/"
              rel="noopener noreferrer"
              target="_blank"
              endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
              style={{ marginRight: 10, marginBottom: 10 }}
              onClick={() => trackEvent('FINANCIAL_HELP_MEDQUEST_VIEW_MORE')}
            >
              {intl.formatMessage({ id: 'careers.financialHelpCard.learnMore' })}
            </Button>
            <Button
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                trackEvent('FINANCIAL_HELP_MEDQUEST_VIEW_HELP');
                history.push(`${urlPrefix}/financial-help`);
              }}
              endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
              className={styles.financialHelpButton}
            >
              {intl.formatMessage({ id: 'careers.financialHelpCard.viewFinancialHelp' })}
            </Button>
          </div>
          <Box mb={5} />
          <span
            className={layoutStyles.linkWhite}
            onClick={() => {
              trackEvent('FINANCIAL_HELP_SHOWLESS');
              setOpen(false);
            }}
          >
            - {intl.formatMessage({ id: 'careers.financialHelpCard.showLess' })}
          </span>
        </div>
      </div>
    </article>
  );
}
