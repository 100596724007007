import { createSlice } from '@reduxjs/toolkit';

export const financialHelpSlice = createSlice({
  name: 'financialHelp',
  initialState: {
    already_enrolled: false,
    not_interested: false,
  },
  reducers: {
    setFinancialHelp: (state, action) => {
      if ('already_enrolled' in action.payload) {
        state.already_enrolled = action.payload.already_enrolled;
      }
      if ('not_interested' in action.payload) {
        state.not_interested = action.payload.not_interested;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFinancialHelp } = financialHelpSlice.actions;

export default financialHelpSlice.reducer;
