import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { css } from '@emotion/css';

/* i18n */
import { useIntl } from 'react-intl';

/* Assets */
import foodIcon from 'assets/images/icon_food_help.svg';
import cashIcon from 'assets/images/icon_cash_help.svg';
import medicalIcon from 'assets/images/icon_medical_help.svg';
import childCareIcon from 'assets/images/child_care_blue.svg';

/* Styles */
import cssVars from 'styles/vars.module.scss';
import recStyles from 'styles/recommendations-list.module.scss';

/* Material UI and other UI Dependencies */
import { Box, Button, darken, lighten } from '@mui/material';
import Disclaimer from './Disclaimer';

const styles = {
  infoIcon: css(
    {
      position: 'relative',
      top: '1px',
      width: '15px',
      height: '15px',
      transition: 'all .35s',
    },
    { label: 'info-icon' }
  ),
  callOut: css(
    {
      background: lighten(cssVars.blue, 0.5),
      border: `solid 2px ${cssVars.navy}`,
      borderRadius: '4px',
      padding: '10px 20px 20px',
      '& span': {
        display: 'block',
      },
    },
    { label: 'call-out' }
  ),
  callOutTitle: css(
    {
      fontSize: '16px',
      '& img': {
        paddingRight: '4px',
      },
    },
    { label: 'call-out-title' }
  ),
  link: css({
    color: cssVars.actionBlue,
    '&:visited': {
      color: cssVars.actionBlue,
    },
    '&:hover': {
      color: darken(cssVars.actionBlue, 0.2),
    },
  }),
  program: css(
    {
      display: 'flex',
      alignItems: 'center',
      '&:not(:last-child)': {
        marginBottom: 12,
      },
    },
    { label: 'program' }
  ),
  programIcon: css({
    width: 40,
    marginRight: 16,
  }),
  programIconSmall: css({
    width: 36,
    marginLeft: 2,
    marginRight: 18,
  }),
};

const BenefitsFinderLanding = ({ setCurrentStep, trackEvent }) => {
  const intl = useIntl();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  return (
    <div className={recStyles.content}>
      <p>{intl.formatMessage({ id: 'app.benefitsFinder.landing.introduction1' })}</p>
      <p>{intl.formatMessage({ id: 'app.benefitsFinder.landing.introduction2' })}</p>
      <b>{intl.formatMessage({ id: 'app.benefitsFinder.landing.introduction3' })}</b>

      <Box m={4} />
      <div className={styles.callOut}>
        <h2 className={styles.callOutTitle}>{intl.formatMessage({ id: 'app.benefitsFinder.landing.CTA' })}</h2>
        <Button
          color="primary"
          size="large"
          variant="contained"
          type="button"
          onClick={() => {
            trackEvent('BF_Start');
            setCurrentStep(1);
          }}
        >
          {intl.formatMessage({ id: 'app.benefitsFinder.start' })}
        </Button>
      </div>
      <Box mb={4} />
      <Box pl={1} mb={2}>
        <h2 className={styles.callOutTitle}>{intl.formatMessage({ id: 'app.benefitsFinder.callOutTitle' })}</h2>
        <div className={styles.program}>
          <img src={medicalIcon} className={styles.programIconSmall} />
          <div>
            {intl.formatMessage(
              { id: 'app.benefitsFinder.questText' },
              {
                link: (
                  <a
                    className={styles.link}
                    href="https://medquest.hawaii.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackEvent('BF_Landing_QUEST')}
                  >
                    QUEST Integration
                  </a>
                ),
              }
            )}
          </div>
        </div>
        <div className={styles.program}>
          <img
            src={foodIcon}
            className={styles.programIconSmall}
            style={{ filter: 'sepia(100%) saturate(300%) brightness(70%) hue-rotate(180deg)' }}
          />
          <div>
            {intl.formatMessage(
              { id: 'app.benefitsFinder.snapText' },
              {
                link: (
                  <a
                    className={styles.link}
                    href="https://humanservices.hawaii.gov/bessd/snap/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackEvent('BF_Landing_SNAP')}
                  >
                    SNAP
                  </a>
                ),
              }
            )}
          </div>
        </div>
        <div className={styles.program}>
          <img src={cashIcon} className={styles.programIcon} />
          <div>
            {intl.formatMessage(
              { id: 'app.benefitsFinder.tanfText' },
              {
                tanf: (
                  <a
                    className={styles.link}
                    href="https://humanservices.hawaii.gov/bessd/tanf/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackEvent('BF_Landing_TANF')}
                  >
                    TANF
                  </a>
                ),
                aabd: (
                  <a
                    className={styles.link}
                    href="https://humanservices.hawaii.gov/bessd/aabd/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackEvent('BF_Landing_AABD')}
                  >
                    AABD
                  </a>
                ),
                ga: (
                  <a
                    className={styles.link}
                    href="https://humanservices.hawaii.gov/bessd/ga/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackEvent('BF_Landing_GA')}
                  >
                    GA
                  </a>
                ),
              }
            )}
          </div>
        </div>
        <div className={styles.program}>
          <img src={childCareIcon} className={styles.programIcon} />
          {intl.formatMessage(
            { id: 'app.benefitsFinder.childCareText' },
            {
              link: (
                <a
                  className={styles.link}
                  href="https://humanservices.hawaii.gov/bessd/ccch-subsidies/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => trackEvent('BF_Landing_ChildCare')}
                >
                  {intl.formatMessage({ id: 'app.benefitsFinder.childCareLink' })}
                </a>
              ),
            }
          )}
        </div>
      </Box>
      <Box m={4} />
      <Disclaimer />
    </div>
  );
};

BenefitsFinderLanding.propTypes = {
  setCurrentStep: PropTypes.func,
  trackEvent: PropTypes.func,
};

export default BenefitsFinderLanding;
