import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

/* Styles */
import styles from 'styles/layout.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* Browser */
import { isIE } from 'react-device-detect';

const Layout = ({ children, locale, loggedIn, signOut }) => {
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname.split('/').reverse()[0]);

  const intl = useIntl();
  const productName = process.env.REACT_APP_PRODUCT_NAME;
  const locality = process.env.REACT_APP_LOCALITY;
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1;

  useEffect(() => {
    setActivePath(location.pathname.split('/').reverse()[0]);
  }, [location.pathname]);

  return (
    <div className={styles.layout}>
      <div>
        {isIE && <div className={styles.browserBanner}>{intl.formatMessage({ id: 'browsers.ie.banner' })}</div>}
        {environment !== 'prod' && (
          <div className={styles.envBanner}>
            {intl.formatMessage({ id: 'app.envBanner' }, { productName, environment: environment, locality: locality })}
          </div>
        )}
        {/*isSafari && <p className="safari-banner" style={{textAlign: 'center', padding: '12px 16px', backgroundColor: '#EB5152', color: 'white', marginBlockEnd: 0, marginBlockStart: 0}}>
          {intl.formatMessage({id: 'app.safariBanner'})}
        </p>*/}
        <Header locale={locale} loggedIn={loggedIn} signOut={signOut} />
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
