import PropTypes from 'prop-types';
import { useState } from 'react';
import { css } from '@emotion/css';

/* Assets */
import info from 'assets/images/Info_Cards.svg';

/* Styles */
import cssVars from 'styles/vars.module.scss';

/* Material UI and other UI Dependencies */
import { lighten, Collapse } from '@mui/material';

const styles = {
  infoIcon: css(
    {
      position: 'absolute',
      top: '24px',
      left: '18px',
      width: '15px',
      height: '15px',
      transition: 'all .35s',
    },
    { label: 'info-icon' }
  ),
  callOut: css(
    {
      background: lighten(cssVars.blue, 0.5),
      border: `solid 2px ${cssVars.navy}`,
      borderRadius: '4px',
      padding: '10px 20px 8px 40px',
      position: 'relative',
      '& span': {
        paddingBottom: '12px',
        display: 'block',
      },
    },
    { label: 'call-out' }
  ),
  callOutTitle: css(
    {
      fontSize: '16px',
      cursor: 'pointer',
      '& img': {
        paddingRight: '4px',
      },
    },
    { label: 'call-out-title' }
  ),
};

const Collapsible = ({ title, text }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={styles.callOut}>
      <h2 className={styles.callOutTitle} onClick={() => setIsOpen(!isOpen)}>
        <img alt="Info" src={info} className={styles.infoIcon} />
        {title}
      </h2>
      <Collapse in={isOpen}>
        <span>{text}</span>
      </Collapse>
    </div>
  );
};

Collapsible.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Collapsible;
