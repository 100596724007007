/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import stepperStyles from 'styles/stepper.module.scss';
import cn from 'classnames';

/* Material UI and other UI Dependencies */
import CheckIcon from '@mui/icons-material/Check';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';

/* UI Components */

function StepIcon(props) {
  const { active, completed } = props;

  return (
    <div
      className={cn(stepperStyles.root, {
        [stepperStyles.active]: active,
        [stepperStyles.completed]: completed,
      })}
    >
      {completed && <CheckIcon />}
    </div>
  );
}

const OnboardingSteps = ({ activeStep }) => {
  const intl = useIntl();

  const steps = [
    intl.formatMessage({ id: 'onboarding.steps.step1', description: 'string' }),
    intl.formatMessage({ id: 'onboarding.steps.step2', description: 'string' }),
    intl.formatMessage({ id: 'onboarding.steps.step3', description: 'string' }),
  ];

  return (
    <div className={stepperStyles.stepperContainer__Onboarding}>
      <Stepper
        classes={{
          root: stepperStyles.onboardingRoot,
        }}
        activeStep={activeStep}
        connector={
          <StepConnector
            classes={{
              root: stepperStyles.connectorRoot,
              line: stepperStyles.connectorLineThin,
            }}
          />
        }
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={StepIcon}
              classes={{
                label: stepperStyles.stepLabelOnboarding,
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default OnboardingSteps;
