import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { propOr } from 'ramda';

/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useSelector } from 'react-redux';

/* Hooks */
import useBenefitsDisplayLogic from 'hooks/useBenefitsDisplayLogic';

/* Styles */
import recStyles from 'styles/recommendations-list.module.scss';

/* Material UI and other UI Dependencies */
import { Box, Button } from '@mui/material';
import Collapsible from './SurveyComponents/Collapsible';
import SingleChoiceQuestion from './SurveyComponents/SingleChoiceQuestion';
import NumericQuestion from './SurveyComponents/NumericQuestion';
import MultiSelectQuestion from './SurveyComponents/MultiSelectQuestion';

const styles = {
  header: css({
    fontSize: '24px',
    margin: '4px 0 4px',
  }),
};

const BenefitsFinderSection2 = ({ setCurrentStep, currentStep, trackEvent }) => {
  const intl = useIntl();
  const [touched, setTouched] = useState(false);

  const benefitsResults = useSelector((state) => state.benefitsFinder.results);
  const supportInterestedInValue = propOr([], 'support_interested_in', benefitsResults);

  const liveInHawaiiErrors = !propOr('', 'live_in_hawaii', benefitsResults)
    ? 'app.benefitsFinder.validation.selectAndOption'
    : '';
  const [manyChildrenErrors, setManyChildrenErrors] = useState('');
  const youOrAnyOver60Errors = propOr('', 'you_or_anyone_over_60', benefitsResults)
    ? ''
    : 'app.benefitsFinder.validation.selectAndOption';
  const [many6164Errors, set6164Errors] = useState('');
  const youOrAnyBlindErrors = propOr('', 'you_or_anyone_blind_disabled', benefitsResults)
    ? ''
    : 'app.benefitsFinder.validation.selectAndOption';
  const youOrAnyGt65MedicareErrors = propOr('', 'you_or_anyone_gt65_medicare_long_term', benefitsResults)
    ? ''
    : 'app.benefitsFinder.validation.selectAndOption';
  const youOrAny1860Errors = propOr('', 'you_or_anyone_18_60', benefitsResults)
    ? ''
    : 'app.benefitsFinder.validation.selectAndOption';
  const [lte21Errors, setLte21Errors] = useState('');
  const [lte18Errors, setLte18Errors] = useState('');
  const youAnyonePregnantErrors = propOr('', 'you_or_anyone_pregnant', benefitsResults)
    ? ''
    : 'app.benefitsFinder.validation.selectAndOption';
  const youAnyone9MonthsErrors =
    propOr('', 'you_or_anyone_pregnant', benefitsResults) === 'yes' &&
    !propOr('', 'you_or_anyone_9th_month', benefitsResults)
      ? 'app.benefitsFinder.validation.selectAndOption'
      : '';

  const [allApplyLte18Errors, setAllApplyLte18Errors] = useState('');

  const manyChildrenAdultsValue = propOr('', 'how_many_children', benefitsResults);
  const sixtyOne64Value = propOr('', 'how_many_people_61_64', benefitsResults);
  const lte21Value = propOr('', 'how_many_people_lte_21', benefitsResults);
  const lte18Value = propOr('', 'how_many_children_lte_18', benefitsResults);
  const allApplyLte18Value = propOr([], 'all_apply_for_18_or_younger', benefitsResults);

  const {
    liveInHawaiiDisplay,
    blindDisabledDisplay,
    youAnyoneGt65Display,
    manyChildrenAdultsDisplay,
    youAnyoneGt60Display,
    sixtyOne64Display,
    lte21Display,
    youOrAny1860Display,
    lte18Display,
    youAnyonePregnantDisplay,
    youAnyone9MonthsDisplay,
  } = useBenefitsDisplayLogic();

  const submit = () => {
    setTouched(true);
    if (
      (!liveInHawaiiErrors || !liveInHawaiiDisplay) &&
      (!manyChildrenErrors || !manyChildrenAdultsDisplay) &&
      (!youOrAnyOver60Errors || !youAnyoneGt60Display) &&
      (!many6164Errors || propOr([], 'you_or_anyone_over_60', benefitsResults) === 'no' || !sixtyOne64Display) &&
      (!youOrAnyBlindErrors || !blindDisabledDisplay) &&
      (!youOrAnyGt65MedicareErrors || !youAnyoneGt65Display) &&
      (!youOrAny1860Errors || !youOrAny1860Display) &&
      (!lte21Errors || !lte21Display) &&
      (!lte18Errors || !lte18Display) &&
      (!youAnyonePregnantErrors || !youAnyonePregnantDisplay) &&
      (!youAnyone9MonthsErrors ||
        !youAnyone9MonthsDisplay ||
        propOr('', 'you_or_anyone_pregnant', benefitsResults) === 'no')
    ) {
      if (
        (supportInterestedInValue.length === 1 &&
          ((supportInterestedInValue.includes('child_care') && lte18Value === '0') ||
            supportInterestedInValue.includes('quest') ||
            supportInterestedInValue.includes('snap'))) ||
        (supportInterestedInValue.length === 2 &&
          supportInterestedInValue.includes('quest') &&
          supportInterestedInValue.includes('snap'))
      ) {
        setCurrentStep(4);
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  useEffect(() => {
    //Validation
    const numericValue = parseInt(manyChildrenAdultsValue, 10);
    if (!numericValue && numericValue !== 0) {
      setManyChildrenErrors('app.benefitsFinder.validation.enterANumber');
      return;
    }
    if (numericValue < 1) {
      setManyChildrenErrors('app.benefitsFinder.validation.gt0');
      return;
    }
    if (numericValue > 100) {
      setManyChildrenErrors('app.benefitsFinder.validation.lt100');
      return;
    }
    setManyChildrenErrors('');
  }, [manyChildrenAdultsValue]);

  useEffect(() => {
    const numericValue = parseInt(sixtyOne64Value, 10);
    if (!numericValue && numericValue !== 0) {
      set6164Errors('app.benefitsFinder.validation.enterNumberOver60');
      return;
    }
    if (numericValue < 1) {
      set6164Errors('app.benefitsFinder.validation.enterNumberOver60');
      return;
    }
    if (numericValue > 100) {
      set6164Errors('app.benefitsFinder.validation.accurateNumber');
      return;
    }
    set6164Errors('');
  }, [sixtyOne64Value]);

  useEffect(() => {
    const numericValue = parseInt(lte18Value, 10);
    if (!numericValue && numericValue !== 0) {
      setLte18Errors('app.benefitsFinder.validation.enterANumber');
      return;
    }
    if (numericValue > 100) {
      setLte18Errors('app.benefitsFinder.validation.accurateNumber');
      return;
    }
    setLte18Errors('');
  }, [lte18Value]);

  useEffect(() => {
    //Validation
    const numericValue = parseInt(lte21Value, 10);
    const manyChildrenNumeric = parseInt(manyChildrenAdultsValue, 10);
    if (!numericValue && numericValue !== 0) {
      setLte21Errors('app.benefitsFinder.validation.enterANumber');
      return;
    }
    if (numericValue > 100) {
      setLte21Errors('app.benefitsFinder.validation.accurateNumber');
      return;
    }
    if (numericValue < 0 || numericValue > manyChildrenNumeric) {
      setLte21Errors('app.benefitsFinder.validation.gtTotalNumber');
      return;
    }
    setLte21Errors('');
  }, [lte21Value]);

  useEffect(() => {
    //Validation
    if (!allApplyLte18Value.length) {
      setAllApplyLte18Errors('app.benefitsFinder.validation.selectAndOption');
      return;
    }
    if (allApplyLte18Value.length > 4) {
      setAllApplyLte18Errors('Max 4');
      return;
    }
    if (allApplyLte18Value.length > 1 && allApplyLte18Value.includes('none')) {
      setAllApplyLte18Errors('app.benefitsFinder.validation.invalidSelection');
      return;
    }
    setAllApplyLte18Errors('');
  }, [JSON.stringify(allApplyLte18Value)]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  return (
    <div className={recStyles.content}>
      <span>{intl.formatMessage({ id: 'app.benefitsFinder.section2.label' })}</span>
      <h2 className={styles.header}>{intl.formatMessage({ id: 'app.benefitsFinder.section2.header' })}</h2>
      <Box m={3} />
      <Collapsible
        title={intl.formatMessage({ id: 'app.benefitsFinder.section2.collapsibleTitle' })}
        text={
          <>
            <span>{intl.formatMessage({ id: 'app.benefitsFinder.section2.collapsibleText1' })}</span>
            <span>{intl.formatMessage({ id: 'app.benefitsFinder.section2.collapsibleText2' })}</span>
          </>
        }
      />
      <Box m={6} />
      {liveInHawaiiDisplay ? (
        <>
          <SingleChoiceQuestion
            name="live_in_hawaii"
            touched={touched}
            error={liveInHawaiiErrors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.title' })}
            options={[
              { value: 'yes', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option1' }) },
              { value: 'no', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option2' }) },
            ]}
          />
          <Box m={4} />
        </>
      ) : null}
      {manyChildrenAdultsDisplay ? (
        <>
          <NumericQuestion
            name="how_many_children"
            touched={touched}
            error={manyChildrenErrors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section2.question2.title' })}
          />
          <Box m={4} />
        </>
      ) : null}
      {youAnyoneGt60Display ? (
        <>
          <SingleChoiceQuestion
            name="you_or_anyone_over_60"
            touched={touched}
            error={youOrAnyOver60Errors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section2.question3.title' })}
            options={[
              { value: 'yes', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option1' }) },
              { value: 'no', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option2' }) },
              { value: 'not_sure', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question3.option3' }) },
            ]}
          />
          <Box m={4} />
        </>
      ) : null}
      {propOr([], 'you_or_anyone_over_60', benefitsResults) === 'yes' && sixtyOne64Display ? (
        <>
          <NumericQuestion
            name="how_many_people_61_64"
            touched={touched}
            error={many6164Errors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section2.question4.title' })}
          />
          <Box m={4} />
        </>
      ) : null}
      {blindDisabledDisplay ? (
        <>
          <SingleChoiceQuestion
            name="you_or_anyone_blind_disabled"
            touched={touched}
            error={youOrAnyBlindErrors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section2.question5.title' })}
            options={[
              { value: 'yes', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option1' }) },
              { value: 'no', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option2' }) },
              { value: 'not_sure', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question3.option3' }) },
            ]}
          />
          <Box m={4} />
        </>
      ) : null}
      {youAnyoneGt65Display ? (
        <>
          <SingleChoiceQuestion
            name="you_or_anyone_gt65_medicare_long_term"
            touched={touched}
            error={youOrAnyGt65MedicareErrors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section2.question6.title' })}
            options={[
              { value: 'yes', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option1' }) },
              { value: 'no', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option2' }) },
              { value: 'not_sure', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question3.option3' }) },
            ]}
          />
          <Box m={4} />
        </>
      ) : null}
      {youOrAny1860Display ? (
        <>
          <SingleChoiceQuestion
            name="you_or_anyone_18_60"
            touched={touched}
            error={youOrAny1860Errors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section2.question7.title' })}
            options={[
              { value: 'yes', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option1' }) },
              { value: 'no', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option2' }) },
              { value: 'not_sure', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question3.option3' }) },
            ]}
          />
          <Box m={4} />
        </>
      ) : null}
      {lte21Display ? (
        <>
          <NumericQuestion
            name="how_many_people_lte_21"
            touched={touched}
            error={lte21Errors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section2.question8.title' })}
          />
          <Box m={4} />
        </>
      ) : null}
      {lte18Display ? (
        <>
          <NumericQuestion
            name="how_many_children_lte_18"
            touched={touched}
            error={lte18Errors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section2.question9.title' })}
          />
          <Box m={4} />
        </>
      ) : null}
      {lte18Display && lte18Value && parseInt(lte18Value, 10) > 0 ? (
        <>
          <MultiSelectQuestion
            name="all_apply_for_18_or_younger"
            touched={touched}
            error={allApplyLte18Errors}
            title={intl.formatMessage(
              {
                id:
                  lte18Value === '1'
                    ? 'app.benefitsFinder.section2.question10.titleSingular'
                    : 'app.benefitsFinder.section2.question10.title',
              },
              { response: lte18Value }
            )}
            options={[
              {
                value: 'parent_or_relative_caring',
                text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option1' }),
              },
              {
                value: 'child_us_citizen_requires_child_care',
                text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option2' }),
              },
              {
                value: 'child_under_13_requires_child_care',
                text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option3' }),
              },
              {
                value: 'child_between_13_18_requires_child_care',
                text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option4' }),
              },
              {
                value: 'you_are_under_18',
                text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option5' }),
                tooltipTitle: intl.formatMessage({ id: 'app.benefitsFinder.section2.tooltipTitle' }),
                tooltipBody: intl.formatMessage({ id: 'app.benefitsFinder.section2.tooltipBody' }),
              },
              { value: 'none', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question10.option6' }) },
            ]}
          />
          <Box m={4} />
        </>
      ) : null}
      {youAnyonePregnantDisplay ? (
        <>
          <SingleChoiceQuestion
            name="you_or_anyone_pregnant"
            touched={touched}
            error={youAnyonePregnantErrors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section2.question11.title' })}
            options={[
              { value: 'yes', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option1' }) },
              { value: 'no', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option2' }) },
              { value: 'not_sure', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question3.option3' }) },
            ]}
          />
          <Box m={4} />
        </>
      ) : null}
      {youAnyonePregnantDisplay && propOr('', 'you_or_anyone_pregnant', benefitsResults) === 'yes' ? (
        <>
          <SingleChoiceQuestion
            name="you_or_anyone_9th_month"
            touched={touched}
            error={youAnyone9MonthsErrors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section2.question12.title' })}
            options={[
              { value: 'yes', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option1' }) },
              { value: 'no', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option2' }) },
              { value: 'not_sure', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question3.option3' }) },
            ]}
          />
          <Box m={4} />
        </>
      ) : null}
      <Box display="flex" justifyContent="space-between">
        <Button
          color="primary"
          size="large"
          variant="contained"
          type="button"
          onClick={() => {
            trackEvent('BF_Section2_Back');
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< '}
          {intl.formatMessage({ id: 'forms.onboarding.back' })}
        </Button>
        <Button
          color="primary"
          size="large"
          variant="contained"
          type="button"
          onClick={() => {
            trackEvent('BF_Section2_Next');
            submit();
          }}
        >
          {intl.formatMessage({ id: 'forms.onboarding.next' })}
          {' >'}
        </Button>
      </Box>
    </div>
  );
};

BenefitsFinderSection2.propTypes = {
  setCurrentStep: PropTypes.func,
  currentStep: PropTypes.number,
  trackEvent: PropTypes.func,
};

export default BenefitsFinderSection2;
