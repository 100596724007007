import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';

/* Assets */
import backToTop from 'assets/images/Back_To_Top.svg';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const ScrollToTopButton = ({ toTopEvent = '' }) => {
  const intl = useIntl();

  const scrollTop = (e) => {
    e.preventDefault();
    trackEvent(toTopEvent);
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <span onClick={scrollTop} className={styles.backToTop}>
      {intl.formatMessage({ id: 'recommendations.scrollTop', description: 'string' })}
      <img
        className={styles.backToTopImg}
        src={backToTop}
        alt={intl.formatMessage({ id: 'recommendations.scrollTop', description: 'string' })}
      />
    </span>
  );
};

ScrollToTopButton.propTypes = {
  toTopEvent: PropTypes.string,
};

export default ScrollToTopButton;

export const ScrollToTopOnPathChange = withRouter(({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
});
