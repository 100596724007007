import PropTypes from 'prop-types';
import { css } from '@emotion/css';

/* i18n */
import { useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import { LinearProgress } from '@mui/material';

const styles = {
  progressContainer: css(
    {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gap: '24px',
      padding: '30px 0 20px',
      '@media screen and (min-width: 768px)': {
        padding: '30px 20px 20px',
      },
      '@media screen and (max-width: 767px)': {
        gap: '12px',
      },
    },
    { label: 'progress-container' }
  ),
  barRoot: css(
    {
      height: '10px !important',
    },
    { label: 'bar-root' }
  ),
  stepContainer: css({ textAlign: 'center' }, { label: 'step-container' }),
  stepLabel: css(
    {
      fontSize: '14px',
      fontWeight: '700',
      position: 'relative',
      top: '4px',
      '@media screen and (max-width: 767px)': {
        display: 'none',
      },
    },
    { label: 'step-label' }
  ),
};

export default function Progress({ currentStep }) {
  const intl = useIntl();
  return (
    <div className={styles.progressContainer}>
      <div className={styles.stepContainer}>
        <LinearProgress variant="determinate" value={currentStep > 0 ? 100 : 50} classes={{ root: styles.barRoot }} />
        <span className={styles.stepLabel}>
          {intl.formatMessage({ id: 'app.benefitsFinder.progress.introduction' })}
        </span>
      </div>
      <div className={styles.stepContainer}>
        <LinearProgress
          variant="determinate"
          value={currentStep > 1 ? 100 : currentStep === 1 ? 50 : -2}
          classes={{ root: styles.barRoot }}
        />
        <span className={styles.stepLabel}>{intl.formatMessage({ id: 'app.benefitsFinder.progress.section1' })}</span>
      </div>
      <div className={styles.stepContainer}>
        <LinearProgress
          variant="determinate"
          value={currentStep > 2 ? 100 : currentStep === 2 ? 50 : -2}
          classes={{ root: styles.barRoot }}
        />
        <span className={styles.stepLabel}>{intl.formatMessage({ id: 'app.benefitsFinder.progress.section2' })}</span>
      </div>
      <div className={styles.stepContainer}>
        <LinearProgress
          variant="determinate"
          value={currentStep > 3 ? 100 : currentStep === 3 ? 50 : -2}
          classes={{ root: styles.barRoot }}
        />
        <span className={styles.stepLabel}>{intl.formatMessage({ id: 'app.benefitsFinder.progress.section3' })}</span>
      </div>
      <div className={styles.stepContainer}>
        <LinearProgress variant="determinate" value={currentStep === 4 ? 100 : -2} classes={{ root: styles.barRoot }} />
        <span className={styles.stepLabel}>{intl.formatMessage({ id: 'app.benefitsFinder.progress.results' })}</span>
      </div>
    </div>
  );
}

Progress.propTypes = {
  currentStep: PropTypes.number,
};
