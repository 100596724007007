import { createSlice } from '@reduxjs/toolkit';

export const skillsSlice = createSlice({
  name: 'skills',
  initialState: {
    skillsDict: {},
    skills: [],
    userSkills: [],
  },
  reducers: {
    setSkills: (state, { payload }) => {
      state.skills = payload;
    },
    setUserSkills: (state, { payload }) => {
      state.userSkills = payload;
    },
    setSkillsDict: (state, { payload }) => {
      state.skillsDict = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSkills, setUserSkills, setSkillsDict } = skillsSlice.actions;

export default skillsSlice.reducer;
