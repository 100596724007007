import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { propOr } from 'ramda';

/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useSelector } from 'react-redux';

/* Hooks */
import useBenefitsDisplayLogic from 'hooks/useBenefitsDisplayLogic';

/* Styles */
import recStyles from 'styles/recommendations-list.module.scss';

/* Material UI and other UI Dependencies */
import { Box, Button } from '@mui/material';
import Collapsible from './SurveyComponents/Collapsible';
import SingleChoiceQuestion from './SurveyComponents/SingleChoiceQuestion';
import MultiSelectQuestion from './SurveyComponents/MultiSelectQuestion';

const styles = {
  header: css({
    fontSize: '24px',
    margin: '4px 0 4px',
  }),
};

const BenefitsFinderSection3 = ({ setCurrentStep, currentStep, trackEvent }) => {
  const intl = useIntl();
  const [touched, setTouched] = useState(false);

  const benefitsResults = useSelector((state) => state.benefitsFinder.results);
  const whyArentYouWorkingValue = propOr([], 'why_arent_you_working', benefitsResults);

  const [q2Errors, setQ2Errors] = useState('');
  const currentlyWorkingErrors = propOr('', 'currently_working', benefitsResults)
    ? ''
    : 'app.benefitsFinder.validation.selectAndOption';
  const receivingChildProtectiveServicesErrors = propOr('', 'receiving_child_protective_services', benefitsResults)
    ? ''
    : 'app.benefitsFinder.validation.selectAndOption';

  const submit = () => {
    setTouched(true);
    if (
      (!currentlyWorkingErrors || !currentlyWorkingDisplay) &&
      (!q2Errors || propOr('', 'currently_working', benefitsResults) === 'yes') &&
      (!receivingChildProtectiveServicesErrors || !receivingChildProtectiveDisplay)
    ) {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    //Validation
    if (!whyArentYouWorkingValue.length) {
      setQ2Errors('app.benefitsFinder.validation.selectAndOption');
      return;
    }
    if (whyArentYouWorkingValue.length > 1 && whyArentYouWorkingValue.includes('prefer_not_to_say')) {
      setQ2Errors('app.benefitsFinder.validation.invalidSelection');
      return;
    }
    setQ2Errors('');
  }, [JSON.stringify(whyArentYouWorkingValue)]);

  // Display conditions
  const { currentlyWorkingDisplay, receivingChildProtectiveDisplay } = useBenefitsDisplayLogic();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  return (
    <div className={recStyles.content}>
      <span>{intl.formatMessage({ id: 'app.benefitsFinder.section3.label' })}</span>
      <h2 className={styles.header}>{intl.formatMessage({ id: 'app.benefitsFinder.section3.header' })}</h2>
      <Box m={3} />
      <Collapsible
        title={intl.formatMessage({ id: 'app.benefitsFinder.section3.collapsibleTitle' })}
        text={intl.formatMessage({ id: 'app.benefitsFinder.section3.collapsibleText' })}
      />
      <Box m={6} />
      {currentlyWorkingDisplay ? (
        <>
          <SingleChoiceQuestion
            name="currently_working"
            touched={touched}
            error={currentlyWorkingErrors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section3.question1.title' })}
            options={[
              { value: 'yes', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option1' }) },
              { value: 'no', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option2' }) },
            ]}
          />
          <Box m={4} />
        </>
      ) : null}
      {currentlyWorkingDisplay && propOr('', 'currently_working', benefitsResults) === 'no' ? (
        <>
          <MultiSelectQuestion
            name="why_arent_you_working"
            touched={touched}
            error={q2Errors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section3.question2.title' })}
            options={[
              {
                value: 'physical_psycological_disability',
                text: intl.formatMessage({ id: 'app.benefitsFinder.section3.question2.option1' }),
              },
              {
                value: 'attending_training_or_education',
                text: intl.formatMessage({ id: 'app.benefitsFinder.section3.question2.option2' }),
              },
              {
                value: 'prefer_not_to_say',
                text: intl.formatMessage({ id: 'app.benefitsFinder.section3.question2.option3' }),
              },
            ]}
          />
          <Box m={4} />
        </>
      ) : null}
      {receivingChildProtectiveDisplay ? (
        <>
          <SingleChoiceQuestion
            name="receiving_child_protective_services"
            touched={touched}
            error={receivingChildProtectiveServicesErrors}
            title={intl.formatMessage({ id: 'app.benefitsFinder.section3.question3.title' })}
            options={[
              { value: 'yes', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option1' }) },
              { value: 'no', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question1.option2' }) },
              { value: 'not_sure', text: intl.formatMessage({ id: 'app.benefitsFinder.section2.question3.option3' }) },
            ]}
          />
          <Box m={4} />
        </>
      ) : null}
      <Box display="flex" justifyContent="space-between">
        <Button
          color="primary"
          size="large"
          variant="contained"
          type="button"
          onClick={() => {
            trackEvent('BF_Section3_Back');
            setCurrentStep(currentStep - 1);
          }}
        >
          {'< '}
          {intl.formatMessage({ id: 'forms.onboarding.back' })}
        </Button>
        <Button
          color="primary"
          size="large"
          variant="contained"
          type="button"
          onClick={() => {
            trackEvent('BF_Section3_Next');
            submit();
          }}
        >
          {intl.formatMessage({ id: 'forms.onboarding.next' })}
          {' >'}
        </Button>
      </Box>
    </div>
  );
};

BenefitsFinderSection3.propTypes = {
  setCurrentStep: PropTypes.func,
  currentStep: PropTypes.number,
  trackEvent: PropTypes.func,
};

export default BenefitsFinderSection3;
