export function anyWordIsSubstring(haystack, needlesString) {
  const contains = (element) => element !== '' && haystack.toLowerCase().indexOf(element.toLowerCase()) > -1;
  return needlesString.split(' ').some(contains);
}

export const getTodayMmDdYyyy = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return yyyy + '-' + mm + '-' + dd;
};

export function preventSubmitOnEnter(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

export * from './src/lib/experience';
