import cn from 'classnames';
import { useState, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { REC_ENGINE_ROUTES } from 'lib/routes';
import PropTypes from 'prop-types';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* Styles */
import styles from 'styles/recommendations-header.module.scss';

/* Assets */
import thumbsUp from 'assets/images/heart_plus_FILL0.svg';
import thumbsDown from 'assets/images/heart_minus_FILL0.svg';
import chevronDown from 'assets/images/Chevron_Down.svg';
import chevronUp from 'assets/images/Chevron_Up.svg';
import chevronRight from 'assets/images/Chevron_Right.svg';
import chevronLeft from 'assets/images/Chevron_Left.svg';
import arrowRight from 'assets/images/Arrow_RightWhite.svg';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Material UI and other UI Dependencies */
import { Box, Typography, Container, useMediaQuery } from '@mui/material';
import { Edit as EditIcon, Notifications as NotificationsIcon } from '@mui/icons-material';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setActiveSoc } from 'app/slices/jobsSlice';
import { setOpenContactInfo } from 'app/slices/experienceSlice';

const RecommendationsHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const activePath = location.pathname.split('/').reverse()[0];

  const [showContent, setShowContent] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(false);

  /* Refs to get menu's scroll position */
  const itemsContainer = useRef(null);

  const intl = useIntl();
  const urlPrefix = getUrlPrefix(intl.locale);
  let history = useHistory();

  const preferredName = useSelector((state) => state.app.preferredName);
  const user_settings = useSelector((state) => state.app.user_settings);
  const worksearch_status = useSelector((state) => state.worksearch.status);

  const smallScreen = useMediaQuery('(max-width:599px)');
  const isSmall = useMediaQuery('(max-width:767px)');

  const handleHeroButtonClick = (path) => {
    if (path === 'job-search-activity') {
      trackEvent('HERO_JOBSEARCH_BUTTON');
    }
    if (path === 'financial-help') {
      trackEvent('HERO_FINANCIAL_BUTTON');
    }
    if (path !== activePath) {
      history.push(`${urlPrefix}/${path}`);
    }
  };

  const navScrollHandler = (e) => {
    const endOfScroll = e.target.scrollWidth - e.target.clientWidth;
    const currentPosition = e.target.scrollLeft;
    if (currentPosition === endOfScroll) {
      setShowRightArrow(false);
    } else {
      setShowRightArrow(true);
    }

    if (currentPosition === 0) {
      setShowLeftArrow(false);
    } else {
      setShowLeftArrow(true);
    }
  };

  const arrowRightClickHandler = () => {
    const endOfScroll = itemsContainer.current.scrollWidth - itemsContainer.current.clientWidth;
    const currentPosition = itemsContainer.current.scrollLeft;
    const hop = 90;
    if (currentPosition + hop <= endOfScroll) {
      itemsContainer.current.scrollLeft = currentPosition + hop;
    } else {
      itemsContainer.current.scrollLeft = endOfScroll;
    }
  };

  const arrowLeftClickHandler = () => {
    const currentPosition = itemsContainer.current.scrollLeft;
    const hop = 90;
    if (currentPosition - hop >= 0) {
      itemsContainer.current.scrollLeft = currentPosition - hop;
    } else {
      itemsContainer.current.scrollLeft = 0;
    }
  };
  const handleMenuItemClick = (page) => () => {
    const eventName = `HORIZONTAL_MENU_${page}`;
    trackEvent(eventName);
    if (page === 'JOBS') {
      dispatch(setActiveSoc(''));
    }
  };

  const handleShowExperience = () => {
    setShowContent(!showContent);
    const eventName = !showContent ? 'MOBILE_SHOWEXPERIENCE' : 'MOBILE_HIDEEXPERIENCE';
    trackEvent(eventName);
  };

  return (
    <>
      <div className={styles.header}>
        <Container maxWidth="lg">
          <div className={styles.header__content}>
            <Typography align="center" variant="h1" className={styles.headerTitle} gutterBottom>
              {preferredName
                ? intl.formatMessage({ id: 'recommendations.titlePersonalized' }, { name: preferredName })
                : intl.formatMessage({ id: 'recommendations.title' })}
            </Typography>
            <div
              className={cn({
                [styles.accordionContent]: true,
                [styles.accordionContent__visible]: showContent && smallScreen,
                [styles.accordionContent__hidden]: !showContent && smallScreen,
              })}
            >
              <Typography align="center">
                {intl.formatMessage({ id: 'recommendations.subtitleLine1' }, { state: "Hawai'i" })}
              </Typography>
              <Typography align="center" gutterBottom>
                <span>{intl.formatMessage({ id: 'recommendations.subtitleLine2' })}</span>
                <Box component="span" display="inline-block">
                  [<img alt="Thumbs Up" className={styles.thumbs} src={thumbsUp} />
                  <img alt="Thumbs Down" className={styles.thumbs} src={thumbsDown} />]
                </Box>
                <span>{intl.formatMessage({ id: 'recommendations.subtitleLine3' })}</span>
              </Typography>
              <HeaderExperienceLine activePath={activePath} />
            </div>
          </div>
          <div className={styles.showButton} onClick={handleShowExperience}>
            {intl.formatMessage({
              id: showContent ? 'recommendations.hideExperience' : 'recommendations.showExperience',
            })}
            <span>
              {' '}
              {showContent ? (
                <img alt="Hide content" className={styles.chevron} src={chevronUp} />
              ) : (
                <img alt="Show content" className={styles.chevron} src={chevronDown} />
              )}
            </span>
          </div>
          <div className={styles.extraButtons}>
            {user_settings.add_job_search_activity_hero_button ? (
              <div
                className={cn({
                  [styles.heroButton]: true,
                  [styles.heroButton__active]: activePath === 'job-search-activity',
                })}
                onClick={() => handleHeroButtonClick('job-search-activity')}
              >
                {user_settings.add_job_search_activity_hero_button_bell &&
                ((worksearch_status?.weekly_activities_required !== 0 &&
                  worksearch_status?.weekly_activities_required > worksearch_status?.weekly_activities_completed) ||
                  worksearch_status?.ssn_required) ? (
                  <span className={styles.bellIcon__container}>
                    <NotificationsIcon className={styles.bellIcon} />
                    <span className={styles.bellIcon__number}>
                      {worksearch_status?.ssn_required
                        ? 3
                        : worksearch_status?.weekly_activities_required -
                          worksearch_status?.weekly_activities_completed}
                    </span>
                  </span>
                ) : null}
                {intl.formatMessage({ id: 'recommendations.addActivity' })}
                {activePath !== 'job-search-activity' ? (
                  <img alt="Arrow" src={arrowRight} className={styles.arrowRight} />
                ) : null}
              </div>
            ) : null}
            {user_settings.view_benefits_for_job_seekers_button ? (
              <div
                className={cn({
                  [styles.heroButton]: true,
                  [styles.heroButton__active]: activePath === 'financial-help',
                })}
                onClick={() => handleHeroButtonClick('financial-help')}
              >
                {intl.formatMessage({ id: 'recommendations.viewBenefits' })}
                {activePath !== 'financial-help' ? (
                  <img alt="Arrow" src={arrowRight} className={styles.arrowRight} />
                ) : null}
              </div>
            ) : null}
          </div>
        </Container>
      </div>
      {isSmall ? null : (
        <nav className={styles.mainNav}>
          <span
            onClick={arrowLeftClickHandler}
            className={cn({
              [styles.chevronLink]: true,
              [styles.chevronLinkLeft]: true,
              [styles.hidden]: !showLeftArrow,
            })}
          >
            <img alt="Arrow left" src={chevronLeft} />
          </span>
          <span
            onClick={arrowRightClickHandler}
            className={cn({
              [styles.chevronLink]: true,
              [styles.chevronLinkRight]: true,
              [styles.hidden]: !showRightArrow,
            })}
          >
            <img alt="Arrow right" src={chevronRight} />
          </span>
          <Container maxWidth="lg">
            <div ref={itemsContainer} className={styles.mainNav__itemsContainer} onScroll={navScrollHandler}>
              <Link
                to={`${urlPrefix}/careers`}
                onClick={handleMenuItemClick('CAREERPATHS')}
                className={cn({
                  [styles.mainNav__item]: true,
                  [styles.mainNav__item__active]: activePath === 'careers',
                })}
              >
                <span
                  className={cn({
                    [styles.breakSpace]: intl.formatMessage({ id: 'layout.menu.careers' }).length > 12,
                  })}
                >
                  {intl.formatMessage({ id: 'layout.menu.careers' })}
                </span>
              </Link>
              <Link
                to={`${urlPrefix}/jobs`}
                onClick={handleMenuItemClick('JOBS')}
                className={cn({
                  [styles.mainNav__item]: true,
                  [styles.mainNav__item__active]: activePath === 'jobs',
                })}
              >
                <span
                  className={cn({
                    [styles.breakSpace]: intl.formatMessage({ id: 'layout.menu.jobs' }).length > 12,
                  })}
                >
                  {intl.formatMessage({ id: 'layout.menu.jobs' })}
                </span>
              </Link>
              <Link
                to={`${urlPrefix}/training`}
                onClick={handleMenuItemClick('TRAINING')}
                className={cn({
                  [styles.mainNav__item]: true,
                  [styles.mainNav__item__active]: activePath === 'training',
                })}
              >
                <span
                  className={cn({
                    [styles.breakSpace]: intl.formatMessage({ id: 'layout.menu.training' }).length > 12,
                  })}
                >
                  {intl.formatMessage({ id: 'layout.menu.training' })}
                </span>
              </Link>
              <Link
                to={`${urlPrefix}/experience`}
                onClick={handleMenuItemClick('SKILLSEXPERIENCE')}
                className={cn({
                  [styles.mainNav__item]: true,
                  [styles.mainNav__item__active]: activePath === 'experience',
                })}
              >
                <span
                  className={cn({
                    [styles.breakSpace]: intl.formatMessage({ id: 'layout.menu.experience' }).length > 12,
                  })}
                  dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'layout.menu.experience' }) }}
                />
              </Link>
              <Link
                to={`${urlPrefix}/ratings`}
                onClick={handleMenuItemClick('RATINGS')}
                className={cn({
                  [styles.mainNav__item]: true,
                  [styles.mainNav__item__active]: activePath === 'ratings',
                })}
              >
                <span
                  className={cn({
                    [styles.breakSpace]: intl.formatMessage({ id: 'layout.menu.savedItems' }).length > 12,
                  })}
                >
                  {intl.formatMessage({ id: 'layout.menu.savedItems' })}
                </span>
              </Link>
              {process.env.REACT_APP_JOB_COACH === 'true' && (
                <Link
                  to={`${urlPrefix}/coach`}
                  onClick={handleMenuItemClick('JOBCOACH')}
                  className={cn({
                    [styles.mainNav__item]: true,
                    [styles.mainNav__item__active]: activePath === 'coach',
                  })}
                >
                  <span
                    className={cn({
                      [styles.breakSpace]: intl.formatMessage({ id: 'layout.menu.coach' }).length > 12,
                    })}
                  >
                    {intl.formatMessage({ id: 'layout.menu.coach' })}
                  </span>
                </Link>
              )}
            </div>
          </Container>
        </nav>
      )}
    </>
  );
};

RecommendationsHeader.propTypes = {
  width: PropTypes.string,
};

const HeaderExperienceLine = ({ activePath }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const { recentJob, educationLevel } = useSelector((state) => state.experience.form.banner_text);

  const hasRecentJob = !!recentJob.title;
  const hasEducation = !!educationLevel;
  const displayExperienceLine = hasRecentJob || hasEducation;
  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  const handleEditClick = () => {
    trackEvent('HERO_EDIT');
    dispatch(setOpenContactInfo(true));
  };
  if (displayExperienceLine) {
    return (
      <div className={styles.experienceTag__container}>
        {hasRecentJob && (
          <>
            <div className={styles.experienceTag}>
              <span key="job-title">{recentJob.title}</span>
            </div>
            {recentJob.month !== -1 && (
              <div className={styles.experienceTag}>
                <span key="job-since">
                  {intl.formatMessage({ id: 'experience.heroBanner.since', description: 'string' })}
                </span>
                <span key="job-date">
                  {intl.formatMessage({ id: `jobSearchActivity.${months[recentJob.month]}` })} {recentJob.year}
                </span>
              </div>
            )}
          </>
        )}
        {hasEducation && (
          <div className={styles.experienceTag}>
            <span key="education-level">{intl.formatMessage({ id: educationLevel })}</span>
          </div>
        )}
        {activePath !== REC_ENGINE_ROUTES.EXPERIENCE && (
          <div className={styles.experienceTag}>
            <Link to={`${urlPrefix}/experience`} className={styles.editLink} onClick={handleEditClick}>
              <span key="edit-link">{intl.formatMessage({ id: 'layout.header.editProfile' })}</span>
              <EditIcon />
            </Link>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

HeaderExperienceLine.propTypes = {
  activePath: PropTypes.string,
};

export default RecommendationsHeader;
