import { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import filterStyles from 'styles/filter-input.module.scss';

/* API */
import { apiRequest } from 'shared/API';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import {
  setCareerPathsFilterValues,
  setDistanceFilterValue,
  setDurationFilterValues,
  setFilterOptions,
  setKeywordFilter,
  setLocationsFilterValues,
  setZipcodeFilterValue,
} from 'app/slices/trainingSlice';
import { useInfiniteTrainings, useRateTraining, useInfiniteRatings } from 'shared/hooks';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';

/* Utils */
import { debounce } from 'lib/performance';

/* Material UI and other UI Dependencies */
import { Box, Typography, Container, Dialog, Button, CircularProgress } from '@mui/material';
import ScrollToTopButton from 'components/ScrollToTopButton';
import {
  Tag,
  RecommendationsCard,
  CustomNotification,
  TrainingFiltersV2,
  KeywordSearchInput,
  FiltersPopper,
  TrainingNotificationContents,
} from 'shared/components';

import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';

const Training = () => {
  const { enqueueSnackbar } = useSnackbar();
  const searchInputEl = useRef(null);
  const smallScreen = useMediaQuery('(max-width:599px)');
  const intl = useIntl();
  const ratingsQuery = useInfiniteRatings({});
  const allRatings = ratingsQuery.isSuccess ? ratingsQuery.data.pages.flat(1) : [];
  const [rated, setRated] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();

  const {
    pageSize,
    keywordFilter,
    filterOptions,
    locationsFilterValues,
    careerPathsFilterValues,
    distanceFilterValue,
    durationFilterValues,
    zipcodeFilterValue,
  } = useSelector((state) => state.training);
  const zipcode = useSelector((state) => state.experience.form.zipcode);
  const { loadingLanguage } = useSelector((state) => state.app);
  const [anchorEl, setAnchorEl] = useState(null);

  const filters = {
    filter_by_name: keywordFilter,
    filter_by_county: locationsFilterValues,
    filter_by_soc: careerPathsFilterValues,
    distance_max: distanceFilterValue,
    zipcode: zipcodeFilterValue,
    duration_min: durationFilterValues[0],
    duration_max: durationFilterValues[1],
  };

  const { isSuccess, data, isFetching, isFetched, hasNextPage, fetchNextPage, failureReason } =
    useInfiniteTrainings(filters);
  const filtersQuery = useQuery({
    queryKey: [
      '/users/me/trainings_filters',
      {
        params: {
          ...(careerPathsFilterValues.length > 0
            ? { filter_by_soc: careerPathsFilterValues.map((c) => c.soc).join('|') }
            : {}),
          ...(locationsFilterValues && locationsFilterValues.length
            ? { filter_by_county: locationsFilterValues.join('|') }
            : {}),
          ...(durationFilterValues[0] > 0 ? { duration_min: durationFilterValues[0] } : {}),
          ...(durationFilterValues[1] > 0 ? { duration_max: durationFilterValues[1] } : {}),
          ...(distanceFilterValue !== 100 && zipcodeFilterValue?.length === 5
            ? { distance_max: distanceFilterValue, zipcode: zipcodeFilterValue }
            : {}),
          ...(keywordFilter && keywordFilter.length ? { filter_by_name: keywordFilter } : {}),
        },
      },
    ],
    queryFn: async ({ queryKey }) => apiRequest('GET', queryKey[0], queryKey[1]),
    onSuccess: (data) => {
      dispatch(
        setFilterOptions({
          minDuration: data.duration_min,
          maxDuration: data.duration_max,
          locations: data.counties,
          careerPaths: data.career_paths,
          minDistance: Math.floor(data.distance_min),
          maxDistance: Math.ceil(data.distance_max),
        })
      );
      if (data.distance_max && data.distance_max !== 100 && distanceFilterValue === 100) {
        dispatch(setDistanceFilterValue(data.distance_max));
      }
      if (zipcodeFilterValue === '' && zipcode !== '') {
        dispatch(setZipcodeFilterValue(zipcode));
      }
    },
  });
  const rateTrainingMutation = useRateTraining(filters);

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [resetCounter, setResetCounter] = useState(0);

  const debouncedSetKeywordFilter = debounce((val) => dispatch(setKeywordFilter(val)), 400);

  const filtersActive =
    !filtersQuery.isLoading &&
    (careerPathsFilterValues.length > 0 ||
      locationsFilterValues.length > 0 ||
      keywordFilter.length > 0 ||
      durationFilterValues[0] !== 0 ||
      durationFilterValues[1] !== 0 ||
      (zipcodeFilterValue?.length === 5 && distanceFilterValue !== filterOptions.maxDistance));

  const baseTrainingList = isSuccess ? data.pages.flat(1) : [];
  const displayedTrainings = baseTrainingList.slice(0, pageSize);

  /**
   * This additional response items will be used with the 'ShowMore' button
   * if there are no filters applied
   */
  const more = baseTrainingList.slice(pageSize);

  const showMore = () => {
    trackEvent('TRAININGS_MORE');
    fetchNextPage();
  };

  const removeCareerFilter = (career) => {
    const newActiveCareers = careerPathsFilterValues.filter((f) => f.soc !== career.soc);
    dispatch(setCareerPathsFilterValues(newActiveCareers));
  };

  const removeCountyFilter = (county) => {
    const newCountyFilter = locationsFilterValues.filter((c) => c !== county);
    dispatch(setLocationsFilterValues(newCountyFilter));
  };

  const removeDurationFilter = (idx) => (e) => {
    e.preventDefault();
    const newDurationFilter = durationFilterValues.slice(0);
    newDurationFilter[idx] = 0;
    dispatch(setDurationFilterValues(newDurationFilter));
  };

  const clearAllFilters = () => {
    dispatch(setCareerPathsFilterValues([]));
    dispatch(setLocationsFilterValues([]));
    dispatch(setDistanceFilterValue(filterOptions.maxDistance));
    dispatch(setDurationFilterValues([0, 0]));
    dispatch(setKeywordFilter(''));
    searchInputEl.current.value = '';
    setResetCounter(resetCounter + 1);
  };

  const handleGlobalLikeClick = (training) => () => {
    const newRating = training.rating === 1 ? 0 : 1;
    setRated(true);
    rateTrainingMutation.mutate(
      { training, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
        onSuccess: () => {
          enqueueSnackbar('', {
            variant: 'default',
            content: (key) => (
              <CustomNotification id={key} check>
                <TrainingNotificationContents rate="like" intl={intl} history={history} />
              </CustomNotification>
            ),
          });
        },
      }
    );
    trackEvent('TRAINING_GLOBAL_THUMBSUP', training);
  };

  const handleGlobalDislikeClick = (training) => () => {
    const newRating = training.rating === -1 ? 0 : -1;
    setRated(true);
    rateTrainingMutation.mutate(
      { training, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
        onSuccess: () => {
          enqueueSnackbar('', {
            variant: 'default',
            content: (key) => (
              <CustomNotification id={key} check>
                <TrainingNotificationContents rate="dislike" intl={intl} history={history} />
              </CustomNotification>
            ),
          });
        },
      }
    );
    trackEvent('TRAINING_GLOBAL_THUMBSDOWN', training);
  };

  const handleGlobalApplyClick = (item) => () => {
    trackEvent('TRAINING_GLOBAL_APPLY', item);
  };

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('TRAINING');
    TimeMe.startTimer();
    return () => {
      console.log('Leaving Training');
    };
  }, []);

  const handleFiltersButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setFiltersModalOpen(!filtersModalOpen);
  };

  const handleChangeDurationFilterValues = (event, newValue) => {
    dispatch(setDurationFilterValues(newValue));
  };

  const handleChangeLocationsFilterValues = (event, newValue) => {
    dispatch(setLocationsFilterValues(newValue));
  };

  const handleChangeCareerPathsFilterValues = ({ target: { value: newValue } }) => {
    trackEvent('TRAINING_FILTER_CAREERPATHS');
    dispatch(setCareerPathsFilterValues(newValue));
  };

  const handleChangeDistanceFilterValue = (event, newValue) => {
    dispatch(setDistanceFilterValue(newValue));
  };
  const handleChangeZipcodeFilterValue = (value) => {
    dispatch(setZipcodeFilterValue(value));
  };

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <div className={styles.titleFilters}>
            <Typography variant="h1" component="h2">
              {intl.formatMessage(
                { id: baseTrainingList.length === 1 ? 'training.titleSingular' : 'training.title' },
                { nTrainings: baseTrainingList.length }
              )}
            </Typography>
            <div className={cn(styles.autocompleteContainer, styles.autocompleteModernContainer)}>
              <KeywordSearchInput
                keywordFilter={keywordFilter}
                setKeywordFilter={debouncedSetKeywordFilter}
                uncontrolled
                inputRef={searchInputEl}
              />
              <FiltersPopper
                id="filters-popper"
                setOpen={setFiltersModalOpen}
                open={filtersModalOpen && !smallScreen}
                anchorEl={anchorEl}
                onButtonClick={handleFiltersButtonClick}
              >
                <TrainingFiltersV2
                  popper
                  handleClose={() => setFiltersModalOpen(false)}
                  handleReset={clearAllFilters}
                  // Duration
                  minDuration={filterOptions.minDuration}
                  maxDuration={filterOptions.maxDuration}
                  durationFilterValues={durationFilterValues}
                  handleChangeDurationFilterValues={handleChangeDurationFilterValues}
                  // Location
                  locationFilterTitle="training.filters.county"
                  locations={filterOptions.locations}
                  locationsFilterValues={locationsFilterValues}
                  handleChangeLocationsFilterValues={handleChangeLocationsFilterValues}
                  // Career paths
                  careerPaths={filterOptions.careerPaths}
                  careerPathsFilterValues={careerPathsFilterValues}
                  handleChangeCareerPathsFilterValues={handleChangeCareerPathsFilterValues}
                  // Distance
                  minDistance={filterOptions.minDistance}
                  maxDistance={filterOptions.maxDistance}
                  distanceFilterValue={distanceFilterValue}
                  handleChangeDistanceFilterValue={handleChangeDistanceFilterValue}
                  zipcode={zipcodeFilterValue}
                  zipcodeFailure={failureReason?.message?.includes('zip code')}
                  handleChangeZipcodeFilterValue={handleChangeZipcodeFilterValue}
                />
              </FiltersPopper>
            </div>
          </div>
          <div className={filterStyles.tags}>
            {!loadingLanguage && filtersActive ? (
              <>
                <hr className={layoutStyles.separator} style={{ marginBottom: '10px' }} />
                <Typography variant="h5">{intl.formatMessage({ id: 'recommendations.filterBy' })}</Typography>
                {durationFilterValues[0] !== 0 && durationFilterValues[0] !== durationFilterValues[1] && (
                  <Tag
                    text={`${intl.formatMessage({ id: 'jobs.filters.from' })} ${intl.formatNumber(
                      durationFilterValues[0]
                    )} ${
                      durationFilterValues[0] === 1
                        ? intl.formatMessage({ id: 'training.duration.month' })
                        : intl.formatMessage({ id: 'training.duration.months' })
                    }`}
                    onClick={removeDurationFilter(0)}
                    type="filter"
                  />
                )}
                {durationFilterValues[1] !== 0 && durationFilterValues[0] !== durationFilterValues[1] && (
                  <Tag
                    text={`${intl.formatMessage({ id: 'training.filters.upTo' })} ${intl.formatNumber(
                      durationFilterValues[1]
                    )} ${
                      durationFilterValues[1] === 1
                        ? intl.formatMessage({ id: 'training.duration.month' })
                        : intl.formatMessage({ id: 'training.duration.months' })
                    }`}
                    onClick={removeDurationFilter(1)}
                    type="filter"
                  />
                )}
                {durationFilterValues[1] !== 0 && durationFilterValues[0] === durationFilterValues[1] && (
                  <Tag
                    text={`${intl.formatNumber(durationFilterValues[1])} ${
                      durationFilterValues[1] === 1
                        ? intl.formatMessage({ id: 'training.duration.month' })
                        : intl.formatMessage({ id: 'training.duration.months' })
                    }`}
                    onClick={() => {
                      dispatch(setDurationFilterValues([0, 0]));
                    }}
                    type="filter"
                  />
                )}
                {careerPathsFilterValues.map((f) =>
                  f ? <Tag key={f.soc} text={f.title} onClick={() => removeCareerFilter(f)} type="filter" /> : null
                )}
                {locationsFilterValues.map((c) => (
                  <Tag key={c} text={c} onClick={() => removeCountyFilter(c)} type="filter" />
                ))}
                {!filtersQuery.isLoading &&
                  zipcodeFilterValue?.length === 5 &&
                  distanceFilterValue !== filterOptions.maxDistance && (
                    <Tag
                      text={`${intl.formatMessage({
                        id: 'jobs.filters.distanceTag',
                      })} <= ${distanceFilterValue} mi ${intl.formatMessage({
                        id: 'jobs.filters.from',
                      })} ${zipcodeFilterValue}`}
                      onClick={() => dispatch(setDistanceFilterValue(filterOptions.maxDistance))}
                      type="filter"
                    />
                  )}
                {keywordFilter.length > 0 && (
                  <Tag
                    text={keywordFilter}
                    onClick={() => {
                      dispatch(setKeywordFilter(''));
                      searchInputEl.current.value = '';
                    }}
                    type="filter"
                  />
                )}
                <span
                  className={cn(layoutStyles.link, filterStyles.clearAll)}
                  onClick={() => {
                    clearAllFilters();
                    setFiltersModalOpen(false);
                  }}
                >
                  {intl.formatMessage({ id: 'recommendations.clearAllFilters' })}
                </span>
              </>
            ) : null}
          </div>
          <Box m={1} />
          <div className={styles.header__content}>
            <hr className={layoutStyles.separator} />
            <Box m={3} />
            {(isFetching && !isFetched) || loadingLanguage ? (
              <Box m={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : null}
            {!loadingLanguage && isFetched && displayedTrainings.length === 0 && (
              <Typography variant="body2">{intl.formatMessage({ id: 'training.filters.noResults' })}</Typography>
            )}
            {!loadingLanguage &&
              isFetched &&
              displayedTrainings.map((item, idx) => (
                <RecommendationsCard
                  key={`${item.id}-${idx}`}
                  type="training"
                  item={item}
                  link={item.link}
                  rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
                  onButtonClick={handleGlobalApplyClick(item)}
                  onLikeClick={handleGlobalLikeClick(item)}
                  onDislikeClick={handleGlobalDislikeClick(item)}
                  rangeLabel={intl.formatMessage({ id: 'training.averagePay', description: 'string' })}
                  hideRateTooltip={allRatings.length > 0 || rated}
                />
              ))}
            {!loadingLanguage &&
              isFetched &&
              more.length > 0 &&
              more.map((item, idx) => (
                <RecommendationsCard
                  key={`${item.id}-${idx}`}
                  type="training"
                  item={item}
                  link={item.link}
                  rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
                  onButtonClick={handleGlobalApplyClick(item)}
                  onLikeClick={handleGlobalLikeClick(item)}
                  onDislikeClick={handleGlobalDislikeClick(item)}
                  rangeLabel={intl.formatMessage({ id: 'training.averagePay', description: 'string' })}
                  hideRateTooltip={allRatings.length > 0 || rated}
                />
              ))}
            <div className={styles.bottomBar}>
              <span className={styles.backToTopPlaceholder} />
              {!loadingLanguage && isFetched && hasNextPage && (
                <Button
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={showMore}
                  disabled={isFetching}
                >
                  {intl.formatMessage({ id: 'recommendations.trainings.showMore', description: 'string' })}
                </Button>
              )}
              <ScrollToTopButton toTopEvent="TRAINING_TOP" />
            </div>
          </div>
        </Container>
      </div>
      <Dialog
        maxWidth="xs"
        open={filtersModalOpen && smallScreen}
        onClose={() => setFiltersModalOpen(false)}
        fullWidth
        aria-labelledby="max-width-dialog-title"
      >
        <TrainingFiltersV2
          handleClose={() => setFiltersModalOpen(false)}
          handleReset={clearAllFilters}
          // Duration
          minDuration={filterOptions.minDuration}
          maxDuration={filterOptions.maxDuration}
          durationFilterValues={durationFilterValues}
          handleChangeDurationFilterValues={handleChangeDurationFilterValues}
          // Location
          locationFilterTitle="training.filters.county"
          locations={filterOptions.locations}
          locationsFilterValues={locationsFilterValues}
          handleChangeLocationsFilterValues={handleChangeLocationsFilterValues}
          // Career paths
          careerPaths={filterOptions.careerPaths}
          careerPathsFilterValues={careerPathsFilterValues}
          handleChangeCareerPathsFilterValues={handleChangeCareerPathsFilterValues}
          // Distance
          minDistance={filterOptions.minDistance}
          maxDistance={filterOptions.maxDistance}
          distanceFilterValue={distanceFilterValue}
          handleChangeDistanceFilterValue={handleChangeDistanceFilterValue}
          zipcode={zipcodeFilterValue}
          zipcodeFailure={failureReason?.message?.includes('zip code')}
          handleChangeZipcodeFilterValue={handleChangeZipcodeFilterValue}
        />
      </Dialog>
    </>
  );
};

Training.propTypes = {
  width: PropTypes.string,
};

export default Training;
