import { useState } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Button, Box, TextField } from '@mui/material';

/* i18n Dependencies */
import { useIntl } from 'react-intl';
import definition from 'lib/survey_definitions/customerSatisfactionSurvey.json';

/* API */
import { postProfile } from 'lib/API';

/* Styles */
import styles from 'styles/survey.module.scss';

const typesDict = {
  readonly: Readonly,
  radiogroup: Radiogroup,
  text: Text,
};

function ChatSurvey({ completeRef, setSurveyComplete }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [answers, setAnswers] = useState({});
  const [tempQuestionState, setTempQuestionState] = useState('');
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const postAnswer = (values) => {
    return postProfile({
      action: 'survey',
      ...values,
    });
  };

  return (
    <div className={styles.surveyContainer}>
      {completeRef.current ? (
        <Box>
          <span className={styles.intro}>{intl.formatMessage({ id: definition.bye_message })}</span>
        </Box>
      ) : (
        <>
          {definition.pages[currentPage].elements.map(({ type, ...rest }) => {
            return typesDict[type]({
              key: rest.name,
              currentPage,
              setCurrentPage,
              answers,
              setAnswers,
              setSurveyComplete,
              tempQuestionState,
              setTempQuestionState,
              postAnswer,
              loading,
              setLoading,
              lastQuestion: rest.last,
              ...rest,
            });
          })}
          <LinearProgress variant="determinate" value={(currentPage / definition.pages.length) * 100} />
        </>
      )}
    </div>
  );
}

ChatSurvey.propTypes = {
  completeRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  setSurveyComplete: PropTypes.func,
};

export default ChatSurvey;

function Readonly({ value }) {
  const intl = useIntl();
  return (
    <Box mb={2}>
      <span className={styles.intro}>{intl.formatMessage({ id: value })}</span>
    </Box>
  );
}

Readonly.propTypes = {
  value: PropTypes.string,
};

function Radiogroup({ name, title, choices, postAnswer, loading, setLoading, ...props }) {
  const intl = useIntl();
  const handleClick = (value) => () => {
    props.setAnswers({ ...props.answers, [name]: value });
    setLoading(true);
    postAnswer({ question: name, answer: value })
      .then(function () {
        if (props.lastQuestion) {
          props.setSurveyComplete(true);
        } else {
          props.setCurrentPage(props.currentPage + 1);
        }
        setLoading(false);
      })
      .catch(function () {
        setLoading(false);
      });
  };
  return (
    <Box mb={2}>
      <span className={styles.questionTitle}>{intl.formatMessage({ id: title })}</span>
      <Box mt={2}>
        {choices.map((choice) => (
          <div key={choice.value}>
            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              style={{ marginBottom: 4, marginRight: 4 }}
              onClick={handleClick(choice.value)}
            >
              {intl.formatMessage({ id: choice.text })}
            </Button>
            <br />
          </div>
        ))}
      </Box>
    </Box>
  );
}

Radiogroup.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.number,
    })
  ),
  postAnswer: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  setAnswers: PropTypes.func,
  lastQuestion: PropTypes.bool,
  answers: PropTypes.object,
  setSurveyComplete: PropTypes.func,
  setCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
};

function Text({ name, title, postAnswer, loading, setLoading, ...props }) {
  const intl = useIntl();

  const handleClick = () => {
    props.setAnswers({ ...props.answers, [name]: props.tempQuestionState });
    setLoading(true);
    postAnswer({ question: name, answer: props.tempQuestionState })
      .then(function () {
        if (props.lastQuestion) {
          props.setSurveyComplete(true);
        } else {
          props.setCurrentPage(props.currentPage + 1);
        }
        setLoading(false);
      })
      .catch(function () {
        setLoading(false);
      });
  };
  return (
    <Box mb={2}>
      <span className={styles.questionTitle}>{intl.formatMessage({ id: title })}</span>
      <Box mt={2}>
        <TextField
          id="filled-textarea"
          multiline
          rows={3}
          disabled={loading}
          variant="outlined"
          InputProps={{ style: { fontSize: 16, height: '90px' } }}
          value={props.tempQuestionState}
          onChange={(e) => props.setTempQuestionState(e.target.value)}
          name="notes"
          inputProps={{
            title: intl.formatMessage({ id: 'jobSearchActivity.form.notes' }),
          }}
        />
        <Button
          color="primary"
          variant="contained"
          disabled={loading}
          style={{ marginBottom: 4, marginRight: 4 }}
          onClick={handleClick}
        >
          {intl.formatMessage({ id: 'forms.resetPassword.submit' })}
        </Button>
      </Box>
    </Box>
  );
}

Text.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  tempQuestionState: PropTypes.string,
  postAnswer: PropTypes.func,
  loading: PropTypes.bool,
  lastQuestion: PropTypes.bool,
  setLoading: PropTypes.func,
  setAnswers: PropTypes.func,
  setSurveyComplete: PropTypes.func,
  setCurrentPage: PropTypes.func,
  setTempQuestionState: PropTypes.func,
  currentPage: PropTypes.number,
  answers: PropTypes.object,
};
